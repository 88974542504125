import React from 'react';

const PaginationComponent = ({ currentPage, totalPages, handlePageChange, visiblePages = 3 }) => {
  const getVisiblePageNumbers = () => {
    const halfVisible = Math.floor(visiblePages / 2);
    let start = Math.max(1, currentPage - halfVisible);
    let end = Math.min(totalPages, start + visiblePages - 1);

    if (end - start + 1 < visiblePages) {
      start = Math.max(1, end - visiblePages + 1);
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  const visiblePageNumbers = getVisiblePageNumbers();

  return (
    <div className="custom-sm:hidden custom-md:block">
      {totalPages > 0 && (
        <>
          {/* Show left ellipsis if there are earlier pages */}
          {visiblePageNumbers[0] > 1 && (
            <span key="left-ellipsis" className="text-[#091E42] px-3 py-2 mx-1">
              ...
            </span>
          )}

          {/* Display page numbers */}
          {visiblePageNumbers.map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber)}
              className={`w-[40px] h-[40px] px-3 py-2 mx-1 rounded-[8px] text-[14px] ${
                currentPage === pageNumber
                  ? 'border border-[#197FF5] bg-[#E9F3FF] text-[#667085]'
                  : 'text-[#091E42]'
              }`}
            >
              {pageNumber}
            </button>
          ))}

          {/* Show right ellipsis if there are more pages */}
          {visiblePageNumbers[visiblePageNumbers.length - 1] < totalPages && (
            <span key="right-ellipsis" className="text-[#091E42] px-3 py-2 mx-1">
              ...
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default PaginationComponent;