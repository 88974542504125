import {
    clevercruitlogo,
    expired,
    linkexpiredbg,
} from '../assets/imgs';


export const LinkExpired = () => {
    const currentDate = new Date().toISOString().split("T")[0].replace(/-/g, "/");

    const [year, month, day] = currentDate.split("/");
    const formateDate = `${day}/${month}/${year}`;

    return (
        <main
            className=" flex flex-col text-[#1B3351] items-center justify-around"
            style={{
                backgroundImage: `url(${expired})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                backgroundRepeat:'no-repeat'
            }}
        >
            <section className="flex flex-col items-center">
                <div className="absolute flex items-start" style={{ top: '8%', left: '4%' }}>
                    <img
                        src={clevercruitlogo}
                        alt="clevercruit logo"
                        className="w-[11vw]"
                    />
                    <div className="text-[1.5vw] font-satoshi font-[300] ml-2">
                        <span className='flex'>
                            AI video interview - <div className='font-[500]'> {formateDate}</div>
                        </span>
                    </div>
                </div>
                <p className="text-[4.306vw] font-semibold absolute bottom-[23%]">
                    <i>
                        <b>URL Link Expired !</b>
                    </i>
                </p>
                <div className="absolute bottom-[18%] text-[1.944vw]">
                    For Continue The Interview Please Connect To The HR
                </div>
            </section>
        </main>
    );
};