import React from "react";
import { loaderIcon } from "../assets/imgs/index";
import "../assets/css/style.css"

// export const Loader = () => {
//   return (
//     <section className="fixed left-0 top-0 w-full h-full z-50 flex items-center justify-center backdrop-blur">
//       <img className="h-52" src={loaderIcon} alt="..." />
//     </section>
//   );
// };

// export const Loader = () => {
//   return (
//     <section className="ai-loder fixed left-0 top-0 w-full h-full z-50 flex items-center justify-center backdrop-blur">
//       <div className="max-w-300">
//           <div className="">
//             <div className="absolute w-[250px] h-[30px] bg-[#505050] rounded-lg mt-[40px] ml-[-10px] z-[-1]"></div>
//              <div className="w-[230px] h-[110px] bg-[#0875F4] rounded-full flex justify-center items-center z-10">
//                 <div className="w-[180px] h-[80px] bg-[#505050] rounded-full flex items-center">
//                   <div className="relative  ball-1 w-[40px] h-[40px] bg-white rounded-full"></div>
//                   <div className="relative  ball-2 w-[40px] h-[40px] bg-white rounded-full"></div>
//                 </div>
//              </div>
//           </div>
//       </div>
//     </section>
//   );
// };


export const Loader = () => {
  return (
    <section className="ai-loder-2 fixed left-0 top-0 w-full h-full z-50 flex items-center justify-center backdrop-blur">
      <div className="max-w-300">
          <div className="">
            <div className="relative ear-component z-[3]">
              <div className="relative z-[0]">
                <div className="absolute ear-1 w-[25px] h-[35px] bg-[#505050] rounded-t-full  rounded-b-full z-[-1] left-[-30px] top-[35px]"></div>
                <div className="absolute ear-2 w-[25px] h-[35px] bg-[#505050] rounded-t-full  rounded-b-full z-[-1] right-[-30px] top-[35px]"></div>
              </div>
              <div className="absolute  w-[180px] h-[80px] bg-[#505050] rounded-full top-[15px] left-[25px]"></div>
              <div className="absolute flex items-center ball-component z-10 top-[35px]">
                    <div className="relative  ball-1 w-[40px] h-[40px] left-[55px] bg-white rounded-full"></div>
                    <div className="relative  ball-2 w-[40px] h-[40px] right-[-95px] bg-white rounded-full"></div>
              </div>
            </div>
             <div className="relative w-[230px] h-[110px] bg-[#0875F4] rounded-full flex justify-center items-center z-[1]">
             </div>
          </div>
      </div>
    </section>
  );
};



// export const Loader = ({ width = 80, height = 40 }) => {
//   return (
//     <svg width={width} height={height} viewBox="0 0 80 40" xmlns="http://www.w3.org/2000/svg">
//       <rect x="0" y="0" width="80" height="40" rx="20" ry="20" fill="#1a73e8" />
//       <circle cx="25" cy="20" r="8" fill="white">
//         <animate 
//           attributeName="opacity"
//           values="1;0.5;1" 
//           dur="2s"
//           repeatCount="indefinite"
//         />
//       </circle>
//       <circle cx="55" cy="20" r="8" fill="white">
//         <animate 
//           attributeName="opacity"
//           values="1;0.5;1" 
//           dur="2s"
//           repeatCount="indefinite"
//           begin="1s"
//         />
//       </circle>
//     </svg>
//   );
// };
