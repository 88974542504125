import { useEffect, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { closeButton, close } from '../../assets/imgs';
import { validatePassword } from '../../utils/validation';
import { useNavigate, useParams } from 'react-router-dom';
import { handleApiResponse } from '../../API/services';
import e from 'cors';

export const SetPassword = ({ setShowSetPassword, setEmail }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [cpassword, setCPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [conpasswordError, setConPasswordError] = useState('');
  const [valid, setValid] = useState(false);
  const urlData = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyLinkExist = async () => {
      console.log(urlData.token);
      try {
        const response = await handleApiResponse(
          '/api/v1/auth/view_member_link/',
          {
            member_token: urlData.token,
          }
        );
        if (response?.code === 'token_not_valid') {
          navigate('/');
          sessionStorage.clear();
          return;
        }
        console.log('API Response:', response, response.data);
        if (response.status_code === 200) {
          setValid(true);
        } else if (response.status_code === 400) {
          navigate('/not-found');
        }
      } catch (error) {
        console.error('API Error:', error);
      }
    };

    verifyLinkExist();
  }, [urlData]);

  const sendPass = async () => {
    setPasswordError('');
    setConPasswordError('');
    setError('');
    if (!password && !cpassword) {
      setPasswordError('Please enter the password');
      setConPasswordError('Please enter the confirm password');
      return;
    }
    if (!cpassword) {
      setConPasswordError('Please enter the confirm password');
      return;
    }
    if (!password) {
      setPasswordError('Please enter the password');
      return;
    }
    if (password !== cpassword) {
      setError("Passwords doesn't match");
      return;
    } else {
      const reqData = {
        member_token: urlData.token,
        password: password,
        cpassword: cpassword,
      };

      const response = await handleApiResponse(
        '/api/v1/auth/add_member_credentials/',
        reqData
      );
      if (response?.code === 'token_not_valid') {
        navigate('/');
        sessionStorage.clear();
        return;
      }
      console.log(response.data);
      if (response.status_code === 200) {
        navigate('/');
      } else {
        toast.error('Link can only be used once.', {
          className: "custom-toast-error"
        });
        navigate('/');
      }
    }
  };

  const handelSetPass = (e) => {
    const { name, value } = e.target;
    if (value.trim().length === 0) {
      return;
    }

    const isValid = validatePassword(value);
    if (name === 'password1') {
      setPassword(value);
      if (isValid.status) {
        setPasswordError('');
      } else {
        setPasswordError(isValid.msg);
        console.log('error');
      }
    } else {
      setCPassword(value);
      if (isValid.status) {
        setConPasswordError('');
      } else {
        setConPasswordError(isValid.msg);
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPassVisibility = () => {
    setShowConPassword(!showConPassword);
  };
  if (valid) {
    return (
      <>
        <div
          id="popup-modal"
          tabindex="-1"
          className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[100%] max-h-full bg-[#000000B2]"
        >
          <div className="relative p-4 w-full max-w-lg ">
            <div className="relative bg-white rounded-lg shadow p-5">
              <div className="absolute cursor-pointer right-4">
                <img src={close} alt="close button" />
              </div>

              <div className="p-4 md:p-5 text-center bg-[#fff] rounded-lg">
                <div>
                  <h3 className="mt-5 mb-3 capitalize font-sans1 font-semibold text-[#00112D] text-[1.3rem]">
                    {' '}
                    set a new password{' '}
                  </h3>
                </div>

                <div className="flex flex-col text-left mt-5 mb-3">
                  <div>
                    <label className=" text-[#00112D] text-[0.8rem] font-sans1 font-light">
                      Password<span className="ml-2 text-red-700">*</span>
                    </label>
                    <div className="relative">
                      <input
                        className="p-2 mt-1 h-[40px] border-[1px] border-[#22284E] outline-none w-full rounded-lg text-[#00112D] text-[0.8rem] font-sans1 font-light "
                        name="password1"
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Enter password"
                        onChange={(e) => {
                          handelSetPass(e);
                        }}
                      />
                      <button
                        onClick={togglePasswordVisibility}
                        className="absolute inset-y-0 text-[#22284E] right-0 h-full flex items-center px-2"
                      >
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </button>
                    </div>
                  </div>

                  <div className="mt-5">
                    <label className=" text-[#00112D] text-[0.8rem] font-sans1 font-light">
                      Confirm password
                      <span className="text-red-700 ml-2">*</span>
                    </label>
                    <div className="relative">
                      <input
                        className="p-2 mt-1 h-[40px] border-[1px] border-[#22284E] outline-none w-full rounded-lg text-[#00112D] text-[0.8rem] font-sans1 font-light "
                        name="password2"
                        type={showConPassword ? 'text' : 'password'}
                        placeholder="Enter confirm password"
                        onChange={(e) => {
                          handelSetPass(e);
                        }}
                      />
                      <button
                        onClick={toggleConfirmPassVisibility}
                        className="absolute inset-y-0  text-[#22284E] right-0 h-full flex items-center px-2"
                      >
                        {showConPassword ? <FaEye /> : <FaEyeSlash />}
                      </button>
                    </div>
                  </div>
                </div>

                {conpasswordError && (
                  <p className="text-red-500">{conpasswordError}</p>
                )}

                {error && <p className="text-red-500">{error}</p>}

                <div className="flex justify-between items-center mt-5">
                  <button
                    className={`w-[100%] text-[#fff] bg-[#00112D]  border-[#00112D] border-solid border-[1px] font-satoshi font-medium rounded-lg text-[1rem] inline-flex items-center px-5 py-2.5 justify-center
                  }`}
                    onClick={sendPass}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  if (!valid) {
    navigate('/not-found');
  }
};
