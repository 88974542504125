import React, { useEffect, useState, useRef } from 'react';
import {
  arrowIcon,
  Ellipse,
  deleteIcon,
  editIcon,
  userImg,
  searchIcon,
} from '../../assets/imgs/index';
import { ViewCampaignTable } from '../../components/AdminComponents/table';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { handleApiResponse } from '../../API/services';
import { CustomButtonWhite, CustomButtonWhite5 } from '../../components/buttons';
import { toast } from 'react-toastify';
import { Pagination } from '../../features/Pagination';
import { getLocal } from '../../utils/localStorage';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import isEqual from 'lodash/isEqual';
import { DropdownFilter } from '../../components/AdminComponents/dropdown';

const base_url = process.env.REACT_APP_FRONEND;

function ViewCampaigns() {
  const [selectedStatus, setSelectedStatus] = useState('');
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [isLeftOption, setIsLeftOption] = useState(() => {
    const storedValue = sessionStorage.getItem('isLeftOption');
    return storedValue !== null ? JSON.parse(storedValue) : true;
  });
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);
  const itemsPerPage = 20;
  const location = useLocation();
  console.log(location.state)
  const [search, setSearch] = useState('');
  const [fileBlob, setFileBlob] = useState(null);
  // console.log(data);
  const campUid = location?.state?.uid;
  const campType = location?.state?.campaign_type;
  const campName = location?.state?.name;
  const jr_name = location?.state?.jr_name;
  const status = location?.state?.status;
  const start_date = location?.state?.start_date;
  const end_date = location?.state?.end_date;
  const campaign_type = location?.state?.campaign_type;
  const name = location?.state?.name;
  const [tableData, setTableData] = useState([]);
  const [candidateId, setCandidateId] = useState([]);
  const [campTypeLocal, setCampTypeLocal] = useState(
    sessionStorage.getItem("campType")
  );
  const [downloadLinksClicked, setDownloadLinksClicked] = useState(false);
  console.log(campUid);
  console.log(campType);
  const [counts, setCounts] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);  // Toggle the dropdown visibility
  };


  const useDeepCompareEffect = (callback, dependencies) => {
    const currentDependenciesRef = useRef();

    if (!isEqual(currentDependenciesRef.current, dependencies)) {
      currentDependenciesRef.current = dependencies;
    }

    useEffect(callback, [currentDependenciesRef.current]);
  };

  //   const completedRows = searchResults?.length > 0
  //   ? searchResults.filter(rowData => getStatus(rowData.status) === "Completed")
  //   : currentPageData.filter(rowData => getStatus(rowData.status) === "Completed");

  // const completedCount = completedRows.length;

  // sessionStorage.setItem("campType", campType);

  useEffect(() => {
    sessionStorage.removeItem("uploadResume");
    sessionStorage.removeItem("excelData");
    sessionStorage.removeItem("prevEmail");
    sessionStorage.removeItem("singleAddCandidate");
    sessionStorage.removeItem("checkedFilteredData");
    sessionStorage.removeItem("hrQuestion");
    sessionStorage.removeItem("camp_type");
    sessionStorage.removeItem("coding_ques");
    sessionStorage.removeItem("endDate1");
    sessionStorage.removeItem("createdDate");
    sessionStorage.removeItem("skills");
    sessionStorage.removeItem("campName");
    sessionStorage.removeItem("JobRole");
    sessionStorage.removeItem("selectedCheckboxes");
    sessionStorage.removeItem("savedAllSelcted");
    sessionStorage.removeItem("JobRole");
    sessionStorage.removeItem("jrName");
    sessionStorage.removeItem("sortlist");
    sessionStorage.removeItem("sortedData");
    sessionStorage.removeItem("uploadResume")
  }, []);


  const callApi = async () => {
    let reqData;

    if (campType === 'Regular') {
      reqData = {
        campaign: campUid,
        is_selected: 1,
        is_deleted: 0,
      };
    } else if (campType === 'Mass') {
      reqData = {
        campaign: campUid,
      };
    } else {
      // Handle cases where campType is neither "Regular" nor "Mass"
      console.error('Unknown campType:', campType);
      return;
    }

    try {
      const response = await handleApiResponse(
        '/api/v1/interview/candidate_list/',
        reqData,
        1
      );
      if (response?.code === 'token_not_valid') {
        navigate('/');
        sessionStorage.clear();
        return;
      }
      console.log(response?.data?.data?.data);
      const newCounts = response?.data?.data?.data?.map(
        (item) => item?.linkinfo?.count
      );

      console.log(newCounts);

      setCounts(newCounts);
      setTableData(response?.data?.data?.data);
    } catch (error) {
      console.error('API call failed:', error);
      // Handle error, e.g., set an error state
    }
  };

  // console.log(tableData);
  useEffect(() => {
    callApi();
  }, []);

  useDeepCompareEffect(() => {
    callApi();
  }, [counts]);

  // const [campTypeLocal, setCampTypeLocal] = useState(sessionStorage.getItem('campType'));
  console.log(campUid);
  console.log(campType);
  // sessionStorage.setItem("campType", campType);

  useEffect(() => {
    setCampTypeLocal(sessionStorage.getItem("campType"));
  }, []);

  const tableData1 = [];
  const [searchResults, setSearchResults] = useState([]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const startIndex1 = (currentPage1 - 1) * itemsPerPage;
  const endIndex1 = startIndex1 + itemsPerPage;
  const [clicked, setClicked] = useState(false);
  // const currentPageData = tableData?.slice(startIndex, endIndex);
  const currentPageData = tableData
  const currentPageData1 = tableData1?.slice(startIndex1, endIndex1);
  const [redirectLink, setRedirectLink] = useState();
  const [bulkCountExists, setbulkCountExists] = useState(0);
  const [formattedQuestions, setFormattedQuestions] = useState([]);
  const [candidates, setCandidates] = useState([]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageChange1 = (pages) => {
    setCurrentPage1(pages);
  };

  const openModalEditJobRole = () => {
    setIsModalOpen4(true);
  };

  const closeModalEditJobRole = () => {
    setIsModalOpen4(false);
  };
  //   const completedRows = searchResults?.length > 0
  //   ? searchResults.filter(rowData => getStatus(rowData.status) === "Completed")
  //   : currentPageData.filter(rowData => getStatus(rowData.status) === "Completed");

  // const completedCount = completedRows.length;

  // const copyToClipboard = async (link) => {
  //   try {
  //     // Try using the Clipboard API first
  //     if (navigator.clipboard) {
  //       await navigator.clipboard.writeText(link);
  //       console.log("Link copied to clipboard:", link);
  //     } else {
  //       // Fallback to document.execCommand for older browsers
  //       const textarea = document.createElement("textarea");
  //       textarea.value = link;
  //       textarea.style.position = "absolute";
  //       textarea.style.left = "-9999px";

  //       document.body.appendChild(textarea);
  //       textarea.select();

  //       try {
  //         const successful = document.execCommand("copy");
  //         if (successful) {
  //           console.log("Link copied to clipboard:", link);
  //         } else {
  //           throw new Error("Failed to copy");
  //         }
  //       } catch (error) {
  //         console.error("Error copying link to clipboard:", error);
  //         let fallbackLink = `${base_url}/interview/candidate/fallback`;
  //     alert(`An error occurred. Please use this link: ${fallbackLink}`);
  //     copyToClipboard(fallbackLink);
  //       } finally {
  //         document.body.removeChild(textarea);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error copying link to clipboard:", error);
  //   }
  // };

  window.Clipboard = (function (window, document, navigator) {
    var textArea, copy;

    function isOS() {
      return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text) {
      textArea = document.createElement('textArea');
      textArea.value = text;
      document.body.appendChild(textArea);
    }

    function selectText() {
      var range, selection;

      if (isOS()) {
        range = document.createRange();
        range.selectNodeContents(textArea);
        selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
      } else {
        textArea.select();
      }
    }

    function copyToClipboard() {
      document.execCommand('copy');
      document.body.removeChild(textArea);
    }

    copy = function (text) {
      createTextArea(text);
      selectText();
      copyToClipboard();
    };

    return {
      copy: copy,
    };
  })(window, document, navigator);

  const copyToClipboard = async (link) => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(link);
        console.log('Link copied to clipboard:', link);
        toast.success('link copied', {
          className: "custom-toast-success"
        });
      } else {
        // Fallback for older browsers and Safari on iOS
        window.Clipboard.copy(link);
      }
    } catch (error) {
      console.error('Error copying link to clipboard:', error);
      alert(`Please use this link: ${link}`);
    }
  };

  const handleBulkCount = async () => {
    let data = { campaign_uid: campUid };
    const res = await handleApiResponse(
      '/api/v1/interview/get_bulk_link/',
      data,
      1
    );
    if (res?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    if (res?.status) {
      setbulkCountExists(res?.data?.data?.count);
    } else {
      setbulkCountExists(0);
    }
  };

  const getQuestions = async () => {
    const reqData = {
      campaign: campUid,
    };
    const response = await handleApiResponse(
      '/api/v1/interview/question/get_question/',
      reqData,
      1
    );
    if (response?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    if (response?.data?.status_code === 200) {
      console.log(response?.data?.data?.quest);
      setFormattedQuestions(response.data.data);
      // const questions = response.data.data.quest;

      // questions.forEach((item, index) => {
      //   console.log(`Question${index + 1}: ${item.question}`);
      //   console.log(`Answer${index + 1}: ${item.answer}`);
      // });

      // const formattedQuestions = questions.map((item, index) => ({
      //   question: `Question${index + 1}: ${item.question}`,
      //   answer: `Answer${index + 1}: ${item.answer}`,
      // }));

      // return formattedQuestions;
      // setIsUpdate(response?.data?.data?.uid);
      // console.log(response?.data?.data);
      // console.log(response?.data?.data?.quest_type);
      // setSelectedOption(`option${response?.data?.data?.quest_type}`);

      // const prevQuestion = response?.data?.data?.quest;
      // if (response?.data?.data?.quest_type === 1) {
      //   setQuestions1(prevQuestion);
      // } else if (response?.data?.data?.quest_type === 2) {
      //   const updatedQuestions = prevQuestion.map((item, index) => ({
      //     id: index + 1,
      //     name: `Question ${index + 1}`,
      //     question: item.question,
      //     answer: item.answer,
      //     QError: "",
      //     AError: "",
      //   }));
      //   setQuestions(updatedQuestions);
      // } else if (response?.data?.data?.quest_type === 3) {
      //   const updatedQuestions = prevQuestion.map((item, index) => ({
      //     id: index + 1,
      //     name: `Question ${index + 1}`,
      //     question: item.question,
      //     answer: item.answer,
      //     QError: "",
      //     AError: "",
      //     isHr: item?.isHr,
      //   }));
      //   setQuestions3(updatedQuestions);
      // }
    }
  };

  useEffect(() => {
    getQuestions();
  }, []);

  useEffect(() => {
    handleBulkCount();
  }, []);

  const handleBulkLink = async () => {
    try {
      let data = { campaign_uid: campUid };
      console.log(data);
      const response = await handleApiResponse(
        '/api/v1/interview/generate_bulk_link/',
        data,
        1
      );
      if (response?.code === 'token_not_valid') {
        navigate('/');
        sessionStorage.clear();
        return;
      }
      if (response?.data?.status_code === 200) {
        let link =
          `${base_url}/interview/candidate/` + response?.data?.data?.link;
        // navigator.clipboard.writeText(link)
        copyToClipboard(link);
        sessionStorage.setItem("linkType", "1");
        handleBulkCount();
        // setRedirectLink(link);
        setRedirectLink(link);
        // setCount((prev) => prev + 1);
      } else {
        toast.error(response?.data?.msg, {
          className: "custom-toast-error"
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getStatus = (isPerformanceGenerated) => {
    console.log(isPerformanceGenerated);
    if (isPerformanceGenerated === 1) {
      return 'Pending';
    } else if (isPerformanceGenerated >= 3) {
      return 'Completed';
    }
  };

  const handleCopyLink = async () => {
    try {
      let data = { campaign_uid: campUid };
      console.log(data);
      const response = await handleApiResponse(
        '/api/v1/interview/get_bulk_link/',
        data,
        1
      );
      if (response?.code === 'token_not_valid') {
        navigate('/');
        sessionStorage.clear();
        return;
      }
      console.log(response?.data);
      console.log(response?.data?.data?.link);
      let link =
        `${base_url}/interview/candidate/` + response?.data?.data?.link;
      copyToClipboard(link);
      setRedirectLink(link);
    } catch (e) {
      console.log(e);
      // let fallbackLink = `${base_url}/interview/candidate/fallback`;
      // alert(`An error occurred. Please use this link: ${fallbackLink}`);
      // copyToClipboard(fallbackLink);
    }
  };

  const sendMail = async () => {
    const reqData = {
      campaign_uid: campUid,
    };
    const response = await handleApiResponse(
      '/api/v1/interview/generate_unique_link/',
      reqData,
      1
    );
    if (response?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    console.log(response?.data);
    if (response?.data?.status_code === 200) {
      setClicked(true);
      toast.success('Email has been shared to the candidates.', {
        className: "custom-toast-success"
      });
    }
  };

  const handleSearch = async (searchTerm) => {
    const reqData = {
      campaign: campUid,
      q: searchTerm,
      is_deleted: 0,
      is_selected: 1
    };
    const response = await handleApiResponse(
      '/api/v1/interview/candidate_list/',
      reqData,
      1
    );
    if (response?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    console.log(response.data.data.data);
    setSearchResults(response.data.data.data);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      handleSearch();
    }
  };

  const completedRows =
    searchResults?.length > 0
      ? searchResults.filter(
        (rowData) => getStatus(rowData.status) === 'Completed'
      )
      : currentPageData.filter(
        (rowData) => getStatus(rowData.status) === 'Completed'
      );

  const completedCount = completedRows.length;

  const handleOptionClick = (status) => {
    setSelectedStatus(status);  // Update the state based on the selected option
    setIsOpen(false)
  };

  const downloadLinks = async () => {
    setDownloadLinksClicked(true);
    const reqData = {
      campaign: campUid,
      is_selected: 1,
    };
    const response = await handleApiResponse(
      '/api/v1/interview/candidate_list/',
      reqData,
      1
    );
    if (response?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    const candidateData = response?.data?.data?.data;
    console.log('candidateData', candidateData);
    if (candidateData && Array.isArray(candidateData)) {
      // Filter candidates where candidate?.linkinfo?.count is 0
      const filteredCandidates = candidateData.filter(
        (candidate) => candidate?.linkinfo?.count === 0
      );

      const linkInfoArray = filteredCandidates.map(
        (candidate) => candidate?.linkinfo?.link
      );
      console.log(linkInfoArray, 'linkinfo');

      if (linkInfoArray.some((link) => !link)) {
        // If any linkinfo is empty, perform the alternate request
        const newReqData = {
          campaign_uid: campUid,
        };

        const newResponse = await handleApiResponse(
          '/api/v1/interview/generate_unique_link/',
          newReqData,
          1
        );
        if (response?.code === 'token_not_valid') {
          navigate('/');
          sessionStorage.clear();
          return;
        }

        if (newResponse?.data?.status_code === 200) {
          const dataArray = newResponse?.data?.data;
          console.log(dataArray, 'dataArray');

          const formattedData = dataArray.map((item) => ({
            first_name: item.candidate.first_name,
            last_name: item.candidate.last_name,
            emailid: item.candidate.email,
            links:
              `${process.env.REACT_APP_FRONEND}/interview/candidate/` +
              item.link,
          }));

          const worksheet = XLSX.utils.json_to_sheet(formattedData);

          // Create a new workbook
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Links');

          // Generate a buffer
          const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
          });

          // Create a blob from the buffer
          const blob = new Blob([excelBuffer], {
            type: 'application/octet-stream',
          });

          // Save the file and store the blob in state
          setFileBlob(blob);
          saveAs(blob, 'links.xlsx');
        }
      } else {
        const formattedData = candidateData.map((item) => ({
          first_name: item.first_name,
          last_name: item.last_name,
          emailid: item.email,
          links: item.linkinfo
            ? `${process.env.REACT_APP_FRONEND}/interview/candidate/` +
            item.linkinfo.link
            : '',
        }));

        const worksheet = XLSX.utils.json_to_sheet(formattedData);

        // Create a new workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Links');

        // Generate a buffer
        const excelBuffer = XLSX.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });

        // Create a blob from the buffer
        const blob = new Blob([excelBuffer], {
          type: 'application/octet-stream',
        });

        // Save the file and store the blob in state
        setFileBlob(blob);
        saveAs(blob, 'links.xlsx');
      }
    }
    // if (fileBlob) {
    //   // If the fileBlob exists, download the existing file
    //   saveAs(fileBlob, 'links.xlsx');
    //   return;
    // }
    // const reqData = {
    //   campaign_uid: campUid,
    // };

    // const response = await handleApiResponse(
    //   "/api/v1/interview/generate_unique_link/",
    //   reqData,
    //   1
    // );

    // if (response?.data?.status_code === 200) {
    //   const dataArray = response?.data?.data;
    //   console.log(dataArray,'dataarray')
    //   const formattedData = dataArray.map((item) => ({
    //     first_name: item.candidate.first_name,
    //     last_name: item.candidate.last_name,
    //     emailid: item.candidate.email,
    //     links: `${process.env.REACT_APP_FRONEND}/interview/candidate/` + item.link,
    //   }));
    //   const worksheet = XLSX.utils.json_to_sheet(formattedData);

    //   // Create a new workbook
    //   const workbook = XLSX.utils.book_new();
    //   XLSX.utils.book_append_sheet(workbook, worksheet, "Links");

    //   // Generate a buffer
    //   const excelBuffer = XLSX.write(workbook, {
    //     bookType: "xlsx",
    //     type: "array",
    //   });

    //   // Create a blob from the buffer
    //   const blob = new Blob([excelBuffer], {
    //     type: "application/octet-stream",
    //   });

    //   // Save the file and store the blob in state
    //   setFileBlob(blob);
    //   saveAs(blob, "links.xlsx");
    //   // if()
    // }
  };


  const handleClickOutside = (event) => {
    // Check if click is outside the dropdown element
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false); // Close dropdown if clicked outside
    }
  };

  useEffect(() => {
    // Add the event listener for clicks on the entire document
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className="text-[#A6AEBA]  bg-[#FFFFFF] pl-[1.5rem] pr-[1.5rem] gap-4 flex flex-col rounded-[30px]"
      style={{
        boxShadow: '0px 8px 16px 0px #00000014, 0px 0px 4px 0px #0000000A',
      }}
    >
      <div className="flex flex-col pt-4 overflow-auto">
        <div className=" flex  justify-between mb-[1rem] gap-4">
          <div className="font-[700] italic text-[#222950] text-[24px]">
            Campaign Details
          </div>
          <div className="flex gap-4">
            <div className="relative">
              <input
                placeholder="Search Candidate"
                onChange={(e) => {
                  handleSearch(e.target.value); // Trigger search directly on change
                }}
                className="font-poppins w-[18.125vw] h-[36px] text-[12px] p-5 pl-8 outline-0 bg-[#E9F3FF] rounded-lg placeholder-text-sm"
              />
              <img
                src={searchIcon}
                alt="search icon"
                className="absolute cursor-pointer top-0 left-0 w-[16px] h-[40.2px] ml-2"
              />
            </div>
            <div>
              {campType === 'Mass' &&
                (bulkCountExists > 0 || bulkCountExists !== undefined ? (
                  <div className="flex gap-4">
                    <CustomButtonWhite
                      text="Copy Link"
                      onClick={handleCopyLink}
                    />
                    <CustomButtonWhite5
                      text="Re-Generate Link"
                      onClick={handleBulkLink}
                    />
                  </div>
                ) : (
                  <CustomButtonWhite5
                    text="Generate Link"
                    onClick={handleBulkLink}
                  />
                ))}
              {/* {(campType === "Regular" && getLocal("mail_share_type") !== "1") && (
            <div className="flex gap-4 mr-20">
              <CustomButtonWhite text="Share Link" onClick={sendMail} />
            </div>
          )} */}

              {campType === 'Regular' &&
                getLocal('mail_share_type') === '1' && (
                  <div className="flex gap-4 mr-20">
                    <CustomButtonWhite
                      text="Download Links"
                      onClick={downloadLinks}
                    />
                  </div>
                )}
            </div>
            {/* <CustomButtonFilter1 text={'Filter'} /> */}
          </div>
        </div>
      </div>
      <div className="flex font-sans1 flex-col-reverse gap-4">
        <div className="flex gap-4 mb-4 h-[34.028vh]">
          <div
            className="font-satoshi rounded-[20px] h-[34.028vh]  w-[40%] flex flex-col py-[20px]"
            style={{ boxShadow: '0px 0px 4px 0px #00112D40' }}
          >
            <p className="font-[700] font-sans1 text-[21px] text-[#1B3351] mb-2 px-[10px]">
              Campaign summary
            </p>
            <div className='overflow-auto'>
              <table className='w-full'>
                <tbody>
                  <tr className='border-b border-[#E2E8F0]'>
                    <td className='px-4 py-1 text-[#222950] font-[500]'>Campaign Name</td>
                    <td className='px-4 py-1 text-[#222950] font-[500]'>:</td>
                    <td className='px-4 py-1 text-[#0875F4] font-[500]'>{campName}</td>
                  </tr>
                  <tr className='border-b border-[#E2E8F0]'>
                    <td className='px-4 py-1 text-[#222950] font-[500]'>Job Role</td>
                    <td className='px-4 py-1 text-[#222950] font-[500]'>:</td>
                    <td className='px-4 py-1 text-[#0875F4] font-[500]'>{jr_name}</td>
                  </tr>
                  <tr className='border-b border-[#E2E8F0]'>
                    <td className='px-4 py-1 text-[#222950] font-[500]'>Campaign Type</td>
                    <td className='px-4 py-1 text-[#222950] font-[500]'>:</td>
                    <td className='px-4 py-1 text-[#0875F4] font-[500]'>{campaign_type}</td>
                  </tr>
                  <tr className='border-b border-[#E2E8F0]'>
                    <td className='px-4 py-1 text-[#222950] font-[500]'>Start Date</td>
                    <td className='px-4 py-1 text-[#222950] font-[500]'>:</td>
                    <td className='px-4 py-1 text-[#0875F4] font-[500]'>{start_date}</td>
                  </tr>
                  <tr className=''>
                    <td className='px-4 py-1 text-[#222950] font-[500]'>End Date</td>
                    <td className='px-4 py-1 text-[#222950] font-[500]'>:</td>
                    <td className='px-4 py-1 text-[#0875F4] font-[500]'>{end_date}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* <div className="overflow-auto grid grid-cols-1 gap-4">
              <p className="flex justify text-[#222950] font-[500] py-[6px] border-b border-[#E2E8F0] px-[20px]">
                <div className="w-[142px]">Campaign Name</div>
                <div className="px-4"> :</div>
                <div className="italic text-[#0875F4]">{campName}</div>
              </p>
              <p className="flex justify  text-[#222950] font-[500] py-[6px] border-b border-[#E2E8F0] px-[20px]">
                <div className="w-[142px]">Job Role</div> 
                <div className="px-4"> :</div>
                <div className="italic text-[#0875F4]">{jr_name}</div>
              </p>
              <p className="flex justify-  text-[#222950] font-[500] py-[6px] border-b border-[#E2E8F0] px-[20px]">
                <div className="w-[142px]">Campaign Type</div> 
                <div className="px-4"> :</div>
                <div className="italic text-[#0875F4]">{campaign_type}</div>
              </p>
              <p className="flex justify-  text-[#222950] font-[500] py-[6px] border-b border-[#E2E8F0] px-[20px]">
                <div className="w-[142px]">Start Date</div>
                <div className="px-4"> :</div>
                <div className="italic text-[#0875F4]">{start_date}</div>
              </p>
              <p className="flex justify-  text-[#222950] font-[500] py-[6px] border-b border-[#E2E8F0] px-[20px]">
                <div className="w-[142px]">End Date</div> 
                <div className="px-4"> :</div>
                <div className="italic text-[#0875F4]">{end_date}</div>
              </p>
            </div> */}
          </div>

          <div
            className=" font-sans1 rounded-[20px] h-[34.028vh] w-[60%] flex flex-col py-[20px]"
            style={{ boxShadow: '0px 0px 4px 0px #00112D40' }}
          >
            <div className="overflow-y-scroll" id="campaign-questions">
              <p className="flex font-[700] mb-2 text-[21px] text-[#1B3351] px-[20px] mb-2">
                Campaign Questions
              </p>
              {formattedQuestions?.quest?.map((item, index) => (
                <div
                  key={index}
                  className="border-b border-gray-300 pb-2 pt-2 px-[20px]"
                >
                  <p
                    className={`overflow-auto mb-2 items-center font-sans1 text-[15px] font-[600] justify-center ${
                      (item?.mandatory && !item?.isHr && item?.isEdit) ||
                      (item?.mandatory && !item?.isHr)
                        ? formattedQuestions?.quest_type === 3 ?  'text-green-600' : "text-blue-600"
                        : item?.mandatory &&
                          item?.isHr &&
                          formattedQuestions?.quest_type === 2
                          ? 'text-blue-400'
                          : item?.mandatory && item?.isHr
                            ? 'text-green-600'
                            : 'text-[#1B3351]'
                      }`}
                  >
                    <div className=" font-[600]">
                      {index + 1 < 10 ? `0${index + 1}` : index + 1}-{' '}
                      {formattedQuestions?.quest_type === 3 && item.mandatory
                        ? 'HR ' + item.name
                        : formattedQuestions?.quest_type === 2
                          ? 'HR Question '
                          : 'AI Question '}
                      {formattedQuestions?.quest_type === 3 &&
                        item.mandatory &&
                        item.isEdit
                        ? ' (AI question Modified)'
                        : formattedQuestions?.quest_type === 3 &&
                          item.mandatory &&
                          !item.isHr
                          ? '(AI Mandated)'
                          : item.mandatory && item.isHr
                            ? formattedQuestions?.quest_type === 2
                              ? ' (Hr Mandated)'
                              : ' (Hr Mandated)'
                            : formattedQuestions?.quest_type === 1 && item.mandatory
                              ? ' (AI Mandated)'
                              : ''}
                    </div>
                    <p className=" font-sans1 text-[15px] font-[500]">
                      {item.question}
                    </p>
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='py-[20px] px-[16px] pb-[8px] rounded-[20px]' style={{ boxShadow: '0px 0px 4px 0px #00112D40' }}>
          <div  className="border-solid border-l border-r border-t " style={{
            borderWidth: "1px 1px 0px 1px",
            borderRadius: "20px"
          }}>
        <span
          className="h-[34.028vh] bg-[#FFFFFF] rounded-[20px]"
        >
          {/* <div className="flex p-4">
            <input
              placeholder="Search candidate"
              onChange={(e) => setSearch(e.target.value)}
              className="w-full h-10 p-5 outline-0 border-[#F1F2F4] border-2 bg-[#FFFFFF] rounded-lg mt-2 placeholder-text-sm"
              value={search}
              onKeyDown={handleKeyDown}
            />
            <img
              src={searchIcon}
              alt="search icon"
              className="cursor-pointer right-8 relative"
            />
          </div> */}
          <div className="overflow-x-scroll table-wrp block  h-[34.028vh] rounded-[20px]">
            <table
              className={`w-full text-sm text-[#A6AEBA] text-left rtl:text-right`}
            >
              <thead class="sticky top-0 z-10 bg-[#F7FAFC] text-[14px] font-inter font-[400] text-[#222950] border-b h-[2px] border-[#E4E7EB]">
                <tr>
                  <td scope="col" class=" px-6 py-3 ">
                    Candidate UID
                  </td>
                  <td scope="col" class=" px-2 py-3 text-left">
                    Interview ID
                  </td>
                  <td scope="col" class="  px-2 py-3 text-left">
                    Name
                  </td>
                  <td scope="col" class=" px-2 py-3 text-left">
                    Email ID
                  </td>
                  {/* <th scope="col" class="px-6 py-3">
                    Company
                  </th> */}
                  {/* <td scope="col" class=" px-2 py-3">
                    Mobile No.
                  </td> */}
                  <td
                    scope="col"
                    className=" px-2  py-3 "
                  >
                    <div ref={dropdownRef} className='flex justify-start items-center dropdown-container'>
                        <span>Status</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="12"
                          viewBox="0 0 23 12"
                          fill="none"
                          onClick={toggleDropdown} // Toggle the dropdown on SVG click
                          style={{ cursor: 'pointer' }} // Add cursor pointer for better UX
                        >
                          <path
                            d="M7 4L11.5 8L16 4"
                            stroke="#222850"
                            strokeLinecap="round"
                          />
                        </svg>
                        {isOpen && (
                          <DropdownFilter
                            options={['Pending', 'Completed', 'All']}
                            onOptionClick={handleOptionClick}
                          />
                        )}
                    </div>
                  </td>
                  <td scope="col" class="px-6 py-3 text-left">
                    Action
                  </td>
                </tr>
              </thead>
              <tbody className="overflow-x-scroll justify-start items-center">
                {searchResults?.length > 0
                  ? searchResults
                    ?.filter((rowData) => {
                      if (selectedStatus === 'All') return true; // Show all when 'All' is selected
                      if (!selectedStatus) return true; // Default case if no status is selected
                      return selectedStatus === 'Pending'
                        ? getStatus(rowData.status) !== 'Completed'
                        : getStatus(rowData.status) === selectedStatus; // Filter for 'Pending' or 'Completed'
                    })
                    .map((rowData, index) => (
                      <ViewCampaignTable
                        counts={counts}
                        key={index}
                        jr_name={rowData?.campign_info?.jr_name}
                        icon={userImg}
                        name={`${rowData.first_name} ${rowData.last_name}`}
                        row12={rowData.email}
                        candVideo={rowData.video}
                        row13={rowData.mobile}
                        taskId={rowData.task_id}
                        clicked={clicked}
                        candId={rowData.id}
                        row17={getStatus(rowData.status)}
                        editIcon={editIcon}
                        deleteIcon={deleteIcon}
                        openModal={openModalEditJobRole}
                        closeModal={closeModalEditJobRole}
                        isModalOpen4={isModalOpen4}
                        setCandidateId={setCandidateId}
                        candidateId={candidateId}
                        uid={rowData.uid}
                        bulkCountExists={rowData?.linkinfo?.count}
                        callApi={callApi}
                        campUid={campUid}
                        campType={campType}
                        candPhoto={rowData.photo}
                        candIntId={rowData.linkinfo.id}
                        linkInfo={rowData.linkinfo}
                        downloadLinksClicked={downloadLinksClicked}
                        exp={rowData.work_experience}
                      />
                    ))
                  : currentPageData
                    ?.filter((rowData) => {
                      if (selectedStatus === 'All') return true; // Show all when 'All' is selected
                      if (!selectedStatus) return true; // Default case if no status is selected
                      return selectedStatus === 'Pending'
                        ? getStatus(rowData.status) !== 'Completed'
                        : getStatus(rowData.status) === selectedStatus; // Filter for 'Pending' or 'Completed'
                    })
                    .map((rowData, index) => (
                      <ViewCampaignTable
                        counts={counts}
                        clicked={clicked}
                        key={index}
                        icon={userImg}
                        jr_name={rowData?.campign_info?.jr_name}
                        name={`${rowData.first_name} ${rowData.last_name}`}
                        row12={rowData.email}
                        candId={rowData.id}
                        candVideo={rowData.video}
                        row13={rowData.mobile}
                        row17={getStatus(rowData.status)}
                        candIntId={rowData.linkinfo.id}
                        editIcon={editIcon}
                        taskId={rowData.task_id}
                        deleteIcon={deleteIcon}
                        openModal={openModalEditJobRole}
                        closeModal={closeModalEditJobRole}
                        isModalOpen4={isModalOpen4}
                        setCandidateId={setCandidateId}
                        candidateId={candidateId}
                        uid={rowData.uid}
                        campUid={campUid}
                        bulkCountExists={rowData?.linkinfo?.count}
                        callApi={callApi}
                        campType={campType}
                        candPhoto={rowData.photo}
                        candCompany={rowData.company}
                        candJobRole={rowData.campign_info.jr_name}
                        linkInfo={rowData.linkinfo}
                        downloadLinksClicked={downloadLinksClicked}
                        exp={rowData.work_experience}
                      />
                    ))}
              </tbody>
            </table>
            {/* <Pagination
              className="pagination"
              currentPage={currentPage}
              totalCount={tableData?.length}
              pageSize={itemsPerPage}
              onPageChange={handlePageChange}
            /> */}
          </div>
          {/* <Pagination
            className="pagination"
            currentPage={currentPage}
            totalCount={tableData?.length}
            pageSize={itemsPerPage}
            onPageChange={handlePageChange}
          />
          <div class={`${isLeftOption ? "hidden" : "block"}`}>
            <table
              class={`w-full text-sm text-[#A6AEBA] text-left rtl:text-right ${
                isLeftOption ? "hidden" : "block"
              }`}
            >
              <thead class="w-[100%] bg-[#F9FAFB] text-[0.833vw] font-semibold text-[#A6AEBA] border-b h-[2px] border-[#E4E7EB]">
                <tr>
                  <th scope="col" class="w-[2%] px-6 py-3">
                    Name
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Mobile number
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentPageData1?.map((rowData, index) => (
                  <ViewCampaignTable
                    key={index}
                    icon={userImg}
                    name={"Shubham Uniyal "}
                    row12={"abc123@gmail.com"}
                    row13={"+91 9717119299"}
                    row17={"Ongoing"}
                    editIcon={editIcon}
                    deleteIcon={deleteIcon}
                    openModal={openModalEditJobRole}
                    closeModal={closeModalEditJobRole}
                    isModalOpen4={isModalOpen4}
                  />
                ))}
              </tbody>
            </table>

            <Pagination
              className="pagination"
              currentPage={currentPage}
              totalCount={tableData?.length}
              pageSize={itemsPerPage}
              onPageChange={handlePageChange}
            />
          </div> */}
        </span>
        </div>
        </div>
      </div>
    </div>
  );
}

export default ViewCampaigns;
