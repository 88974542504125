import { toast } from "react-toastify";
import {
  arrowIcon,
  clockSvg,
  coverImg,
  shareIcon,
  shareIcon2,
  clevercruitlogo,
  lineBreak,
  downloadButton,
  downloadIcon,
  digitalfonticon,
} from "../../assets/imgs";
import {
  Card1,
  Card2,
  Card3,
  Card4,
  DurationCard,
  PersonalCard,
  PersonalCard1,
  SegmentedCircleBar,
} from "../card";
import { ScoreCard } from "./card";
import { handleApiResponse } from "../../API/services";
import jsPDF from "jspdf";
import { useAsyncValue, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Loader } from "../loader";
import SuspiciousChart from "../../utils/SuspiciousChart";
import TeamWorkChart from "../../utils/TeamWorkChart";
import html2canvas from "html2canvas";
import domtoimage from 'dom-to-image';


export const NewAnalytics = () => {
  const navigate = useNavigate();
  const certificateRef = useRef();
  const [isLeftOption, setIsLeftOption] = useState(true);
  const location = useLocation();
  const status = location?.state?.status || "Completed";
  const [overall_score, setOverall_score] = useState("");
  const [suspicious, setSuspicious] = useState("");
  const [comprehensiveAbility, setComprehensiveAbility] = useState("");
  const [fluency, setFluency] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [aboutCandidate, setAboutCandidate] = useState("");
  const [relevant_skills, setRelevantSkills] = useState("");
  const [description, setDescription] = useState("");
  const [stability, setStability] = useState("");
  const [teamColab, setTeamColab] = useState("");
  const [languageCommand, setLanguageCommand] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const searchParams = new URLSearchParams(location.search);
  const uuid = searchParams.get("uid");
  const candId = searchParams.get("candId");
  const startDate = new Date(start);
  const endDate = new Date(end);
  const queryParams = new URLSearchParams(location.search);
  const [isLoader, setLoader] = useState(false);
  const [messages, setMessages] = useState([]);
  // console.log(evalData);
  console.log(start);
  console.log(end);
  console.log(uuid);
  const timeDifferenceInMillis = endDate - startDate;
  // console.log(messages)
  // Convert milliseconds to minutes and seconds
  const minutes = Math.floor(timeDifferenceInMillis / (1000 * 60));
  const seconds = Math.round((timeDifferenceInMillis % (1000 * 60)) / 1000);
  const candPhoto = queryParams.get("candPhoto").split("?")[0];
  const candName = queryParams.get("candName");
  const candCompany = queryParams.get("candCompany");
  const candJobRole = queryParams.get("candJobRole");
  const candExp = queryParams.get("candExp");
  const fullPhotoURL = `${candPhoto}`;
  const [marks, setMarks] = useState("0.275rem");
  const [candidateStability, setCandidateStability] = useState("");
  console.log(candidateStability, "candidateStability");
  const [suspiciousCause, setSuspiciousCause] = useState([]);
  const date = createdDate.split("T")[0].replace(/-/g, "/");
  const [year, month, day] = date.split("/");
  const formateDate = `${day}/${month}/${year}`;
  console.log(suspiciousCause);
  let noFaceDetectedCount = 0;
  let dontManipulateWindowCount = 0;
  let otherCount = 0;

  suspiciousCause.forEach((cause) => {
    if (cause === "No face detected" || cause === "Candidate not looking forward") {
      noFaceDetectedCount++;
    } else if (cause === "Don't manipulate the window") {
      dontManipulateWindowCount++;
    } else {
      otherCount++;
    }
  });

  const totalCount = suspiciousCause.length;

  const noFaceDetectedPercentage = ((noFaceDetectedCount / 5) * 100).toFixed(1);
  const dontManipulateWindowPercentage = (
    (dontManipulateWindowCount / 5) *
    100
  ).toFixed(1);
  const otherPercentage = ((otherCount / 5) * 100).toFixed(1);

  useEffect(() => {
    setMarks(`${((100/100)*100).toFixed(1)}`);
  }, [overall_score]);

  console.log(fullPhotoURL);
  console.log(minutes);
  console.log(seconds);
  console.log(`Time difference: ${minutes} minutes and ${seconds} seconds`);
console.log((languageCommand * 0.5) + (fluency * 0.5))
  const downloadPDF = (messages) => {
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10;
    const maxLineWidth = pageWidth - margin * 2;
    const lineHeight = 10;
    let y = 10;
    let sender = "Candidate";

    console.log(messages);

    messages.forEach((message) => {
      y += 5;

      // Toggle sender between "AI" and "Interviewer" for each message
      sender = sender === "AI" ? "Candidate" : "AI";
      const text = `${sender}: ${message.msg}`;

      // Split text to fit within the page width
      const lines = doc.splitTextToSize(text, maxLineWidth);

      lines.forEach((line) => {
        // Check if adding this line will exceed the page height
        if (y + lineHeight > pageHeight - margin) {
          doc.addPage();
          y = margin; // Reset y position for the new page
        }
        doc.text(line, margin, y);
        y += lineHeight; // Move y position for the next line
      });

      y += 5; // Add some space between messages
    });

    doc.save("chat_transcript.pdf");
  };

  const handleDownloadTranscript = async (e) => {
    try {
      const response = await handleApiResponse(
        "/api/v1/interview/data/get_candidate_transcript/",
        {
          candidate_uid: uuid,
        },
        1
      );
      console.log("API Response:", response.data);
      if (response?.code === "token_not_valid") {
        navigate("/");
        sessionStorage.clear();
        return;
      }
      if (response.data.status_code === 200) {
        console.log(response.data.data);
        setMessages(response.data.data);
        downloadPDF(response.data.data);
        // dispatch(signupSuccess(response.data));
        // setLocal('adminToken', response.data.access);
        // setLocal('first_name', response.data.first_name);
        // setLocal('last_name', response.data.last_name);
        // setLocal('uid', response.data.uid);
        // setLocal('parentUid', response.data.parent);
        // setLocal(
        //   'notPermited',
        //   response?.data?.user_type === 3 ? true : false
        // );
        // if (!response?.data.is_email_verified) {
        //   setShowEmailVerification(true);
        // }
      }
    } catch (e) {}
  };
  console.log(relevant_skills);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await handleApiResponse(
          "/api/v1/get_performance_status/",
          {
            task_id: uuid,
            uid: candId,
          },
          1
        );
        console.log(response?.data);
        if (response?.code === "token_not_valid") {
          navigate("/");
          sessionStorage.clear();
          return;
        }
        if (response?.data.status_code === 200) {
          setLoader(false);
          console.log(response?.data.data.overall_score);
          setOverall_score(response?.data?.data?.result.overall_score);
          setCreatedDate(response?.data?.data?.result?.created_date);
          setTeamColab(response?.data?.data?.result.team_colab);
          setSuspicious(response?.data?.data?.result.suspicious);
          setComprehensiveAbility(
            response?.data?.data?.result.comprehensive_ability
          );
          setLanguageCommand(response?.data?.data?.result.lang_command);
          setRelevantSkills(response?.data?.data?.result.domain_experties);
          setStability(response?.data?.data?.result.candidate_stability);
          setStart(response?.data?.data?.result.interview_start);
          setEnd(response?.data?.data?.result.interview_end);
          setDescription(response?.data?.data?.result.description);
          console.log(response?.data?.data?.result.candidate_stability);
          setCandidateStability(
            response?.data?.data?.result.candidate_stability
          );
          setFluency(response?.data?.data?.result?.fluency);
          setSuspiciousCause(response?.data?.data?.result?.suspicious_cause);
          setAboutCandidate(response?.data?.data?.result?.about_you);
        } else if (response?.data.status_code === 400) {
          setLoader(false);
          toast.error("The interview is not completed by candidate. ", {
            className: "custom-toast-error",
          });
        } else {
          toast.error(response?.msg, {
            className: "custom-toast-error",
          });
        }
        // setLoader(false);
      } catch (error) {
        // Handle errors here
      }
    };

    fetchData(); // Invoke the async function
  }, [overall_score, uuid]);
  console.log(overall_score);
  console.log(candidateStability);
  const diamond = "◆";

  // const downloadAnalyticPDF = () => {
  //   const certificateElement = certificateRef.current;
  //   if (!certificateElement) {
  //     return;
  //   }

  //   const loadAllImages = (element) => {
  //     const images = element.getElementsByTagName("img");
  //     return Promise.all(
  //       Array.from(images).map((img) => {
  //         if (img.complete) {
  //           return Promise.resolve();
  //         } else {
  //           return new Promise((resolve) => {
  //             img.onload = resolve;
  //             img.onerror = resolve;
  //           });
  //         }
  //       })
  //     );
  //   };

  //   loadAllImages(certificateElement).then(() => {
  //     html2canvas(certificateElement, {
  //       scale: 2,
  //       useCORS: true,
  //       allowTaint: true,
  //       timeout: 5000,
  //     })
  //       .then((canvas) => {
  //         const imgData = canvas.toDataURL("image/png");
  //         const pdf = new jsPDF({
  //           orientation: "a4",
  //           unit: "px",
  //           format: [canvas.width, canvas.height],
  //         });
  //         pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
  //         pdf.save(`${candName}_analytics_report.pdf`);
  //       })
  //       .catch((error) => {
  //         console.error("Error generating PDF", error);
  //       });
  //   });
  // };


  

  const downloadAnalyticPDF = async () => {
    const certificateElement = certificateRef.current;
    if (!certificateElement) {
      return;
    }
  
    // Function to ensure all images are loaded before generating the PDF
    const loadAllImages = (element) => {
      const images = element.getElementsByTagName("img");
      return Promise.all(
        Array.from(images).map((img) => {
          if (img.complete) {
            return Promise.resolve();
          } else {
            return new Promise((resolve) => {
              img.onload = resolve;
              img.onerror = resolve;
            });
          }
        })
      );
    };
  
    // Load all images before converting the element to a PNG and generating the PDF
    await loadAllImages(certificateElement);

    // Set a high scale factor for better quality (e.g., 2x or 3x the size)
    const scaleFactor = 1.5;
  
    // Convert the certificate element to a PNG using dom-to-image
    domtoimage.toPng(certificateElement, {
      width: certificateElement.clientWidth * scaleFactor,
      height: certificateElement.clientHeight * scaleFactor,
      style: {
        transform: `scale(${scaleFactor})`,
        transformOrigin: 'top left',
        width: `${certificateElement.clientWidth}px`,
        height: `${certificateElement.clientHeight}px`
      }
    })
      .then((blob) => {
      const customPageWidth = 842; // A4 width in points
      const customPageHeight = 1000; // A4 height in points + additional height (e.g., 500 points)
      const pdf = new jsPDF('p', 'pt', [customPageWidth, customPageHeight]); 

       // Set the width and height for the PDF page
       const pageWidth = pdf.internal.pageSize.getWidth();
       const pageHeight = pdf.internal.pageSize.getHeight();
 
       // Force the image to fill the entire PDF page (no white space)
       pdf.addImage(blob, 'PNG', 0, 0, pageWidth, pageHeight);  // Stretch the image to fill both width and height
 
       // Save the PDF
       pdf.save(`${candName}_analytics_report.pdf`);
      })
      .catch((error) => {
        console.error('Error generating PDF:', error);
      });

  };

  // parseFloat(overall_score).toFixed(1)

  const pointer = `${0.87*parseFloat(overall_score).toFixed(1)}%`;
  

  return (
    <main
      ref={certificateRef}
      className="font-satoshi flex flex-col gap-4 sm:gap-6 text-dark-blue p-4 sm:p-8 w-full min-h-screen bg-gray-100"
    >
      {isLoader ? <Loader /> : ""}
      <section className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
        <div className="flex items-center mb-4 sm:mb-0">
          {/* <img
            src={arrowIcon}
            alt="arrow icon"
            onClick={() => {
              navigate(-1);
            }}
            className="cursor-pointer mr-4"
          /> */}
          {/* <div>
            <p className="font-semibold text-lg">Analytics</p>
          </div> */}
        </div>
      </section>
      <section className="flex flex-col gap-4 sm:gap-6">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center w-full">
          <p className="font-satoshi flex  flex-col  lg:flex-row  lg:items-center gap-2 mb-4 sm:mb-0">
            <img src={clevercruitlogo} alt="" className="w-[154px] h-auto" />
            <div className="text-2xl mt-2">
              <span className="font-satoshi text-[#00112D]  text-[22px]" >
                <b>Analytics Report - {formateDate}</b>
              </span>
            </div>
          </p>
          <button
            onClick={downloadAnalyticPDF}
            className="flex items-center font-[600] gap-2 px-4 py-2 bg-white rounded-full shadow"
          >
            <img
              className="w-4 h-4 object-contain shrink-0 filter"
              src={downloadIcon}
              alt=""
              style={{ filter: "brightness(0) saturate(80%)" }}
            />
            Download
          </button>
        </div>
        <div
          className="p-6 sm:p-8 text-white relative flex flex-col sm:flex-row justify-between items-center overflow-hidden rounded-3xl"
          style={{
            backgroundImage: "linear-gradient(to right, #064197 , #021531 )",
          }}
        >
          <div className="w-full lg:w-2/3 mb-6 sm:mb-0">
            <p>
              <p className="text-[40px]  italic font-satoshi font-[700] sm:text-[40px] mb-4 block">
              Candidate Performance Overview
              </p>
            </p>
            <p className="text-[16px] width-[55vw] font-[400] font-satoshi sm:text-[16px]">
            This score represents a comprehensive evaluation of the candidate's performance across various assessment criteria, including technical skills, communication abilities, and behavioural traits.
            </p>
          </div>

          {/* <div className="w-1 h-48 bg-white shadow-sm rounded-full"></div> */}
          {/* <div className="flex justify-center items-center h-screen bg-navy-900"> */}
          <div className="relative w-4 h-48 ms-2 hidden md:block">
            <div
              className="absolute inset-0 bg-white"
              style={{
                clipPath:
                  "polygon(0% 0%, 10% 0%, 18% 0%, 35% 54%, 10% 100%, 0% 100%)",
              }}
            />
          </div>
          {/* </div> */}
          <div className="hidden sm:block h-3/4 border-l-2 border-white mx-4"></div>

          <div className="flex flex-col gap-2 w-full  lg:w-1/3 text-center sm:text-left">
            <p className="font-satoshi">
              <p className="text-[32px] font-[700] italic">Overall score</p>
            </p>
             {/* <p className="text-sm font-[300] font-roboto">
              Overall summary of your performance
            </p>  */}
            <p className="flex align-items-start font-sans1">
              <p className="font-satoshi font-[700] text-4xl">
                {overall_score ? parseFloat(overall_score).toFixed(1) : "0.00"}
              </p>{" "}
              <span className="ml-[0.8rem] mt-1 text-[19px] font-satoshi">Out of 100</span>
            </p>
            <div
              className="relative  w-full h-12 rounded-full border-[3px] border-white mt-2"
              style={{
                backgroundImage:
                  "linear-gradient(to right, #FF0E01, #F6CD02 , #1EF848 )",
              }}
            >
              <div className="border-[3px] px-5  py-2 rounded-full border-[#021837] h-full flex justify-around items-center gap-1">
                {/* <svg
                  width="100%"
                  height=""
                  className="mx-3"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="10"
                    y1="50%"
                    x2="100%"
                    y2="50%"
                    stroke="#FFFFFF"
                    strokeWidth="20"
                    strokeDasharray="1 , 19"
                  />
                </svg> */}
                <div className="w-[0.5px] h-[100%] bg-white"></div>
                <div className="w-[0.5px] h-[100%] bg-white"></div>
                <div className="w-[0.5px] h-[100%] bg-white"></div>
                <div className="w-[0.5px] h-[100%] bg-white"></div>
                <div className="w-[0.5px] h-[100%] bg-white"></div>
                <div className="w-[0.5px] h-[100%] bg-white"></div>
                <div className="w-[0.5px] h-[100%] bg-white"></div>
                <div className="w-[0.5px] h-[100%] bg-white"></div>
                <div className="w-[0.5px] h-[100%] bg-white"></div>
                <div className="w-[0.5px] h-[100%] bg-white"></div>
                <div className="w-[0.5px] h-[100%] bg-white"></div>
                <div className="w-[0.5px] h-[100%] bg-white"></div>
                <div className="w-[0.5px] h-[100%] bg-white"></div>
                <div className="w-[0.5px] h-[100%] bg-white"></div>
                <div className="w-[0.5px] h-[100%] bg-white"></div>
                <div className="w-[0.5px] h-[100%] bg-white"></div>
                <div className="w-[0.5px] h-[100%] bg-white"></div>
                <div className="w-[0.5px] h-[100%] bg-white"></div>
                <div className="w-[0.5px] h-[100%] bg-white"></div>
                <div className="w-[0.5px] h-[100%] bg-white"></div>
                <div className="w-[0.5px] h-[100%] bg-white"></div>
              </div>
              <div className={`px-[5%]`}  style={{ position: "absolute", top: "1.5rem",left: pointer }}>
                  <div className="tringle-pointer w-0 h-0 border-0 border-transparent border-r-[10px] border-l-[10px] border-b-[20px] border-b-[#fff] rounded-sm">
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="flex flex-col lg:flex-row gap-4 sm:gap-6">
        <div className="flex flex-col gap-4 sm:gap-6 w-full lg:w-2/3">
          <div className="flex justify-center flex-col sm:flex-row gap-4 sm:gap-6">
            <div className="">
              <div className="flex items-center  w-full gap-2 lg:w-[33vw]  rounded-2xl">
                <div className="w-[100%] flex items-center gap-2 sm:gap-0 sm:px-3 rounded-2xl lg:px-3 xl:px-10">
                    <img
                      src={clockSvg}
                      className="md:mr-[-5rem] lg:mr-[0rem] xl:mr-[-6rem] lg:h-[18vw] sm:h-100 z-[1000] w-[13rem] sm:w-[8rem] md:w-[13rem] top-[41%] left-2 lg:left-[2.1rem] sm:left-4 md:left-6 "
                      alt=""
                    />
                  <div className="flex relative gap-4 flex-col items-end w-full">
                    <div className="bg-[#F6F8FF] w-[92%] lg:w-[100%] xl:w-[92%] relative text-[4.2rem] md:text-[4.4rem] lg:text-[4rem] xl:text-[5.5rem] text-[#043376] text-center  font-digital rounded-3xl ">
                      <div className="flex text-[#00BD1C] justify-center items-center p-[24px]">
                        <div className="flex flex-col justify-center items-center">
                          <div className="flex ">

                            <div className="flex flex-col justify-center items-center font-digitalfont ">
                                <span className="relative leading-[66px] font-digitalfont">
                                  {minutes < 10 ? `0${minutes}` : minutes} 
                                </span>
                                <p className="mt-[0.5rem] text-[#00BD1C] font-semibold bottom-4 text-[14px] md:text-[16px] lg:text-[16px] right-12 md:right-14 font-satoshi">
                                  Min
                                </p>
                            </div>

                            <div className="flex flex-col  h-full justify-center items-center mt-[-6px] mx-5 w-[18px] lg:w-[15px] xl:w-[18px]">
                              {/* <span className="text-[0.2em] mx-1">{diamond}</span>
                              <span className="text-[0.2em] mx-1">{diamond}</span> */}
                              <img className="w-[2rem] h-[2rem]" src={digitalfonticon}/>
                              <img className="w-[2rem] h-[2rem]" src={digitalfonticon}/>
                            </div>

                            <div className="flex flex-col justify-center items-center font-digitalfont">
                              <span className="leading-[66px]">
                                {seconds < 10 ? `0${seconds}` : seconds}
                              </span>
                              <p className="mt-[0.5rem] text-[#00BD1C] font-semibold bottom-4 text-[14px] md:text-[16px] lg:text-[16px] right-12 md:right-14 font-satoshi">
                                Sec
                              </p>
                            </div>
                        </div>
                        </div>
                      </div>
                      {/* <p className="absolute bottom-[7px] left-[0px] text-[#043376] font-semibold text-[14px] md:text-[16px] lg:text-[16px] md:left-12 font-satoshi ms-2">
                    Mints
                  </p> */}
                    </div>

                    <div className="w-[55%] lg:w-[100%] xl:w-[55%] text-right justify-end items-end bg-[#F6F8FF]   flex flex-col py-3 px-5 rounded-3xl">
                      <span className="italic font-[700] text-[#00BD1C] text-[2rem] md:text-[28px] lg:text-[2.6rem] leading-tight">Interview Time</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white p-4 border rounded-[30px] shadow w-full  sm:w-1/2">
              <p className="text-xl font-bold italic mb-2 ">
                Tech / Domain Skill
              </p>
              <div className="flex bg-[#F6F8FF] rounded-[24px] flex-col items-center justify-center w-full h-[82%] mt-4 py-8">
                <div className="w-full aspect-square flex justify-center sm:p-5 lg:p-0 lg:max-w-[150px]  xl:max-w-[220px] mt-4 ">
                  <TeamWorkChart
                    totalData={{
                      total: (100 - parseFloat(relevant_skills)).toFixed(1),
                      progres: parseFloat(relevant_skills).toFixed(1),
                      ProgColor: "#00BD1C",
                      withoutProgColor: "rgba(0, 189, 28, 0.2)",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-6 bg-white border rounded-[30px] shadow p-6">
            <div>
              <b>
                <i className="text-xl text-gray-800">Communication score</i>
              </b>
            </div>
            <div className="flex flex-col sm:flex-row w-full justify-around gap-4">

              <div className="flex flex-col bg-[#F6F8FF] justify-center  rounded-[24px] p-6 py-8 items-center w-full sm:w-1/3">
                <div className="w-44 h-44 lg:w-36 lg:h-36 xl:w-44 xl-h-44 flex justify-center items-center ">
                  <TeamWorkChart
                    totalData={{
                      total: (100 - parseFloat(languageCommand)).toFixed(1),
                      progres: parseFloat(languageCommand).toFixed(1),
                      ProgColor: "#1AAEFE",
                      withoutProgColor: `rgba(26, 174, 254, 0.2)`,
                    }}
                  />
                </div>
                <p className="mt-4 font-[700] text-[#021531]  italic text-center">Fluency</p>
              </div>

              <div className="flex flex-col bg-[#F6F8FF] justify-center rounded-[24px] p-6  py-8 items-center w-full sm:w-1/3">
                <div className="w-44 h-44 lg:w-36 lg:h-36 xl:w-44 xl-h-44 flex justify-center items-center ">
                  <TeamWorkChart
                    totalData={{
                      total: (100 - parseFloat(fluency)).toFixed(1),
                      progres: parseFloat(fluency).toFixed(1),
                      ProgColor: "#FEF31A",
                      withoutProgColor: `rgba(254, 243, 26, 0.2)`,
                    }}
                  />
                </div>
                <p className="mt-4 font-[700] text-[#021531]  italic text-center">Grammar</p>
              </div>

              <div className="flex flex-col bg-[#F6F8FF] justify-center  rounded-[24px] p-6  py-8 items-center w-full sm:w-1/3">
                <div className="w-44 h-44 lg:w-36 lg:h-36 xl:w-44 xl-h-44 flex justify-center items-center ">
                  <TeamWorkChart
                    totalData={{
                      total: (100 - parseFloat(comprehensiveAbility)).toFixed(
                        1
                      ),
                      progres: ((languageCommand * 0.5) + (fluency * 0.5)).toFixed(1),
                      ProgColor: "#FE261A",
                      withoutProgColor: "rgba(254, 38, 26, 0.2)",
                    }}
                  />
                </div>
                <p className="font-[700] mt-4 text-[#021531] italic text-center">
                  Comprehensive ability
                </p>
              </div>

            </div>
          </div>
        </div>
        <div
          className="w-full lg:w-1/3 p-2 sm:p-4 rounded-[20px]"
          style={{
            background:
              "linear-gradient(271.94deg, #021531 29.78%, #053378 77.84%, #064197 99.27%)",
            boxShadow: "0px 0px 2px 0px #00112D33",
          }}
        >
          <div className="flex flex-col gap-4 bg-white rounded-[24px] shadow p-4 sm:p-6 h-full">
            <img
              className="mx-auto border-2 rounded-full object-cover"
              src={fullPhotoURL}
              alt="Photo"
              style={{
                width: "168px",
                height: "169px",
              }}
              crossorigin="anonymous"
            />
            <p className="font-[700] italic text-xl sm:text-2xl w-full text-center text-dark-blue">
              <i>{candName}</i>
            </p>
            <div className="flex flex-wrap justify-center gap-2 sm:gap-4">
              <div className="font-[500] min-w-[150px] min-h-[40px] italic text-center shadow border px-2 sm:px-3 py-1 sm:py-2 bg-white rounded-full text-xs sm:text-sm">
                {candJobRole}
              </div>
              <div className="font-[500] min-w-[150px] min-h-[40px] italic text-center shadow border px-2 sm:px-3 py-1 sm:py-2 bg-white rounded-full text-xs sm:text-sm">
                {candExp} years experience
              </div>
            </div>
            <div
              className="flex flex-col gap-2 sm:gap-4 bg-[#f6f8ff] rounded-[16px] p-3 sm:p-4 mt-2 sm:mt-4 flex-grow"
              style={{
                boxShadow: "0px 0px 2px 0px #00112D33",
              }}
            >
              <p className="font-[700] text-lg sm:text-[24px] text-dark-blue">
                <i>Candidate Profile</i>
              </p>
              <p
                className="text-xs sm:text-sm overflow-y-auto"
                style={{ maxHeight: "calc(100vh - 300px)" }}
              >
                {aboutCandidate}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="flex gap-4 flex-col lg:flex-row justify-between fill-available lg:overflow-auto">
        <div className="bg-white p-8  w-full lg:w-1/3 rounded-[30px] border-2 ">
          <p className="text-xl font-[700] italic">Team Work / Collaboration</p>
          <div className="flex justify-center flex-col items-center h-full w-full ">
              <div className="gap-3 w-44 h-44 lg:min-w-36 lg:h-36 xl:w-48 xl:h-48 rounded-3xl">
                <TeamWorkChart
                  totalData={{
                    total: (100 - parseFloat(teamColab)).toFixed(1),
                    progres: parseFloat(teamColab).toFixed(1),
                    ProgColor: "#FE1ADC",
                    withoutProgColor: "rgba(254, 26, 220, 0.2)",
                  }}
                />
              </div>
          </div>
        </div>

        <div className="flex flex-col w-full bg-white lg:w-1/3 gap-4  p-4 rounded-[30px] border-2">
          <b className="text-xl font-bold italic mt-4 ml-2">Suspicious Activity</b>

          <div className="flex flex-row lg:flex-col lg:justify-center xl:flex-row xl:items-center  overflow-auto">
            <div className="flex flex-col   mb-5 md:mb-0 w-full xl:w-2/4">
              <div className="flex gap-3 items-center rounded-3xl p-3 w-max ">
                <div className="w-4 h-4 rounded-full bg-[#d57d00] flex-shrink-0 "></div>
                <p className="flex text-[#d57d00]">Multiple Faces detected</p>
              </div>
              <div className="flex gap-3 items-center rounded-3xl p-3 w-max">
                <div className="w-4 h-4 rounded-full bg-[#975ced] flex-shrink-0 "></div>

                <p className="text-[#975ced]">Active Tabs switching</p>
              </div>
              <div className="flex gap-3 items-center rounded-3xl p-3 ">
                <div className="w-4 h-4 rounded-full bg-[#9C9898] flex-shrink-0 "></div>
                <p className="text-[#9c9898]">Candidate not looking straight or not visible</p>
              </div>
              
            </div>

            <div className="w-full lg:w-full xl:w-2/4  lg:items-center lg:justify-center">
              <div className="flex justify-center items-center">
                <SuspiciousChart
                    totalData={{
                      total: totalCount,
                      noFacePer: dontManipulateWindowPercentage,
                      dontManPer:otherPercentage,
                      multiface: noFaceDetectedPercentage,
                      overAll:
                        100 -
                        parseInt(
                          noFaceDetectedPercentage +
                            dontManipulateWindowPercentage +
                            otherPercentage
                        ),
                    }}
                  />
              </div>
            </div>

          </div>

          <section className="flex flex-col md:flex-row text-white justify-center items-center gap-4 text-center">
            <div className="bg-[#D57D00] flex justify-center items-center rounded-2xl py-2 w-[30%] px-6 md:px-12 lg:px-8 xl:px-12">
              <p>{otherCount}</p>
            </div>
            <div className="bg-[#975CED] flex justify-center items-center rounded-2xl py-2 w-[30%] px-6 md:px-12 lg:px-8 xl:px-12">
              <p>{dontManipulateWindowCount}</p>
            </div>
            <div className="bg-[#9C9898] flex justify-center items-center rounded-2xl py-2 w-[30%] px-6 md:px-12 lg:px-8 xl:px-12">
              <p>{noFaceDetectedCount}</p>
            </div>
          </section>

        </div>

        <div className="bg-white p-6 sm:p-8 w-full lg:w-1/3  rounded-[30px]  border-2">
          <div className="flex flex-col gap-4">
            <h2 className="text-xl font-bold text-navy-blue italic">
              Stability
            </h2>
            {(() => {
              let parsedStability = candidateStability;

              if (typeof candidateStability === "string") {
                try {
                  const sanitizedData = candidateStability
                    .replace(/'/g, '"')
                    .replace(/^\[|\]$/g, "");
                  parsedStability = JSON.parse(`[${sanitizedData}]`);
                } catch (e) {
                  console.error("Failed to parse candidateStability:", e);
                }
              }

              return Array.isArray(parsedStability) ? (
                parsedStability.map((item, index) => (
                  <div key={index} className="flex items-start space-x-4">
                    <div className="w-3 h-3 rounded-full bg-[#334157] flex-shrink-0 mt-2"></div>
                    <p className="text-[#334157] text-base font-semibold leading-relaxed">
                      {item.replace(/^"|"$/g, "").replace("•", "")}{" "}
                    </p>
                  </div>
                ))
              ) : (
                <p className="text-navy-blue">N/A</p>
              );
            })()}
          </div>
        </div>

      </section>
      <section className="flex gap-4 justify-between w-full">
        <div className="flex gap-4 bg-white rounded-[20px] shadow w-full flex-col p-4">
          <p className="text-xl font-[700]">Interview Summary</p>
          <p className="text-sm -mt-4 font-roboto font-[300]">
          Summary of candidate’s interview performance
          </p>
          <div className="font-manrope font-[400] flex gap-4 rounded-[24px] bg-[#F6F8FF] w-full flex-col p-4">
            {description}
          </div>
        </div>
      </section>
    </main>
  );
};
