import React, { useState, useEffect, useRef } from "react";
import { AnalyticsPageBtn, CustomButtonFilter } from "../../components/buttons";
import {
  deleteIcon,
  editIcon,
  userImg,
  searchIcon,
  arrowleft,
  arrowright,
  clockSvg,
  nodata,
} from "../../assets/imgs/index";
import { CampaignTable } from "../../components/AdminComponents/table";
import { useNavigate } from "react-router-dom";
import { handleApiResponse } from "../../API/services";
import { ModalDeleteCampaign } from "../../components/AdminComponents/modal";
import { CustomButtonWhite } from "../../components/buttons";
import {
  DropdownFilter,
  DropdownFilterMember,
} from "../../components/AdminComponents/dropdown";
import PaginationComponent from "../../components/AdminComponents/pagination"

function Campaign() {
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState();
  const navigate = useNavigate();
  const [deleteUid, setDeleteUid] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchResults, setSearchResults] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  let creator = sessionStorage.getItem("uid");
  let userType = sessionStorage.getItem("userType");
  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState();
  const [selectedStatus, setSelectedStatus] = useState("");
  const visiblePages = 3

  // Handler for the "Previous" button
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  console.log(searchResult)
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const rowsPerPage = 7;

  useEffect(() => {
    sessionStorage.removeItem("uploadResume");
    sessionStorage.removeItem("excelData");
    sessionStorage.removeItem("prevEmail");
    sessionStorage.removeItem("singleAddCandidate");
    sessionStorage.removeItem("checkedFilteredData");
    sessionStorage.removeItem("hrQuestion");
    sessionStorage.removeItem("camp_type");
    sessionStorage.removeItem("coding_ques");
    sessionStorage.removeItem("endDate1");
    sessionStorage.removeItem("createdDate");
    sessionStorage.removeItem("skills");
    sessionStorage.removeItem("campName");
    sessionStorage.removeItem("JobRole");
    sessionStorage.removeItem("selectedCheckboxes");
    sessionStorage.removeItem("savedAllSelcted");
    sessionStorage.removeItem("JobRole");
    sessionStorage.removeItem("jrName");
  }, []);

  const itemsPerPage = 7;

  const handleSearch = async (search) => {
    setHasSearched(!!search);
    const reqData = {
      q: search,
      is_deleted: 0,
      creator: userType === "3" ? creator : "",
    };
    const response = await handleApiResponse(
      "/api/v1/interview/campaign_list/",
      reqData,
      1
    );
    if (response?.code === "token_not_valid") {
      navigate("/");
      sessionStorage.clear();
      return;
    }
    console.log(response.data.data.data);
    setSearchResult(response.data.data.data);
    setTotalData(response.data.data.data.length);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      handleSearch();
    }
  };

  const findStatus = (start, end) => {
    const monthMap = {
      Jan: "1",
      Feb: "2",
      Mar: "3",
      Apr: "4",
      May: "5",
      Jun: "6",
      Jul: "7",
      Aug: "8",
      Sep: "9",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    function isSecDateBigger(date1, date2, pos) {
      // console.log(date1, date2);
      let [month1, day1, year1] = date1.split(" ");
      let [year2, month2, day2] = date2.split("-").map(Number);
      month1 = monthMap[month1];

      month1 = Number(month1);
      day1 = Number(day1);
      year1 = Number(year1);
      // console.log("today", year1, month1, day1);
      // console.log("start", year2, month2, day2);

      if (day1 === day2 && month1 === month2 && year1 === year2 && pos) {
        // console.log("true");
        return true;
      }

      if (year1 > year2) {
        return true;
      } else if (year1 < year2) {
        return false;
      }

      if (month1 > month2) {
        return true;
      } else if (month1 < month2) {
        return false;
      }

      return day1 > day2;
    }

    const todayDate = new Date();
    let thisDate = String(todayDate).slice(4, 15);
    let result = isSecDateBigger(thisDate, start, 1);
    if (result) {
      const res = isSecDateBigger(thisDate, end);
      if (res) {
        return "Completed";
      } else {
        return "Ongoing";
      }
    } else {
      return "Upcoming";
    }
  };

  const getUser = async (userLimit, pageNo) => {
    const reqData = {
      // campaign_type: 1,
      // q: search,
      is_deleted: 0,
      // creator: "",
      creator: userType === "3" ? creator : "",
    };
    const response = await handleApiResponse(
      "/api/v1/interview/campaign_list/",
      reqData,
      1
    );
    if (response?.code === "token_not_valid") {
      navigate("/");
      sessionStorage.clear();
      return;
    }
    console.log(response);
    if (response.data.status_code === 200) {
      console.log("camp displayed", Object.values(response.data.data).flat());
      console.log("response.data.data.total", response.data.data.total);
      setTotalData(response.data.data.data.length);
      setTableData(Object.values(response.data.data.data).flat());
      setSearchResults(Object.values(response.data.data.data).flat());
    }
  };

  console.log("tableData", tableData);

  const handleOptionClick = (status) => {
    setSelectedStatus(status);
    setIsOpen(false)
  };

  useEffect(() => {
    sessionStorage.setItem("currentAddCampaign", 0);

    getUser(itemsPerPage, currentPage);

    console.log("in camp", deleteUid);
  }, [isDeleteOpen]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [search, setSearch] = useState("");
  const currentPageData = tableData;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [isSortingOpen, setIsSortingOpen] = useState(false);
  const [selectedSorting, setSelectedSorting] = useState(null);
  const [selectedSort, setSelectedSort] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredData1, setFilteredData1] = useState([]);
  const [filteredData2, setFilteredData2] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const dropdownRef = useRef(null);

  // console.log(filteredData1.length)
  const handleTypeToggle = () => {
    setIsTypeOpen(!isTypeOpen);
  };

  const handleSortToggle = () => {
    setIsSortOpen(!isSortOpen);
  };

  const handleSortingToggle = () => {
    setIsSortingOpen(!isSortingOpen);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const openModalEditJobRole = () => {
    setIsModalOpen4(true);
  };

  const closeModalEditJobRole = () => {
    setIsModalOpen4(false);
  };

  const openDelete = () => {
    setIsDeleteOpen(true);
  };
  const closeDelete = () => {
    setIsDeleteOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen); // Toggle the dropdown visibility
  };

  const handleClickOutside = (event) => {
    // Check if click is outside the dropdown element
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false); // Close dropdown if clicked outside
    }
  };

  useEffect(() => {
    // Add the event listener for clicks on the entire document
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const totalPages = Math.ceil(totalData / itemsPerPage);
  console.log(searchResult && searchResult.length > 0)

  const getVisiblePageNumbers = () => {
    const halfVisible = Math.floor(visiblePages / 2);
    let start = Math.max(1, currentPage - halfVisible);
    let end = Math.min(totalPages, start + visiblePages - 1);

    if (end - start + 1 < visiblePages) {
      start = Math.max(1, end - visiblePages + 1);
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  const visiblePageNumbers = getVisiblePageNumbers();


  return (
    <section
      className="min-w-[643px]"
      style={{
        height: 'calc(96vh - 76px)',
      }}
    >
      <div
        className={`text-[#A6AEBA] font-satoshi rounded-[30px] bg-[#FFFFFF]  flex flex-col h-[100%] custom-sm:min-h-fit custom-sm-md:min-h-[10px] ${searchResults.length === 0 ? '' : ''
          }`}
      >
        {isDeleteOpen && (
          <ModalDeleteCampaign
            isOpen={openDelete}
            deleteUid={deleteUid}
            onClose={closeDelete}
          />
        )}
        {searchResults.length > 0 ? (
          <>
            <div className="flex items-center justify-between p-[1rem] custom-sm:h-[20%] custom-md2:h-[15%] max-h-[5rem]">
              <div className="text-[24px] text-[#222850] font-[700]">
                <i>Campaign List</i>
              </div>
              <div className="flex gap-2 justify-center items-center">
                <div className="relative">
                  <input
                    placeholder="Search"
                    onChange={(e) => {
                      console.log(e.target.value);
                      setCurrentPage(1);
                      // setSearch(e.target.value)
                      handleSearch(e.target.value);
                    }}
                    className="font-poppins w-[261px] h-[36px] text-[12px] p-5 pl-8 outline-0  bg-[#E9F3FF] rounded-lg mt-2 placeholder-text-sm"
                  // value=
                  // onKeyDown={handleKeyDown}
                  />
                  <img
                    src={searchIcon}
                    alt="search icon"
                    className="absolute cursor-pointer top-0 left-0 w-[16px] h-[51.2px] ml-2"
                  />
                </div>

                {/* {userType !== '2' && (
              <AnalyticsPageBtn
                name={'Add Campaign'}
                img={5}
                onClick={() => navigate('/campaign/addCampaign')}
              /> */}
              </div>
            </div>
            <div className="h-[90%] overflow-scroll">
              <div class="relative h-full flex flex-col justify-between">
                <table class="font-inter w-full text-sm text-[#A6AEBA] text-left rtl:text-right">
                  <thead class="font-[400] text-[15px] h-[54px] text-[#222850] bg-[#F7FAFC] border-b border-[#F4F5F6]">
                    <tr>
                      <td scope="col" class="px-6 text-left">
                        S.No.
                      </td>
                      <td scope="col" class="px-3 text-left">
                        Campaign Name
                      </td>
                      {userType !== '3' ? (
                        <td scope="col" class="px-3 text-left">
                          Created By Whom
                        </td>
                      ) : (
                        ''
                      )}

                      {/* {userType !== "2" && (<th scope="col" class="px-6 ">
                  Type
                </th>
                )} */}
                      <td scope="col" class="px-3 text-left">
                        Start Date
                      </td>
                      <td scope="col" class="px-3 text-left">
                        End Date
                      </td>
                      <td scope="col" class="px-3 text-left">
                        Job Role
                      </td>
                      <td scope="col" class="px-3 py-3 text-left">
                        Type
                      </td>
                      {/* {userType !== "2" && (  <th scope="col" class="px-6 py-3">
                  Edited By
                </th>
                )} */}
                      <td
                        scope="col"
                        ref={dropdownRef}
                        class="flex justify-start items-center min-w-max leading-[3] px-3 py-3 dropdown-container"
                      >
                        Status
                        <svg
                          className="status-dropdown"
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="12"
                          viewBox="0 0 23 12"
                          fill="none"
                          onClick={toggleDropdown} // Toggle the dropdown on SVG click
                          style={{ cursor: 'pointer' }} // Add cursor pointer for better UX
                        >
                          <path
                            d="M7 4L11.5 8L16 4"
                            stroke="#222850"
                            strokeLinecap="round"
                          />
                        </svg>
                        {isOpen && (
                          <div className="">
                            <DropdownFilterMember
                              options={[
                                'Ongoing',
                                'Completed',
                                'Upcoming',
                                'All',
                              ]}
                              onOptionClick={handleOptionClick}
                            />
                          </div>
                        )}
                      </td>
                      {userType === '3' ? (
                        <td scope="col" class="px-3 text-left">
                          Action
                        </td>
                      ) : (
                        <td scope="col" class="px-3 text-left">
                          Action
                        </td>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {hasSearched ? (
                      searchResult && searchResult.length > 0 ? (
                        searchResult
                          .filter((rowData) => {
                            if (selectedStatus === 'All') return true;
                            if (!selectedStatus) return true;

                            const status = findStatus(
                              rowData?.start_date?.slice(0, 10),
                              rowData?.end_date?.slice(0, 10)
                            );
                            return selectedStatus === status;
                          })
                          .slice(startIndex, endIndex)
                          .map((rowData, index) => (
                            <CampaignTable
                              key={index}
                              icon={userImg}
                              number={startIndex + index}
                              row12={rowData?.name}
                              row13={rowData?.campaign_type === 1 ? 'Regular' : 'Mass'}
                              row14={rowData?.jr_name}
                              row15={rowData?.start_date?.slice(0, 10)}
                              end={rowData?.end_date?.slice(0, 10)}
                              row17={rowData?.creater_name}
                              row19={rowData?.updater_name}
                              row18={findStatus(
                                rowData?.start_date?.slice(0, 10),
                                rowData?.end_date?.slice(0, 10)
                              )}
                              launched={rowData?.status}
                              skills={rowData?.required_skills}
                              description={rowData?.description}
                              editIcon={editIcon}
                              resumeOn={rowData?.is_candidate_resume_allow}
                              codingOn={rowData?.is_coding_quest_allow}
                              deleteIcon={deleteIcon}
                              openModal={openModalEditJobRole}
                              closeModal={closeModalEditJobRole}
                              isModalOpen4={isModalOpen4}
                              uid={rowData.uid}
                              type={rowData.campaign_type}
                              openDelete={openDelete}
                              setDeleteUid={setDeleteUid}
                              jobRoleUid={rowData.jobrole}
                              creator={rowData.creator}
                              style={{
                                backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F9FBFD',
                              }}
                            />
                          ))
                      ) : (
                        <></> // Render nothing if searchResult is empty after a search
                      )
                    ) : (
                      searchResults?.filter((rowData) => {
                        if (selectedStatus === 'All') return true;
                        if (!selectedStatus) return true;

                        const status = findStatus(
                          rowData?.start_date?.slice(0, 10),
                          rowData?.end_date?.slice(0, 10)
                        );
                        return selectedStatus === status;
                      })
                        .slice(startIndex, endIndex)
                        .map((rowData, index) => (
                          <CampaignTable
                            key={index}
                            icon={userImg}
                            number={startIndex + index}
                            row12={rowData?.name}
                            row13={rowData?.campaign_type === 1 ? 'Regular' : 'Mass'}
                            row14={rowData?.jr_name}
                            row15={rowData?.start_date?.slice(0, 10)}
                            end={rowData?.end_date?.slice(0, 10)}
                            row17={rowData?.creater_name}
                            row19={rowData?.updater_name}
                            row18={findStatus(
                              rowData?.start_date?.slice(0, 10),
                              rowData?.end_date?.slice(0, 10)
                            )}
                            launched={rowData?.status}
                            skills={rowData?.required_skills}
                            description={rowData?.description}
                            editIcon={editIcon}
                            resumeOn={rowData?.is_candidate_resume_allow}
                            codingOn={rowData?.is_coding_quest_allow}
                            deleteIcon={deleteIcon}
                            openModal={openModalEditJobRole}
                            closeModal={closeModalEditJobRole}
                            isModalOpen4={isModalOpen4}
                            uid={rowData.uid}
                            type={rowData.campaign_type}
                            openDelete={openDelete}
                            setDeleteUid={setDeleteUid}
                            jobRoleUid={rowData.jobrole}
                            creator={rowData.creator}
                            style={{
                              backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F9FBFD',
                            }}
                          />
                        ))
                    )}

                  </tbody>
                </table>
                {/* <div className="pagination flex justify-between mt-4">
            <div className='flex text-[#091E42] text-[14px] border-[1px] border-[#D0D5DD] h-fit rounded-[3rem] p-2 w-[110px] justify-center gap-2 cursor-pointer' onClick={goToPreviousPage}>
              <img src={arrowleft} alt="arrow left" className="w-[20px] h-[20px]" />
              Previous
            </div>
            <div>
              {totalData > 0 && itemsPerPage > 0 && (
                [...Array(Math.ceil(totalData / itemsPerPage)).keys()].map((page) => {
                  const pageNumber = page + 1;
                  const isCurrentPage = currentPage === pageNumber;
                  const isFirstPage = pageNumber === 1;
                  const isLastPage = pageNumber === Math.ceil(totalData / itemsPerPage);
                  const isWithinLeftRange = pageNumber >= currentPage - 3 && pageNumber < currentPage;
                  const isWithinRightRange = pageNumber <= currentPage + 3 && pageNumber > currentPage;

                  if (isFirstPage || isLastPage || isCurrentPage || isWithinLeftRange || isWithinRightRange) {
                    return (
                      <button
                        key={pageNumber}
                        onClick={() => {
                          handlePageChange(pageNumber);
                          getUser(itemsPerPage, pageNumber); // Pass the page number to getUser
                        }}
                        className={`px-3 py-2 mx-1 rounded-md text-[14px] ${isCurrentPage
                          ? " border border-[#197FF5] bg-[#E9F3FF] text-[#091E42]"
                          : " text-[#091E42]"
                          }`}
                      >
                        {pageNumber}
                      </button>
                    );
                  } else if (
                    (pageNumber === currentPage - 4 && pageNumber !== 1) ||
                    (pageNumber === currentPage + 4 && pageNumber !== Math.ceil(totalData / itemsPerPage))
                  ) {
                    return (
                      <span key={pageNumber} className="text-[#091E42] px-3 py-2 mx-1">...</span>
                    );
                  } else {
                    return null;
                  }
                })
              )}
            </div>
            <div className='flex text-[#667085] text-[14px] border-[1px] border-[#D0D5DD] h-fit rounded-[3rem] p-2 w-[110px] justify-center gap-2 cursor-pointer' onClick={goToNextPage}>
              Next
              <img src={arrowright} className="w-[20px] h-[20px]" alt="arrow right" />
            </div>
          </div> */}
                <div className="font-inter p-[1rem] pagination flex justify-between mt-4 h-full items-end">
                  <div
                    className="flex text-[#718096] text-[14px] border-[1px] border-[#D0D5DD] h-fit rounded-[3rem] pl-[0.8rem] pr-[1rem] py-2 w-[110px] py-2   justify-center gap-2 cursor-pointer"
                    onClick={goToPreviousPage}
                    style={{
                      boxShadow: '0px 1px 2px 0px #1018280D',
                    }}
                  >
                    <img
                      src={arrowleft}
                      alt="arrow left"
                      className="w-[20px] h-[20px]"
                    />
                    Previous
                  </div>

                  <PaginationComponent
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    visiblePages={3}  // Or any other number you prefer
                  />

                  <div
                    className="flex text-[#718096] text-[14px] border-[1px] border-[#D0D5DD] h-fit rounded-[3rem] pl-[1.8rem] pr-[0.8rem] py-2 w-[100px]  justify-center gap-2 cursor-pointer"
                    onClick={goToNextPage}
                    style={{
                      boxShadow: '0px 1px 2px 0px #1018280D',
                    }}
                  >
                    Next
                    <img
                      src={arrowright}
                      className="w-[20px] h-[20px]"
                      alt="arrow right"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="p-4 h-full font-[500] text-center justify-center items-center flex text-[36px] trailing-[57px] text-[#A0AEC0] flex-col gap-4">
            <img src={nodata} alt="no data" className="w-[135px] h-[135px]" />
            No campaign added yet
          </div>
        )}
      </div>
    </section>
  );
}

export default Campaign;
