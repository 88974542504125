import React, { useState, useEffect, useRef } from "react";
import mammoth from "mammoth";
import {
  close,
  closeBtnIcon,
  closeButton,
  closeIcon,
  correct,
  resume,
  resumeUpload,
  wrong,
} from "../../assets/imgs";
import importIcon from "../../assets/imgs/browseicon1.svg";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";

import {
  InputBox,
  InputBox1,
  InputBox3,
  InputBoxColor,
  InputBoxg,
  InputBoxRed,
} from "../../features/pages/FillFormPage";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {
  CustomBlueButton,
  CustomButton,
  CustomButtonWhite,
  CustomWhiteButton,
  DownloadButton1,
  NextButton,
} from "../buttons";
import { UploadResumeOnly } from "../../features/pages/FillFormPage";
import { useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import {
  handleApiResponse,
  handleFormDataApiResponse,
} from "../../API/services";
import axios from "axios";
import OtpValidation from "./OtpValidation";
import {
  validateDesignation,
  validateEmail,
  validateName,
} from "../../utils/validation";
import { toast } from "react-toastify";
import { FiRewind } from "react-icons/fi";
import { useSelector } from "react-redux";
import { Loader } from "../loader";
import { propTypesInteractive } from "@material-tailwind/react/types/components/popover";
const base_url = process.env.REACT_APP_URL;
const file_url = process.env.REACT_APP_URL;

export const Modal = ({ editData, onClose }) => {
  const [status, setStatus] = useState(
    editData?.status === 1 ? "active" : "inactive"
  );
  const [resume, setResume] = useState(true);

  const [mobile, setMobile] = useState(editData?.mobile || "");
  const [dept, setDept] = useState(editData?.department || "");
  const organization = sessionStorage.getItem("organization");

  const [firstChange, setFirstChange] = useState(editData?.first_name || "");
  const [lastChange, setLastChange] = useState(editData?.last_name || "");
  const [emailChange, setEmailChange] = useState(editData?.email || "");
  const [designationChange, setDesignationChange] = useState(
    editData?.designation || ""
  );
  const [apiResponseError, setApiResponseError] = useState("");
  const [error, setError] = useState({
    first: "0",
    last: "0",
    email: "0",
    designation: "0",
  });
  console.log("edit ", editData);
  console.log("edit resume ", editData.is_resume_upload_allow);

  useEffect(() => {
    console.log(error);
  }, [error]);

  const handleDeptChange = (e) => {
    let newValue = e.target.value;
    setDept(newValue);
  };
  const navigate = useNavigate();

  const handleMobileChange = (e) => {
    if (/^\d{0,10}$/.test(e.target.value)) {
      setMobile(e.target.value);
    }
  };

  const setClose = () => {
    setFirstChange("");
    setLastChange("");
    setEmailChange("");
    setDesignationChange("");
    setApiResponseError("");
    setError({
      first: "0",
      last: "0",
      email: "0",
      designation: "0",
    });
    onClose();
  };

  const setCloseOnFocus = () => {
    // setConfirmPassword('');
    // setPassword('');
    // setFirstChange('');
    // setLastChange('');
    // setEmailChange('');
    // setDesignationChange('');
    // setApiResponseError('');
    // setError({
    //   first: '0',
    //   last: '0',
    //   email: '0',
    //   designation: '0',
    //   password: '0',
    //   confirmPassword: '0',
    // });
    onClose();
  };

  const handleFirstChange = (e) => {
    let first = e.target.value;
    if (validateName(first)) {
      setError((prevState) => ({
        ...prevState,
        first: "0",
      }));
      console.log("valid", first);
      setFirstChange(first);
    } else {
      return;
    }
  };

  const handleLastChange = (e) => {
    let newValue = e.target.value;
    if (validateName(newValue)) {
      setError((prevState) => ({
        ...prevState,
        last: "0",
      }));
      setLastChange(newValue);
    }
  };

  const handleDesignationChange = (e) => {
    let newValue = e.target.value;
    if (newValue === "") {
      // Limit to 40 characters
      newValue = newValue;
      setError((prevState) => ({
        ...prevState,
        designation: newValue ? "0" : "1",
      }));

      setDesignationChange(newValue);
    }
  };

  const validation = () => {
    let isValid = 1;

    if (!firstChange) {
      console.log("set firstname");
      setError((prevState) => ({
        ...prevState,
        first: "1",
      }));
      isValid = 0;
    }

    if (!lastChange) {
      console.log("set lastname");
      setError((prevState) => ({
        ...prevState,
        last: "1",
      }));
      isValid = 0;
    }

    if (!validateEmail(emailChange)) {
      console.log("set lastname");
      setError((prevState) => ({
        ...prevState,
        email: "1",
      }));
      isValid = 0;
    }

    if (!designationChange) {
      console.log("set designation", designationChange);
      setError((prevState) => ({
        ...prevState,
        designation: "1",
      }));
      isValid = 0;
    }
    if (mobile.length !== 10 && mobile.length !== 0) {
      console.log("set mob", mobile.length);
      setError((prevState) => ({
        ...prevState,
        mobile: "1",
      }));
      isValid = 0;
    }

    //   if (!password) {
    //     console.log('set password');
    //     setError((prevState) => ({
    //       ...prevState,
    //       password: '1',
    //     }));
    //     isValid = 0;
    //   }

    //   if (!confirmPassword) {
    //     console.log('set designation');
    //     setError((prevState) => ({
    //       ...prevState,
    //       confirmPassword: '1',
    //     }));
    //     isValid = 0;
    //   }

    //   if (password !== confirmPassword) {
    //     toast.error('Password does not match');
    //     isValid = 0;
    //   }
    return isValid;
  };
  console.log("fefe", editData);
  const handleAdd = async () => {
    console.log(
      "form Data ",
      firstChange,
      lastChange,
      emailChange,
      designationChange
    );
    console.log(status);
    if (validation()) {
      console.log("inside validate", validation());
      const reqData = {
        uid: editData?.uid,
        first_name: firstChange,
        last_name: lastChange,
        email: emailChange,
        designation: designationChange,
        status: status === "inactive" ? 0 : 1,
        is_resume_upload_allow: resume,
        organization: organization,
      };

      if (dept.length) {
        reqData["department"] = dept;
      }

      const response = await handleApiResponse(
        "/api/v1/updateuser/",
        reqData,
        1
      );
      if (response?.code === "token_not_valid") {
        navigate("/");
        sessionStorage.clear();
        return;
      }
      if (response?.status === 200) {
        if (response.data.status_code === 200) {
          toast.success("User Updated", {
            className: "custom-toast-success",
          });
          setClose();
        } else {
          if (response.data.msg.split(":")[0].includes("duplicate")) {
            setApiResponseError("User already exists");
          } else {
            setApiResponseError(response.data.msg);
          }
        }
      }
    }
  };
  const handleEmailChange = (e) => {
    setError((prevState) => ({
      ...prevState,
      email: "0",
    }));
    setEmailChange(e.target.value);
  };

  const handleToggle = (event) => {
    const selectedValue = event.target.value;
    setStatus(selectedValue);
    // Additional logic to filter or handle the selected member type
  };

  const handleToggleResume = () => {
    setResume((prevState) => !prevState);
  };

  useEffect(() => {
    console.log(editData);
  }, []);
  return (
    <>
      <div className="flex justify-center items-center h-[100vh] w-[100vw] backdrop-blur absolute top-0 left-0 z-50">
        <div className="w-[702px] text-[#00112D] h-auto bg-white rounded-[30px] p-8 shadow-md   ">
          {/* <div className="items-center flex justify-between"> */}
          <p className="text-[24px] font-semibold">Edit Member</p>
          <p className="font-satoshi text-[14px] font-normal">
            Add a single team member by filling out the form below.
          </p>
          {/* <img
              src={closeButton}
              alt="close button"
              onClick={setClose}
              className="cursor-pointer"
            /> */}
          {/* </div> */}
          {/* <hr className="my-2" /> */}
          <div className="flex gap-4 justify-between mt-4">
            <div className="w-[50%] font-normal mb-[10px]">
              <InputBox1
                name={"First Name"}
                holder={"Enter first name"}
                value={firstChange}
                onChange={handleFirstChange}
                error={"Fill a valid first name"}
                showErrorOnInput={error["first"]}
              />
            </div>
            <div className="w-[50%] font-normal mb-[10px]">
              <InputBox1
                name={"Last Name"}
                holder={"Enter last name"}
                value={lastChange}
                onChange={handleLastChange}
                error={"Fill a valid last name"}
                showErrorOnInput={error["last"]}
              />
            </div>
          </div>
          <div className="font-normal mb-[10px]">
            <InputBox1
              name={"Email ID"}
              holder={"Enter work email address"}
              value={emailChange}
              // onChange={handleEmailChange}
              // error={"Fill a valid Email"}
              // showErrorOnInput={error["email"]}
              readOnly
            />
          </div>
          <div className="flex gap-4">
            <div className="font-normal w-[50%] mb-[10px] fill-available">
              <InputBox1
                name={"Job Title"}
                holder={"e.g., HR Manager, Technical Interviewer"}
                value={designationChange}
                onChange={handleDesignationChange}
                error={"Fill a valid Designation"}
                showErrorOnInput={error["designation"]}
              />
            </div>
            <div className="font-normal w-[50%] mb-[10px] fill-available">
              <InputBox1
                name={"Department"}
                holder={"e.g., Human Resources, Engineering"}
                value={dept}
                onChange={handleDeptChange}
              />
            </div>
          </div>

          <div className="text-[14px] flex flex-col gap-2 mt-2">
            <label htmlFor="memberType">
              Member Status<span className="text-red-500">*</span>
            </label>
            <div className="border-[1px]  border-[#22284E] rounded-lg">
              <select
                id="memberType"
                className="h-[50px] outline-none fill-available text-[14px] bg-white m-[5px] cursor-pointer "
                value={status}
                onChange={handleToggle} // This will be updated to handle dropdown change
              >
                <option value="active">Active member</option>
                <option value="inactive">Inactive member</option>
              </select>
            </div>
          </div>

          {<div className="text-red-500">{apiResponseError}</div>}

          <div className="mt-12 gap-12 flex justify-center">
            {/* <CustomWhiteButton text={'Cancel'} onClick={setClose} /> */}
            <div
              className={`w-[50%] text-[18px] h-[52px] border-[1px]  rounded-[16px] text-[#22284E] flex justify-center items-center cursor-pointer  border-[#22284E]`}
              onClick={setClose}
            >
              Cancel
            </div>
            {/* <div
              className={`w-[384px] text-[14px] h-[52px] rounded-[16px] text-[#22284E] mt-4 flex justify-center items-center cursor-pointer ${hovered ? 'border-2 border-[#22284E]' : ''
                }`}
              onClick={handleAdd}
            >
              Save
            </div> */}
            <button
              className={`w-[50%] text-[18px] justify-end text-white h-[52px] rounded-[16px] bg-[#22284E]`}
              onClick={handleAdd}
            >
              Save
            </button>
            {/* <button
              className="h-12 w-[12.5rem] border-2"
              style={{
                backgroundColor: 'white',
                transition: 'background-color 0.3s',
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = '#F1F2F4';
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = '';
              }}
              onClick={handleAdd}
            >
              Update
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export const Modal1 = ({ isOpen, onClose }) => {
  const organization = sessionStorage.getItem("organization");
  const [mobile, setMobile] = useState("");
  const [dept, setDept] = useState("");
  const [password, setPassword] = useState("1234");
  const [firstChange, setFirstChange] = useState("");
  const [lastChange, setLastChange] = useState("");
  const [emailChange, setEmailChange] = useState("");
  const [nextStep, setNextStep] = useState(false);
  const [designationChange, setDesignationChange] = useState("");
  console.log(designationChange, "designationChange");
  const [apiResponseError, setApiResponseError] = useState("");
  const [error, setError] = useState({
    first: "0",
    last: "0",
    email: "0",
    designation: "0",
    mobile: 0,
  });

  const [uploadError, setUploadError] = useState(true);
  const [status, setStatus] = useState(true);
  const [resume, setResume] = useState(true);
  const [uploadResume, setUploadResume] = useState(null);
  const [excelData, setExcelData] = useState(null);
  const navigate = useNavigate();
  const handleToggle = () => {
    setStatus((prevStatus) => !prevStatus);
  };

  const generateExcelFile = () => {
    const requiredHeaders = [
      "First Name",
      "Last Name",
      "Email",
      "Designation",
      "Department",
    ];

    // Create a new workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([]);

    // Append the headers
    XLSX.utils.sheet_add_aoa(ws, [requiredHeaders], { origin: "A1" });

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate Excel file and trigger download
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    saveAs(blob, "members_template.xlsx");
  };

  const handleToggleResume = () => {
    setResume((prev) => !prev);
  };

  const handlePasswordChange = (e) => {
    setError((prevState) => ({
      ...prevState,
      password: "0",
    }));
    setPassword(e.target.value);
  };

  const setClose = () => {
    setFirstChange("");
    setLastChange("");
    setEmailChange("");
    setDesignationChange("");
    setApiResponseError("");
    setDept("");
    setMobile("");
    setError({
      first: "0",
      last: "0",
      email: "0",
      designation: "0",
      mobile: 0,
    });
    onClose();
  };

  const setCloseOnFocus = () => {
    // setConfirmPassword('');
    // setPassword('');
    // setFirstChange('');
    // setLastChange('');
    // setEmailChange('');
    // setDesignationChange('');
    // setApiResponseError('');
    // setError({
    //   first: '0',
    //   last: '0',
    //   email: '0',
    //   designation: '0',
    //   password: '0',
    //   confirmPassword: '0',
    // });
    onClose();
  };

  const handleFirstChange = (e) => {
    let first = e.target.value;
    if (validateName(first)) {
      setError((prevState) => ({
        ...prevState,
        first: "0",
      }));
      console.log("valid", first);
      setFirstChange(first);
    } else {
      return;
    }
  };

  const handleLastChange = (e) => {
    let newValue = e.target.value;
    if (validateName(newValue)) {
      setError((prevState) => ({
        ...prevState,
        last: "0",
      }));
      setLastChange(newValue);
    }
  };

  const handleEmailChange = (e) => {
    setError((prevState) => ({
      ...prevState,
      email: "0",
    }));
    setEmailChange(e.target.value);
  };

  const handleDesignationChange = (e) => {
    let newValue = e.target.value;

    // Remove leading spaces
    newValue = newValue.replace(/^\s+/, "");

    // Validate using a regex that allows spaces and certain special characters
    const validInput = /^[A-Za-z][A-Za-z0-9@\s!#%^&*'()_\-+=]*$/.test(newValue);

    if (newValue === "" || validInput) {
      // Limit to 40 characters
      newValue = newValue;
      setError((prevState) => ({
        ...prevState,
        designation: newValue ? "0" : "1",
      }));

      setDesignationChange(newValue);
    }
  };

  const handleDeptChange = (e) => {
    let newValue = e.target.value;

    setDept(newValue);
  };

  const handleMobileChange = (e) => {
    if (/^\d{0,10}$/.test(e.target.value)) {
      setMobile(e.target.value);
    }
  };

  const validation = () => {
    let isValid = 1;

    if (!firstChange) {
      console.log("set firstname");
      setError((prevState) => ({
        ...prevState,
        first: "1",
      }));
      isValid = 0;
    }

    if (!lastChange) {
      console.log("set lastname");
      setError((prevState) => ({
        ...prevState,
        last: "1",
      }));
      isValid = 0;
    }

    if (!validateEmail(emailChange)) {
      console.log("set lastname");
      setError((prevState) => ({
        ...prevState,
        email: "1",
      }));
      isValid = 0;
    }

    if (!designationChange) {
      console.log("set designation", designationChange);
      setError((prevState) => ({
        ...prevState,
        designation: "1",
      }));
      isValid = 0;
    }

    if (mobile.length !== 10 && mobile.length !== 0) {
      console.log("set mob", mobile.length);
      setError((prevState) => ({
        ...prevState,
        mobile: "1",
      }));
      isValid = 0;
    }

    // if (!password) {
    //   console.log('set password');
    //   setError((prevState) => ({
    //     ...prevState,
    //     password: '1',
    //   }));
    //   isValid = 0;
    // }

    // if (!confirmPassword) {
    //   console.log('set designation');
    //   setError((prevState) => ({
    //     ...prevState,
    //     confirmPassword: '1',
    //   }));
    //   isValid = 0;
    // }

    // if (password !== confirmPassword) {
    //   toast.error('Password does not match');
    //   isValid = 0;
    // }
    return isValid;
  };

  const handleAdd = async () => {
    setNextStep(true);
    if (!uploadResume) {
      console.log(
        "form Data ",
        uploadResume,
        firstChange,
        lastChange,
        emailChange,
        designationChange
      );
      console.log("return from validation", validation());
      if (validation()) {
        console.log("inside validate", validation());
        let reqData = {
          first_name: firstChange,
          last_name: lastChange,
          email: emailChange,
          designation: designationChange,
          status: status ? 1 : 0,
          is_resume_upload_allow: resume,
          organization: organization,
        };
        if (dept.length) {
          reqData["department"] = dept;
        }

        console.log(reqData);
        const response = await handleApiResponse(
          "/api/v1/auth/generate_member_link/",
          reqData,
          1
        );
        if (response?.code === "token_not_valid") {
          navigate("/");
          sessionStorage.clear();
          return;
        }
        if (response?.status === 200) {
          if (response.data.status_code === 200) {
            toast.success("User Added", {
              className: "custom-toast-success",
            });
            setClose();
          } else {
            if (response.data.msg.split(":")[0].includes("duplicate")) {
              setApiResponseError("User already exists");
            } else {
              setApiResponseError(response.data.msg);
            }
          }
        }
      }
    }
  };

  const handleDropResume = (e) => {
    e.preventDefault();
    const permissibleExtensions = ["xls", "xlsx"];
    const files = Array.from(e.dataTransfer.files);
    if (
      files.length > 0 &&
      permissibleExtensions.includes(
        files[0].name.split(".").pop().toLowerCase()
      )
    ) {
      setUploadResume(files[0]);
      handleExcelData(files[0]);
    } else {
      toast.warning("Invalid file type. Please upload a PDF file.", {
        className: "custom-toast-warning",
      });
    }
  };

  const handleUploadResume = (e) => {
    const file = e.target.files[0];
    const extension = file.name.split(".").pop().toLowerCase();
    const allowedExtensions = [
      "pdf",
      "docx",
      "csv",
      "xlsx",
      "odt",
      "txt",
      "ods",
    ];

    if (allowedExtensions.includes(extension)) {
      setUploadResume(file);
      handleExcelData(file);
      console.log("handleUploadResume");
    } else {
      // Optionally, add a warning or error message for unsupported file types
      toast.warning(
        "Unsupported file type. Please upload a PDF, DOCX, or CSV file.",
        {
          className: "custom-toast-warning",
        }
      );
      setUploadResume("");
    }
  };

  const handleExcelData = (file) => {
    const reader = new FileReader();
    const requiredHeaders = [
      "First Name",
      "Last Name",
      "Email",
      "Designation",
      "Department",
    ];
    const columnsToExtract = [
      "First Name",
      "Last Name",
      "Email",
      "Designation",
      "Department",
    ];
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const FirstRow = parsedData[0];

      const isValidFormat = requiredHeaders.every(
        (header, index) => FirstRow[index] === header
      );

      if (isValidFormat) {
        const dataRows = parsedData.slice(1);
        const finalData = dataRows.filter((ele) => {
          if (ele.length !== 0) {
            return ele;
          }
        });

        const isDataValid = finalData.every((row) =>
          columnsToExtract.every(
            (column) =>
              row[FirstRow.indexOf(column)] !== undefined &&
              row[FirstRow.indexOf(column)] !== ""
          )
        );

        console.log("is valid data ", isDataValid);
        if (isDataValid) {
          setExcelData(parsedData);
          setError("");
        } else {
          setExcelData([]);
          setUploadResume("");
          setUploadError("Missing data in required columns");
        }
      } else {
        setExcelData([]);
        setUploadResume("");
        setUploadError("Wrong Format");
      }
    };

    reader.onerror = () => {
      setUploadError("Error reading file");
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <>
      {isOpen && (
        <div className="flex justify-center items-center h-[100vh] w-[100vw] backdrop-blur absolute top-0 left-0  z-50">
          {uploadResume && nextStep && (
            <ModalAddingBulkMembers
              onClose={onClose}
              excelData={excelData}
              closeModal={() => setUploadResume(null)}
            />
          )}
          <div className="w-[702px] font-sans1  text-[#00112D] h-auto bg-white rounded-[30px] px-6 py-4 shadow-md   ">
            {/* <div className="items-center flex justify-between"> */}
            <section className="flex flex-col bg-[#F5F8FF] p-2 px-4 gap-4 rounded-2xl">
              <div>
                <h1 className="text-[#00112D] font-semibold text-[24px]">
                  Import Member By Uploading a List
                </h1>
                <p className="text-[14px] font-[400]">
                  Quickly add multiple team members by uploading a CSV file.
                  Ensure your CSV file matches the required format.{" "}
                </p>
              </div>

              <div className="flex flex-col gap-2">
                <div className="font-[500] text-[14px] flex justify-between font-[manrope]">
                  <p className="">Import Member List</p>
                  <button
                    onClick={generateExcelFile}
                    className="text-[#E03137] pr-[3px]"
                  >
                    Download Format
                  </button>
                </div>
                <div className="flex flex-col fill-available">
                  <div
                    onDrop={handleDropResume}
                    onDragOver={(e) => e.preventDefault()}
                    className=" relative text-white placeholder:text-[#8893A2] focus:outline-none flex items-center w-full p-[1px] rounded-xl "
                  >
                    <input
                      type="text"
                      value={uploadResume?.name}
                      placeholder="Drag and drop CSV file here (Max file size : 50MB)"
                      className=" text-[14px] h-[51px] flex-grow p-3.5 px-3 rounded-xl border-[1px] border-[#22284E] text-black placeholder:text-[#A6AEBA] focus:outline-none"
                      readOnly
                      style={{
                        letterSpacing: "2%",
                      }}
                    />
                    <div
                      className="absolute top-[0.4rem] right-[0.4rem] z- 10 flex items-center justify-center cursor-pointer h-[39px] w-[187px] bg-[#22284E] text-white rounded-lg ml-2"
                      onDrop={handleDropResume}
                      onDragOver={(e) => e.preventDefault()}
                    >
                      <label
                        htmlFor="resume-upload"
                        className="flex items-center justify-center gap-[0.4rem] text-[16px]"
                      >
                        <img src={importIcon} alt="upload icon" />
                        Browse
                        <input
                          type="file"
                          id="resume-upload"
                          className="hidden"
                          onChange={handleUploadResume}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <span className="text-red-500"> {uploadError}</span>
              </div>
            </section>
            <section className="mt-2">
              <p className="text-[24px] font-semibold">Add Member</p>
              <p className="font-satoshi text-[14px] font-normal">
                Add a single team member by filling out the form below.
              </p>
              {/* <img
                      src={closeButton}
                      alt="close button"
                      onClick={setClose}
                      className="cursor-pointer"
                    /> */}
              {/* </div> */}
              {/* <hr className="my-2" /> */}
              <div className="flex gap-4 justify-between mt-4">
                <div className="w-[50%] font-normal mb-[10px]">
                  <InputBox1
                    name={"First Name"}
                    holder={"Enter first name"}
                    value={firstChange}
                    onChange={handleFirstChange}
                    error={"Fill a valid first name"}
                    showErrorOnInput={error["first"]}
                  />
                </div>
                <div className="w-[50%] font-normal mb-[10px]">
                  <InputBox1
                    name={"Last Name"}
                    holder={"Enter last name"}
                    value={lastChange}
                    onChange={handleLastChange}
                    error={"Fill a valid last name"}
                    showErrorOnInput={error["last"]}
                  />
                </div>
              </div>
              <div className="font-normal mb-[10px]">
                <InputBox1
                  name={"Email ID"}
                  holder={"Enter work email address"}
                  value={emailChange}
                  onChange={handleEmailChange}
                  error={"Fill a valid Email"}
                  showErrorOnInput={error["email"]}
                  readOnly
                />
              </div>
              <div className="flex gap-4">
                <div className="font-normal mb-[10px] w-[50%] fill-available">
                  <InputBox1
                    name={"Job Title"}
                    holder={"e.g., HR Manager, Technical Interviewer"}
                    value={designationChange}
                    onChange={handleDesignationChange}
                    error={"Fill a valid Designation"}
                    showErrorOnInput={error["designation"]}
                  />
                </div>

                <div className="font-normal mb-[10px] w-[50%] fill-available">
                  <InputBox1
                    name={"Department"}
                    holder={"e.g., Human Resources, Engineering"}
                    value={dept}
                    onChange={handleDeptChange}
                  />
                </div>
              </div>

              {/* <div className="font-normal mb-[10px]">
                    <label htmlFor="">Mobile number</label>
                    <input
                      type="text"
                      className="p-3 outline-0 border-[#F1F2F4] bg-[#FBFBFB] rounded-lg mt-2 placeholder-text-sm fill-available"
                      value={mobile}
                      onChange={handleMobileChange}
                    />
                  </div> */}

              {/* <div className="w-auto flex font-normal mb-[10px] relative">
                    <span className="flex flex-col w-screen">
                      <span className="flex">
                        <label htmlFor="">Password</label>
                        <span className="text-red-500">*</span>
                      </span>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Create password"
                        value={password}
                        className=" border-[#F1F2F4] outline-0 bg-[#FBFBFB] p-3 rounded-lg mt-2"
                        onChange={handlePasswordChange}
                      />
                    </span>
                    <button
                      onClick={togglePasswordVisibility}
                      className="mr-[10px] mt-[15px] absolute inset-y-0 right-0 h-full flex items-center px-2"
                    >
                      {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </button>
                  </div>
                  {error['password'] === '1' ? (
                    <div className=" mt-[-12px] mb-[12px] text-red-500">
                      Fill the password
                    </div>
                  ) : null}
                  <div className="w-auto flex font-normal mb-[10px] relative">
                    <span className="flex flex-col w-screen">
                      <span className="flex">
                        <label htmlFor="">Confirm Password</label>
                        <span className="text-red-500">*</span>
                      </span>
                      <input
                        type={showPassword1 ? 'text' : 'password'}
                        value={confirmPassword}
                        placeholder="Re-enter password"
                        className="border-[#F1F2F4] bg-[#FBFBFB] p-3 rounded-lg outline-0 mt-2"
                        onChange={handleConfirmPasswordChange}
                      />
                    </span>
                    <button
                      onClick={togglePasswordVisibility1}
                      className="mr-[10px] mt-[15px] absolute inset-y-0 right-0 h-full flex items-center px-2"
                    >
                      {showPassword1 ? <FaEye /> : <FaEyeSlash />}
                    </button>
                  </div>
                  {error['confirmPassword'] === '1' ? (
                    <div className="mt-[-12px] mb-[12px] text-red-500">
                      Fill the confirm password
                    </div>
                  ) : null} */}

              {/* <div className="text-[14px] flex gap-2 mt-2">
                    <label htmlFor="">Member Type</label>
                    <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        id="toggle"
                        className={`toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border-4 appearance-none cursor-pointer ${status ? 'right-0 border-green-400' : 'left-0 border-gray-300'
                          }`}
                        checked={status}
                        onChange={handleToggle}
                      />
                      <label
                        htmlFor="toggle"
                        className={`toggle-label block overflow-hidden h-5 rounded-full bg-gray-300 cursor-pointer
                  }`}
                      ></label>
                    </div>
                  </div> */}

              <div className="text-[14px] flex flex-col gap-2 mt-2">
                <label htmlFor="memberType">
                  Member Status<span className="text-red-500">*</span>
                </label>
                <div className="border-[1px] border-[#22284E] rounded-lg">
                  <select
                    id="memberType"
                    className="h-[50px] outline-none fill-available text-[14px] m-[5px] bg-white  cursor-pointer"
                    value={status}
                    onChange={handleToggle} // This will be updated to handle dropdown change
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                    {/* <option value="pending">Pending Approval</option> */}
                  </select>
                </div>
              </div>

              {/* <div className="flex gap-2 my-6">
                    <label htmlFor="">Upload resume</label>
                    <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        id="toggleResume"
                        className={`toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border-4 appearance-none cursor-pointer ${
                          resume ? 'right-0 border-green-400' : 'left-0 border-gray-300'
                        }`}
                        checked={resume}
                        onChange={handleToggleResume}
                      />
                      <label
                        htmlFor="toggleResume"
                        className={`toggle-label block overflow-hidden h-5 rounded-full bg-gray-300 cursor-pointer
                  }`}
                      ></label>
                    </div>
                  </div> */}

              {<div className="text-red-500">{apiResponseError}</div>}
            </section>

            <div className="mt-6 gap-12 flex justify-center">
              {/* <CustomWhiteButton text={'Cancel'} onClick={setClose} /> */}
              <div
                className={`w-[50%] text-[18px] h-[52px] rounded-[16px] text-[#22284E] flex justify-center items-center cursor-pointer border-[1px] border-[#22284E]`}
                onClick={setClose}
              >
                Cancel
              </div>
              {/* <div
                    className={`w-[384px] text-[14px] h-[52px] rounded-[16px] text-[#22284E] mt-4 flex justify-center items-center cursor-pointer ${hovered ? 'border-2 border-[#22284E]' : ''
                      }`}
                    onClick={handleAdd}
                  >
                    Save
                  </div> */}
              <button
                className={`w-[50%] text-[18px] justify-end text-white h-[52px] rounded-[16px] bg-[#22284E]`}
                onClick={handleAdd}
              >
                Save
              </button>
              {/* <button
                    className="h-12 w-[12.5rem] border-2"
                    style={{
                      backgroundColor: 'white',
                      transition: 'background-color 0.3s',
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = '#F1F2F4';
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = '';
                    }}
                    onClick={handleAdd}
                  >
                    Update
                  </button> */}
            </div>
          </div>
        </div>
        // <div className="fixed inset-0 overflow-auto flex justify-end z-50">
        //   <div className="fill-available" onClick={setCloseOnFocus}></div>
        //   <div className="w-[34.792vw] h-auto bg-white rounded-lg p-8 shadow-md absolute ">
        //     <div className="items-center flex justify-between">
        //       <p className="text-[1.528vw] font-medium">Add Member</p>
        //       <img
        //         src={closeButton}
        //         alt="close button"
        //         onClick={setClose}
        //         className="cursor-pointer"
        //       />
        //     </div>
        //     <hr className="my-2" />
        //     <div className="flex gap-4 justify-between mt-4">
        //       <div className="w-[50%] font-normal mb-[10px]">
        //         <InputBox1
        //           name={'First Name'}
        //           holder={'Enter first name'}
        //           value={firstChange}
        //           onChange={handleFirstChange}
        //           error={'Fill a valid first name'}
        //           showErrorOnInput={error['first']}
        //         />
        //       </div>
        //       <div className="w-[50%] font-normal mb-[10px]">
        //         <InputBox1
        //           name={'Last Name'}
        //           holder={'Enter last name'}
        //           value={lastChange}
        //           onChange={handleLastChange}
        //           error={'Fill a valid last name'}
        //           showErrorOnInput={error['last']}
        //         />
        //       </div>
        //     </div>
        //     <div className="font-normal mb-[10px]">
        //       <InputBox1
        //         name={'Email'}
        //         holder={'Enter email'}
        //         value={emailChange}
        //         onChange={handleEmailChange}
        //         error={'Fill a valid Email'}
        //         showErrorOnInput={error['email']}
        //       />
        //     </div>
        //     <div className="font-normal mb-[10px] fill-available">
        //       <InputBox1
        //         name={'Designation'}
        //         holder={'Enter designation'}
        //         value={designationChange}
        //         onChange={handleDesignationChange}
        //         error={'Fill a valid Designation'}
        //         showErrorOnInput={error['designation']}
        //       />
        //     </div>
        //     {/* <div className="font-normal mb-[10px]">
        //       <label htmlFor="">organization</label>
        //       <span className="text-red-500">*</span>
        //       <input
        //         type="text"
        //         className="p-3 outline-0 border-[#F1F2F4] bg-[#FBFBFB] rounded-lg mt-2 placeholder-text-sm fill-available"
        //         value={organization}
        //         readOnly
        //       />
        //     </div> */}
        //     <div className="font-normal mb-[10px]">
        //       <label htmlFor="">Department</label>
        //       <input
        //         type="text"
        //         placeholder={'Enter department'}
        //         className="p-3 outline-0 border-[#F1F2F4] bg-[#FBFBFB] rounded-lg mt-2 placeholder-text-sm fill-available"
        //         value={dept}
        //         onChange={handleDeptChange}
        //       />
        //     </div>
        //     {/* <div className="font-normal mb-[10px]">
        //       <label htmlFor="">Mobile number</label>
        //       <input
        //         type="text"
        //         className="p-3 outline-0 border-[#F1F2F4] bg-[#FBFBFB] rounded-lg mt-2 placeholder-text-sm fill-available"
        //         value={mobile}
        //         onChange={handleMobileChange}
        //       />
        //     </div>
        //     {error.mobile === '1' ? (
        //       <div className="text-red-500">Mobile should be of 10 digits</div>
        //     ) : null} */}

        //     {/* <div className="w-auto flex font-normal mb-[10px] relative">
        //       <span className="flex flex-col w-screen">
        //         <span className="flex">
        //           <label htmlFor="">Password</label>
        //           <span className="text-red-500">*</span>
        //         </span>
        //         <input
        //           type={showPassword ? 'text' : 'password'}
        //           placeholder="Create password"
        //           value={password}
        //           className=" border-[#F1F2F4] outline-0 bg-[#FBFBFB] p-3 rounded-lg mt-2"
        //           onChange={handlePasswordChange}
        //         />
        //       </span>
        //       <button
        //         onClick={togglePasswordVisibility}
        //         className="mr-[10px] mt-[15px] absolute inset-y-0 right-0 h-full flex items-center px-2"
        //       >
        //         {showPassword ? <FaEye /> : <FaEyeSlash />}
        //       </button>
        //     </div>
        //     {error['password'] === '1' ? (
        //       <div className=" mt-[-12px] mb-[12px] text-red-500">
        //         Fill the password
        //       </div>
        //     ) : null}
        //     <div className="w-auto flex font-normal mb-[10px] relative">
        //       <span className="flex flex-col w-screen">
        //         <span className="flex">
        //           <label htmlFor="">Confirm Password</label>
        //           <span className="text-red-500">*</span>
        //         </span>

        //         <input
        //           type={showPassword1 ? 'text' : 'password'}
        //           value={confirmPassword}
        //           placeholder="Re-enter password"
        //           className="border-[#F1F2F4] bg-[#FBFBFB] p-3 rounded-lg outline-0 mt-2"
        //           onChange={handleConfirmPasswordChange}
        //         />
        //       </span>
        //       <button
        //         onClick={togglePasswordVisibility1}
        //         className="mr-[10px] mt-[15px] absolute inset-y-0 right-0 h-full flex items-center px-2"
        //       >
        //         {showPassword1 ? <FaEye /> : <FaEyeSlash />}
        //       </button>
        //     </div>
        //     {error['confirmPassword'] === '1' ? (
        //       <div className="mt-[-12px] mb-[12px] text-red-500">
        //         Fill the confirm password
        //       </div>
        //     ) : null} */}

        //     <div className="flex gap-2 my-6">
        //       <label htmlFor="">Active</label>
        //       <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
        //         <input
        //           type="checkbox"
        //           id="toggle"
        //           className={`toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border-4 appearance-none cursor-pointer ${status
        //             ? 'right-0 border-green-400'
        //             : 'left-0 border-gray-300'
        //             }`}
        //           checked={status}
        //           onChange={handleToggle}
        //         />
        //         <label
        //           htmlFor="toggle"
        //           className={`toggle-label block overflow-hidden h-5 rounded-full bg-gray-300 cursor-pointer
        //   }`}
        //         ></label>
        //       </div>
        //     </div>

        //     {/* <div className="flex gap-2 my-6">
        //       <label htmlFor="">Upload resume</label>
        //       <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
        //         <input
        //           type="checkbox"
        //           id="toggleResume"
        //           className={`toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border-4 appearance-none cursor-pointer ${
        //             resume
        //               ? 'right-0 border-green-400'
        //               : 'left-0 border-gray-300'
        //           }`}
        //           checked={resume}
        //           onChange={handleToggleResume}
        //         />
        //         <label
        //           htmlFor="toggleResume"
        //           className={`toggle-label block overflow-hidden h-5 rounded-full bg-gray-300 cursor-pointer
        //   }`}
        //         ></label>
        //       </div>
        //     </div> */}

        //     {<div className="text-red-500">{apiResponseError}</div>}

        //     <div className=" gap-4 flex justify-center">
        //       <CustomButtonWhite text={'Cancel'} onClick={setClose} />

        //       {/* <CustomButton text={'Add'} onChange={handleAdd}/> */}
        //       <button
        //         className="h-12 w-[12.5rem] border-2"
        //         style={{
        //           backgroundColor: 'white',
        //           transition: 'background-color 0.3s',
        //         }}
        //         onMouseEnter={(e) => {
        //           e.target.style.backgroundColor = '#F1F2F4';
        //         }}
        //         onMouseLeave={(e) => {
        //           e.target.style.backgroundColor = '';
        //         }}
        //         onClick={handleAdd}
        //       >
        //         Add
        //       </button>
        //     </div>
        //   </div>
        // </div>
      )}
    </>
  );
};

export const Modal2 = ({ onClose, uid, setUuid }) => {
  console.log(uid);

  const navigate = useNavigate();
  const deleteMemeber = async () => {
    const reqData = {
      uid: uid,
      is_deleted: 1,
    };
    const response = await handleApiResponse("/api/v1/updateuser/", reqData, 1);
    if (response?.code === "token_not_valid") {
      navigate("/");
      sessionStorage.clear();
      return;
    }
    onClose();
    setUuid("");
    if (response.status === 200) {
      console.log(response);
      toast.success("Deleted successfully.", {
        className: "custom-toast-success",
      });
    } else {
      toast.warning("Something went wrong.", {
        className: "custom-toast-warning",
      });
    }
  };

  return (
    <>
      <div className="fixed backdrop-blur inset-0 overflow-auto flex justify-end z-50">
        <div className="absolute gap-8 justify-center flex flex-col items-center top-[30%] left-[30%] h-auto w-[55%] max-w-[630px] z-50 bg-white rounded-[30px] p-8 shadow-md ">
          <div className="rounded-[15px] flex flex-col bg-[#F5F8FF] w-full items-center justify-center py-8">
            <div className="text-center self-center w-[80%] text-[28px] font-semibold tracking-[0.15px]">
              Are you sure you want to delete this member?
            </div>
            <div className="text-[18px] text-center mt-5 w-[70%] font-normal self-center">
              This member will be deleted immediately. You can't undo this
              action.
            </div>
            {/* <img
              src={closeButton}
              alt="close button"
              onClick={onClose}
              className="cursor-pointer w-[40px] h-[40px]"
            /> */}
            <div className="w-full gap-12 mt-[2rem] px-4 flex justify-center">
              {/* <CustomWhiteButton text={'Cancel'} onClick={setClose} /> */}
              <div
                className={`w-[50%] text-[18px] h-[52px] rounded-[16px] text-[#22284E] flex justify-center items-center cursor-pointer border-2 border-[#22284E]`}
                onClick={onClose}
              >
                Cancel
              </div>
              {/* <div
              className={`w-[384px] text-[14px] h-[52px] rounded-[16px] text-[#22284E] mt-4 flex justify-center items-center cursor-pointer ${hovered ? 'border-2 border-[#22284E]' : ''
                }`}
              onClick={handleAdd}
            >
              Save
            </div> */}
              <button
                className={`w-[50%] text-[18px] justify-end text-white h-[52px] rounded-[16px] bg-[#22284E]`}
                onClick={deleteMemeber}
              >
                Delete
              </button>
              {/* <button
              className="h-12 w-[12.5rem] border-2"
              style={{
                backgroundColor: 'white',
                transition: 'background-color 0.3s',
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = '#F1F2F4';
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = '';
              }}
              onClick={handleAdd}
            >
              Update
            </button> */}
            </div>
          </div>
          {/* <div className="justify-center flex gap-4 self-center">
            <CustomButtonWhite text={'Cancel'} onClick={onClose} />
            <CustomButton text={'Yes'} onClick={deleteMemeber} />
          </div> */}
        </div>
      </div>
    </>
  );
};

export const ModalGenerateSelfAnalytics = ({
  taskId,
  candPhoto,
  name,
  candCompany,
  candJobRole,
  candUid,
  onClose,
  uid,
  setUuid,
}) => {
  console.log(candUid);
  const navigate = useNavigate();
  const generateSelfAnalytics = async () => {
    try {
      const response = await handleApiResponse(
        "/api/v1/generate_performance/",
        {
          uid: candUid,
        },
        1
      );
      if (response?.code === "token_not_valid") {
        navigate("/");
        sessionStorage.clear();
        return;
      }
      if (response?.data?.status_code === 200) {
        navigate(
          `/admin/analytics1?uid=${encodeURIComponent(
            candUid
          )}&candPhoto=${encodeURIComponent(
            candPhoto
          )}&candName=${encodeURIComponent(
            name
          )}&candCompany=${encodeURIComponent(
            candCompany
          )}&candJobRole=${encodeURIComponent(candJobRole)}`
        );
      } else if (response?.data.status_code === 400) {
        // setLoader(true);
        // toast.warning("Interview is not completed.");
        // setGenerateSelfAnalyticsPopup(true);
      } else {
        toast.error(response?.msg, {
          className: "custom-toast-error",
        });
      }
      // setLoader(false);
    } catch (error) {
      // Handle errors here
    }
  };
  return (
    <>
      <div className="fixed inset-0 flex z-10 justify-center">
        <div className="w-[100vw] h-[100vh]" onClick={onClose}></div>
        <div className="absolute w-[38.792vw] z-50 bg-white rounded-lg p-8 shadow-md add-member-animation-top ">
          <div className="flex justify-between mb-8">
            <div className="ml-[100px] text-center leading-[30px] self-center w-[20vw] text-[1.389vw] font-medium tracking-[0.15px]">
              Generate Analytics
            </div>
            <img
              src={closeButton}
              alt="close button"
              onClick={onClose}
              className="cursor-pointer w-[40px] h-[40px]"
            />
          </div>
          <div className="justify-center flex gap-4 self-center">
            <CustomButtonWhite text={"Cancel"} onClick={onClose} />
            <CustomButton text={"Generate"} onClick={generateSelfAnalytics} />
          </div>
        </div>
      </div>
    </>
  );
};

export const ModalAddJobRole = ({
  isOpen,
  onClose,
  setData,
  setjobroleValue,
  setJobRoleUid,
  openEditConfirmation,
  head,
}) => {
  let creator = sessionStorage.getItem("uid");
  const [isLoader, setLoader] = useState(false);
  const [uploadFile, setUploadFile] = useState(openEditConfirmation?.jd);
  // const [jobRoleName, setJobRoleName] = useState();
  const [editResumeChange, setEditResumeChange] = useState(false);
  const [creatorId, setCreator] = useState(openEditConfirmation?.creator);
  const [isurlToFile, setIsUrlToFile] = useState(false);
  const [jobRoleDescription, setJobRoleDescription] = useState(
    openEditConfirmation?.description
  );
  const [jobRoleExperienceFrom, setJobRoleExperienceFrom] = useState(
    openEditConfirmation?.experience_from
  );
  const [jobRoleExperienceTo, setJobRoleExperienceTo] = useState(
    openEditConfirmation?.experience_to
  );
  const [jobRoleName, setJobRoleName] = useState(
    creator !== creatorId ? "" : openEditConfirmation?.name
  );
  console.log(creatorId);
  console.log(creator);
  console.log(creator === creatorId);
  // const isClickable = creator === ;

  const [uid, setUid] = useState(openEditConfirmation?.uid);
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState({
    name: "0",
    description: "0",
    experience_from: "0",
    experience_to: "0",
    jd: "0",
  });

  const validation = () => {
    let isValid = 1;

    if (!jobRoleName) {
      console.log("set firstname");
      setError((prevState) => ({
        ...prevState,
        name: "1",
      }));
      isValid = 0;
    }

    if (!jobRoleDescription) {
      console.log("set lastname");
      setError((prevState) => ({
        ...prevState,
        description: "1",
      }));
      isValid = 0;
    }

    if (!jobRoleExperienceFrom) {
      console.log("set lastname");
      setError((prevState) => ({
        ...prevState,
        experience_from: "1",
      }));
      isValid = 0;
    }

    if (!jobRoleExperienceTo) {
      console.log("set designation");
      setError((prevState) => ({
        ...prevState,
        experience_to: "1",
      }));
      isValid = 0;
    }

    if (!uploadFile) {
      console.log("set password");
      setError((prevState) => ({
        ...prevState,
        jd: "1",
      }));
      isValid = 0;
    }
    return isValid;
  };
  const setClose = () => {
    setJobRoleName("");
    setJobRoleDescription("");
    setJobRoleExperienceFrom("");
    setJobRoleExperienceTo("");
    setUploadFile("");
    setError({
      name: "0",
      description: "0",
      experience_from: "0",
      experience_to: "0",
      jd: "0",
    });
    onClose();
  };

  const incrementNameSuffix = (name) => {
    let newName = name;
    const match = name.match(/^(.*?)(\d*)$/);
    if (match && match[2] !== "") {
      const baseName = match[1];
      const suffix = parseInt(match[2], 10);
      newName = `${baseName}${suffix + 1}`;
    } else {
      newName = `${name}1`;
    }
    return newName;
  };

  const urlToFile = async (url) => {
    const response = await fetch(url);
    const data = await response.blob();
    const filename = url.substring(url.lastIndexOf("/") + 1);
    const file = new File([data], filename, {
      type: response.headers.get("content-type"),
    });
    setIsUrlToFile(true);
    return file;
  };

  const handleCreateJobRole = async (e) => {
    // setLoader(true);
    e.preventDefault();
    setPasswordError("");
    if (validation()) {
      console.log("inside validate", validation());
    }
    console.log(uploadFile);
    if (uploadFile === "" || uploadFile === undefined) {
      setPasswordError("Please upload jd.");
    } else if (Number(jobRoleExperienceFrom) > Number(jobRoleExperienceTo)) {
      setPasswordError("Invalid value for job experience.");
    } else {
      console.log(openEditConfirmation);
      console.log(jobRoleName);
      if (
        jobRoleName === undefined ||
        jobRoleDescription === "" ||
        jobRoleExperienceFrom === "" ||
        jobRoleExperienceTo === "" ||
        uploadFile === "" ||
        jobRoleName === "" ||
        jobRoleDescription === undefined ||
        jobRoleExperienceFrom === undefined ||
        jobRoleExperienceTo === undefined
        // ||
        // (openEditConfirmation && jobRoleName === openEditConfirmation.name)
      ) {
        console.log("----------------");
      } else {
        setLoader(true);
        try {
          const formData = new FormData();
          formData.append("name", jobRoleName);
          formData.append("description", jobRoleDescription);
          formData.append("experience_from", jobRoleExperienceFrom);
          formData.append("experience_to", jobRoleExperienceTo);
          console.log(uploadFile, "file", file_url);
          if (openEditConfirmation) {
            // if (uploadFile.name !== undefined) {
            // formData.append("jd", uploadFile);
            formData.append("jd_file", uploadFile);
            if (uploadFile instanceof File) {
              formData.append("jd", uploadFile.name);
            } else {
              formData.append("jd", uploadFile);
            }

            // }
            // else {
            //   const url = file_url + uploadFile;
            //   urlToFile(url)
            //     .then((file) => {
            //       formData.append("jd", file);
            //     })
            //     .catch((error) => {
            //       console.error("Error converting URL to file:", error);
            //     });
            // }
          } else {
            // formData.append("jd", uploadFile);
            formData.append("jd_file", uploadFile);
          }

          if (creator === creatorId) {
            if (openEditConfirmation) {
              formData.append("uid", uid);
            }
          }
          for (var pair of formData.entries()) {
            console.log(pair[0] + ": " + pair[1], "add job role");
          }
          const response = await handleFormDataApiResponse(
            "/api/v1/interview/add_jobrole/",
            formData,
            1
          );
          if (response.data.status_code === 200) {
            setLoader(false);
            if (openEditConfirmation) {
              toast.success("Job Role updated successfully.", {
                className: "custom-toast-success",
              });
              onClose();
            } else {
              toast.success("Job Role added successfully.", {
                className: "custom-toast-success",
              });
              try {
                setData((prev) => {
                  let updated = [response?.data?.data, ...prev];
                  updated = updated.filter((ele) => {
                    if (ele.name !== "Fetching...") {
                      return ele;
                    }
                  });
                  return updated;
                });
              } catch (e) {
                console.log(e);
              }
              onClose();
              // setjobroleValue(response?.data?.data?.name);
              // setJobRoleUid(response?.data?.data?.uid);
              sessionStorage.setItem(
                "expFrom",
                response?.data?.data?.experience_from
              );
              sessionStorage.setItem(
                "expTo",
                response?.data?.data?.experience_to
              );
            }
          } else {
            if (response.data.status_code === 400) {
              // toast.error("The submitted data is not correct.");
              setLoader(false);
              toast.error(response?.data?.msg, {
                className: "custom-toast-error",
              });
            }
          }
        } catch (error) {
          setLoader(false);
          console.error("API Error:", error);
        }
      }
    }
  };

  const handleJobRoleNameChange = (e) => {
    setError((prevState) => ({
      ...prevState,
      name: "0",
    }));
    let newValue = e.target.value;
    setJobRoleName(newValue);
  };

  const handleJobRoleDescriptionChange = (e) => {
    setError((prevState) => ({
      ...prevState,
      description: "0",
    }));
    let newValue = e.target.value;
    setJobRoleDescription(newValue);
  };

  const handleJobRoleExperienceFromChange = (e) => {
    setError((prevState) => ({
      ...prevState,
      experience_from: "0",
    }));

    // Get the value from the input
    const inputValue = e.target.value;

    // Use a regular expression to allow only numeric values and ensure the length does not exceed 2
    const numericValue = inputValue.replace(/[^0-9]/g, ""); // Remove non-numeric characters

    // Set the value only if it is valid and does not exceed the length
    if (numericValue.length <= 2) {
      setJobRoleExperienceFrom(numericValue);
    }
  };

  const handleKeyPress = (e) => {
    if (
      e.key === "e" ||
      e.key === "+" ||
      e.key === "-" ||
      e.key === "." ||
      e.key === "E"
    ) {
      e.preventDefault();
    }
  };

  const handleJobRoleExperienceToChange = (e) => {
    setError((prevState) => ({
      ...prevState,
      experience_to: "0",
    }));

    // Get the value from the input
    const inputValue = e.target.value;

    // Use a regular expression to allow only numeric values and ensure the length does not exceed 2
    const numericValue = inputValue.replace(/[^0-9]/g, ""); // Remove non-numeric characters

    // Set the value only if it is valid and does not exceed the length
    if (numericValue.length <= 2) {
      setJobRoleExperienceTo(numericValue);
    }
  };

  const handleKeyDown2 = (e) => {
    const key = e.key;
    const isAlphabetic = key.match(/[a-zA-Z]/);
    const isSpace = key === " ";
    // if (!isAlphabetic && !isSpace) {
    //   e.preventDefault();
    // }
  };

  console.log(
    "first",
    error["experience_from"],
    jobRoleExperienceFrom,
    jobRoleExperienceTo
  );
  return (
    <>
      <div className="flex justify-center items-center h-[100vh] w-[100vw] backdrop-blur absolute top-0 left-0 z-50">
        {isLoader ? <Loader /> : ""}
        {/* <div className="fill-available h-[100%]" onClick={setClose}></div> */}
        <div className="w-[702px] text-[#00112D] h-auto bg-white rounded-[30px] p-8 shadow-md">
          {/* <div className="items-center flex justify-between"> */}
          <p className="text-[24px] font-semibold">{head} Job Role</p>

          <p className="font-[400] text-[14px] font-satoshi ">
            Here you can define a new job role to be used
            in a campaign
          </p>
          {/* <img
              src={closeButton}
              alt="close button"
              onClick={setClose}
              className="cursor-pointer"
            /> */}
          {/* </div> */}
          {/* <hr className="my-2" /> */}
          <div className="mt-4 text-[14px] font-sans1">
            {/* <div className="font-normal mb-[10px]">
              <InputBoxRed
                name={"Job Role Name"}
                holder={"Enter job role name"}
                onChange={(e) => handleJobRoleNameChange(e)}
                openEditConfirmation={openEditConfirmation}
                value={jobRoleName}
                onKeyDown={handleKeyDown2}
                error={
                  jobRoleName !== undefined ||
                  (jobRoleName !== "") === openEditConfirmation?.name
                    ? "Job role name can't be same"
                    : "Fill the Job Role Name"
                }
                showErrorOnInput={error["name"]}
                required
              />
            </div> */}
            {creator === creatorId && (
              <div className="font-normal mb-[10px]">
                <InputBox1
                  name={'Name of the Job Role'}
                  holder={'Enter job role name'}
                  onChange={(e) => handleJobRoleNameChange(e)}
                  openEditConfirmation={openEditConfirmation}
                  value={jobRoleName}
                  onKeyDown={handleKeyDown2}
                  error={
                    jobRoleName !== undefined ||
                      (jobRoleName !== '') === openEditConfirmation?.name
                      ? "Job role name can't be same"
                      : "Fill the Job Role Name"
                  }
                  showErrorOnInput={error["name"]}
                  required
                />
              </div>
            )}
            {creator !== creatorId && (
              <div className="font-normal mb-[10px]">
                <InputBoxRed
                  name={'Name of the Job Role'}
                  holder={'Enter job role'}
                  onChange={(e) => handleJobRoleNameChange(e)}
                  openEditConfirmation={openEditConfirmation}
                  value={jobRoleName}
                  onKeyDown={handleKeyDown2}
                  error={
                    jobRoleName !== undefined ||
                      (jobRoleName !== '') === openEditConfirmation?.name
                      ? "Job role name can't be same"
                      : "Fill the Job Role Name"
                  }
                  showErrorOnInput={error["name"]}
                  required
                />
              </div>
            )}

            <div className="flex gap-4">
              <div className="w-[50%] font-normal mb-[10px]">
                <span className="flex flex-col ">
                  <span className="flex">
                    <label htmlFor="">Minimum experience (in years)</label>
                    <span className="text-red-500">*</span>
                  </span>
                  <input
                    type="text"
                    placeholder="Enter experience years (from)"
                    className="p-3 outline-0 border-[#22284E] border-[1px] rounded-lg mt-2 placeholder-text-sm"
                    onChange={(e) => handleJobRoleExperienceFromChange(e)}
                    value={jobRoleExperienceFrom}
                    onKeyDown={(e) => handleKeyPress(e)}
                    maxLength="2"
                  />
                  {!jobRoleExperienceFrom &&
                    error['experience_from'] === '1' ? (
                    <div className="text-red-500">
                      Fill the experience range
                    </div>
                  ) : null}
                </span>
              </div>
              <div className="w-[50%] font-normal mb-[10px]">
                <span className="flex flex-col ">
                  <span className="flex">
                    <label htmlFor="">Maximum experience (in years)</label>
                    <span className="text-red-500">*</span>
                  </span>
                  <input
                    type="text"
                    placeholder="Enter experience years (to)"
                    className="p-3 outline-0 border-[#22284E] border-[1px] rounded-lg mt-2 placeholder-text-sm"
                    onChange={(e) => handleJobRoleExperienceToChange(e)}
                    value={jobRoleExperienceTo}
                    onKeyDown={(e) => handleKeyPress(e)}
                    maxLength="2"
                  />
                  {!jobRoleExperienceTo && error["experience_to"] === "1" ? (
                    <div className="text-red-500">
                      Fill the experience range
                    </div>
                  ) : null}
                </span>
              </div>
            </div>

            <span className="flex flex-col mb-2">
              <span className="flex">
                <label htmlFor="">Job Description</label>
                <span className="text-red-500">*</span>
              </span>

              <UploadResumeOnly
                setUploadResume={setUploadFile}
                uploadResume={uploadFile}
                error={"Upload JD"}
                showErrorOnInput={error["jd"]}
                setEditResumeChange={setEditResumeChange}
              />
            </span>
            {!uploadFile && error["jd"] === "1" ? (
              <div className="text-red-500">Upload jd_file</div>
            ) : null}

            <div className="font-normal mb-[10px]">
              <InputBox3
                name={"Description"}
                holder={"Enter description"}
                onChange={(e) => handleJobRoleDescriptionChange(e)}
                value={jobRoleDescription}
                error={"Fill the Description"}
                showErrorOnInput={error["description"]}
              />
            </div>
            {/* <div className="flex gap-4">
              <div className="w-[50%] font-normal mb-[10px]">
                <span className="flex flex-col ">
                  <span className="flex">
                    <label htmlFor="">Experience Years (from)</label>
                    <span className="text-red-500">*</span>
                  </span>
                  <input
                    type="text"
                    placeholder="Enter experience years (from)"
                    className="p-3 outline-0 border-[#F1F2F4] bg-[#FBFBFB] rounded-lg mt-2 placeholder-text-sm"
                    onChange={(e) => handleJobRoleExperienceFromChange(e)}
                    value={jobRoleExperienceFrom}
                    onKeyDown={(e) => handleKeyPress(e)}
                    maxLength="2"
                  />
                  {!jobRoleExperienceFrom &&
                  error['experience_from'] === '1' ? (
                    <div className="text-red-500">
                      Fill the experience range
                    </div>
                  ) : null}
                </span>
              </div>
              <div className="w-[50%] font-normal mb-[10px]">
                <span className="flex flex-col ">
                  <span className="flex">
                    <label htmlFor="">Experience Years (to)</label>
                    <span className="text-red-500">*</span>
                  </span>
                  <input
                    type="text"
                    placeholder="Enter experience years (to)"
                    className="p-3 outline-0 border-[#F1F2F4] bg-[#FBFBFB] rounded-lg mt-2 placeholder-text-sm"
                    onChange={(e) => handleJobRoleExperienceToChange(e)}
                    value={jobRoleExperienceTo}
                    onKeyDown={(e) => handleKeyPress(e)}
                    maxLength="2"
                  />
                  {!jobRoleExperienceTo && error['experience_to'] === '1' ? (
                    <div className="text-red-500">
                      Fill the experience range
                    </div>
                  ) : null}
                </span>
              </div>
            </div> */}
            {/* <span className="flex flex-col ">
              <span className="flex">
                <label htmlFor="">JD</label>
                <span className="text-red-500">*</span>
              </span>

              <UploadResumeOnly
                setUploadResume={setUploadFile}
                uploadResume={uploadFile}
                error={'Upload JD'}
                showErrorOnInput={error['jd']}
                setEditResumeChange={setEditResumeChange}
              />
            </span>
            {!uploadFile && error['jd'] === '1' ? (
              <div className="text-red-500">Upload jd_file</div>
            ) : null} */}
            {/* <div style={{ color: "red", marginTop: "10px" }}>
              {passwordError}
            </div> */}
            <div className="mt-12 gap-12 flex justify-center">
              {/* <CustomWhiteButton text={'Cancel'} onClick={setClose} /> */}
              <div
                className={`w-[50%] text-[18px] h-[52px] rounded-[16px] text-[#22284E] flex justify-center items-center cursor-pointer border-2 border-[#22284E]`}
                onClick={setClose}
              >
                Cancel
              </div>
              {/* <div
              className={`w-[384px] text-[14px] h-[52px] rounded-[16px] text-[#22284E] mt-4 flex justify-center items-center cursor-pointer ${hovered ? 'border-2 border-[#22284E]' : ''
                }`}
              onClick={handleAdd}
            >
              Save
            </div> */}
              <button
                className={`w-[50%] text-[18px] justify-end text-white h-[52px] rounded-[16px] bg-[#22284E]`}
                onClick={(e) => {
                  handleCreateJobRole(e);
                }}
              >
                Save
              </button>
              {/* <button
              className="h-12 w-[12.5rem] border-2"
              style={{
                backgroundColor: 'white',
                transition: 'background-color 0.3s',
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = '#F1F2F4';
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = '';
              }}
              onClick={handleAdd}
            >
              Update
            </button> */}
            </div>
            {/* <div className="mt-8 flex justify-center gap-4">
              <CustomButtonWhite text={'Cancel'} onClick={setClose} />
              <CustomButton
                text={openEditConfirmation ? 'Edit' : 'Add'}
                onClick={(e) => {
                  handleCreateJobRole(e);
                }}
              />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export const ModalAddCandidate = ({
  isOpen,
  onClose,
  excelData,
  setSingleAddCandidate,
  setSelectedCheckboxes,
  selectedCheckboxes,
}) => {
  const location = useLocation();
  const [mobile, setMobile] = useState("");
  const [firstChange, setFirstChange] = useState("");
  const [lastChange, setLastChange] = useState("");
  const [emailChange, setEmailChange] = useState("");
  const [apiResponseError, setApiResponseError] = useState("");
  const [error, setError] = useState({
    first: "",
    last: "",
    email: "",
    // mobile: "",
  });

  console.log(error, "errorrrrrrrrrr");

  const setClose = () => {
    setFirstChange("");
    setLastChange("");
    setEmailChange("");
    setApiResponseError("");
    setMobile("");
    setError({
      first: "",
      last: "",
      email: "",
      // mobile: "",
    });
    onClose();
  };

  const setCloseOnFocus = () => {
    onClose();
  };

  const handleFirstChange = (e) => {
    let first = e.target.value;
    if (validateName(first)) {
      setError((prevState) => ({
        ...prevState,
        first: "",
      }));
      setFirstChange(first);
    }
  };

  const handleLastChange = (e) => {
    let newValue = e.target.value;
    if (validateName(newValue)) {
      setError((prevState) => ({
        ...prevState,
        last: "",
      }));
      setLastChange(newValue);
    }
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmailChange(email);
    if (validateEmail(email)) {
      setError((prevState) => ({
        ...prevState,
        email: "",
      }));
    }
  };

  const handleMobileChange = (e) => {
    const value = e.target.value;
    // Check if the value is a valid number and does not exceed 10 digits
    if (/^\d{0,10}$/.test(value)) {
      setMobile(value);
      // Clear any previous mobile error if exactly 10 digits are entered
      if (value.length === 10) {
        setError((prevState) => ({
          ...prevState,
          mobile: "",
        }));
      }
    }
  };

  // Prevent non-numeric input (like symbols or letters) at the time of keypress
  const preventInvalidInput = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const validateName = (name) => typeof name === "string" && name.trim() !== "";
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const validation = () => {
    let isValid = true;

    if (!firstChange) {
      setError((prevState) => ({
        ...prevState,
        first: "First name is required",
      }));
      isValid = false;
    }

    if (!lastChange) {
      setError((prevState) => ({
        ...prevState,
        last: "Last name is required",
      }));
      isValid = false;
    }

    if (!validateEmail(emailChange)) {
      setError((prevState) => ({
        ...prevState,
        email: "Invalid email format",
      }));
      isValid = false;
    }

    // if (mobile.length !== 10) {
    //   setError((prevState) => ({
    //     ...prevState,
    //     mobile: "Mobile number must be 10 digits",
    //   }));
    //   isValid = false;
    // }

    return isValid;
  };

  const handleAdd = async () => {
    if (!validation()) {
      return;
    }

    const newCandidate = {
      first_name: firstChange,
      last_name: lastChange,
      email: emailChange,
      mobile: mobile,
      is_selected: true,
    };

    const existingExcelData =
      JSON.parse(sessionStorage.getItem("excelData")) || [];
    const existingCheckedFilteredData =
      JSON.parse(sessionStorage.getItem("checkedFilteredData")) || [];
    const existingSingleAddCandidateData =
      JSON.parse(sessionStorage.getItem("singleAddCandidate")) || [];

    const isDuplicateEmailInState = excelData.some(
      (candidate) => candidate.email === newCandidate.email
    );

    const isDuplicateEmail =
      existingExcelData.some(
        (candidate) => candidate.email === newCandidate.email
      ) ||
      existingCheckedFilteredData.some(
        (candidate) => candidate.email === newCandidate.email
      ) ||
      existingSingleAddCandidateData.some(
        (candidate) => candidate.email === newCandidate.email
      );

    if (isDuplicateEmailInState || isDuplicateEmail) {
      toast.warning(
        "A candidate with this email already exists. Duplicate emails are not acceptable.",
        {
          className: "custom-toast-warning",
        }
      );
      return;
    }

    setSingleAddCandidate((prevData) => {
      const updatedData = [...existingSingleAddCandidateData, newCandidate];
      sessionStorage.setItem("singleAddCandidate", JSON.stringify(updatedData));

      return updatedData;
    });

    let uploadSelected = { [newCandidate.email]: true };

    setSelectedCheckboxes({ ...selectedCheckboxes, ...uploadSelected });

    sessionStorage.setItem(
      "selectedCheckboxes",
      JSON.stringify({ ...selectedCheckboxes, ...uploadSelected })
    );

    toast.success("Candidate Added.", {
      className: "custom-toast-success",
    });
    onClose();
  };

  const preventBackspaceOnEmpty = (e, valueSetter, fieldValue) => {
    if (e.key === "Backspace" && fieldValue.length <= 1) {
      e.preventDefault();
      valueSetter("");
    }
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 overflow-auto flex justify-center items-center z-50 bg-[rgba(0,0,0,0.7)]">
          <div className="fill-available" onClick={setCloseOnFocus}></div>
          <div className=" w-[59.028vw] text-[#1B3351] h-auto bg-white rounded-[30px] py-[40px] px-[30px] shadow-md absolute add-member-animation">
            <div className="overflow-auto">
              <div className="items-center flex justify-between">
                <p className="font-figtree text-[34px] italic  font-[600] mt-2">
                  Add Candidate Manually
                </p>
              </div>
              <hr className="my-2 mt-4 border-t-2 border-[#1A7EF3]" />
              <div className="flex gap-4 justify-between mt-4">
                <div className="w-[50%] font-normal mb-[10px]">
                  <InputBox1
                    name={"First Name"}
                    holder={"Enter first name"}
                    value={firstChange}
                    onChange={handleFirstChange}
                    onKeyDown={(e) =>
                      preventBackspaceOnEmpty(e, setFirstChange, firstChange)
                    }
                  />
                  {error.first && (
                    <div className="text-red-500 mt-1">{error.first}</div>
                  )}
                </div>
                <div className="w-[50%] font-normal mb-[10px]">
                  <InputBox1
                    name={"Last Name"}
                    holder={"Enter last name"}
                    value={lastChange}
                    onChange={handleLastChange}
                    error={error.last}
                    onKeyDown={(e) =>
                      preventBackspaceOnEmpty(e, setLastChange, lastChange)
                    }
                  />
                  {error.last && (
                    <div className="text-red-500 mt-1">{error.last}</div>
                  )}
                </div>
              </div>
              <div className="font-normal mb-[10px]">
                <InputBox1
                  name={"Email"}
                  holder={"Enter email"}
                  value={emailChange}
                  onChange={handleEmailChange}
                  error={error.email}
                />
                {error.email && (
                  <div className="text-red-500 mt-1">{error.email}</div>
                )}
              </div>
              <div className="font-normal mb-[10px] flex flex-col">
                <div className="flex text-[17px]">
                  <label htmlFor="mobile">Mobile Number</label>
                </div>
                <input
                  type="number"
                  className="py-[16px] h-[52px] px-[20px] rounded-lg font-sans1 text-[16px] border-[1px] border-[#22284E]  outline-0 mt-2 placeholder-tracking-[0.6px]"
                  placeholder="Enter mobile number"
                  onChange={handleMobileChange}
                  name="mobile"
                  value={mobile}
                  onKeyPress={preventInvalidInput}
                  maxLength={10}
                />
                {/* <InputBoxColor
                head={"Mobile Number"}
                name={"mobile"}
                holder={"Enter mobile number"}
                value={mobile}
                onChange={handleMobileChange}
                error={error.mobile}
              /> */}
                {/* {error.mobile && (
                <div className="text-red-500 mt-1">{error.mobile}</div>
              )} */}
              </div>
              {apiResponseError && (
                <div className="text-red-500">{apiResponseError}</div>
              )}
              <div className="gap-4 flex justify-center mt-12">
                {/* <CustomButtonWhite text={'Cancel'} onClick={setClose} />
              <button
                className="h-12 w-[12.5rem] border-2"
                style={{
                  backgroundColor: "white",
                  transition: "background-color 0.3s",
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#F1F2F4";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "";
                }}
                onClick={handleAdd}
              >
                Add
              </button> */}
                <div className="flex w-full justify-center gap-4">
                  {/* Removed unnecessary 'self-center' class */}
                  <div
                    className={`w-[50%] text-[18px]  border-[1px]  h-[62px] font-satoshi font-bold rounded-[16px] text-[#22284E] flex justify-center items-center cursor-pointer  border-[#22284E]`}
                    onClick={setClose}
                  >
                    Cancel
                  </div>

                  {/* Close modal on cancel */}

                  <button
                    className={`w-[50%] text-[18px] justify-end text-white h-[62px] font-satoshi font-bold rounded-[16px] bg-[#22284E]`}
                    onClick={handleAdd}
                  >
                    Add Candidate
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const ModalEditJobRole = ({ isOpen, onClose, item, uid }) => {
  const [uploadFile, setUploadFile] = useState(false);
  const [status, setStatus] = useState(false);
  const [jobRoleName, setJobRoleName] = useState("");
  const [jobRoleDescription, setJobRoleDescription] = useState("");
  const [jobRoleExperienceFrom, setJobRoleExperienceFrom] = useState("");
  const [jobRoleExperienceTo, setJobRoleExperienceTo] = useState("");
  console.log(item);
  const handleToggle = () => {
    setStatus((prevStatus) => !prevStatus);
  };
  useEffect(() => {
    const handleGetJobRole = async () => {
      try {
        const formData = new FormData();
        formData.append("name", jobRoleName);
        formData.append("description", jobRoleDescription);
        formData.append("experience_from", jobRoleExperienceFrom);
        formData.append("experience_to", jobRoleExperienceTo);
        formData.append("jd", uploadFile);
        formData.append("uid", uid);

        const response = await handleFormDataApiResponse(
          "/api/v1/interview/add_jobrole/",
          formData,
          1
        );
        console.log(response.data);
        if (response.data.status_code === 200) {
          toast.success("Yes.", {
            className: "custom-toast-success",
          });
          toast.success("Job Role added successfully.", {
            className: "custom-toast-success",
          });
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    };
    handleGetJobRole();
  });

  const handleJobRoleNameChange = (e) => {
    setJobRoleName(e.target.value);
  };

  const handleJobRoleDescriptionChange = (e) => {
    setJobRoleDescription(e.target.value);
  };

  const handleJobRoleExperienceFromChange = (e) => {
    setJobRoleExperienceFrom(e.target.value);
  };

  const handleKeyDown = (e) => {
    // Prevent entering a decimal point as the first character
    if (e.key === ".") {
      e.preventDefault();
    }
  };

  const handleJobRoleExperienceToChange = (e) => {
    setJobRoleExperienceTo(e.target.value);
  };
  //  console.log(jobRoleName);
  //  console.log(jobRoleDescription);
  //  console.log(jobRoleExperienceFrom);
  //  console.log(jobRoleExperienceTo);

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-end z-50 ">
          <div className="w-[34.792vw] bg-white rounded-[30px] p-8 shadow-md add-member-animation">
            <div className="items-center flex justify-between">
              <p className="text-[1.528vw] font-medium">Edit Job Role</p>
              <img
                src={closeButton}
                alt="close button"
                onClick={onClose}
                className="cursor-pointer"
              />
            </div>
            <hr className="my-2" />
            <div className="text-[0.972vw]">
              <div className="font-normal mb-[10px]">
                <InputBox1
                  name={"Name"}
                  holder={"Enter job role name"}
                  onChange={(e) => handleJobRoleNameChange(e)}
                  value={jobRoleName}
                />
              </div>
              <div className="font-normal mb-[10px]">
                <InputBox1
                  name={"Description"}
                  holder={"Enter description"}
                  onChange={(e) => handleJobRoleDescriptionChange(e)}
                  value={jobRoleDescription}
                />
              </div>
              <div className="flex gap-4">
                <div className="w-[50%] font-normal mb-[10px]">
                  <InputBox1
                    name={'Minimum experience (in years)'}
                    holder={'Enter experience years (from)'}
                    onChange={(e) => handleJobRoleExperienceFromChange(e)}
                    value={jobRoleExperienceFrom}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div className="w-[50%] font-normal mb-[10px]">
                  <InputBox1
                    name={'Maximum experience (in years)'}
                    holder={'Enter experience years (to)'}
                    onChange={(e) => handleJobRoleExperienceToChange(e)}
                    value={jobRoleExperienceTo}
                  />
                </div>
              </div>
              <span className="flex flex-col ">
                <label htmlFor="">Upload JD</label>
                <UploadResumeOnly
                  setUploadResume={setUploadFile}
                  uploadResume={uploadFile}
                />
              </span>
              <div className="flex gap-2 mt-6">
                <label htmlFor="">Status</label>
                <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                  <input
                    type="checkbox"
                    id="toggle"
                    className={`toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border-4 appearance-none cursor-pointer ${status
                      ? 'right-0 border-green-400'
                      : 'left-0 border-gray-300'
                      }`}
                    checked={status}
                    onChange={handleToggle}
                  />
                  <label
                    htmlFor="toggle"
                    className={`toggle-label block overflow-hidden h-5 rounded-full bg-gray-300 cursor-pointer
          }`}
                  ></label>
                </div>
              </div>
              <div className="mt-8 flex justify-center gap-4">
                <CustomButtonWhite
                  text={"Cancel"}
                  onClick={() => {
                    onClose();
                  }}
                />
                <CustomButton text={"Save"} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const ModalDeleteJobRole = ({ onClose, uid, setUuid }) => {
  console.log(uid);
  const navigate = useNavigate();
  const deleteJobRole = async () => {
    const reqData = {
      uid: uid,
      is_deleted: 1,
    };
    const response = await handleApiResponse(
      "/api/v1/interview/add_jobrole/",
      reqData,
      1
    );
    if (response?.code === "token_not_valid") {
      navigate("/");
      sessionStorage.clear();
      return;
    }
    onClose();
    setUuid("");
    console.log(response);
  };

  return (
    <>
      <div className="fixed backdrop-blur inset-0 overflow-auto flex justify-end z-50">
        <div className="absolute gap-8 justify-center flex flex-col items-center top-[30%] left-[30%] h-auto w-[55%] max-w-[630px] z-50 bg-white rounded-[30px] p-8 shadow-md ">
          <div className="p-[30px] rounded-[15px] flex flex-col bg-[#F5F8FF] w-full items-center justify-center py-8">
            <div className="text-center self-center w-[80%] font-[manrope] text-[28px] font-semibold tracking-[0.15px]">
              Are you sure you want to delete this job role?
            </div>
            <div className="text-[18px] font-satoshi text-center mt-2 w-[70%] font-normal self-center">
              This Job Role will be deleted immediately. You can't undo this
              action.
            </div>
            <div className="w-full gap-12 flex justify-center mt-8">
              {/* <CustomWhiteButton text={'Cancel'} onClick={setClose} /> */}
              <div
                className={`w-[50%] font-satoshi font-medium text-[18px] h-[52px] rounded-[16px] text-[#22284E] flex justify-center items-center cursor-pointer border-2 border-[#22284E]`}
                onClick={onClose}
              >
                Cancel
              </div>
              {/* <div
              className={`w-[384px] text-[14px] h-[52px] rounded-[16px] text-[#22284E] mt-4 flex justify-center items-center cursor-pointer ${hovered ? 'border-2 border-[#22284E]' : ''
                }`}
              onClick={handleAdd}
            >
              Save
            </div> */}
              <button
                className={`w-[50%] font-satoshi font-medium text-[18px] justify-end text-white h-[52px] rounded-[16px] bg-[#22284E]`}
                onClick={deleteJobRole}
              >
                Delete
              </button>
              {/* <button
              className="h-12 w-[12.5rem] border-2"
              style={{
                backgroundColor: 'white',
                transition: 'background-color 0.3s',
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = '#F1F2F4';
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = '';
              }}
              onClick={handleAdd}
            >
              Update
            </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ModalViewJobRole1 = ({ fileUrl, setShowView }) => {
  const location = useLocation();
  const { state } = location;
  const { jdUrl } = state || {};
  const pdfUrl = fileUrl || jdUrl;
  const navigate = useNavigate();
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const iframeRef = useRef(null);

  const handleFetchError = (error) => {
    console.error("Failed to fetch PDF:", error);
  };

  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    const fetchAndConvert = async () => {
      try {
        if (pdfUrl.endsWith(".pdf")) {
          console.log("PDF file detected. Skipping conversion.");
          return;
        }
        const response = await fetch(pdfUrl);

        // Check if the file exists
        if (!response.ok) {
          throw new Error(`Error fetching the file: ${response.statusText}`);
        }

        const arrayBuffer = await response.arrayBuffer();
        const { value } = await mammoth.convertToHtml({ arrayBuffer });
        setHtmlContent(value);
      } catch (error) {
        // Handle the error: log to console and display an error message in the UI
        console.error("Error fetching or converting the file:", error.message);
        setHtmlContent("<p>Unable to load or convert the document.</p>");
      }
    };

    fetchAndConvert();
  }, [pdfUrl]);

  return (
    <>
      <div className="fixed backdrop-blur inset-0 overflow-auto flex justify-center items-center z-50">
        <div className="absolute gap-8 justify-start flex flex-col items-center top-[5%] left-[15%] h-auto w-[70.417vw] z-50 bg-white rounded-[30px] p-8 shadow-md  ">
          <div className="text-[#00112D] w-full">
            <p className="text-[24px] font-semibold font-[manrope]  ">
              Job Description
            </p>
            {/* <p className="mt-[4px] text-[14px] font-satoshi font-normal ">
              Lorem ipsum Molestiae, officia harum? Suscipit.
            </p> */}
          </div>

          <div
            className="flex flex-end flex-col justify-end"
            style={{
              backgroundColor: "#fff",
              height: "65vh",
              width: "66vw",
              overflow: "auto",
              border: "1px solid #22284E",
              borderRadius: "12px",
            }}
          >
            {/* <im
        src={closeButton}
        className="w-[40px] justify-end cursor-pointer"
        onClick={() => navigate(-1)}
      /> */}
            {pdfUrl ? (
              pdfUrl.endsWith(".pdf") ? (
                <Worker
                  workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js"
                  onError={handleFetchError}
                >
                  <div
                    style={{
                      flex: 1,
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div id="job-role-view" style={{ width: "100%" }}>
                      <Viewer
                        fileUrl={pdfUrl}
                        defaultScale={1.5}
                        plugins={[zoomPluginInstance]}
                      />
                    </div>
                  </div>
                </Worker>
              ) : pdfUrl.endsWith(".docx") ? (
                // <iframe id="job-role-in-docs"
                //   ref={iframeRef}
                //   src={`https://docs.google.com/gview?url=${pdfUrl}&embedded=true`}
                //   style={{ width: '100%', height: '100vh', border: 'none' }}
                //   title="DOCX Viewer"
                // />

                <div
                  dangerouslySetInnerHTML={{ __html: htmlContent }}
                  style={{ padding: "20px", overflow: "auto", color: "#000" }}
                />
              ) : (
                <div>Unsupported file format</div>
              )
            ) : (
              <div>No document available</div>
            )}
          </div>
          <div className="w-[98%] gap-12 flex justify-center">
            {/* <CustomWhiteButton text={'Cancel'} onClick={setClose} /> */}
            {/* <div
              className={`w-[50%] text-[14px] h-[52px] rounded-lg text-[#22284E] flex justify-center items-center cursor-pointer border-2 border-[#22284E]`}
              onClick={onClose}
            >
              Cancel
            </div> */}
            {/* <div
              className={`w-[384px] text-[14px] h-[52px] rounded-[16px] text-[#22284E] mt-4 flex justify-center items-center cursor-pointer ${hovered ? 'border-2 border-[#22284E]' : ''
                }`}
              onClick={handleAdd}
            >
              Save
            </div> */}
            <button
              className={`w-[100%] h-[52px] text-[18px] font-satoshi font-medium justify-end text-white rounded-[16px] bg-[#22284E]`}
              onClick={() => setShowView(false)}
            >
              Close
            </button>
            {/* <button
              className="h-12 w-[12.5rem] border-2"
              style={{
                backgroundColor: 'white',
                transition: 'background-color 0.3s',
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = '#F1F2F4';
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = '';
              }}
              onClick={handleAdd}
            >
              Update
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export const ModalAddConfirmationPopup = ({
  setQuestionsAllowed,
  onClose,
  apiCall,
  setCurrent,
  selectedOption,
  questions3,
  hrQuesCount,
  blendedmandatoryCount,
  questions1,
  questions,
  campaignType,
  filterAiMadated,
  filterHrMadated,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div
        id="popup-modal"
        tabindex="-1"
        className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[100%] max-h-full bg-[#000000B2]"
      >
        <div className="relative p-4 w-full max-w-lg ">
          <div className="relative bg-white rounded-lg shadow p-5">
            <div className="p-4 md:p-5 text-center bg-[#F5F8FF] rounded-lg font-sans1">
              {selectedOption === "option3" ? (
                <div>
                  <h3 className="mt-5 mb-3 font-[600] text-[1.3rem]">
                    <span className="text-blue-600 leading-[25px]">
                      <span className="text-blue-600">
                        {questions3 - blendedmandatoryCount < 10
                          ? `0${questions3 - blendedmandatoryCount}`
                          : questions3 - blendedmandatoryCount}
                      </span>
                      &nbsp;AI-generated questions
                    </span>
                    <span className="text-[#00112D] leading-[25px]">&nbsp;& &nbsp;</span>
                    <div className="text-green-600 leading-[25px]">
                      <span className="text-green-600">
                        {hrQuesCount
                          ? hrQuesCount < 10
                            ? `0${hrQuesCount}`
                            : hrQuesCount
                          : blendedmandatoryCount < 10
                            ? `0${blendedmandatoryCount}`
                            : blendedmandatoryCount}
                      </span>
                      &nbsp;User-crafted question
                    </div>
                  </h3>


                  <p className="text-[1rem] font-satoshi text-[#00112D] mb-5 font-[400] leading-[24px]">
                    Will be included in your interview campaign. Do you want to proceed?
                  </p>
                </div>
              ) : selectedOption === "option1" && questions1 ? (
                <div>
                  <h3 className="mt-5 mb-3  font-normal text-blue-600 text-[1.3rem]">
                    {questions1 < 10 ? `0${questions1}` : questions1} AI-generated questions ({filterAiMadated ? `0${filterAiMadated}` : filterAiMadated} mandatory)
                  </h3>
                  <p className="text-[1rem] text-[#00112D] mb-5 font-light">
                    Will be included in your interview campaign. Do you want to proceed?
                  </p>
                </div>
              ) : (
                selectedOption === "option2" && (
                  <div>
                    <h3 className="mt-5 mb-3  font-normal text-green-600 text-[1.3rem]">
                      {questions < 10 ? `0${questions}` : questions} User-crafted question ({filterHrMadated ? `0${filterHrMadated}` : filterHrMadated} Mandatory)
                    </h3>
                    <p className="text-[1rem] text-[#00112D] mb-5 font-light">
                      Will be included in your interview campaign. Do you want to proceed?
                    </p>
                  </div>
                )
              )}

              <div className="flex justify-between items-center">
                <button
                  data-modal-hide="popup-modal"
                  type="button"
                  className="me-3 w-[50%] text-[#00112D]  border-[#00112D] border-solid border-[1px] font-medium rounded-lg text-[1rem] inline-flex items-center px-5 py-2.5 justify-center"
                  onClick={onClose}
                >
                  Close
                </button>
                <button
                  data-modal-hide="popup-modal"
                  type="button"
                  className="ms-3 w-[50%] text-[#fff] bg-[#00112D]  border-[#00112D] border-solid border-[1px] font-medium rounded-lg text-[1rem] inline-flex items-center px-5 py-2.5 justify-center"
                  onClick={() => {
                    if (sessionStorage.getItem("camp_type") === "1") {
                      toast.success("Questions updated", {
                        className: "custom-toast-success",
                      });
                      setCurrent(2);
                    } else {
                      toast.success("Campaign Updated successfully.", {
                        className: "custom-toast-success",
                      });
                      // navigate("/admin/campaign");
                      navigate("/admin/view-campaigns", {
                        state: {
                          uid: sessionStorage.getItem("campaignUid"),
                          campaign_type:
                            sessionStorage.getItem("camp_type") === "1"
                              ? "Regular"
                              : "Mass",
                          name: sessionStorage.getItem("campName"),
                          jr_name: sessionStorage.getItem("jrName"),
                          start_date: sessionStorage
                            .getItem("createdDate")
                            .split("T")[0],
                          end_date: sessionStorage
                            .getItem("endDate1")
                            .split("T")[0],
                        },
                      });
                    }
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ModalCandidateConfirmationPopup = ({
  totatCandidates,
  checkedFilteredData,
  onClose,
  handleCallApi,
}) => {
  return (
    <>
      {/* <div className="fixed inset-0 flex z-50 justify-center">
        <div className="fill-available  h-[100%]" onClick={onClose}></div>
        <div className="w-[38.792vw] rounded-lg p-6 shadow-md add-member-animation-top bg-[#22284E]">
          <div className="bg-[#101429] p-4">
            <div className="flex justify-between mb-8">
              <div className="ml-[100px] text-center text-white leading-[30px] self-center w-[20vw] text-[1.389vw] font-medium tracking-[0.15px]">
                {`Out of ${totatCandidates} candidates, You have selected ${checkedFilteredData}. Do you want to continue?`}
              </div>
            </div>
            <div className="justify-center flex gap-4 self-center">
              <div className="w-[290px] h-[65px] text-[14px]">
                <CustomWhiteButton text={"Cancel"} onClick={onClose} />
              </div>
              <div className="w-[290px] h-[65px] text-[14px]">
                <CustomBlueButton
                  text={"Yes"}
                  onClick={() => handleCallApi()}
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        id="popup-modal"
        tabindex="-1"
        className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[100%] max-h-full bg-[#000000B2]"
      >
        <div className="relative p-4 w-full max-w-lg ">
          <div className="relative bg-white rounded-lg shadow p-5">
            <div className="p-4 md:p-5 text-center bg-[#F5F8FF] rounded-lg">
            <div>
  <h3 className="mt-5 mb-3 font-normal text-[#00112D] text-[1.3rem]">
    You've selected 
  </h3>
  <h3 className="mt-5 mb-3 font-normal text-[#00112D] text-[1.3rem]">
    {checkedFilteredData === totatCandidates
      ? `all ${checkedFilteredData}`
      : `${checkedFilteredData} out of ${totatCandidates}`} candidates. Do you want to 
  </h3>
  <h3 className="mt-5 mb-3 font-normal text-[#00112D] text-[1.3rem]">
  proceed with this selection?
  </h3>
</div>


              <div className="flex justify-between items-center">
                <button
                  data-modal-hide="popup-modal"
                  type="button"
                  className="me-3 w-[50%] text-[#00112D]  border-[#00112D] border-solid border-[1px] font-medium rounded-lg text-[1rem] inline-flex items-center px-5 py-2.5 justify-center"
                  onClick={onClose}
                >
                  Close
                </button>
                <button
                  data-modal-hide="popup-modal"
                  type="button"
                  className="ms-3 w-[50%] text-[#fff] bg-[#00112D]  border-[#00112D] border-solid border-[1px] font-medium rounded-lg text-[1rem] inline-flex items-center px-5 py-2.5 justify-center"
                  onClick={() => handleCallApi()}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ModalDeleteQuestionPopup = ({
  onClose,
  firstName,
  lastName,
  onConfirm,
  rowToDelete,
}) => {
  return (
    <>
      {/* <div className="fixed inset-0 flex z-50 justify-center">
        <div className="fill-available  h-[100%]" onClick={onClose}></div>
        <div className="w-[38.792vw]  bg-white rounded-lg p-8 shadow-md add-member-animation-top ">
          <div className="flex justify-between mb-8">
            <div className="ml-[100px] text-center leading-[30px] self-center w-[20vw] text-[1.389vw] font-medium tracking-[0.15px]">
              Are you sure you want to delete?
              {rowToDelete}
            </div>
          </div>
          <div className="justify-center flex gap-4 self-center">
            <CustomButtonWhite text={"Cancel"} onClick={onClose} />
            <CustomButton
              text={"Yes"}
              onClick={() => {
                onConfirm();
                onClose();
              }}
            />
          </div>
        </div>
      </div> */}

      <div
        id="popup-modal"
        tabindex="-1"
        className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[100%] max-h-full bg-[#000000B2]"
      >
        <div className="relative p-4 w-full max-w-lg ">
          <div className="relative bg-white rounded-lg shadow p-5">
            <div className="p-4 md:p-5 text-center bg-[#F5F8FF] rounded-lg">
              <div>
                <h3 className="mt-5 mb-5  font-normal text-[#00112D] text-[1.3rem]">
                  {" "}
                  Are you sure you want to delete "{firstName} {lastName}"?
                </h3>
                <div className='font-satoshi font-[400] leading-[20px] mb-6 text-[#00112D]'>This action will permanently remove {firstName} {lastName} from the system. All associated data will be deleted and cannot be recovered. </div>
              </div>

              <div className="flex justify-between items-center">
                <button
                  data-modal-hide="popup-modal"
                  type="button"
                  className="me-3 w-[50%] text-[#00112D]  border-[#00112D] border-solid border-[1px] font-medium rounded-lg text-[1rem] inline-flex items-center px-5 py-2.5 justify-center"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  data-modal-hide="popup-modal"
                  type="button"
                  className="ms-3 w-[50%] text-[#fff] bg-[#00112D]  border-[#00112D] border-solid border-[1px] font-medium rounded-lg text-[1rem] inline-flex items-center px-5 py-2.5 justify-center"
                  onClick={() => {
                    onConfirm();
                    onClose();
                  }}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ModalViewJobRole = ({ isOpen, onClose }) => {
  const handleClickOutsideModal = (e) => {
    if (
      e.target.classList.contains("backdrop-blur") &&
      !e.target.closest("img")
    ) {
      onClose();
    }
  };
  return (
    <>
      {isOpen && (
        <div
          className="border-3 border-[#A6AEBA] fixed inset-0 flex justify-center items-center z-50 backdrop-blur"
          onClick={handleClickOutsideModal}
        >
          <img
            src={resume}
            alt="resume job role"
            className="h-[90vh] border-2 "
          />
        </div>
      )}
    </>
  );
};

export const ModalDeleteCampaign = ({ isOpen, deleteUid, onClose }) => {
  const modalStyles = {
    transition: "transform 3s ease-out", // Reduced duration for quicker transition
    transform: `translateY(${isOpen ? "0" : "-100%"})`,
  };
  const navigate = useNavigate();

  const deleteCamp = async () => {
    console.log("in modal", deleteUid);
    const reqData = {
      uid: deleteUid,
      is_deleted: 1,
    };
    const response = await handleApiResponse(
      "/api/v1/interview/add_campaign/",
      reqData,
      1
    );
    if (response?.code === "token_not_valid") {
      navigate("/");
      sessionStorage.clear();
      return;
    }
    console.log("response", response);
    if (response?.data?.status_code === 200) {
      toast.success("Campaign Deleted", {
        className: "custom-toast-success",
      });
    } else {
      toast.error("Something went wrong, try again", {
        className: "custom-toast-error",
      });
    }
    onClose();
  };
  return (
    <>
      <div className="fixed inset-0 flex justify-center items-center z-10  ">
        <div
          className="absolute w-[100vw] z-30 h-[100vh] backdrop-blur"
          onClick={onClose}
        ></div>
        <div
          className="w-[45%] z-50 bg-white rounded-[24px] p-[20px] shadow-md add-member-animation-top "
          style={modalStyles}
        >
          <div className="flex bg-[#F5F8FF] text-center items-center justify-center rounded-[16px] fill-available flex flex-col gap-8 p-12 pb-8">
            <div className="flex flex-col gap-2">
              <div className="leading-[1.2] text-center font-[manrope] text-[28px] font-medium  text-[#00112D] ">
                Are you sure you want to delete this campaign?
              </div>
              <p className=" font-satoshi font-normal text-[18px] mt-2 text-[#00112D]">
                This campaign will be deleted immediately. you can't Undo this
                action.
              </p>
            </div>
            <div className="flex w-full justify-center gap-4">
              {/* Removed unnecessary 'self-center' class */}
              <div
                className={`w-[50%] font-satoshi font-medium text-[18px] h-[52px] border-[1px]  rounded-[16px] text-[#22284E] flex justify-center items-center cursor-pointer  border-[#22284E]`}
                onClick={onClose}
              >
                Cancel
              </div>

              {/* Close modal on cancel */}

              <button
                className={`w-[50%] font-satoshi font-medium text-[18px] justify-end text-white h-[52px] rounded-[16px] border-[1px] border-[#01C3FD] bg-[#22284E]`}
                onClick={deleteCamp}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ModalChangeCampaign = ({
  isOpen,
  deleteUid,
  onClose,
  handleYesClick,
}) => {
  const modalStyles = {
    transition: "transform 3s ease-out", // Reduced duration for quicker transition
    transform: `translateY(${isOpen ? "0" : "-100%"})`,
  };

  return (
    <>
      <div className="fixed inset-0 flex justify-center items-center z-10  ">
        {/* <div
          className="absolute w-[100vw] z-30 h-[100vh] "
          onClick={onClose}
        ></div> */}
        <div
          className="w-[45%] z-200 bg-white rounded-lg p-8 shadow-md add-member-animation-top "
          style={modalStyles}
        >
          <div className="flex items-center justify-center mb-8 fill-available text-[1.389vw]">
            <div className="leading-[1.2] text-center font-[manrope] text-[28px] font-medium  text-[#00112D] ">
              Editing this will change the Question Builder of your existing
              campaign. Do you still want to proceed?
            </div>
          </div>

          <div className="flex justify-center gap-4">
            {" "}
            {/* Removed unnecessary 'self-center' class */}
            <div
              className={`w-[50%] font-satoshi font-medium text-[18px] h-[52px] border-[1px]  rounded-[16px] text-[#22284E] flex justify-center items-center cursor-pointer  border-[#22284E]`}
              onClick={onClose}
            >
              Cancel
            </div>
            {/* Close modal on cancel */}
            <button
              className={`w-[50%] font-satoshi font-medium text-[18px] justify-end text-white h-[52px] rounded-[16px] bg-[#22284E]`}
              onClick={handleYesClick}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export const ModalVerifyEmail = ({ setVerified, setShowEmailVerification }) => {
  const [otp, setOtp] = useState("");

  const handleOtpSubmit = () => {
    setShowEmailVerification(false);
  };

  return (
    <div className="w-[40%] h-[30%] p-12 bg-fill-color-code1 z-10 flex gap-4 flex-col justify-around items-center shadow-md ">
      <p className="text-center text-text-color-code1">
        We have sent you the OTP on your provided email. If it doesn't appear
        within a few minutes, check your spam folder.
      </p>
      <input
        className="p-2 border-2"
        type="text"
        required
        name="opt"
        onChange={(e) => setOtp(e.target.value)}
      />
      <button className="p-2 bg-fill-btn-color-code1" onClick={handleOtpSubmit}>
        <NavLink to={"/admin/dashboard"}>Submit</NavLink>
      </button>
    </div>
  );
};

export const ModalForgetPassword = ({
  setShowForgetPassword,
  setShowCheckOTP,
  email,
  setEmail,
  handleChange,
}) => {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const sendOTP = async () => {
    if (!validateEmail(email)) {
      setError("Please enter the vaild email address");
      return;
    }
    const reqData = { email: email, otp_type: 2 };
    const response = await handleApiResponse("/api/v1/generateotp/", reqData);
    if (response?.code === "token_not_valid") {
      navigate("/");
      sessionStorage.clear();
      return;
    }
    if (response?.status_code === 200) {
      setShowForgetPassword(false);
      setEmail({ email: email });
      console.log("email sent reponse : ", response.data);
      setShowCheckOTP(true);
    } else {
      console.log(response?.msg);
      setError(response?.msg);
    }
  };

  return (
    <div className="absolute w-[100vw] bg-[rgba(0,0,0,0.7)] z-10 h-[100vh] flex justify-center items-center  text-text-color-code1">
      <span className=" font-sans1 relative w-[32rem] bg-white  rounded-[30px]  h-[22rem] flex flex-col justify-center items-center p-12 py-6 gap-8">
        <img
          className="absolute top-4 right-6 cursor-pointer"
          src={closeButton}
          alt="close"
          onClick={() => {
            setShowForgetPassword(false);
          }}
        />
        <div className="flex flex-col justify-center items-center">
          <div className="font-[600] text-[#00112D] text-[24px]">
            Get Back Into Your Account
          </div>
          <h2 className="text-[14px] font-[400] font-satoshi text-[#00112D] mt-4">
            Enter your Email ID to receive verification code
          </h2>
          {/* <p className="text-xs">
          To recover your account, enter your email address below.
        </p> */}
          <InputBoxg
            type="Email ID"
            holder="Email ID"
            name="Email ID"
            value={email}
            onChange={handleChange}
            error={error.email}
          />
          {/* <input
          className="p-2 border-2 outline-none w-[22rem]"
          type="email"
          placeholder="Email"
          name="email"
          value={email}
          
        /> */}
          <div className="text-red-500">{error}</div>
          <button
            className={`fill-available mt-4 text-[18px] justify-end text-white h-[52px] rounded-2xl bg-[#22284E]`}
            onClick={sendOTP}
          >
            Next
          </button>
        </div>
      </span>
    </div>
  );
};

export const ModalCheckOTP = ({
  setShowCheckOTP,
  email,
  setShowSetPassword,
}) => {
  return (
    <div className="absolute bg-[rgba(0,0,0,0.7)] w-[100vw]  z-10 h-[100vh] flex justify-center items-center  text-text-color-code1">
      <OtpValidation
        setShowSetPassword={setShowSetPassword}
        setShowCheckOTP={setShowCheckOTP}
        email={email}
      />
    </div>
  );
};

export const ModalSetPassword = ({ setShowSetPassword, setEmail }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isBubbleVisible, setBubbleVisible] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [error, setError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [conpasswordError, setConPasswordError] = useState("");
  const [errors, setErrors] = useState({
    minLength: true,
    uppercase: true,
    lowercase: true,
    number: true,
    specialChar: true,
  });

  const conditions = [
    { isValid: password.length >= 8, text: "8 Characters" },
    { isValid: /[A-Z]/.test(password), text: "1 Uppercase Letter" },
    { isValid: /[a-z]/.test(password), text: "1 Lowercase Letter" },
    { isValid: /\d/.test(password), text: "1 Number" },
    { isValid: /[!@#$%^&*]/.test(password), text: "1 Special Character" },
  ];

  const sendPass = async () => {
    console.log("-----------------");
    setPasswordError("");
    setConPasswordError("");
    setError("");
    if (!password && !cpassword) {
      setPasswordError("Please enter the password");
      setConPasswordError("Please enter the confirm password");
      return;
    }
    if (!cpassword) {
      setConPasswordError("Please enter the confirm password");
      return;
    }
    if (password !== cpassword) {
      setError("Passwords doesn't match");
      return;
    } else {
      const reqData = {
        password: password,
        cpassword: cpassword,
      };
      const AuthTkn = sessionStorage.getItem("accesTKN");
      console.log(AuthTkn);
      const response = await axios.post(
        `${base_url}/api/v1/reset_password/`,
        reqData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AuthTkn}`,
          },
        }
      );
      if (response.status === 200) {
        setShowSetPassword(false);
        toast.success(
          "Your password has been successfully reset. Please use your new password to log in.",
          {
            className: "custom-toast-success",
          }
        );
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPassVisibility = () => {
    setShowConPassword(!showConPassword);
  };

  const validatePassword = (password) => {
    const newErrors = {
      minLength: password.length < 8,
      uppercase: !/[A-Z]/.test(password),
      lowercase: !/[a-z]/.test(password),
      number: !/[0-9]/.test(password),
      specialChar: !/[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
    setErrors(newErrors);
  };

  const handleChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const hasErrors = () => {
    return Object.values(errors).some((error) => error);
  };

  return (
    // <div className="absolute bg-white w-[100vw]  z-10 h-[100vh] flex justify-center items-center  text-text-color-code1">
    //   <span className=" relative w-[32rem] mt-5 bg-fill-color-code1  border-2 flex flex-col justify-center items-center p-12 py-6 gap-8">
    //     <div className="absolute top-2 right-5 cursor-pointer">
    //       <img
    //         src={closeButton}
    //         alt="close"
    //         onClick={() => setShowSetPassword(false)}
    //       />
    //     </div>
    //     <h1 className="text-xl">Set new Password</h1>
    //     <div>
    //       <div className="relative w-[22rem]">
    //         <input
    //           className="p-2 border-2 outline-none w-full rounded-lg pr-10"
    //           type={showPassword ? 'text' : 'password'}
    //           placeholder="Password"
    //           onChange={handleChange}
    //         />
    //         <button
    //           onClick={togglePasswordVisibility}
    //           className="absolute inset-y-0 right-0 h-full flex items-center px-2"
    //         >
    //           {showPassword ? <FaEye /> : <FaEyeSlash />}
    //         </button>
    //       </div>
    //       <div className="mt-2 text-red-500">
    //         {errors.minLength && (
    //           <div>Password must be at least 8 characters long.</div>
    //         )}
    //         {errors.uppercase && <div>At least one uppercase letter.</div>}
    //         {errors.lowercase && <div>At least one lowercase letter.</div>}
    //         {errors.number && <div>At least one number.</div>}
    //         {errors.specialChar && <div>At least one special character.</div>}
    //       </div>
    //     </div>
    //     {passwordError && <p className="text-red-500">{passwordError}</p>}
    //     <div className="relative w-[22rem]">
    //       <input
    //         className="p-2 border-2 outline-none w-[22rem]"
    //         type={showConPassword ? 'text' : 'password'}
    //         placeholder="Re-Enter Password"
    //         onChange={(e) => {
    //           setCPassword(e.target.value);
    //         }}
    //       />
    //       <button
    //         onClick={toggleConfirmPassVisibility}
    //         className="absolute inset-y-0 right-0 h-full flex items-center px-2"
    //       >
    //         {showConPassword ? <FaEye /> : <FaEyeSlash />}
    //       </button>
    //     </div>
    //     {conpasswordError && <p className="text-red-500">{conpasswordError}</p>}

    //     {error && <p className="text-red-500">{error}</p>}

    //     <button
    //       className={`bg-fill-btn-color-code1 p-2 px-4 ${
    //         hasErrors() ? 'opacity-50 cursor-not-allowed' : ''
    //       }`}
    //       onClick={sendPass}
    //       disabled={hasErrors()}
    //     >
    //       Set Password
    //     </button>
    //   </span>
    // </div>
    <>
      <div
        id="popup-modal"
        tabindex="-1"
        className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[100%] max-h-full bg-[#000000B2]"
      >
        <div className="relative p-4 w-full max-w-lg ">
          <div className="relative bg-white rounded-[30px] shadow p-5">
            <div
              className="absolute cursor-pointer right-5"
              onClick={() => setShowSetPassword(false)}
            >
              <img src={close} alt="close button" />
            </div>

            <div className="p-4 md:p-5 text-center bg-[#fff] rounded-lg">
              <div>
                <h3 className="mt-5 mb-3 font-[manrope] font-semibold text-[#00112D] text-[24px]">
                  {" "}
                  Get back into your account{" "}
                </h3>
                <p className="text-[14px] font-satoshi font-normal text-[#00112D]">
                  Set a new password
                </p>
              </div>

              <div className="flex flex-col text-left mt-5 mb-3">
                <div>
                  <label className=" text-[#1B3351] text-[14px] font-satoshi font-medium">
                    New password <span className="text-red-700">*</span>
                  </label>
                  <div className="relative">
                    <input
                      className="p-2 mt-1 h-[40px] border-[1px] border-[#22284E] outline-none w-full rounded-lg text-[#00112D] text-[0.8rem] font-sans1 font-light "
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter new password"
                      onChange={handleChange}
                      onBlur={(e) => {
                        setBubbleVisible(false); // Hide bubble on blur
                      }}
                      onFocus={() => {
                        setBubbleVisible(true);
                      }}
                    />
                    <button
                      onClick={togglePasswordVisibility}
                      className="absolute inset-y-0 right-0 h-full flex items-center px-2"
                    >
                      {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </button>
                    {isBubbleVisible && (
                      <div className="absolute top-0 z-[100] right-[-210px] w-[200px] p-2 rounded-md bg-white border border-gray-300 shadow-lg">
                        <p className="text-black-600 text-sm font-medium">
                          Password must contain at least:
                        </p>
                        <ul className="text-left text-gray-700 text-xs">
                          {conditions.map((condition, index) => (
                            <li
                              key={index}
                              className={`flex gap-2 ${condition.isValid
                                ? 'text-green-600'
                                : 'text-red-600'
                                }`}
                            >
                              <img
                                src={condition.isValid ? correct : wrong}
                                alt={condition.isValid ? "correct" : "wrong"}
                              />
                              {condition.text}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-[21px]">
                  <label className=" text-[#1B3351] text-[14px] font-satoshi font-medium">
                    Confirm new password
                    <span className="text-red-700 ml-1">*</span>
                  </label>
                  <div className="relative">
                    <input
                      className="p-2 mt-1 h-[40px] border-[1px] border-[#22284E] outline-none w-full rounded-lg text-[#00112D] text-[0.8rem] font-sans1 font-light "
                      type={showConPassword ? "text" : "password"}
                      placeholder="Enter confirm new password"
                      onChange={(e) => {
                        setCPassword(e.target.value);
                      }}
                    />
                    <button
                      onClick={toggleConfirmPassVisibility}
                      className="absolute inset-y-0 right-0 h-full flex items-center px-2"
                    >
                      {showConPassword ? <FaEye /> : <FaEyeSlash />}
                    </button>
                  </div>
                </div>
              </div>

              {conpasswordError && (
                <p className="text-red-500">{conpasswordError}</p>
              )}

              {error && <p className="text-red-500">{error}</p>}

              <div className="flex justify-between items-center mt-[21px]">
                <button
                  className={`w-[100%] h-[52px] text-[#fff] bg-[#22284E] font-satoshi font-medium rounded-[16px] text-[18px] inline-flex items-center px-5 py-2.5 justify-center ${hasErrors() ? ' cursor-not-allowed' : ''
                    }`}
                  onClick={sendPass}
                  disabled={hasErrors()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ModalImportMembers = ({ onClose, onOpen }) => {
  const [error, setError] = useState("");
  const [excelData, setExcelData] = useState(null);
  const [memberFile, setMemberFile] = useState(null);
  const [forceRender, setForceRender] = useState(false);
  const handleDropMember = (e) => {
    e.preventDefault();
    const permissible = [".xlsx", ".xls"];
    const files = Array.from(e.dataTransfer.files);
    if (
      files.length &&
      permissible.some((ext) => files[0].name.endsWith(ext))
    ) {
      setMemberFile(files[0]);
      handleUpload(files[0]);
    }
  };

  const handleUploadMember = (e) => {
    setError("");
    const file = e.target.files[0];
    if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
      handleUpload(file);
    } else {
      setError("File not supported");
    }
  };

  const handleRemoveResume = () => {
    setExcelData([]);
    const currentFileName = memberFile ? memberFile.name : null;
    setMemberFile(null);
    const fileInput = document.getElementById("member-upload");
    if (fileInput) {
      fileInput.value = "";
    }
    const handleUploadMemberAgain = (e) => {
      const file = e.target.files[0];
      if (file && file.name === currentFileName) {
        setMemberFile(file);
      } else {
        setMemberFile(file);
      }
      handleUpload(file);
    };
    fileInput.addEventListener("change", handleUploadMemberAgain, {
      once: true,
    });
    setTimeout(() => {
      setForceRender((prev) => !prev);
    }, 0);
  };

  const handleUpload = (file) => {
    const reader = new FileReader();
    const requiredHeaders = [
      "First Name",
      "Last Name",
      "Email",
      "Designation",
      "Department",
    ];
    const columnsToExtract = [
      "First Name",
      "Last Name",
      "Email",
      "Designation",
      "Department",
    ];
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const FirstRow = parsedData[0];

      const isValidFormat = requiredHeaders.every(
        (header, index) => FirstRow[index] === header
      );

      if (isValidFormat) {
        const dataRows = parsedData.slice(1);
        const finalData = dataRows.filter((ele) => {
          if (ele.length !== 0) {
            return ele;
          }
        });

        const isDataValid = finalData.every((row) =>
          columnsToExtract.every(
            (column) =>
              row[FirstRow.indexOf(column)] !== undefined &&
              row[FirstRow.indexOf(column)] !== ""
          )
        );

        console.log("is valid data ", isDataValid);
        if (isDataValid) {
          setMemberFile(file);
          setExcelData(parsedData);
          setError("");
        } else {
          setExcelData([]);
          setMemberFile(null);
          setError("Missing data in required columns");
        }
      } else {
        setExcelData([]);
        setMemberFile(null);
        setError("Wrong Format");
      }
    };

    reader.onerror = () => {
      setError("Error reading file");
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <div className=" z-10 top-[0rem] left-[0rem] absolute w-[100vw] h-[100vh] backdrop-blur flex flex-col justify-center items-center">
      <div
        className="bg-white border-2 p-16 flex flex-col items-center gap-3 w-[32rem] relative"
        onDrop={handleDropMember}
        onDragOver={(e) => e.preventDefault()}
      >
        <img
          className="absolute top-3 right-3 cursor-pointer"
          src={closeButton}
          alt="close"
          onClick={() => {
            onClose();
            setExcelData([]);
          }}
        />
        <img src={resumeUpload} alt="upload" className="mt-[1.9rem]" />
        <p>Drag or drop Member list here (xlsx, xls)</p>
        <label
          htmlFor="member-upload"
          className="cursor-pointer border-2 h-[1.5rem] w-[4.125rem] text-center"
        >
          Browse
          <input
            type="file"
            id="member-upload"
            className="hidden"
            onChange={handleUploadMember}
          />
        </label>
        {error && <p className="text-red-500">{error}</p>}
        <div
          className={`bg-fill-btn-color-code1 p-4 rounded-lg flex gap-4 justify-between items-center ${memberFile ? 'block' : 'hidden'
            }`}
        >
          <p>
            File Name:{" "}
            {memberFile?.name.length > 10
              ? memberFile?.name.slice(0, 10) + "..."
              : memberFile?.name || ""}
          </p>
          <p
            onClick={handleRemoveResume}
            className="border-2 p-1 cursor-pointer"
          >
            Close
          </p>
        </div>
      </div>
      {excelData?.length !== 0 && (
        <p
          onClick={() => {
            onClose();
          }}
          className=" p-2 w-[32rem] text-center bg-fill-btn-color-code1 cursor-pointer"
        >
          Next
        </p>
      )}
    </div>
  );
};

export const ModalAddHrQuestions = ({
  onClose,
  onOpen,
  excelData,
  setExcelData,
}) => {
  const [error, setError] = useState("");
  const [memberFile, setMemberFile] = useState(null);
  const [forceRender, setForceRender] = useState(false);

  const handleDropMember = (e) => {
    e.preventDefault();
    const permissibleExtensions = ["xls", "xlsx"];
    const files = Array.from(e.dataTransfer.files);
    if (
      files.length > 0 &&
      permissibleExtensions.includes(
        files[0].name.split(".").pop().toLowerCase()
      )
    ) {
      setMemberFile(files[0]);
      handleUpload(files[0]);
    } else {
      toast.warning("Invalid file type. Please upload a PDF file.", {
        className: "custom-toast-warning",
      });
    }
  };

  const handleUploadMember = (e) => {
    setError("");
    const file = e.target.files[0];
    if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
      handleUpload(file);
    } else {
      setError("File not supported");
    }
  };

  const handleRemoveResume = () => {
    setExcelData([]);
    const currentFileName = memberFile ? memberFile.name : null;
    setMemberFile(null);
    const fileInput = document.getElementById("member-upload");
    if (fileInput) {
      fileInput.value = "";
    }
    const handleUploadMemberAgain = (e) => {
      const file = e.target.files[0];
      if (file && file.name === currentFileName) {
        setMemberFile(file);
      } else {
        setMemberFile(file);
      }
      handleUpload(file);
    };
    fileInput.addEventListener("change", handleUploadMemberAgain, {
      once: true,
    });
    setTimeout(() => {
      setForceRender((prev) => !prev);
    }, 0);
  };

  const handleUpload = (file) => {
    const reader = new FileReader();
    const oldFormat = ["Question", "Answer"];
    const newFormat = ["Question No.", "Question", "Answer No.", "Answer"];

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const firstRow = parsedData[0];

      console.log("Parsed first row:", firstRow); // Log for debugging

      const isOldFormat = oldFormat.every(
        (header, index) => firstRow[index] === header
      );
      const isNewFormat = newFormat.every(
        (header, index) => firstRow[index] === header
      );

      if (isOldFormat || isNewFormat) {
        const dataRows = parsedData.slice(1);
        const finalData = dataRows.filter((row) => row.length !== 0);

        const isDataValid = finalData.every((row) => {
          if (isOldFormat) return row.length === 2;
          if (isNewFormat) return row.length === 4;
          return false;
        });

        console.log("Is data valid:", isDataValid);
        console.log("Final data:", finalData);

        if (isDataValid) {
          setMemberFile(file);
          setExcelData(finalData);
          setError("");
        } else {
          setExcelData([]);
          setMemberFile(null);
          setError("Missing data in required columns");
        }
      } else {
        setExcelData([]);
        setMemberFile(null);
        setError(
          "Wrong Format"
        );
      }
    };

    reader.onerror = () => {
      setError("Error reading file");
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <div className="z-10 top-[0rem] left-[0rem] absolute w-[100vw] h-[100vh] bg-[#000000B2] flex flex-col justify-center items-center">
      <div
        className="bg-white relative rounded-[30px] p-[30px] flex flex-col items-center gap-3 w-[42rem] before:content-[''] before:absolute before:inset-0 before:bg-black before:opacity-40 before:rounded-[30px] before:z-[-1]"
        onDrop={handleDropMember}
        onDragOver={(e) => e.preventDefault()}
      >
        {/* <img
          className="absolute top-3 right-3 cursor-pointer"
          src={closeButton}
          alt="close"
          onClick={() => {
            onClose();
            setExcelData([]);
          }}
        /> */}
        <div className="bg-[#F5F8FF] font-satoshi w-full h-full rounded-[15px] text-[#00112D] p-[30px]">
          <div className="font-sans1 font-[600] text-[24px] trailing-[32.7px] mb-2">
            Import Questions By Uploading a List
          </div>
          <div className="font-[400] text-[14px] trailing-[32.7px] ">
            Hello Let’s get started. lorem ipsum lorem
          </div>
          <div className="font-[400] text-[14px] trailing-[32.7px] mt-8 mb-2">
            Import Questions List
          </div>
          <div className="flex flex-col fill-available">
            <div
              // onDrop={handleDropResume}
              onDragOver={(e) => e.preventDefault()}
              className=" relative text-white placeholder:text-[#8893A2] focus:outline-none flex items-center w-full p-[1px] rounded-xl "
            >
              <input
                type="text"
                value={
                  memberFile?.name.length > 10
                    ? memberFile?.name.slice(0, 50)
                    : memberFile?.name || ""
                }
                placeholder="Drag and drop CSV file here (Max file size : 50MB)"
                className=" text-[14px] h-[51px] flex-grow p-3.5 px-3 rounded-xl border-[1px] border-[#22284E] text-black placeholder:text-[#A6AEBA] focus:outline-none"
                readOnly
                style={{
                  letterSpacing: "2%",
                }}
              />
              <div
                className="absolute top-[0.4rem] right-[0.4rem] z- 10 flex items-center justify-center cursor-pointer h-[39px] w-[187px] bg-[#22284E] text-white rounded-lg ml-2"
                // onDrop={handleDropResume}
                onDragOver={(e) => e.preventDefault()}
              >
                <label
                  htmlFor="resume-upload"
                  className="flex items-center justify-center gap-[0.4rem] text-[16px]"
                >
                  <img src={importIcon} alt="upload icon" />
                  Browse
                  <input
                    type="file"
                    id="resume-upload"
                    className="opacity-0 left-0 right-0 top-0 bottom-0  absolute cursor-pointer"
                    onChange={handleUploadMember}
                  />
                </label>
              </div>
            </div>
          </div>
          {error && <p className="text-red-500">{error}</p>}
          <p
            onClick={() => {
              // onOpen();
              onClose();
            }}
            className="mt-6 flex items-center text-[20px] justify-center cursor-pointer h-[42px] w-full bg-[#22284E] text-white rounded-lg"
          >
            Submit
          </p>
          {/* <div>
        <img src={resumeUpload} alt="upload" className="mt-[1.9rem]" />
        <p>Drag or drop Member list here (xlsx, xls)</p>
        <label
          htmlFor="member-upload"
          className="cursor-pointer border-2 h-[1.5rem] w-[4.125rem] text-center"
        >
          Browse
          <input
            type="file"
            id="member-upload"
            className="hidden"
            onChange={handleUploadMember}
          />
        </label>
        </div> */}
        </div>
      </div>
      {/* {excelData.length !== 0 && ( */}
      {/* )} */}
    </div>
  );
};

export const ModalAddingBulkMembers = ({ onClose, excelData, closeModal }) => {
  const [openLoader, setOpenLoader] = useState(true);
  const [errorFromAPI, setErrorFromAPI] = useState([]);
  const [successCount, setSuccessCount] = useState(0);
  const toastIdRef = useRef(null);
  const navigate = useNavigate();
  const handleAdd = async (
    first,
    last,
    email,
    designation,
    organization,
    dept
  ) => {
    const reqData = {
      first_name: first,
      last_name: last,
      email: email.replace(/\s+/g, ""),
      designation: designation.replace(/\s+/g, ""),
      is_resume_upload_allow: true,
      organization: organization.replace(/\s+/g, ""),
    };

    if (dept) {
      reqData["department"] = dept.replace(/\s+/g, "");
    }

    try {
      const response = await handleApiResponse(
        "/api/v1/auth/generate_member_link/",
        reqData,
        1
      );
      if (response?.code === "token_not_valid") {
        navigate("/");
        sessionStorage.clear();
        return;
      }
      if (response?.status === 200) {
        if (response?.data?.status_code === 200) {
          setSuccessCount((prevCount) => {
            const newCount = prevCount + 1;
            if (newCount === 50) {
              toast.success("User Added", {
                className: "custom-toast-success",
              });
              setSuccessCount(0);
            }
            return newCount;
          });
          setOpenLoader(false);
        } else {
          const errorMessage = response?.data?.msg
            ?.split(":")[0]
            .includes("duplicate")
            ? "User already exists"
            : response.data.msg;
          setErrorFromAPI((prev) => [
            ...prev,
            [email.replace(/\s+/g, ""), errorMessage],
          ]);
          setOpenLoader(false);
        }
      } else {
        setOpenLoader(false);
      }
    } catch (error) {
      setOpenLoader(false);
      console.error("API call failed", error);
      setErrorFromAPI((prev) => [
        ...prev,
        [email, "Couldn't connect to the server"],
      ]);
    }
  };

  const processExcelData = async () => {
    const organization = sessionStorage.getItem("organization");
    console.log("->", excelData);
    for (let i = 1; i < excelData.length; i++) {
      const [first, last, email, designation, department] = excelData[i];
      await handleAdd(
        first,
        last,
        email,
        designation,
        organization,
        department
      );
    }
    setOpenLoader(false);
  };

  useEffect(() => {
    if (excelData) {
      processExcelData();
    }
  }, [excelData]);

  useEffect(() => {
    console.log("useEffect triggered with:", {
      errorFromAPI,
      excelData,
      successCount,
    });

    if (excelData.length - 1 === 0) {
      closeModal();
      toast.warn("Empty file uploaded.", {
        className: "custom-toast-warn",
      });
    } else if (successCount > 0) {
      const message =
        successCount === 1
          ? "1 Member added successfully."
          : `${successCount} Members Added Successfully`;

      if (toastIdRef.current === null) {
        toastIdRef.current = toast.success(message, {
          className: "custom-toast-success",
        });
      } else {
        toast.update(message, {
          className: "custom-toast-update",
        });
      }
    }
  }, [errorFromAPI, excelData, successCount]);

  return (
    <div className="z-10 top-0 left-0 absolute w-full h-full backdrop-blur flex flex-col justify-center items-center">
      {openLoader && <Loader />}
      {errorFromAPI.length !== 0 && (
        <div className="flex flex-col rounded-3xl p-6 gap-4 justify-between border-2 bg-white items-center h-[70vh] w-[34rem]">
          <div className="bg-[#F5F8FF] pt-[60px] pb-[30px] px-[30px] overflow-auto flex flex-col justify-between items-center gap-4">
            <p className="font-[manrope] font-semibold text-center text-[32px] ">
              Encountered Error in Adding Following users
            </p>

            <div className="w-full h-full overflow-auto ">
              {successCount.length > 0 && (
                <span className="flex gap-1 text-[#48C885] font-semibold font-satoshi">
                  {" "}
                  <img src={correct} alt="" />{" "}
                  <span>{successCount.length}</span>{" "}
                  <span>User Added Successfully</span>
                </span>
              )}
              <span className="flex gap-1 text-[#FF5F52] font-semibold font-satoshi">
                {" "}
                <img src={wrong} alt="" /> <span>{errorFromAPI.length}</span>{" "}
                <span>User Already Exists</span>
              </span>
              {errorFromAPI.map((ele, index) => (
                <div
                  key={index}
                  className="flex items-center text-[#FF5F52] mt-4 gap-2"
                >
                  <span className="w-[5px] h-[5px] rounded-full bg-[#FF5F52]"></span>
                  <span>{ele[0]}</span>
                  <span>-</span>
                  <span>{ele[1]}</span>
                </div>
              ))}
            </div>
            <button
              className="w-[50%] text-[18px] justify-end text-white h-[90px] rounded-lg bg-[#22284E]"
              onClick={() => {
                onClose();
                setErrorFromAPI([]);
                window.location.reload();
              }}
            >
              Finish
            </button>
          </div>
        </div>
      )}
      {errorFromAPI.length == 0 && (
        <div className="flex flex-col mt-4 bg-white p-[30px] gap-2 justify-between rounded-[24px] w-2/4 items-center">
          <div className="w-full bg-[#F5F8FF] justify-center items-center rounded-[16px] flex flex-col">
            {excelData.length - 1 !== 0 && (
              <p className=" bg-[#F5F8FF] py-[60px] pb-[20px] px-[50px] rounded-[16px] text-[#00112D] text-2xl">
                {successCount} Members Added Successfully
              </p>
            )}

            <button
              className="p-1 rounded-[8px] w-[405px] h-[42px] bg-[#22284E] text-white mb-[60px]"
              onClick={() => {
                onClose();
                window.location.reload();
              }}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export const ModalPopup = ({ message, onConfirm }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p>{message}</p>
        <button onClick={onConfirm}>OK</button>
      </div>
    </div>
  );
};
