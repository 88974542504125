import { NavBar } from "../components/AdminComponents/navbar";
import { SideBar } from "../components/AdminComponents/sidebar";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { addMemberbg, campaignbg, sideBar } from "../assets/imgs";

export const AdminLayout = () => {
  const [logout, setLogout] = useState(false);
  const navigate = useNavigate();
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);

  return (
    <header className="h-[100vh] text-black w-full bg-[#F8FBFF] flex flex-col">
      {logout && (
        <div className="fixed backdrop-blur inset-0 overflow-auto flex justify-end z-50">
          <div
            className="fill-available"
            onClick={() => setLogout(false)}
          ></div>
          <div className="absolute gap-8 justify-center flex flex-col items-center top-[30%] left-[33%] h-auto w-[55%] max-w-[630px] z-50 bg-white rounded-[30px] text-center p-8 shadow-md ">
            <div className="rounded-[15px] flex flex-col bg-[#F5F8FF] w-full items-center justify-center gap-[3rem] px-[2rem] py-8">
              <div className="text-center font-satoshi self-center text-[#00112D] text-[28px] font-semibold tracking-[0.15px]">
                Are you sure you want to log out?
                <p className="text-[21px] font-normal ">
                  You're about to log out of your Clevercruit account. Any
                  unsaved changes will be lost.
                </p>
              </div>

              {/* <p>Lorem ipsum, dolor sit amet repudiandae ullam libero?</p> */}
              <div className="w-full custom-sm:gap-4 custom-md:gap-12 flex justify-center">
                {/* <CustomWhiteButton text={'Cancel'} onClick={setClose} /> */}
                <div
                  className={`custom-sm:w-[60%] custom-md:w-[50%] text-[18px] h-[52px] rounded-[16px] text-[#22284E] flex justify-center items-center cursor-pointer border-2 border-[#22284E]`}
                  onClick={() => setLogout(false)}
                >
                  Go Back
                </div>
                {/* <div
              className={`w-[384px] text-[14px] h-[52px] rounded-[16px] text-[#22284E] mt-4 flex justify-center items-center cursor-pointer ${hovered ? 'border-2 border-[#22284E]' : ''
                }`}
              onClick={handleAdd}
            >
              Save
            </div> */}
                <button
                  className={`custom-sm:w-[70%] custom-md:w-[50%]  text-[18px] justify-end text-white h-[52px] rounded-[16px] bg-[#22284E]`}
                  onClick={() => {
                    navigate("/");
                    sessionStorage.clear();
                  }}
                >
                  Yes Log Me Out
                </button>
                {/* <button
              className="h-12 w-[12.5rem] border-2"
              style={{
                backgroundColor: 'white',
                transition: 'background-color 0.3s',
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = '#F1F2F4';
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = '';
              }}
              onClick={handleAdd}
            >
              Update
            </button> */}
              </div>
              {/* <div className="text-[18px] text-center mt-5 w-[40vw] font-normal self-center">
                Hello Let’s get started. lorem ipsum lorem
              </div> */}

              {/* <img
              src={closeButton}
              alt="close button"
              onClick={onClose}
              className="cursor-pointer w-[40px] h-[40px]"
            /> */}
            </div>

            {/* <div className="justify-center flex gap-4 self-center">
            <CustomButtonWhite text={'Cancel'} onClick={onClose} />
            <CustomButton text={'Yes'} onClick={deleteMemeber} />
          </div> */}
          </div>
        </div>
      )}
      <>
        <NavBar
          setIsShowPopup={setIsShowPopup}
          openNotification={openNotification}
          setOpenNotification={setOpenNotification}
        />
      </>
      <div
        className="flex  bg-[#F8FBFF] fill-available fill-available-firefox fill-available-h"
        onClick={() => setOpenNotification(false)}
      >
        <aside
          className="bg-[#F8FBFF] w-[20rem] flex flex-col items-center custom-sm:pl-[29px] custom-md:pl-0"
          style={{
            background: `url(${sideBar}) no-repeat bottom`,
          }}
        >
          <SideBar
            setLogout={setLogout}
            isShowPopup={isShowPopup}
            setIsShowPopup={setIsShowPopup}
          />
        </aside>

        <main
          onClick={() => setIsShowPopup(false)}
          className="flex fill-available rounded-tl-[2rem] p-4 pb-2 bg-[#EFF5FF] flex-col overflow-auto h-auto "
        >
          <Outlet />
        </main>
      </div>
    </header>
  );
};
