import React, { useState, useEffect, useMemo, useRef } from "react";
import { cam, clevercruitlogo, full, timerBg } from "../assets/imgs";
import { Loader } from "../components/loader";
import { useSpeechRecognition } from "react-speech-recognition";

import { useNavigate } from "react-router-dom";
import { handleApiResponse } from "../API/services";
import { useParams } from "react-router-dom";

import { WhisperSTT } from "./WhisperSTT";
import { formatMinutesSeconds, formatTime } from "../utils/formatTime";
import { useSelector } from "react-redux";
import { getLocal, setLocal } from "../utils/localStorage";
import { TemplateBox, TemplateBox1 } from "../components/textArea";
import { anstimerbg } from "../assets/imgs";

const WebcamComponent = ({
  isLogoVisible,
  setIsLogoVisible,
  endTimer,
  showTimer,
  no_Face_detected,
  warningCount,
  setMessages,
  isListening,
  setIsListening,
  setSpeech,
  timerRunning,
  setTimerRunning,
  setSocket,
  timer,
  setTimer,
  isSpeaking,
  setIsSpeaking,
  socket,
  endInterviewCounter,
  setEndInterviewCounter,
  resetIdleCounter,
  setStarted,
  isStarted,
  setFirst,
  handleSocket,
  interviewTime,
  setInterviewTime,
  interviewTimeRun,
  setInterviewTimeRun,
  openEditor,
  videoRefElement,
  setLoader,
  loader,
  audioSource,
  exitquestion,
  questcounter,
  exit,
  first_name,
  first_name1,
  last_name,
  last_name1,
  recording,
  setRecording,
  loader1,
  setLoader1,
  face_detect,
  handleFaceDetect,
  setFaceSocket,
  faceSocket,
  setExitQuestion,
  setNext,
  setExit,
  isEndTimer,
  setAudioSource,
  audio,
  coveringCTimer,
  setCover,
  setPauseAlerts,
}) => {
  const uid1 = sessionStorage.getItem("CandidateUid");
  const uid = getLocal("user");
  const campType = sessionStorage.getItem("campType");
  const locallyNext = localStorage.getItem("showNext");
  const endTimer1 = sessionStorage.getItem("setIsEndTimer");
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const partNumberRef = useRef(1);
  const { browserSupportsSpeechRecognition } = useSpeechRecognition();
  const [lastApiResponse, setLastApiResponse] = useState([]);
  const [showSurePopup, setShowSurePopup] = useState(false);
  const [videoOn, setVideoOn] = useState(true);
  const [videoStream, setVideoStream] = useState(null);
  const [isSure, setIsSure] = useState(false);
  const chunkIntervalRef = useRef(null);
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [recordingBlob, setRecordingBlob] = useState(null);

  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const videoRef = useRef(null);
  const videoStreaming = useRef(null);
  const prevIsOnlineRef = useRef(isOnline);
  const [multiFaceDetected, setMultiFaceDetected] = useState(false);
  const [headDirection, setHeadDirection] = useState("");
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const disconnectTimeRef = useRef(null);
  const timeoutRef = useRef(null);
  const [startLoader, setIsStartLoader] = useState(false);
  const [nettimer, setNetTimer] = useState(3 * 60); // 3 minutes in seconds

  const handleOnline = () => {
    setIsOnline(true);
    clearTimeout(timeoutRef.current);
    disconnectTimeRef.current = null;
    setNetTimer(0);
  };

  useEffect(() => {
    if (isLogoVisible) {
      const timer = setTimeout(() => {
        setIsLogoVisible(false); // Turn off after 3 seconds
      }, 3000);

      // Cleanup the timer if the component unmounts or isLogoVisible changes
      return () => clearTimeout(timer);
    }
  }, [isLogoVisible]);
  
  useEffect(() => {
    let intervalRef;

    if (!isOnline && nettimer > 0) {
      intervalRef = setInterval(() => {
        setNetTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (nettimer <= 0) {
      clearInterval(intervalRef);
      sessionStorage.setItem("networkError", true);
      endInterview();
    }

    return () => clearInterval(intervalRef);
  }, [isOnline, nettimer]);

  const handleOffline = () => {
    setIsOnline(false);
    disconnectTimeRef.current = Date.now();
    timeoutRef.current = setTimeout(() => {
      const now = Date.now();
      if (
        disconnectTimeRef.current &&
        now - disconnectTimeRef.current >= 3 * 60 * 1000
      ) {
        sessionStorage.setItem("networkError", true);
        // alert('Network has been disconnected for 0.5 minute');
        // navigate("../certificate");
        endInterview();
      }
    }, 3 * 60 * 1000); //3 minutes
  };

  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleRecordingComplete = (blob) => {
    setRecordingBlob(blob);
  };

  useEffect(() => {
    let interviewTimeInterval;
    if (interviewTimeRun) {
      interviewTimeInterval = setInterval(() => {
        setInterviewTime((prevTime) => {
          const newTime = prevTime + 1;
          sessionStorage.setItem("interviewTime", newTime);
          return newTime;
        });
      }, 1000);
    }
    return () => clearInterval(interviewTimeInterval);
  }, [interviewTimeRun]);

  useEffect(() => {
    let timerInterval;
    if (timerRunning) {
      timerInterval = setInterval(() => {
        setTimer((prevTimer) => {
          const newTimer = prevTimer - 1;
          sessionStorage.setItem("timer", newTimer);
          if (newTimer <= 0) {
            clearInterval(timerInterval);
          }
          return newTimer;
        });
      }, 1000);
    }
    return () => clearInterval(timerInterval);
  }, [timerRunning]);

  useEffect(() => {
    sessionStorage.setItem("interviewTimeRun", interviewTimeRun);
  }, [interviewTimeRun]);

  useEffect(() => {
    sessionStorage.setItem("timerRunning", timerRunning);
  }, [timerRunning]);

  console.log("network status", isOnline ? "Online" : "Offline");

  const endInterview = async () => {
    setLoading(true);
    let networkError = sessionStorage.getItem("networkError");
    // await handleVideoSubmit();
    if (!loading) {
      if (
        warningCount >= "5" ||
        no_Face_detected >= "3" ||
        exit === "NO" ||
        endInterviewCounter ||
        isEndTimer ||
        endTimer <= 1 ||
        coveringCTimer <= 1 ||
        networkError
      ) {
        if (socket) {
          socket.close();
          console.log("Socket connection closed");
        }
        if (faceSocket) {
          faceSocket.close();
          console.log(" face Socket connection closed");
        }
        setAudioSource("");
        if (audio) {
          audio.pause();
        }
        setIsSpeaking(false);
        setTimerRunning(false);
        setInterviewTimeRun(false);
        setSocket(null);
        setFaceSocket(null);
        window.speechSynthesis.cancel();
        sessionStorage.removeItem("hasStartedRecording");
        const initialInterviewTime = 0;
        const initialTimer = 90;
        setInterviewTime(initialInterviewTime);
        setTimer(initialTimer);
        setTimerRunning(false);
        setInterviewTimeRun(false);
        sessionStorage.setItem("interviewTime", initialInterviewTime);
        sessionStorage.setItem("timer", initialTimer);
        sessionStorage.removeItem("showNext");
        sessionStorage.removeItem("endInterview");
        sessionStorage.removeItem("lastEndInterview");
        sessionStorage.removeItem("chatroomLoading");
        sessionStorage.removeItem("questionCounter");
        sessionStorage.removeItem("setIsEndTimer");
        sessionStorage.removeItem("setCoveringCamera");
        sessionStorage.removeItem("setcoveringCTimer");
        sessionStorage.removeItem("setcoverModel");

        const data = {
          candidate_uid: campType === "Regular" ? uid : uid1,
          suspicious_cause: JSON.parse(sessionStorage.getItem("causeArray"))
            ?.suspicious_cause,
        };
        console.log(data, "ddddddddddddddddd");
        const response = await handleApiResponse(
          "/api/v1/interview/start_analytics_generations/",
          data
        );
        console.log(response, "response data");
        setTimeout(() => {
          setLoading(false);
          navigate("../certificate", { state: { timer: formatTime(timer) } });
        }, 5000);
      }
    }
  };

  useEffect(() => {
    if (!isOnline) {
      setLoading(true);
      setTimerRunning(false);
      setInterviewTimeRun(false);
    } else {
      setLoading(false);
      const savedTimer = sessionStorage.getItem("timer");
      const savedTimer1 = sessionStorage.getItem("interviewTime");
      if (savedTimer !== null && parseInt(savedTimer, 10) > 0) {
        setTimer(parseInt(savedTimer, 10));
        setTimerRunning(true);
      }
      if (savedTimer1 !== null && parseInt(savedTimer1, 10) > 0) {
        setInterviewTime(parseInt(savedTimer1, 10));
        setInterviewTimeRun(true);
      }
    }

    // Check if the network status has changed from offline to online
    if (prevIsOnlineRef.current === false && isOnline === true) {
      window.location.reload();
    }

    // Update the ref with the current isOnline value
    prevIsOnlineRef.current = isOnline;
  }, [isOnline]);

  // const handleVideoSubmit = async () => {
  //   console.log('setting loading')
  //   setLoading(true);
  //   try {
  //     await handleStopRecording(async () => {
  //       try {
  //         const parts = lastApiResponse.map((response, index) => ({
  //           ETag: response.ETag,
  //           PartNumber: response.PartNumber,
  //         }));
  //         const formData = new FormData();
  //         formData.append(
  //           'candidate_uid',
  //           sessionStorage.getItem('CandidateUid')
  //         );
  //         formData.append('upload_id', sessionStorage.getItem('upload_id'));
  //         formData.append('is_complted', '1');
  //         formData.append('parts', JSON.stringify(parts));

  //         const response = await handleApiResponse(
  //           '/api/v1/interview/upload_video/',
  //           formData,
  //           0
  //         );

  //         if (response?.status_code === 200) {
  //           console.log("Recording sent successfully.");
  //           setLoading(false);
  //           setIsSpeaking(false);
  //           setTimerRunning(false);
  //           setInterviewTimeRun(false);
  //           sessionStorage.removeItem('CandidateUid');
  //           if (socket) {
  //             socket.close();
  //             console.log('Socket connection closed');
  //           }
  //           setSocket(null);
  //           window.speechSynthesis.cancel();
  //           console.log('here')
  //           navigate("../certificate", { state: { timer: formatTime(timer) } });
  //         } else {
  //           setLoading(false);
  //           console.error("Failed to send recording:", response);
  //         }
  //       } catch (error) {
  //         setLoading(false);
  //         console.error("Failed to submit video", error);
  //       }
  //     });
  //   } catch (error) {
  //     console.error('Error in handleStopRecording:', error);
  //   }
  // };

  const scheduleInterview = async () => {
    if (isStarted) {
      if (endInterviewCounter) {
        setStarted(!isStarted); //false
        sessionStorage.setItem("isStarted", !isStarted);
        endInterview();
        console.log("first");
      } else {
        setShowSurePopup(true);
      }
    } else {
      // start interview
      setInterviewTimeRun(true);
      setFirst(1);
      setStarted(!isStarted);
      sessionStorage.setItem("isStarted", !isStarted);
      handleSocket();
      handleFaceDetect(sendFrame);
    }
  };

  // let frameInterval;

  // const startSendingFrames = () => {
  //   frameInterval = setInterval(() => {
  //     sendFrame();
  //   }, 33.33);
  // };

  // const stopSendingFrames = () => {
  //   clearInterval(frameInterval);
  // };

  useEffect(() => {
    const isStarted = sessionStorage.getItem("isStarted") === "true";
    if (isStarted) {
      sendFrame();
      // startSendingFrames();
    }

    // return () => {
    //   stopSendingFrames();
    // };
  }, [interviewTime]);

  useEffect(() => {
    if (isSure) {
      setStarted(!isStarted);
      sessionStorage.setItem("isStarted", !isStarted);
      endInterview();
    }
  }, [isSure]);

  useEffect(() => {
    console.log("reason", no_Face_detected);
    if (warningCount >= "5" || no_Face_detected >= "3") {
      setTimeout(() => {
        setCover(false);
        endInterview();
      }, 3000);
    }
  }, [warningCount, no_Face_detected]);

  useEffect(() => {
    if (endTimer <= 1) {
      endInterview();
    }
  }, [endTimer]);

  useEffect(() => {
    if (coveringCTimer <= 1) {
      sessionStorage.setItem("coverCamera", true);
      endInterview();
    }
  }, [coveringCTimer]);

  useEffect(() => {
    let stream = null;
    if (
      videoOn &&
      navigator.mediaDevices &&
      navigator.mediaDevices.getUserMedia
    ) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((streamObj) => {
          setVideoStream(streamObj);
          stream = streamObj;
          setTimeout(() => {
            stream = streamObj;
            videoRef.current.srcObject = streamObj;
          }, 5000);
        })
        .catch((error) => {
          console.error("Error accessing webcam:", error);
        });
    }

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
        setVideoStream(null); // Clear the video stream state
      }
    };
  }, [videoOn]);

  const sendFrame = () => {
    const canvas = document.createElement("canvas");
    canvas.width = videoRef?.current?.videoWidth;
    canvas.height = videoRef?.current?.videoHeight;
    const ctx = canvas.getContext("2d");

    try {
      ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        if (blob) {
          faceSocket?.send(blob);
        }
      }, "image/jpeg");
    } catch (e) {
      return;
    }
  };

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const screenStreamRef = useRef(null);

  const startRecording = async () => {
    setLoading(true);
    setError(null);
    setPauseAlerts(true);

    try {
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: { cursor: "always" },
        audio: true, // System audio
      });

      if (screenStream.getAudioTracks().length === 0) {
        throw new Error("System audio must be enabled.");
      }

      if (
        screenStream.getVideoTracks()[0].getSettings().displaySurface ===
        "monitor"
      ) {
        const microphoneStream = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
          },
        });

        const audioStream = audio ? audio.captureStream() : null;
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        const systemAudioSource =
          audioContext.createMediaStreamSource(screenStream);
        const microphoneAudioSource =
          audioContext.createMediaStreamSource(microphoneStream);
        const micGainNode = audioContext.createGain();
        micGainNode.gain.value = 2;
        const audioDestination = audioContext.createMediaStreamDestination();

        systemAudioSource.connect(audioDestination);
        microphoneAudioSource.connect(micGainNode);
        micGainNode.connect(audioDestination);

        if (audioStream) {
          const additionalAudioSource =
            audioContext.createMediaStreamSource(audioStream);
          additionalAudioSource.connect(audioDestination);
        }

        const combinedStream = new MediaStream([
          ...screenStream.getVideoTracks(),
          ...audioDestination.stream.getAudioTracks(),
        ]);

        const mediaRecorder = new MediaRecorder(combinedStream, {
          mimeType: "video/webm; codecs=vp9,opus",
        });
        setIsLogoVisible(true);
        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            recordedChunksRef.current.push(event.data);
          }
        };
        mediaRecorder.onstop = async () => {
          if (recordedChunksRef.current.length > 0) {
            const blob = new Blob(recordedChunksRef.current, {
              type: "video/webm",
            });
            console.log("Generated Blob:", blob);
            setRecordingBlob(blob);
            await sendChunkToAPI(blob);
            recordedChunksRef.current = [];
          } else {
            console.error("No data was recorded.");
          }
        };

        mediaRecorder.start(100);

        mediaRecorderRef.current = mediaRecorder;
        setRecording(true);
        setLoading(false);
        setPauseAlerts(false);
        chunkIntervalRef.current = setInterval(() => {
          if (mediaRecorderRef.current.state === "recording") {
            mediaRecorderRef.current.stop();
            setTimeout(() => {
              mediaRecorderRef.current.start();
            }, 500);
          }
        }, 5000);
      }
    } catch (err) {
      try {
        const screenStream = await navigator.mediaDevices.getDisplayMedia({
          video: { cursor: "always" },
          audio: true,
        });

        if (screenStream.getAudioTracks().length === 0) {
          console.error("System audio must be enabled.");
        }
        if (
          screenStream.getVideoTracks()[0].getSettings().displaySurface ===
          "monitor"
        ) {
          const microphoneStream = await navigator.mediaDevices.getUserMedia({
            audio: {
              echoCancellation: false,
              noiseSuppression: false,
              autoGainControl: false,
            },
          });

          const audioStream = audio ? audio.captureStream() : null;
          const audioContext = new (window.AudioContext ||
            window.webkitAudioContext)();
          let systemAudioSource;
          if (!screenStream.getAudioTracks().length === 0) {
            systemAudioSource =
              audioContext.createMediaStreamSource(screenStream);
          }
          const microphoneAudioSource =
            audioContext.createMediaStreamSource(microphoneStream);

          const micGainNode = audioContext.createGain();
          micGainNode.gain.value = 2;

          const audioDestination = audioContext.createMediaStreamDestination();

          if (systemAudioSource) {
            systemAudioSource.connect(audioDestination);
          }
          microphoneAudioSource.connect(micGainNode);
          micGainNode.connect(audioDestination);

          if (audioStream) {
            const additionalAudioSource =
              audioContext.createMediaStreamSource(audioStream);
            additionalAudioSource.connect(audioDestination);
          }

          const combinedStream = new MediaStream([
            ...screenStream.getVideoTracks(),
            ...audioDestination.stream.getAudioTracks(),
          ]);

          const mediaRecorder = new MediaRecorder(combinedStream, {
            mimeType: "video/webm; codecs=vp9,opus",
          });
          setIsLogoVisible(true);
          mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              recordedChunksRef.current.push(event.data);
            }
          };

          mediaRecorder.onstop = async () => {
            if (recordedChunksRef.current.length > 0) {
              const blob = new Blob(recordedChunksRef.current, {
                type: "video/webm",
              });
              console.log("Generated Blob:", blob);
              setRecordingBlob(blob);
              await sendChunkToAPI(blob);
              recordedChunksRef.current = [];
            } else {
              console.error("No data was recorded.");
            }
          };

          mediaRecorder.start(100);
          mediaRecorderRef.current = mediaRecorder;
          setRecording(true);
          setLoading(false);

          chunkIntervalRef.current = setInterval(() => {
            if (mediaRecorderRef.current.state === "recording") {
              mediaRecorderRef.current.stop();
              setTimeout(() => {
                mediaRecorderRef.current.start();
              }, 500);
            }
          }, 5000);
        }
      } catch (err) {
        console.error("Error during screen capture and recording:", err);
        setError(
          err.message ||
          "Failed to start screen capture and recording. Please try again."
        );
        setLoading(false);
      }
    }
    // if (err.message === 'Please select "Entire Screen" for recording.') {
    //   setError(err.message);
    //   setLoading(false);
    //   // Optionally prompt the user again or automatically retry
    //   // await startRecording(); // Uncomment to retry automatically
    // } else {
    //   setError(err.message || 'Failed to start screen capture and recording. Please try again.');
    //   setLoading(false);
    // }
  };

  useEffect(() => {
    startRecording();

    return () => {
      if (chunkIntervalRef.current) {
        clearInterval(chunkIntervalRef.current);
      }
      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state !== "inactive"
      ) {
        mediaRecorderRef.current.stop();
      }
      if (screenStreamRef.current) {
        screenStreamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  const handleStopRecording = async (callback) => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.onstop = async () => {
        const blob = new Blob(recordedChunksRef.current, {
          type: "video/webm",
        });
        setRecordingBlob(blob);
        await sendChunkToAPI(blob);
        if (typeof callback === "function") {
          callback();
        }
      };
      mediaRecorderRef.current.stop();
      setRecording(false);
      if (chunkIntervalRef.current) {
        clearInterval(chunkIntervalRef.current);
      }
    }
  };

  const sendChunkToAPI = async (blob) => {
    const formData = new FormData();
    formData.append("candidate_uid", sessionStorage.getItem("CandidateUid"));
    // formData.append("upload_id", sessionStorage.getItem("upload_id"));
    formData.append("chunk_number", partNumberRef.current);
    formData.append("chunk", blob, "chunk.webm");

    try {
      const response = await handleApiResponse(
        "/api/v1/interview/upload_video_2/",
        formData
      );
      if (response?.code === "token_not_valid") {
        navigate("/");
        sessionStorage.clear();
        return;
      }
      if (response?.status_code === 200) {
        // setLastApiResponse((prevResponses) => [
        //   ...prevResponses,
        //   response?.data,
        // ]);
        partNumberRef.current += 1; // Increment part number after successful API call
      } else {
        console.error("Failed to send chunk to API:", response.statusText);
      }
    } catch (error) {
      console.error("Error sending chunk to API:", error);
    }
  };

  const renderVideo = useMemo(() => {
    if (!videoStream) return null;
    return (
      <video
        className="w-[100%] h-[96vh] object-cover rounded-[16px]"
        autoPlay={true}
        ref={(videoRefElement) => {
          if (videoRefElement) {
            videoRefElement.srcObject = videoStream;
            videoRef.current = videoRefElement;
          }
        }}
      />
    );
  }, [videoStream]);

  useEffect(() => {
    const screenValue = sessionStorage.getItem("Screen");
    if (screenValue !== "false") {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, []);

  if (!isVisible) {
    return null;
  }

  const fname = getLocal("first_name") || getLocal("candFName");
  const lname = getLocal("last_name") || getLocal("candLName");
  const role = getLocal("jobRole") || getLocal("roleName");

  // const handleStopRecording = (callback) => {
  //   if (
  //     mediaRecorderRef.current &&
  //     mediaRecorderRef.current.state !== "inactive"
  //   ) {
  //     mediaRecorderRef.current.onstop = () => {
  //       const blob = new Blob(recordedChunksRef.current, {
  //         type: "video/webm",
  //       });
  //       setRecordingBlob(blob);
  //       if (typeof callback === "function") {
  //         callback();
  //       }
  //     };
  //     mediaRecorderRef.current.stop();
  //     setRecording(false);
  //   }
  // };
console.log(isLogoVisible)
  return (
    <div className="relative h-full ">
      {isLogoVisible && (
        <div className="absolute z-[10000000] h-screen w-screen bg-black opacity-80 flex justify-center items-center backdrop-blur">
          <img
            src={clevercruitlogo}
            alt="Clevercruit Logo"
            className="w-[906px] h-[140px]"
          />
        </div>
      )}
      <div>
        {loading && <Loader />}
        {!isOnline && nettimer > 0 && (
          <div
            style={{
              position: "fixed",
              top: "20%",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 1000, // Higher z-index to ensure it's on top
              backgroundColor: "#101429", // Slightly opaque background
              padding: "10px 20px",
              borderRadius: "8px",
              textAlign: "center",
              color: "white",
            }}
          >
            <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
              Reconnect in {Math.floor(nettimer / 60)}:
              {("0" + (nettimer % 60)).slice(-2)} . Otherwise, the interview
              will be terminated.
            </p>
          </div>
        )}
        {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
        {/* <div>
          {recording && (
            <button className="" onClick={handleStopRecording}>
              Stop Recording
            </button>
          )}
        </div> */}
      </div>
      {browserSupportsSpeechRecognition ? null : (
        <span>Browser doesn't support speech recognition.</span>
      )}
      {showSurePopup ? (
        <Surepopup
          setExit={setExit}
          setShowSurePopup={setShowSurePopup}
          setIsSure={setIsSure}
          handleStopRecording={handleStopRecording}
          endInterview={endInterview}
          setMessages={setMessages}
          socket={socket}
          timer={timer}
          setExitQuestion={setExitQuestion}
          setNext={setNext}
          setSpeech={setSpeech}
          setEndInterviewCounter={setEndInterviewCounter}
        />
      ) : null}
      {showSurePopup ? setIsListening(false) : null}
      {/* <button onClick={handleVideoSubmit}>Submit</button> */}
      <div className="relative">
        {renderVideo}
        <div className="absolute z-8 top-4 left-3 ">
          <TemplateBox1 />
        </div>{" "}
        <div
          className="absolute z-8 font-inter top-4 right-3 h-[3.142vw] text-[1.389vw] justify-center items-center flex text-white pb-4 p-2 pl-[2.778vw] px-4 rounded-2xl"
          style={{
            backgroundImage: `url(${timerBg})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        >
          {formatTime(interviewTime)}
        </div>
        {endTimer1 !== "true" &&
          locallyNext !== "NO" &&
          showTimer &&
          formatTime(timer) && (
            <div className="w-full justify-center items-center flex text-center">
              <div className="z-100 absolute bottom-24 font-digital self-center text-[14vh] flex justify-center items-center text-white p-2 px-4 w-[226px] h-[70px]">
                {formatMinutesSeconds(timer)}
              </div>
            </div>
          )}
      </div>
      <div className="absolute bottom-2 left-4 flex md:gap-4 lg:gap-0  md:flex-col lg:flex-row justify-between items-end fill-available px-4">
        <button
          className={`${socket && !isSpeaking ? null : "pointer-events-none"}`}
        >
          {/* <Mic
            isListening={isListening}
            setIsListening={setIsListening}
            setSpeech={setSpeech}
            isSpeaking={isSpeaking}
          /> */}
          {/* <GoogleCloudSTT
            isListening={isListening}
            setIsListening={setIsListening}
            setSpeech={setSpeech}
            isSpeaking={isSpeaking}
            setIsSpeaking={setIsSpeaking}
          /> */}
          <WhisperSTT
            timer={timer}
            setTimer={setTimer}
            setTimerRunning={setTimerRunning}
            isListening={isListening}
            setIsListening={setIsListening}
            setSpeech={setSpeech}
            isSpeaking={isSpeaking}
            setIsSpeaking={setIsSpeaking}
            openEditor={openEditor}
            setLoader={setLoader}
            loader={loader}
            exitquestion={exitquestion}
            first_name={first_name}
            first_name1={first_name1}
            last_name={last_name}
            last_name1={last_name1}
            face_detect={face_detect}
            audioSource={audioSource}
            questcounter={questcounter}
          />
        </button>

        <TemplateBox text={`Job Interview For ${role} - ${fname} ${lname}`} />

        {/* <button
          className={` p-3 ${
            videoOn ? 'bg-green-200' : 'bg-fill-btn-color-code1'
          } rounded-full`}
          onClick={toggleVideo}
        >
          <img src={cam} alt="vidBtn" />
        </button> */}
        {/* {isStarted ? ( <div className="w-[8rem]"></div>) : null} */}

        {!isStarted ? (
          <button
            onClick={scheduleInterview}
            className="h-[72px] p-[10px] bg-dark-blue rounded-xl text-white  text-text-color-code1"
          >
            <div className=" h-[52px] text-[19.5px] py-3 px-8 bg-[#DD2600] rounded-xl">
              START
            </div>
          </button>
        ) : exit === "NO" ? (
          <button
            onClick={scheduleInterview}
            className="h-[72px] p-[10px] bg-dark-blue rounded-xl text-white  text-text-color-code1"
          // disabled={questcounter !== 16}
          >
            <div className="h-[52px] text-[19.5px] py-3 px-8 bg-[#DD2600] rounded-xl">
              END
            </div>
          </button>
        ) : questcounter === 15 ? (
          <button
            onClick={() => endInterview()}
            className="h-[72px] p-[10px] bg-dark-blue rounded-xl text-white  text-text-color-code1"
          // disabled={questcounter !== 16}
          >
            <div className=" h-[52px] text-[19.5px] py-3 px-8 bg-[#DD2600] rounded-xl">
              END
            </div>
          </button>
        ) : (
          <div className="w-[7.8rem]"></div>
        )}
        {/* <button
            onClick={scheduleInterview}
            className="p-4 px-12 rounded-3xl text-sm bg-fill-btn-color-code1 text-text-color-code1"
            disabled={questcounter !== 19 }
          >
            {isStarted ? "End Interview" : "Start Interview"}
          </button> */}
      </div>
    </div>
  );
};

export default WebcamComponent;

const Surepopup = ({
  setIsSure,
  setShowSurePopup,
  handleVideoSubmit,
  handleStopRecording,
  endInterview,
  setEndInterviewCounter,
  setMessages,
  timer,
  socket,
  setSpeech,
  setNext,
  setExitQuestion,
  setExit,
}) => {
  const formatTimeFromSeconds = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };
  const convertToSeconds = (time) => {
    const [minutes, seconds] = time?.split(":")?.map(Number);
    return minutes * 60 + seconds;
  };

  const calculateRemainingTime = (currentTime) => {
    const startTime = "01:30";
    const startInSeconds = convertToSeconds(startTime);
    const currentInSeconds = convertToSeconds(currentTime);
    const remainingTimeInSeconds = startInSeconds - currentInSeconds;
    return formatTimeFromSeconds(remainingTimeInSeconds);
  };

  const currentTime = formatTime(timer);
  const remainingTime = calculateRemainingTime(currentTime);

  return (
    <section className="absolute top-[50%] left-[50%] justify-center items-center z-10 flex items-end">
      <div className="bottom-[0%] left-[35%] gap-4 bg-[#22284E] rounded-md roup-4 text-white flex flex-col w-[15rem] items-center">
        <div className="m-4 bg-[] justify-center items-center">
          <p className="justify-center flex mb-4">Are You Sure?</p>
          <div className="flex px-2 justify-between gap-5">
            <button
              className="border-2 p-2 w-[5rem]"
              onClick={() => {
                setIsSure(true);
                setShowSurePopup(false);
                setEndInterviewCounter(true);
                endInterview();
                sessionStorage.setItem("ForcefullyEnded", "1");
              }}
            >
              Yes
            </button>
            <button
              className="border-2 p-2 w-[5rem]"
              onClick={() => {
                setSpeech("Yes..");
                setNext("YES");
                setExit("");
                setExitQuestion(false);
                setIsSure(false);
                setShowSurePopup(false);
                sessionStorage.removeItem("endInterview");
              }}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
