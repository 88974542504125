import React from "react";
import { noFaceBg } from "../../assets/imgs";

const NoFaceBackground = ({ noFaceCount, resumeInterview }) => {
  return (
    <div className="z-[9999] bg-[#232638] bg-opacity-85 backdrop-blur-lg absolute top-0 left-0 w-full h-full flex flex-col gap-4 justify-center items-center">
      <article className="p-8 bg-[#00112d] border-[10px] border-[#22284e] rounded-3xl w-[48rem] h-[32rem] flex justify-center items-center flex flex-col gap-4">
        <img src={noFaceBg} alt="no img" className="w-40 h-40" />
        <h5 className="text-[2rem] text-white font-semibold ">
          {noFaceCount}/3
        </h5>
        <h1 className="text-[3rem] text-blue-600 font-bold italic -mt-4">
          No Face Detected!
        </h1>
        <p className="text-white text-center">
          Face the camera for the entire duration of the interview and
          <br />
          avoid looking around or away from the camera/screen
        </p>
        {noFaceCount <= 2 && (
          <button
            className="bg-[#0199fd] p-2 ps-12 pe-12 rounded-2xl text-white"
            onClick={resumeInterview}
          >
            Resume Your Interview
          </button>
        )}
      </article>
    </div>
  );
};

export default NoFaceBackground;
