import {
  downloadIcon,
  shareIcon,
  importIcon,
  addIcon,
  downloadButtonc,
  shareButtonc,
} from '../assets/imgs';
import { FiLogOut } from 'react-icons/fi';
import browseIcon from '../assets/imgs/browseicon1.svg';
import { current } from '@reduxjs/toolkit';

export const Nextbutton = ({ isFinal, campaignType, current }) => {
  const final = campaignType === 2 && current === 1 ? true : isFinal;
  return (
    <button className="font-[700] border-[1px] font-sans1 justify-center items-center flex h-[48px] w-[169px] text-white rounded-[100px] bg-[#222850] ">
      {final ? 'Finish' : 'Next'}
    </button>
  );
};

export const CustomButton = (props) => {
  return (
    <button
      className="h-12 w-[12.5rem] border-2"
      onChange={props?.onChange}
      type={props.type}
      style={{ backgroundColor: 'white', transition: 'background-color 0.3s' }}
      onMouseEnter={(e) => {
        e.target.style.backgroundColor = '#F1F2F4';
      }}
      onMouseLeave={(e) => {
        e.target.style.backgroundColor = '';
      }}
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};

export const CustomButtonWhite = (props) => {
  return (
    <button
      className="h-[40px] w-[178px] font-satoshi rounded-[8px] bg-[#197FF5] font-[700] text-[16px] italic text-white"
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};

export const CustomButtonWhite6 = (props) => {
  return (
    <button
      className="h-[40px] w-[178px] font-satoshi rounded-[8px] bg-[#197FF5] font-[700] text-[16px] italic text-white"
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};

export const CustomButtonWhite5 = (props) => {
  return (
    <button
      className="h-[40px] font-satoshi w-[178px] rounded-[8px] text-[#197FF5] border-[1px] border-[#197FF5] font-[700] text-[16px] italic"
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};

export const CustomButtonFilter = (props) => {
  return (
    <button
      className="font-poppins h-[36px] w-[7.014vw] rounded-[8px] text-white bg-[#0875F4] px-2 text-[0.833vw] mt-2"
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};

export const CustomButtonFilter1 = (props) => {
  return (
    <button
      className="font-poppins h-[36px] w-[7.014vw] rounded-[8px] text-white bg-[#0875F4] px-2 text-[1.1vw]"
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};

export const Finishbutton = ({ isAllCheck }) => {
  return (
    <button
      className={`h-12 w-[12.5rem] bg-fill-btn-color-code1 ${isAllCheck ? 'bg-red-300 text-white' : null
        }`}
    >
      Accept
    </button>
  );
};

export const Backbutton = () => {
  return (
    <button className="font-[700] border-[1px] border-[#222850] text-[#1B3351] rounded-[100px] border-solid font-sans1 h-[48px] w-[169px] bg-transparent">
      Back
    </button>
  );
};

export const AnalyticsPageBtn = ({ img, name, onClick }) => {
  return (
    <button
      className="h-[36px] w-[129px] border-[1px] border-[#0875F4] bg-[#E9F3FF] text-[#0875F4] text-[12px] flex items-center justify-center gap-[0.4rem] rounded-lg"
      onClick={onClick}
    >
      {img === 1 ? (
        <img src={downloadIcon} alt="" />
      ) : img === 4 ? (
        <img src={importIcon} alt="" />
      ) : img === 5 ? (
        <img src={addIcon} alt="" />
      ) : img === 3 ? (
        <FiLogOut />
      ) : (
        <img src={shareIcon} alt="" />
      )}
      <p>{name}</p>
    </button>
  );
};

export const Button = ({ name, onClick }) => {
  return (
    <button
      className="h-12 w-[10.7rem] bg-fill-btn-color-code1 flex items-center justify-center gap-[0.4rem] rounded-lg"
      onClick={onClick}
    >
      {name}
    </button>
  );
};

export const BrowseButton = ({ img, id, name, onClick }) => {
  return (
    <button
      className="w-[118px] h-[26px] bg-[#0075FF] w-[10.7rem] text-white flex items-center justify-center gap-[0.4rem] rounded-lg"
      onClick={onClick}
      id={id}
    >
      {img === 1 ? (
        <img src={browseIcon} alt="upload icon" className='w-[24px] h-[24px]' />
      ) : (
        <img src={shareIcon} alt="" />
      )}
      {name}
    </button>
  );
};

export const NextButton = ({ current, onClick, isAllCheck }) => {
  const isDisabled = current === 2 && !isAllCheck;

  return (
    <button
      className={`w-[100%] text-[18px] justify-end text-white h-[57px] rounded-[16px] bg-[#22284E] ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''
        }`}
      onClick={onClick}
      disabled={isDisabled}
    >
      Next
    </button>
  );
};

export const NextButton1 = ({ current, onClick, isAllCheck }) => {
  const isDisabled = current === 2 && !isAllCheck;

  return (
    <button
      className={`w-[100%] text-[18px] justify-end text-white h-[42px] rounded-lg ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''
        }`}
      onClick={onClick}
      disabled={isDisabled}
      style={{
        background: 'linear-gradient(83.46deg, #00C3FD -13.99%, #0194FE 31.77%, #0284E2 73.27%)',
      }}
    >
      Next
    </button>
  );
};

export const NextButton2 = ({ current, onClick, isAllCheck, name }) => {
  const isDisabled = current === 2 && !isAllCheck;
  const btnName = name ? name : 'Next';

  return (
    <div className=" font-satoshi  p-[0.9px] h-[46px] w-[152px] rounded-[12px] bg-gradient-to-b from-[#01FFFF] via-transparent to-transparent ">
      <button
        className=" w-full text-[19.5px] justify-end text-white h-[46px] rounded-[12px] bg-gradient-to-l from-[#00C3FD] to-[#0194FE]"
        onClick={onClick}
        disabled={isDisabled}
      >
        {btnName}
      </button>
    </div>
  );
};

export const NextButton3 = ({ current, onClick, isAllCheck, name }) => {
  const isDisabled = current === 2 && !isAllCheck;
  const btnName = name ? name : 'Next';

  return (
    <div className=" font-satoshi  p-[0.9px] h-[56px] w-[203px] rounded-[12px] bg-gradient-to-b from-[#01FFFF] via-transparent to-transparent ">
      <button
        className=" w-full text-[19.5px] justify-end text-white h-[56px] rounded-[12px] bg-gradient-to-l from-[#00C3FD] to-[#0194FE]"
        onClick={onClick}
        disabled={isDisabled}
      >
        {btnName}
      </button>
    </div>
  );
};

export const BackButton = ({ onClick }) => {
  return (
    <button
      className="justify-end text-white h-[46px] w-[152px] rounded-[12px] border-[1px] border-[#055298] text-[19.5px] bg-gradient-to-l from-[rgba(0,195,253,0.15)] to-[rgba(1,148,254,0.15)]"
      onClick={onClick}
    >
      Back
    </button>
  );
};

export const BackButton2 = ({ onClick }) => {
  return (
    <button
      className="justify-end text-white h-[56px] w-[203px] rounded-[12px] border-[1px] border-[#055298]  text-[19.5px] bg-gradient-to-l from-[rgb(0,195,253,0.15)] to-[rgb(1,148,254,0.15)]"
      onClick={onClick}
    >
      Back
    </button>
  );
};

export const DownloadButton = ({ onClick, text }) => {
  return (
    <button
      className="justify-end text-white h-[57px] w-[187px] rounded-lg bg-gradient-to-r from-[#00C3FD] text-[20px] via-[#0194FE] to-[#018CF0]"
      onClick={onClick}
    >
      {text}
    </button>
  );
};

// export const ShareButton = ({onClick}) => {
//   return (
//     <button
//       className={`flex gap-2 w-[100%] items-center justify-center text-black h-[56px] rounded-[30px] bg-white text-[18px]`}
//       onClick={onClick}
//     >
//       <img src={shareIcon} alt="share icon"/>
//       Share
//     </button>
//   );
// };

export const DownloadButton1 = ({ onClick }) => {
  return (
    <button
      className={`flex gap-2 w-[100%] min-w-[246px] h-[56px] items-center justify-center text-white h-[57px] rounded-[30px] bg-gradient-to-r from-[#00C3FD] text-[18px] via-[#0194FE] to-[#018CF0]`}
      onClick={onClick}
    >
      <img
        src={shareButtonc}
        alt="download icon"
        className="w-[20px] h-[20px]"
      />
      Download
    </button>
  );
};

export const CustomBlueButton = ({ onClick, text }) => {
  return (
    <button
      className={`flex gap-2 w-[100%] items-center justify-center text-white h-[57px] rounded-[30px] bg-gradient-to-r from-[#00C3FD] via-[#0194FE] to-[#018CF0]`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export const CustomWhiteButton = ({ onClick, text }) => {
  return (
    <button
      className={`border-2 border-[#FFFFFF] flex gap-2 w-[100%] items-center justify-center text-white h-[57px] rounded-[30px]`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export const CampaignButton = ({ number, text }) => {
  return (
    <div
      className="inline-flex rounded-full items-center bg-[#D9D9D9] text-white px-4 py-2 font-semibold"
      style={{
        boxShadow: '0px 2.34px 7.01px 0px #00000040',
      }}
    >
      <div
        className="inline-flex rounded-full items-center bg-gradient-to-r from-[#0875F4] to-[rgba(8, 117, 244, 0.83)] text-white rounded-full px-4 py-2 font-semibold"
        style={{
          boxShadow:
            '2.34px 2.34px 4.67px 0px #00000014, 0px 0px 7.01px 0px #00000005',
        }}
      >
        <div className="bg-[#FFFFFF] fill-available flex rounded-full">
          <div className="bg-white text-blue-500 rounded-full w-6 h-6 flex items-center justify-center mr-2">
            <span className="text-sm">{number}</span>
          </div>
          <span className="text-[#19213D]">{text}</span>
        </div>
      </div>
    </div>
  );
};

export const CustomBtn = (props) => {
  return (
    <div
      className="min-h-[30px] min-w-[39px] flex justify-center items-center cursor-pointer"
      style={{
        backgroundColor: `${props.color}`,
        borderRadius: '6px'
      }}
      onClick={props.onClick}
    >
      <img src={props.img} alt="icon" />
    </div>
  );
};


export const CustomBtnBig = (props) => {
  return (
    <div
      className="min-h-[35px] min-w-[85px] flex justify-center items-center"
      style={{
        backgroundColor: `${props.color}`,
        borderRadius: '6px'
      }}
      onClick={props.onClick}
    >
      <img className='w-[25px] h-[25px]' src={props.img} alt="icon" />
    </div>
  );
};


export const CustomMemberBtn = (props) => {
  return (
    <div
      className="min-h-[35px] min-w-[85px] flex justify-center items-center"
      style={{
        backgroundColor: `${props.color}`,
        borderRadius: '6px'
      }}
      onClick={props.onClick}
    >
      <img className='w-[25px] h-[25px]' src={props.img} alt="icon" />
    </div>
  );
};
