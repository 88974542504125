import React, { useEffect, useState, useRef } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { handleApiResponse } from '../../API/services';
import { useDispatch } from 'react-redux';
import { userDetailsSuccess } from '../../redux/userDetails/actions';
import { setLocal } from '../../utils/localStorage';
import { LinkedinShareButton } from 'react-share';
import { bulkCandidateDetailsSuccess } from '../../redux/bulkCandidateUserDetails/actions';
import { MediaDevices } from '../mediaDevices';
import {
  BackButton,
  CustomButtonWhite,
  DownloadButton,
  DownloadButton1,
} from '../../components/buttons';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useSelector } from 'react-redux';
import Certificatepage from '../../components/AdminComponents/certificate';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import MicConfig from '../../utils/MicConfig';
import {
  accessVideo,
  robot,
  clevercruitlogo,
  shareIcon,
  suspiciouspic,
  internetpic,
  linkexpiredbg,
  clevercruit,
  mic,
  latestRobot,
  shareButtonc,
  downloadButtonc,
  sus,
  expired,
  startupBg,
  arrowleft,
  backArrow,
  voiceBg,
  doneTick,
} from '../../assets/imgs';
import {
  FinalPageCard1,
  StartPageCard1,
  StartPageCard2,
  FinalPageCard2,
} from '../../components/card';
import { ShareButton } from '../../components/buttons';
import { AudioLevels } from '../AudioLevels';
import AudioRecorderWithWaveform from '../../utils/AudioRecorderWithWaveform';

export const LandingPage = () => {
  const { intLink } = useParams();
  const [userData, setUserData] = useState(false);
  const [allowed, setAllowed] = useState(true);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const [resumeAllowed, setResumeAllowed] = useState('');
  const dispatch = useDispatch();
  // const campType=sessionStorage.getItem('campType');
  const [campType, setCampType] = useState('');
  const navigate = useNavigate();
  sessionStorage.removeItem('openEditor');
  sessionStorage.removeItem('curr');
  sessionStorage.removeItem('codingQuestion');
  sessionStorage.removeItem('code');
  console.log(allowed, 'allowed');
  console.log(intLink);
  console.log(campType);
  const [isZoomed, setIsZoomed] = useState(false);
  const [isOp, setIsOp] = useState(false);
  const [isBackdrop, setIsBackdrop] = useState(false);
  const [isWhite, setIsWhite] = useState(false);
  if (!allowed) {
    sessionStorage.setItem('allowed', 'false');
  }
  const toggleZoom = () => {
    setTimeout(() => {
      setIsBackdrop(true);
    }, 800);

    setTimeout(() => {
      setIsOp(true);
      setIsWhite(true);
    }, 2300);

    setTimeout(() => {
      navigate('details', {
        state: { resume: resumeAllowed },
      });
    }, 5500);

    setIsZoomed(true);
  };

  useEffect(() => {
    (async () => {
      let data = { interview_link_token: intLink };
      sessionStorage.clear();
      const response1 = await handleApiResponse(
        '/api/v1/interview/general_link_view/',
        data,
        0
      );
      sessionStorage.removeItem('upload_id');
      console.log(response1?.data?.campaign_type);
      if (response1?.status_code === 200) {
        console.log(response1?.data?.campign_info?.campaign_type);
        if (response1?.data?.campign_info?.campaign_type === 1) {
          setCampType('Regular');
        } else if (response1?.data?.campaign_type === 2) {
          setCampType('Mass');
        }
      } else if (response1?.status_code === 400) {
        setAllowed(false);
        setMessage(response1?.msg);
      }
      if (campType === 'Regular') {
        let data = { interview_link_token: intLink };
        const response = await handleApiResponse(
          '/api/v1/interview/unique_link_view/',
          data,
          0
        );
        if (response?.code === 'token_not_valid') {
          navigate('/');
          sessionStorage.clear();
          return;
        }
        if (response?.status_code === 200) {
          dispatch(bulkCandidateDetailsSuccess(response.data));
          console.log(response.data);
          sessionStorage.setItem('campType', campType);
          sessionStorage.setItem('candFName', response?.data?.first_name);
          sessionStorage.setItem('user', response?.data?.uid);
          setLocal('organization', response?.data?.company);
          sessionStorage.setItem('candLName', response?.data?.last_name);
          sessionStorage.setItem('candMobile', response?.data?.mobile);
          sessionStorage.setItem('candEmail', response?.data?.email);
          sessionStorage.setItem(
            'jobRole',
            response?.data?.campign_info?.jr_name
          );
          // dispatch(setLinkType(campType))
          sessionStorage.setItem('CandidateUid', response.data.uid);
          sessionStorage.setItem('link', response.data.linkinfo.link);
          setResumeAllowed(
            response.data.campign_info.is_candidate_resume_allow
          );
          setUserData(response?.data);
          // setLocal('Cid',response?.data.uid)
          // setLocal('Oid',response?.data.organization)
          dispatch(userDetailsSuccess(response?.data));
          // setDontMoveForward(true);
        } else if (response?.status_code === 400) {
          setMessage(response.msg);
          setAllowed(false);
        } else {
          setError(response?.msg);
        }
      }
      if (campType === 'Mass') {
        let data = { interview_link_token: intLink };
        const response = await handleApiResponse(
          '/api/v1/interview/bulk_link_view/',
          data,
          0
        );
        if (response?.code === 'token_not_valid') {
          navigate('/');
          sessionStorage.clear();
          return;
        }
        console.log('response', response?.data?.is_candidate_resume_allow);
        if (response?.status_code === 200) {
          sessionStorage.setItem('campType', campType);
          setUserData(response?.data);
          setResumeAllowed(response?.data?.is_candidate_resume_allow);
          // setDontMoveForward(true);
          setLocal('');
          setLocal('Oid', response?.data.organization);
          setLocal('Cid', response?.data.uid);
          setLocal('roleName', response?.data.jr_name);
          setLocal('roleName', response?.data.jr_name);
          setLocal('organization', response?.data.company);
          dispatch(userDetailsSuccess(response?.data));
        } else if (response?.status_code === 400) {
          setMessage(response.msg);
          setAllowed(false);
        } else {
          setError(response?.msg);
        }
      }
    })();
  }, [campType]);
  const sendToDetails = () => {
    toggleZoom();
    navigate('details', {
      state: { resume: resumeAllowed },
    });
  };
  console.log(userData.first_name);
  console.log(allowed);
  if (allowed) {
    return (
      <main className=" relative font-satoshi w-fit custom-media px-4 flex flex-col text-white  custom-sm:justify-start custom-md:justify-around custom-lg:justify-center  items-center h-available pt-[2vh]">
        <section className="flex flex-col items-center text-center">
          <p className="text-[2rem] font-bold">
            {userData?.first_name ? (
              <span>
                Welcome to CleverCruit,
                <span className="ml-1">{userData?.first_name}!</span>
              </span>
            ) : (
              <span>Welcome to CleverCruit!</span>
            )}
          </p>
          <div className=" font-satoshi text-text-colour-code1 text-[1.125rem] flex flex-col items-center mt-[0.375rem]">
            <p>
              Embark on a cutting-edge interview experience powered by AI.
              Showcase your skills, embrace the challenge, and let your true
              potential shine.
            </p>
            <p>Your journey to your dream job starts here!</p>
            {/* <div className='relative'>
              <img src={mic} className='bg-white' />
               <div className='rotate-180 flex absolute bottom-[48px] left-[16px] w-[2.4rem] h-[4.6rem] rounded-lg overflow-hidden' style={{ borderRadius: '1.75rem', flexDirection: 'row' }}>
               {
          // Map through the array to create columns
          circlesPerColumn.map((circles, columnIndex) => (
            <div key={columnIndex} style={{
              flexDirection: 'column',
              alignItems: 'end',
              justifyContent: 'end',
            }}>
              {
                // Generate circles based on the value from the array
                Array.from({ length: circles }).map((_, rowIndex) => (
                  <div key={rowIndex} style={{
                    width: '0.4rem',       // Circle width
                    height: '0.4rem',      // Circle height
                    backgroundColor: '#4AF867',
                    borderRadius: '2.75rem',   // Makes the div a circle          
                    boxSizing: 'border-box',
                  }}></div>
                ))
              }
            </div>
          ))
        }
      </div>
            </div> */}
          </div>
        </section>
        <section>
          {isZoomed && (
            <div
              className={`w-[100vw] h-[100vh] absolute top-[-1.8rem] left-[-2rem] transition-transform delay-[3000ms] ${
                isWhite ? 'bg-white' : ''
              } ${isBackdrop ? 'backdrop-blur' : ''}  `}
            ></div>
          )}

          <div
            className={`transition-transform duration-[7000ms] ease-in-out overflow-hidden rounded-lg ${
              isZoomed ? 'scale-[7.5] rotate-[40deg]' : 'scale-100'
            }`}
          >
            <div
              className={`transition-opacity duration-500 ease-in-out ${
                isOp ? 'opacity-40' : 'opacity-100'
              }`}
            >
              <img
                src={latestRobot}
                alt="Robot"
                className="custom-md:w-[34rem] h-[50vh] custom-sm:w-[50vh] custom-sm:h-[50vh] object-cover"
              />
            </div>
          </div>
        </section>
        <section className="  w-[784px]">
          <button
            className="w-full custom-sm:mb-[3rem] custom-md:mb-[0rem]  h-[41.92px]  bg-gradient-to-l from-[#0284E2] via-[#0194FE] to-[#00C3FD] rounded-[12.9px] text-[19.5px] font-bold border-transparent"
            onClick={sendToDetails}
          >
            <p>Begin Your AI Interview</p>
          </button>
        </section>
      </main>
    );
  } else {
    window.location.reload(); //this reload will render the expire link component layout mentioned in user route page.
  }
};

export const StartPage = () => {
  const location = useLocation();
  const [showTooltip, setShowTooltip] = useState(false);
  const circlesPerColumn = [20, 20, 4, 5, 6, 7];
  const navigate = useNavigate();
  const [candidateAudio, setCandidateAudio] = useState(null);
  const [noAudio, setNoAuido] = useState(null);
  console.log(noAudio, 'noAudio');
  let item = location?.state?.isCoding_quest_allow;
  const [isOk, setIsOK] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const [micConfigOn, setMicConfigOn] = useState(false);
  const [content, setContent] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const interview_link_token = useSelector((state) => {
    if (state?.candidateData?.user?.linkinfo?.link) {
      return state.candidateData.user.linkinfo.link;
    } else {
      return '';
    }
  });
  const { intLink } = useParams();
  console.log(intLink);
  const [uploadId, setUploadId] = useState('');

  sessionStorage.removeItem('fillForm');

  console.log(uploadId);
  window.speechSynthesis.cancel();

  useEffect(() => {
    // Show the tooltip when the component mounts
    setShowTooltip(true);

    // Hide the tooltip after 2 minutes
    const timer = setTimeout(() => {
      setShowTooltip(false);
    }, 30000); // 2 minutes in milliseconds

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     let data = { candidate_uid: sessionStorage.getItem('CandidateUid') };
  //     const response1 = await handleApiResponse(
  //       '/api/v1/interview/upload_video/',
  //       data,
  //       0
  //     );
  //     console.log(response1?.data?.upload_id);
  //     setLocal('upload_id', response1?.data?.upload_id);
  //   })();
  // }, []);

  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };
  console.log(interview_link_token);
  const updateProfilePhoto = async () => {
    console.log('updating profile');
    try {
      const formData = new FormData();
      formData.append('uid', sessionStorage.getItem('CandidateUid'));
      const base64Image = imgSrc.split(',')[1];
      const mimeType = 'image/jpeg';
      const blob = base64ToBlob(base64Image, mimeType);

      formData.append('photo', blob, 'profile.jpg');
      // if (sessionStorage.getItem('campType') === 'Regular') {
      //   formData.append('interview_link_token', interview_link_token);
      // }

      // if (sessionStorage.getItem('campType') === 'Mass') {
      formData.append('interview_link_token', intLink);
      // }
      const response = await handleApiResponse(
        '/api/v1/interview/add_bulk_candidate/',
        formData,
        0
      );
      if (response?.status_code === 200) {
        console.log('profile photo added.');
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (imgSrc) {
      updateProfilePhoto(imgSrc);
    }
  }, [imgSrc]);

  // const openFullscreen = () => {
  //   const elem = document.documentElement;

  //   if (elem.requestFullscreen) {
  //     elem.requestFullscreen();
  //   } else if (elem.webkitRequestFullscreen) {
  //     /* Safari */
  //     elem.webkitRequestFullscreen();
  //   } else if (elem.msRequestFullscreen) {
  //     /* IE11 */
  //     elem.msRequestFullscreen();
  //   }
  // };

  const handleRequestPermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      console.log('All access granted!');
    } catch (error) {
      console.error('Error requesting microphone permission:', error);
    }
  };

  useEffect(() => {
    handleRequestPermission();
    if (sessionStorage.getItem('isStarted')) {
      console.log('this is isStarted', sessionStorage.getItem('isStarted'));
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('firstLoadDone');
    } else {
      sessionStorage.setItem('firstLoadDone', 1);
    }
  }, []);

  const handleTooltipHover = () => {
    // Show the tooltip on hover after the initial 2 minutes
    if (!showTooltip) {
      setShowTooltip(true);
    }
  };

  const change = () => {};

  const [isTransitionVisible, setIsTransitionVisible] = useState(false);

  const handleButtonClick = () => {
    setIsTransitionVisible(!isTransitionVisible); // Toggle transition on button click
  };

  return (
    <main
      className="flex flex-col justify-start custom-lg:justify-center text-white items-center w-[85vw] "
      style={{
        height: `${isFinished ? 'calc( 98vh - 8rem)' : 'calc( 100vh - 8rem)'}`,
      }}
    >
      {/* <div className="items-end flex">
        <FaInfoCircle
          data-tooltip-id="skillsInfo"
          className="ml-2 cursor-pointer text-gray-500"
        />
        <Tooltip id="skillsInfo" arrow={false} place="top" effect="solid" isOpen={showTooltip} onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
          1. Allow your camera and microphone permission to proceed with the interview.<br/>
          2. Click on camera icon to click a photograph and save. Click on Start your interview <br/>
          3. Give the permission of entire screen sharing to proceed with the interview. <br/>
          4. You will be given 2 minutes for each question and to submit your response early you <br />can turn off the mike after completing the answer and it will automatically send your <br />response.<br />
          5. Your response will be automatically submitted after two minutes.<br />
          6. For coding question you will be given 30 minutes. Click on Submit button after<br/> completing the interview.<br />
          7. Then you can stop sharing your screen and click on End Interview after completing<br/> the interview.
        </Tooltip>
        </div> */}
      <section className="font-satoshi flex flex-col h-sm:min-h-[220px]  h-sm2:min-h-[86%] custom-sm:items-start min-h-[153px] custom-sm:overflow-scroll custom-md:visible custom-md:items-center pt-[1rem] gap-4 justify-around h-full w-full">
        {/* <div className="items-end flex">
        <FaInfoCircle
          data-tooltip-id="skillsInfo"
          className="ml-2 cursor-pointer text-gray-500"
        />
        <Tooltip id="skillsInfo" place="top" effect="solid">
          ***************** Content ********************
        </Tooltip>
        </div> */}

        <section className="flex relative  items-center  w-full h-[95%] bg-[#F8F9FF] rounded-[26.67px] min-w-[875px] min-h-[450px] max-w-[90rem] max-h-[41rem]">
          <div
            className="flex  items-center  w-full h-full rounded-[26.67px]"
            style={{
              backgroundImage: `url(${startupBg})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            <div
              id="transition"
              className={`absolute flex justify-start items-end  h-full w-full top-0 z-[100] rounded-[26.67px] backdrop-blur ${
                isTransitionVisible ? 'translate-x-0' : 'translate-x-[-550%] '
              } transition-transform duration-1000 ease-in-out`}
            >
              <div
                className="w-[62%] min-w-fit px-[12px] py-[14px]  flex gap-4 rounded-[26px] justify-around ml-[19px] mb-[29px]"
                style={{
                  backgroundImage: `url(${voiceBg})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                }}
              >
                <div className="flex flex-col">
                  <p className="font-bold font-satoshi text-[#00B7FD] text-[26px]">
                    Your Voice Matters To Us
                  </p>
                  <p className="text-[#00112D] text-[manrope] font-medium text-[20px]">
                    Speak Few Words For Mic Testing
                  </p>
                  {isFinished && (
                    <p className="flex justify-center gap-2 text-[#00112D] font-[900] text-[manrope] text-[21.6px]">
                      <img src={doneTick} alt="" /> Done
                    </p>
                  )}
                </div>
                <div>
                  <AudioRecorderWithWaveform
                    startRec={isTransitionVisible}
                    setIsTransitionVisible={setIsTransitionVisible}
                    setIsFinished={setIsFinished}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center w-[65%] ">
              <p className=" font-satoshi font-medium text-[#00B7FD] text-[36px]">
                Lights, Camera, Interview!
              </p>

              <div className="font-manrope font-medium text-[#00112D] text-[24px] flex  flex-col fill-available items-center text-center">
                <p>Get ready for your close-up chat with AI.</p>

                <p className="text-[16px]">
                  Let's make sure you're looking and sounding your best!{' '}
                </p>
              </div>
            </div>

            <div
              className="absolute bottom-[10%] left-[2%] "
              onClick={() => {
                sessionStorage.setItem('fillForm', 2);
                navigate(-1);
              }}
            >
              <span className=" flex gap-[10px] text-black font-satoshi font-medium text-[24.7px]">
                <img src={backArrow} alt="" />
                Back
              </span>
            </div>

            <div
              className={`flex  gap-12 justify-end items-center h-full`}
              style={{
                width: `calc( 35% - 34px)`,
              }}
            >
              {imgSrc ? (
                <FinalPageCard1
                  img={imgSrc}
                  setContent={setContent}
                  setImage={setImgSrc}
                />
              ) : (
                <StartPageCard1
                  img={accessVideo}
                  handleButtonClick={handleButtonClick}
                  setImage={setImgSrc}
                  imgSrc={imgSrc}
                  base64ToBlob={base64ToBlob}
                  text2={'Smile For Your Headshot'}
                  setContent={setContent}
                  setCandidateAudio={setCandidateAudio}
                  candidateAudio={candidateAudio}
                />
              )}
            </div>
          </div>
        </section>

        <span
          className={`${
            showWarning && !imgSrc && isOk ? 'block text-red-500' : 'hidden'
          }`}
        >
          Click a photo before procedding
        </span>
        <span
          className={`${
            showWarning && !imgSrc && !isOk ? 'block text-red-500' : 'hidden'
          }`}
        >
          Please give permissions to proceed.
        </span>

        {/* <MicConfig /> */}
      </section>
      <div className="flex flex-col gap-[6px] fill-available max-w-[74.5remrem] my-4">
        <section
          className={`fill-available bg-[#00FFFF] rounded-[12px]  custom-sm:mb-[3rem] custom-md:mb-[0rem] ${
            isFinished ? null : 'hidden'
          }`}
        >
          <NavLink
            to={`${
              sessionStorage.getItem('isStarted')
                ? '../certificate'
                : imgSrc && isFinished
                ? '../interview'
                : '.'
            }`}
            state={{
              isCoding_quest_allow: item,
              upload_id: uploadId,
              imgSrc: imgSrc,
            }}
          >
            <button
              className="w-full mt-[1px]  h-[41.92px]  bg-gradient-to-l from-[#0284E2] via-[#0194FE] to-[#00C3FD] rounded-[12px] text-[19.5px] font-medium border-transparent font-satoshi"
              onClick={() => {
                if (!imgSrc) {
                  setShowWarning(true);
                }
              }}
            >
              Start Your Interview
            </button>
            {/* <button onClick={openFullscreen}>Go Fullscreen</button> */}
          </NavLink>
        </section>
      </div>
    </main>
  );
};
export const Certificate = () => {
  const certificateRef = useRef();
  const shareUrl = 'https://example.com';
  // const urlCertificate = `http://localhost:3000${certificate}`;
  // const urlCertificate = 'https://drive.google.com/file/d/1Y2IHJdOn3pM_ypN0XwkfSNnjG5dSaxK3/view?usp=sharing';
  const urlCertificate = 'https://doczoid.com/media/profile/car_vykcS4V.jpg';
  console.log(urlCertificate);
  const warningCount = sessionStorage.getItem('wc');
  const on_face_detect = sessionStorage.getItem('no_face_detect_count');
  const networkError = sessionStorage.getItem('networkError');
  const ended = sessionStorage.getItem('ForcefullyEnded')
    ? sessionStorage.getItem('ForcefullyEnded')
    : false;
  const coveringCameraTimer = sessionStorage.getItem('coverCamera');

  useEffect(() => {
    // Add a new state to the history stack
    sessionStorage.removeItem('CandidateUid');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('isStarted');
    sessionStorage.removeItem('openEditor');
    sessionStorage.removeItem('curr');
    sessionStorage.removeItem('codingQuestion');
    sessionStorage.removeItem('code');

    window.history.pushState(null, null, window.location.href);

    const preventBack = () => {
      window.history.pushState(null, null, window.location.href);
    };

    // Listen for popstate event (which is fired on back/forward navigation)
    window.addEventListener('popstate', preventBack);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('popstate', preventBack);
    };
  }, []);

  const downloadPDF = () => {
    console.log('ddddddddddddddddddddddddd');
    const certificateElement = certificateRef.current;
    if (!certificateElement) {
      return;
    }

    html2canvas(certificateElement, { scale: 5 })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'landscape',
          unit: 'px',
          format: [canvas.width, canvas.height],
        });
        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
        pdf.save('certificate.pdf');
      })
      .catch((error) => {
        console.error('Error generating PDF', error);
      });
  };

  return (
    <>
      <div className="bg-[#EEEFF3] h-[100vh] w-[100vw]">
        <div className="p-8">
          <div className="flex items-center font-light text-[1.528vw] font-satoshi">
            <img
              src={clevercruitlogo}
              alt="clevercruit logo"
              className="w-[11vw]"
            />
            <span className="ml-2 text-[1.5vw]">
              Ai video interview -{' '}
              <span className="font-medium">
                {new Date().toLocaleDateString('en-GB')}
              </span>
            </span>
          </div>
        </div>
        {warningCount >= '5' ? (
        <div className="relative h-[85%] mx-8 p-8 bg-[#00112D] border-2 text-center border-[#00386133] rounded-[2vw] items-center flex flex-col overflow-auto h-md1:justify-center justify-start">
   <img src={sus} className="w-[230px] h-[230px]" />
            <div className="mb-4 w-[60%] font-satoshi flex justify-center items-center font-bold text-[3.819vw] text-[#0197FE]">
              <i>Suspicious activities detected!</i>
            </div>
            <div className="text-[#EEEFF3] font-satoshi font-[700] italic text-[22px] mb-4">
              We're sorry, but your interview has ended due to multiple
              instances of suspicious activity.
            </div>
            <div className="font-satoshi font-[400] text-[18px] text-[#EEEFF3] mb-4 leading-[24.3px] max-w-[80%]">
              Our AI system detected certain behavior that may compromise the
              integrity of the interview process. This could include, but is not
              limited to presence of other individuals in the room, attempt to
              access other applications or websites during the interview, etc.{' '}
            </div>
            <div className="font-satoshi font-[400] text-[18px] text-[#EEEFF3] leading-[24.3px] max-w-[80%]">
              We appreciate your understanding. Clevercruit is committed to
              maintaining a fair and secure interview process for all
              candidates. In order to generate a new link / give a fresh
              attempt, please get in touch with the HR team of the company
              you’re interviewing for
            </div>
          </div>
        ) : // <div className="w-[60%] self-center flex items-center justify-center text-2xl text-center transform bg-red-500 text-white p-4 rounded shadow-lg z-50">
        //   Suspicious Activity Encountered! The interview has ended due to multiple warnings. For further
        //   questions, please connect with your HR.
        // </div>
        on_face_detect >= '3' ? (
          <div className="relative h-[85%] mx-8 p-8 bg-[#00112D] border-2 text-center border-[#00386133] rounded-[2vw] items-center flex flex-col overflow-auto h-md1:justify-center justify-start">
      <img src={sus} className="w-[230px] h-[230px]" />
            <div className="mb-4 w-[60%] font-satoshi flex justify-center items-center font-bold text-[3.819vw] text-[#0197FE]">
              <i>Suspicious activities detected!</i>
            </div>
            <div className="text-[#EEEFF3] font-satoshi font-[700] italic text-[22px] mb-4">
              We're sorry, but your interview has ended due to multiple
              instances of suspicious activity.
            </div>
            <div className="font-satoshi font-[400] text-[18px] text-[#EEEFF3] mb-4 leading-[24.3px] max-w-[80%]">
              Our AI system detected certain behavior that may compromise the
              integrity of the interview process. This could include, but is not
              limited to presence of other individuals in the room, attempt to
              access other applications or websites during the interview, etc.{' '}
            </div>
            <div className="font-satoshi font-[400] text-[18px] text-[#EEEFF3] leading-[24.3px] max-w-[80%]">
              We appreciate your understanding. Clevercruit is committed to
              maintaining a fair and secure interview process for all
              candidates. In order to generate a new link / give a fresh
              attempt, please get in touch with the HR team of the company
              you’re interviewing for
            </div>
          </div>
        ) : networkError === 'true' ? (
          <div className="relative h-[85%] mx-8 p-8 bg-[#00112D] text-center border-2 border-[#00386133] rounded-[2vw] items-center justify-center flex flex-col mb-[2rem]">
            <img
              src={internetpic}
              alt="network pic"
              className="w-[200px] h-[200px]"
            />
            <div className=" font-satoshi font-bold text-[3.819vw] text-[#0197FE]">
              <i>No Internet Connection Detected</i>
            </div>
            <div className="text-white font-satoshi font-[500] text-[22px]">
              Your interview has ended due to internet connectivity issues.
              Don't panic, it happens to the best of us!
            </div>
            <div className="max-w-[85%] text-white font-satoshi text-[18px] font-normal leading-[24.3px] mt-2">
              We appreciate your understanding. Clevercruit is committed to
              maintaining a fair and secure interview process for all
              candidates. In order to generate a new link / give a fresh
              attempt, please get in touch with the HR team of the company
              you’re interviewing for
            </div>
          </div>
        ) : ended === '1' ? (
          <div className="relative h-[85%] mx-8 p-8 bg-[#00112D] border-2 text-center border-[#00386133] rounded-[2vw] items-center flex flex-col overflow-auto h-md1:justify-center justify-start">
    <div className="w-[60%] font-satoshi font-bold text-[3.819vw] text-[#0197FE]">
              <i>Your Interview Has Ended As Per Your Request.</i>
            </div>
            <div className="text-white text-[1.528vw] font-bold italic w-[86%]">
              If You Have Any Further Questions, Please Connect With Your HR.
              Thank You For Your Time And Interest.
            </div>
            <div className="absolute text-white bottom-8 font-raleway">
              Thank you for giving an interview with Clevercruit
            </div>
          </div>
        ) : (
          <div className="mx-8 p-4 bg-[#00112D] border-2 border-[#00386133] rounded-[2vw] min-w-[700px] h-[82vh] overflow-auto">
            <div className="font-satoshi flex flex-col items-center">
              <i className="font-bold text-[64px] text-[#0197FE] mt-[2vh]">
                Woohoooo ! You Did It!!
              </i>
              <p className="text-white text-[22px] font-[500] mb-4">
                You rocked an AI interview like a superstar!
              </p>
            </div>
            <div className="flex justify-center items-center mb-4">
              <p className="font-satoshi text-[18px] leading-[24.3px] max-w-[60%] font-[400] text-[#EEEFF3]">
                Congratulations on being interviewed by the world’s most
                advanced AI Interviewer. This brings you one step closer to your
                dream job! Here’s your interview completion certificate for you
                to flaunt-
              </p>
            </div>

            {/* <CustomButtonWhite text="Download" onClick={downloadPDF} />
            <LinkedinShareButton
              url={urlCertificate}
              className="h-12 w-[12.5rem] border-2 text-[#A6AEBA]"
              style={{
                color: "#A6AEBA",
                border: "2px solid #A6AEBA !important",
                backgroundColor: "white",
                transition: "background-color 0.3s",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#F1F2F4";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "";
              }}
            >
              Share on LinkedIn
            </LinkedinShareButton> */}

            <main className="flex flex-col items-center justify-center items-center ">
              {/* <img className=" h-[43rem]" src={certificate} alt="certificate" /> */}
              <div ref={certificateRef}>
                <Certificatepage />
              </div>
              <div className="w-[495px]">
                <div className="flex gap-2 mb-2">
                  <div className="custom-sm:w-fit flex h-[56px]">
                    <LinkedinShareButton
                      url={urlCertificate}
                      // className="h-12 w-[12.5rem] border-2 text-[#A6AEBA]"
                      className={`flex gap-2 w-[100%] min-w-[246px] items-center justify-center text-white h-[57px] rounded-[30px] bg-white text-[18px] via-[#0194FE] to-[#018CF0]`}
                      style={{
                        color: 'black',
                        backgroundColor: 'white',
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#F1F2F4';
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = '';
                      }}
                    >
                      <img
                        src={downloadButtonc}
                        className="w-[16px] h-[16px]"
                        alt="share icon"
                      />
                      Share
                    </LinkedinShareButton>
                    {/* <ShareButton /> */}
                  </div>
                  <div className="w-[20.069vw] flex">
                    <DownloadButton1 onClick={downloadPDF} />
                  </div>
                </div>
              </div>
            </main>
          </div>
        )}
        {/* <div className="flex justify-end mr-[5rem] gap-4">
        <CustomButtonWhite text="Download" onClick={downloadPDF} />
        <LinkedinShareButton
          url={urlCertificate}
          className="h-12 w-[12.5rem] border-2 text-[#A6AEBA]"
          style={{
            color: '#A6AEBA',
            border: '2px solid #A6AEBA !important',
            backgroundColor: 'white',
            transition: 'background-color 0.3s',
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = '#F1F2F4';
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = '';
          }}
        >
          Share on LinkedIn
        </LinkedinShareButton>
      </div> */}
        {/* <main className=" w-[69rem] flex flex-col items-center justify-around "> */}
        {/* <img className=" h-[43rem]" src={certificate} alt="certificate" /> */}
        {/* <div ref={certificateRef}>
          <Certificatepage />
        </div>
      </main> */}
      </div>
    </>
  );
};
