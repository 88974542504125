import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { certificatebg, logo, signature } from "../../assets/imgs";
import { useLocation } from "react-router-dom";

const Certificatepage = () => {
  const location = useLocation();
  const firstName = sessionStorage.getItem("candFName");
  const jobRole = sessionStorage.getItem("roleName");
  const lastName = sessionStorage.getItem("candLName");
  const warningCount = sessionStorage.getItem("wc");
  const on_face_detect = sessionStorage.getItem("no_face_detect_count");
  const networkError = sessionStorage.getItem("networkError");

  const first_name = useSelector(
    (state) => state?.certificateData?.data?.first_name
  );
  const last_name = useSelector(
    (state) => state?.certificateData?.data?.last_name
  );
  const job_role = sessionStorage.getItem("jobRole");

  const date = useSelector((state) => state?.certificateData.data.created_date);

  const [formattedDateTime, setFormattedDateTime] = useState("");

  useEffect(() => {
    sessionStorage.removeItem("newExp");
    sessionStorage.removeItem("newExpMonths");
    sessionStorage.removeItem("file");
  });
  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = `${currentDate
      .getDate()
      .toString()
      .padStart(2, "0")}/${(currentDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${currentDate.getFullYear()}`;
    const formattedTime = `${currentDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${currentDate
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
    setFormattedDateTime(`${formattedDate} - ${formattedTime} IST`);
  }, []);

  return (

    <div
      className="h-[23rem] relative mb-4"
    >
      <img src={certificatebg} className='h-[23rem]' />
      <div className="absolute top-[2rem] left-16 gap-[4px] flex flex-col">
        <div className="text-[#0875F4] text-[26px] text-bold font-robotoSlab max-w-[93%]">Certificate of Interview Completion</div>
        <div className="font-light text-[#414752] text-[12px] font-robotoSlab">
          <div className="font-light text-[#414752] text-[12px] font-robotoSlab">
            {new Date().toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: '2-digit', // This ensures the day is always formatted with two digits
            })}
          </div>
        </div>
        <div className="font-medium text-[15px] text-[#00112D] font-robotoSlab">
          {first_name ? first_name : firstName}{" "}
          {last_name ? last_name : lastName}
        </div>
        <div className="text-[#414752] text-[7px] font-robotoSlab font-[300]">has successfully completed the interview for job profile</div>
        <div className="font-medium text-[15px] text-[#00112D] font-robotoSlab max-w-[65%]">
          {job_role ? job_role : jobRole}
          {/* interview for {job_role ? job_role : jobRole} */}
        </div>
        <div className="text-[#414752] text-[7px] font-robotoSlab font-[300]">We appreciate your participation!</div>
        <div className="text-[#414752] text-[7px] font-robotoSlab font-[300] max-w-[55%]">
          <div className="mb-2">Thank you for taking the time to complete your interview with our platform. We hope this experience has been enriching and insightful, and that it contributes to your growth. </div>
          <div>Best wishes for your continued journey towards success!</div>
        </div>
      </div>
      {/* <div className="absolute bottom-10 font-robotoSlab left-[8%]">
        <div className="font-medium text-[7px] text-[#414752]">Reza Jalaeian</div>
        <div className="font-light text-[7px]">Training Department</div>
      </div>
      <div className="absolute bottom-10 font-robotoSlab left-[30%]">
        <div className="font-medium text-[7px] text-[#414752]">Mohsen Ghalenavi</div>
        <div className="font-light text-[7px]">Head of the Clevercruit</div>
      </div> */}
      <div className="absolute font-[300]  bottom-[39px] font-robotoSlab right-[6%] flex-col flex ">
        <div className=" text-[5px] text-[#414752] max-w-[85%] ">AI-Powered Interviews for Unbiased Talent Discovery</div>
      </div>
    </div>

  );
};

export default Certificatepage;


{/* <div className="mb-6"> */ }
{/* <img
      src={logo}
      alt="Cloud Academy Logo"
      className="w-60 mx-auto mb-2"
    /> */}
{/* <h2 className="text-3xl text-blue-600 font-semibold mb-4">
      {first_name ? first_name : firstName}{" "}
      {last_name ? last_name : lastName}
    </h2> */}
{/* <h1 className="text-4xl font-bold">GOT INTERVIEWED BY AI</h1> */ }
{/* <p className="text-xl">OF ACHIEVEMENT</p> */ }
{/* </div>
  <p className="text-lg">for {job_role}</p>
  <div className="flex justify-between items-center mt-24">
    <p className="text-md">{formattedDateTime}</p>
    <div className="text-center flex flex-col justify-center">
      <img
        src={signature}
        alt="Cloud Academy Logo"
        className="w-48 mx-auto mb-2"
      />
      <p className="text-md">Authorized</p>
      <p className="text-sm">Signature</p>
    </div>
  </div> 
</div> */}