import React, { useState, useEffect, useRef, useCallback } from 'react';
import Webcam from 'react-webcam';
import { captureIcon, closeBtnIcon, closeButton } from '../assets/imgs';
import { CustomButtonWhite } from '../components/buttons';

export const AudioVisualizer = ({ audioStream, setNoAuido }) => {
  const [audioContext, setAudioContext] = useState(null);
  const [analyserNode, setAnalyserNode] = useState(null);
  const [dataArray, setDataArray] = useState(null);
  const [isMounted, setIsMounted] = useState(true);
  console.log('this is audio stream', audioStream);

  useEffect(() => {
    setIsMounted(true); // Set the flag to true when component mounts

    if (audioStream) {
      const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      const analyser = audioCtx.createAnalyser();
      const source = audioCtx.createMediaStreamSource(audioStream);
      setNoAuido(source.active);

      analyser.fftSize = 256;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      console.log(source.active, 'audioCtx', source?.mediaStream.active);
      console.log('maxxxxxxxxxxxxxxxxxxxx:', dataArray);

      source.connect(analyser);

      setAudioContext(audioCtx);
      setAnalyserNode(analyser);
      setDataArray(dataArray);

      const draw = () => {
        if (!isMounted) return; // Check if the component is still mounted before drawing

        const canvas = document.getElementById('audioVisualizer');
        if (!canvas) return; // Check if the canvas element exists

        const canvasCtx = canvas.getContext('2d');
        if (!canvasCtx) return; // Check if canvas context is available

        const WIDTH = canvas.width;
        const HEIGHT = canvas.height;

        // Get the time domain data (waveform)
        analyser.getByteTimeDomainData(dataArray);

        // Clear the canvas
        canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

        canvasCtx.lineWidth = 2;
        canvasCtx.strokeStyle = '#A6AEBA';

        canvasCtx.beginPath();

        const sliceWidth = (WIDTH * 1.0) / bufferLength;
        let x = 0;

        // Apply a simple moving average for smoothing
        const smoothDataArray = smoothArray(dataArray, 5);

        for (let i = 0; i < bufferLength; i++) {
          const v = smoothDataArray[i] / 128.0;
          const y = (v * HEIGHT) / 2;

          if (i === 0) {
            canvasCtx.moveTo(x, y);
          } else {
            canvasCtx.lineTo(x, y);
          }

          x += sliceWidth;
        }

        canvasCtx.lineTo(canvas.width, canvas.height / 2);
        canvasCtx.stroke();

        requestAnimationFrame(draw);
      };

      // Simple moving average function
      const smoothArray = (data, windowSize) => {
        const smoothed = [];
        for (let i = 0; i < data.length; i++) {
          let sum = 0;
          let count = 0;
          for (
            let j = i - Math.floor(windowSize / 4);
            j <= i + Math.floor(windowSize / 4);
            j++
          ) {
            if (j >= 0 && j < data.length) {
              sum += data[j];
              count++;
            }
          }
          smoothed.push(sum / count);
        }
        return smoothed;
      };

      draw();

      return () => {
        setIsMounted(false); // Set the flag to false when component unmounts
        audioCtx.close();
      };
    }
  }, [audioStream, isMounted]);

  return <canvas className="w-[23rem] h-full" id="audioVisualizer" />;
};

// export const MediaDevices = ({
//   setIsOK,
//   isOk,
//   imgSrc,
//   setImgSrc,
//   updateProfilePhoto,
// }) => {
//   const [videoStream, setVideoStream] = useState(null);
//   const [audioStream, setAudioStream] = useState(null);
//   const [hasFocus, setHasFocus] = useState(true);
//   const [showImg, setShowImg] = useState(false);
//   const webcamRef = useRef(null);

//   useEffect(() => {
//     const handleFocus = () => {
//       if (document.hasFocus()) {
//         setHasFocus(true);
//         console.log('checking');
//         handleRequestPermission();
//       }
//     };

//     const handleBlur = () => {
//       console.log('out of facus');
//       handleRequestPermission();
//       setHasFocus(false);
//     };

//     window.addEventListener('focus', handleFocus);
//     window.addEventListener('blur', handleBlur);

//     return () => {
//       window.removeEventListener('focus', handleFocus);
//       window.removeEventListener('blur', handleBlur);
//     };
//   }, [hasFocus]);

//   const handleRequestPermission = async () => {
//     console.log('trying inside handleRequestPermission');
//     try {
//       console.log('audio try');
//       const streamAudio = await navigator.mediaDevices.getUserMedia({
//         audio: true,
//       });
//       console.log('successfull', streamAudio);
//       console.log('video try');
//       const streamVideo = await navigator.mediaDevices.getUserMedia(
//         {
//           video: true,
//         } // Requesting video permission
//       );
//       console.log('successfull');
//       console.log('All access granted!');
//       setIsOK(true);

//       // Create a new MediaStream with only video tracks
//       const videoOnlyStream = new MediaStream();
//       streamVideo
//         .getVideoTracks()
//         .forEach((track) => videoOnlyStream.addTrack(track));

//       // Create a new MediaStream with only audio tracks
//       const audioOnlyStream = new MediaStream();
//       streamAudio
//         .getAudioTracks()
//         .forEach((track) => audioOnlyStream.addTrack(track));

//       setVideoStream(videoOnlyStream);
//       setAudioStream(audioOnlyStream);
//     } catch (error) {
//       console.log('Error requesting media permission:', error);
//       setIsOK(false);
//     }
//   };

//   useEffect(() => {
//     console.log('tried in mediaDevice');
//     handleRequestPermission();
//     return () => {
//       if (videoStream) {
//         videoStream.getTracks().forEach((track) => track.stop());
//       }
//       if (audioStream) {
//         audioStream.getTracks().forEach((track) => track.stop());
//       }
//     };
//   }, []);

//   const videoConstraints = {
//     width: 640,
//     height: 480,
//     facingMode: 'user',
//   };

//   const capture = useCallback(() => {
//     const imageSrc = webcamRef.current.getScreenshot({
//       width: 640,
//       height: 480,
//     });
//     setImgSrc(imageSrc);
//   }, [webcamRef, setImgSrc]);

//   // const renderVideo = useMemo(() => {
//   //   if (!videoStream) return null;
//   //   return (
//   //     <video
//   //       className="w-[23rem] h-full object-cover"
//   //       autoPlay={true}
//   //       ref={(videoRef) => {
//   //         if (videoRef) {
//   //           videoRef.srcObject = videoStream;
//   //         }
//   //       }}
//   //     />
//   //   );
//   // }, [videoStream]);

//   const handleCapture = () => {
//     capture();
//     setShowImg(true);
//   };

//   return (
//     <div className="flex gap-[12rem] text-center px-12">
//       <section
//         className={`absolute bg-white top-0 right-0 z-10 h-[100vh] w-[100vw] flex justify-center items-center ${
//           showImg ? 'block' : 'hidden'
//         }`}
//       >
//         {
//           <div className="flex flex-col">
//             <button
//               className=" flex mb-4 justify-end items-end"
//               onClick={() => {
//                 updateProfilePhoto();
//                 setShowImg(false);
//               }}
//             >
//               <img src={closeButton} className="self-end" />
//             </button>
//             <img src={imgSrc} className="w-[25rem]" alt="img" />
//             <p className="bg-white p-2">
//               You can change this image by taking another picture
//             </p>
//             <div
//               className="flex justify-center"
//               onClick={() => {
//                 updateProfilePhoto();
//                 setShowImg(false);
//               }}
//             >
//               <CustomButtonWhite text="Save" />
//             </div>
//           </div>
//         }
//       </section>
//       <span>
//         {audioStream && (
//           <span className="flex gap-2 justify-center items-center">
//             <img
//               className="w-24 h-24 cursor-pointer"
//               src={captureIcon}
//               alt="Capture pic"
//               onClick={handleCapture}
//             />
//             {/* {renderVideo} */}
//             <span className="w-[23rem]">
//               <Webcam
//                 audio={false}
//                 ref={webcamRef}
//                 screenshotFormat="image/jpeg"
//                 videoConstraints={videoConstraints}
//                 minScreenshotWidth={180}
//                 minScreenshotHeight={180}
//               />
//             </span>
//           </span>
//         )}
//         <p className={`${isOk ? 'text-green-500' : 'text-red-500'}`}>
//           Video Output - {isOk ? 'Check' : 'No Access'}{' '}
//         </p>
//       </span>

//       <span>
//         {videoStream ? <AudioVisualizer audioStream={audioStream} /> : null}
//         <p className={`${isOk ? 'text-green-500' : 'text-red-500'}`}>
//           {' '}
//           Audio Input - {isOk ? 'Check' : 'No Access'}{' '}
//         </p>
//       </span>

//       <section
//         className={`absolute bottom-2 right-2 border-2 cursor-pointer ${
//           imgSrc ? 'block' : 'hidden'
//         }`}
//       >
//         <img
//           src={imgSrc}
//           className="w-[5rem]"
//           alt="img"
//           onClick={() => setShowImg(true)}
//         />
//       </section>
//     </div>
//   );
// };

export const MediaDevicesCapturePhoto = ({
  setImgSrc,
  cap,
  setIsCameraOpen,
  setCandidateAudio,
}) => {
  const [videoStream, setVideoStream] = useState(null);
  const [audioStream, setAudioStream] = useState(null);
  const [hasFocus, setHasFocus] = useState(true);
  const [showImg, setShowImg] = useState(false);
  const webcamRef = useRef(null);
  useEffect(() => {
    handleRequestPermission();
    const handleFocus = () => {
      if (document.hasFocus()) {
        setHasFocus(true);
        console.log('checking');
      }
    };

    const handleBlur = () => {
      console.log('out of facus');
      handleRequestPermission();
      setHasFocus(false);
    };

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, [hasFocus]);

  const handleRequestPermission = async () => {
    console.log('trying inside handleRequestPermission');
    try {
      console.log('audio try');
      const streamAudio = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      console.log('successfull', streamAudio);
      console.log('video try');
      const streamVideo = await navigator.mediaDevices.getUserMedia(
        {
          video: true,
        } // Requesting video permission
      );
      console.log('successfull');
      console.log('All access granted!');
      setIsCameraOpen(1);

      // Create a new MediaStream with only video tracks
      const videoOnlyStream = new MediaStream();
      streamVideo
        .getVideoTracks()
        .forEach((track) => videoOnlyStream.addTrack(track));

      // Create a new MediaStream with only audio tracks
      const audioOnlyStream = new MediaStream();
      streamAudio
        .getAudioTracks()
        .forEach((track) => audioOnlyStream.addTrack(track));

      setVideoStream(videoOnlyStream);
      setAudioStream(audioOnlyStream);
      setCandidateAudio(audioOnlyStream);
    } catch (error) {
      console.log('Error requesting media permission:', error);
      setIsCameraOpen(0);
    }
  };

  useEffect(() => {
    console.log('tried in mediaDevice');
    handleRequestPermission();
    return () => {
      if (videoStream) {
        videoStream.getTracks().forEach((track) => track.stop());
      }
      if (audioStream) {
        audioStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  const videoConstraints = {
    facingMode: 'user',
  };
  const capture = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        setImgSrc(imageSrc);
        console.log('Captured Image:', imageSrc);
      } else {
        console.error('Error capturing image');
      }
    }
  }, [webcamRef, setImgSrc]);

  // const renderVideo = useMemo(() => {
  //   if (!videoStream) return null;
  //   return (
  //     <video
  //       className="w-[23rem] h-full object-cover"
  //       autoPlay={true}
  //       ref={(videoRef) => {
  //         if (videoRef) {
  //           videoRef.srcObject = videoStream;
  //         }
  //       }}
  //     />
  //   );
  // }, [videoStream]);

  const handleCapture = () => {
    capture();
    setShowImg(true);
  };

  useEffect(() => {
    if (cap) {
      handleCapture();
    }
  }, [cap]);

  return (
    <div className=" text-center  h-full w-full">
      <div className="h-full w-full ">
        <Webcam
          className="w-[35rem] max-w-[700px]"
          audio={false}
          disablePictureInPicture={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          minScreenshotWidth={180}
          minScreenshotHeight={180}
        />
      </div>
    </div>
  );
};
