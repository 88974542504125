import { toast } from 'react-toastify';
import { handleApiResponse } from '../../API/services';
import {
  bell,
  clevercruit,
  clevercruitlogo,
  closeButton,
  coin,
  cross,
  logo,
  userImg,
} from '../../assets/imgs';
import { getLocal } from '../../utils/localStorage';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { concatenateArgs } from '../../utils/validation';

export const NavBar = (props) => {
  const [isProfilePageVisible, setIsProfilePageVisible] = useState(false);
  const last_name = getLocal('last_name');
  const first_name = getLocal('first_name');
  const Cname = getLocal('organization');
  const id = getLocal('companyId');
  const uid = getLocal('uid');
  const navigate = useNavigate('');
  const [total_credit, setTotalCredits] = useState(0);
  const [used_credit, setUsedCredits] = useState(0);
  const [addCredits, setAddCredits] = useState(null);
  const [openAddCredits, setOpenAddCredits] = useState(false);


  const percentageUsed = (used_credit / total_credit) * 100;
  const isCriticalUsage = percentageUsed >= 80;

  const call = async () => {
    const reqData = {};
    const response = await handleApiResponse('/api/v1/get_credit/', reqData, 1);
    if (response?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    if (response?.data?.status_code === 200) {
      setTotalCredits(response?.data?.data?.total_credits);
      sessionStorage.setItem('total_credit', response.data.data.total_credits);

      setUsedCredits(response.data.data.credit_used);
      sessionStorage.setItem('used_credit', response.data.data.credit_used);
    }
  };

  useEffect(() => {
    call();
  }, []);

  const addCreditCall = async () => {
    const reqData = {
      total_credits: addCredits,
    };
    const resonse = await handleApiResponse('/api/v1/add_credit/', reqData, 1);
    console.log(resonse.data.status_code);
    if (resonse?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    if (resonse.data.status_code) {
      toast.success('Credit added',{
        className: "custom-toast-success",
      });
      setAddCredits(null);
      setOpenAddCredits(false);
    } else {
      toast.error('Something went wrong',{
        className: "custom-toast-error",
          });
    }
  };

  const handleInputInPopUp = (credit) => {
    if (credit > 10000) {
      return;
    } else setAddCredits(credit);
  };

  const openCredit = () => {
    setOpenAddCredits(true);
  };

  return (
    <nav
      className="h-fit pt-[1rem] min-h-[80px] flex justify-between"
      onClick={() => props.setIsShowPopup(false)}
    >
      {openAddCredits && (
        <div className="w-[100vw] h-[100vh] flex justify-center items-center absolute z-50 top-[0rem] left-[0rem] backdrop-blur">
          <span className="flex flex-col justify-between items-center p-12 bg-white w-[40%] h-[30%] relative">
            <img
              src={closeButton}
              alt="btn"
              className="absolute right-[1rem] top-[1rem] cursor-pointer"
              onClick={() => setOpenAddCredits(false)}
            />
            <p>Enter the updated credit amount</p>
            <input
              type="number"
              placeholder="Credit"
              className="border-2 p-2"
              value={addCredits}
              onChange={(e) => {
                handleInputInPopUp(e.target.value);
              }}
            />
            <button className="border-2 w-[5rem] p-1" onClick={addCreditCall}>
              Set
            </button>
          </span>
        </div>
      )}
      <div className=" flex h-fit items-start fill-available   font-normal ml-[3rem] mt-[1rem]">
        <img
          src={
            'https://aishree.s3.ap-south-1.amazonaws.com/Assests/clevercruitlogo.svg'
          }
          alt=""
          className="cursor-pointer min-w-[154px] w-[154px]"
          onClick={() => navigate('/admin/dashboard')}
        />
      </div>
      <section className="flex justify-end items-start gap-4 h-fit">
  <div className="relative group">
    <div
      className="flex w-[12rem] font-satoshi text-[18px] font-bold border-2 py-2 px-1 gap-2 rounded-3xl bg-white justify-center"
      // onClick={openCredit}
    >
      <img src={coin} alt="" />
      <p>{total_credit - used_credit}</p>
      <p>Credits</p>
    </div>

    {/* Tooltip below the credits section */}
    <div className="absolute top-full z-[1000] left-1/2 transform -translate-x-1/2 mt-2 w-max bg-gray-800 text-white text-sm py-1 px-2 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
    Available interview credits
    </div>
  </div>

  <div className="relative cursor-pointer mr-5 p-2 bg-white rounded-full border-[1px] border-[#E8E8E8]">
    {isCriticalUsage && (
      <div className="w-3 h-3 bg-red-500 rounded-full absolute top-[-0.1rem] right-[-0.2rem] z-10"></div>
    )}
    <img
      src={bell}
      className="w-[6rem]"
      alt="bell"
      onClick={() => props.setOpenNotification(!props.openNotification)}
    />
    <div
      className={`${
        props.openNotification ? 'visible' : 'hidden'
      } w-[371px] h-[511px] font-satoshi rounded-2xl absolute right-[0rem] bg-white border-2 z-[100] p-4`}
    >
      <div className="flex justify-between items-center">
        <b>
          <i>Notifications</i>
        </b>
        <div>
          <img
            src={cross}
            onClick={() => props.setOpenNotification(!props.openNotification)}
          />
        </div>
      </div>
      <br />
      <br />
      <p
        className={`${
          isCriticalUsage ? 'text-red-500 bold' : 'text-black'
        }`}
      >
        {isCriticalUsage
          ? `${percentageUsed.toFixed(2)}% of your credits have been used.`
          : 'No Notifications for now'}
      </p>
    </div>
  </div>
</section>

    </nav>
  );
};
