import {
  ModalGenerateSelfAnalytics,
  ModalPopup,
  ModalViewJobRole,
  ModalViewJobRole1,
} from './modal';
import { ModalEditJobRole } from './modal';
import { ModalDeleteJobRole } from './modal';
import { ModalDeleteCampaign } from './modal';
import { useState, useEffect } from 'react';
import { Modal2 } from './modal';
import { LinkIcon, copyIcon, deleteIcon, deleteSmIcon, delteyIcon, downloadButton, editIcon, editSmIcon, edityIcon, eyeButton, icon1, icon2, icon3, icon4, icon5, viewSmIcon, VideoRecordIcon, DownloadIcon, LinkIcon1, RefreshIcon } from '../../assets/imgs';
import { useNavigate } from 'react-router-dom';
import { handleApiResponse } from '../../API/services';
import { toast } from 'react-toastify';
import { AiTwotoneVideoCamera } from 'react-icons/ai';
import jsPDF from 'jspdf';
import { getLocal } from '../../utils/localStorage';
import { Loader } from '../loader';
import { CustomBtn, CustomButton, CustomBtnBig, CustomMemberBtn } from '../buttons';

const base_url = process.env.REACT_APP_FRONEND;

const ProgressBar = ({ score, total }) => {
  const filled = Math.round((score / total) * total);

  return (
    <div className="flex flex-col">
      <div className="flex space-x-1">
        {[...Array(total)].map((_, index) => (
          <div key={index} className="relative">
            <div
              className={`w-4 h-4 ${index < filled ? 'bg-green-500' : 'bg-gray-300'
                }`}
            />
            {(index + 1) % 10 === 0 && (
              <div className="absolute top-6 left-1/2 transform -translate-x-1/2 text-xs text-center">
                {index + 1}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export const Table = (props) => {
  console.log(props);
  const openModal2 = () => {
    props.setuuid(props.uid);
    props.setOpenDeleteConfirmation(true);
    console.log('this is openUID', props.uid);
  };

  const handleEditData = () => {
    props.setEditData(props.all);
    props.setOpenEdit(true);
  };

  const [score, setScore] = useState(3);
  const total = 20;

  // Example to change score dynamically
  const increaseScore = () => setScore((prev) => Math.min(prev + 1, total));
  const decreaseScore = () => setScore((prev) => Math.max(prev - 1, 0));

  return (
    <tr
      class="text-[16px] bg-white border-b border-solid cursor-pointer py-2 h-[54px] border-[#E2E8F0]"
      style={props.style}
    >
      <td class="px-3 pl-6 flex justify-start items-center ">{`${(props?.number) >= 10 ? props?.number : '0' + (props?.number)}`}</td>
      <td class="px-3 py-2 text-left">{props?.name}</td>
      <td class="px-3 py-2 text-left">{props?.row12}</td>
      <td class="px-3 py-2 text-left">{props?.row13}</td>
      <td class="px-3 py-2 text-left">{props?.row14}</td>
      <td class="px-3 py-2 text-left">
        <p
          className={`p-2 border-[1px] ${props.row15 === 'Inactive'
            ? 'text-[#FC7F29] border-[#FC7F29]'
            : 'text-[#48C885] border-[#48C885]'
            } flex items-center justify-center rounded-[30px] text-[16px]`}
        >
          {props?.row15}
        </p>
      </td>

      {props.noPermission === 'false' && (
        <td className="px-3 py-2 flex gap-2 justify-start items-center">
          <CustomMemberBtn
            img={editSmIcon}
            color="#48C885"
            onClick={handleEditData}
          />
          <CustomMemberBtn
            img={deleteSmIcon}
            color="#FC7F29"
            onClick={openModal2}
          />
        </td>
      )}
    </tr>
  );
};

export const JobRoleTable = (props) => {
  const [showTooltip, setShowTooltip] = useState(false);
  let creator = sessionStorage.getItem('uid');
  let userType = sessionStorage.getItem('userType');
  let parentId = sessionStorage.getItem('parentId');
  const navigate = useNavigate();
  const isClickable = creator === props?.rowData?.creator;
  const truncatedText =
    props.row13.length > 300 ? props.row13.slice(0, 300) + '...' : props.row13;
  console.log(truncatedText);
  const [showView, setShowView] = useState(false);

  const openModal5 = () => {
    if (isClickable) {
      props.setuuid(props.uid);
      props.setOpenWhat('Delete');
      props.setOpenDeleteConfirmation(true);
      console.log('this is openUID', props.uid);
    } else {
      toast.info("You can't Delete this job !",{
        className: "custom-toast-info",
      });
    }
  };

  const openModalEditJobRole = () => {
    props.setuuid(props.uid);
    console.log(props?.rowData);
    props.setOpenEditConfirmation(props?.rowData);
    console.log('this is openUID', props.uid);
  };

  const closeModal5 = () => {
    props.setIsModalOpen5(false);
  };
  console.log(props.uid);

  const editJobRole = async () => {
    console.log(props.uid);
    try {
      const formData = new FormData();
      formData.append('uid', props.uid);
      formData.append('is_deleted', '1');
      const response = await handleApiResponse(
        '/api/v1/interview/add_jobrole/',
        formData,
        1
      );
      if (response?.code === 'token_not_valid') {
        navigate('/');
        sessionStorage.clear();
        return;
      }
      console.log(response?.data.status_code);
      if (response?.data.status_code) {
        closeModal5();
        toast.success('Deleted successfully.', {
          className: "custom-toast-success",
        });
      } else {
        closeModal5();
        toast.error('Something went wrong.', {
          className: "custom-toast-error",
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleDownload = async () => {
    try {
      // Construct the URL using the environment variable and props.row17
      const fileUrl = `${process.env.REACT_APP_URL_DOC}/jds/${props.row17}`;
      console.log(fileUrl);
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();

      // Create a hidden anchor element
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileUrl.split('/').pop(); // Extract the file name from the URL
      link.style.display = 'none';

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Remove the link from the body
      document.body.removeChild(link);
    } catch (error) {
      console.error('There was an error downloading the file:', error);
    }
  };
  console.log(props.row14);
  return (
    <tr
      className="text-[16px] py-2 h-[54px] justify-center items-center font-satoshi border-b border-solid cursor-pointer border-[#E2E8F0]"
      style={props.style}
    >
      <th
        scope="row"
        class="flex gap-2 text-left items-center px-3 font-medium text-[12px] text-[#F2F9FC] whitespace-nowrap dark:text-white"
      >
        <span className="text-[16px] text-[#A6AEBA] ml-4">{`${props.number >= 10 ? props.number : '0' + (props.number)}`}</span>
      </th>
      {showView && (
        <ModalViewJobRole1
          fileUrl={`${process.env.REACT_APP_URL_DOC}/jds/${props.row17}`}
          setShowView={setShowView}
        />
      )}
      <td class="px-3 text-left">{props.row12}</td>
      <td
        className="w-[26%] px-3 py-4 relative text-left"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <div className="text-left line-clamp-1 overflow-hidden">{truncatedText}</div>

        {showTooltip && (
          <div className="z-10 bg-black text-white text-xs rounded-lg py-1 px-2 absolute top-full left-0 ml-2 overflow-hidden">
            {props.row13}
          </div>
        )}
      </td>
      <td class="px-3 text-left">{props.row14}</td>
      <td class="px-3 text-left">{String(props.row15).split('-').reverse().join('-')}</td>
      <td class="px-3 text-left">
        <p className="flex items-center justify-start text-[16px]">
          {props.row16}
        </p>
      </td>
      {/* <td class="px-6 py-4">
        <p className="flex items-center justify-center text-[0.972vw]">
          {props.row18}
        </p>
      </td> */}
      <td className='flex justify-start px-3' >
        <div className="inline-flex justify-start gap-2 mt-3 ">

          {userType === '2' ? (
            <CustomBtnBig
              img={viewSmIcon}
              color="#0875F4"
              onClick={() => {
                setShowView(true);
                // const fileUrl = `${process.env.REACT_APP_URL_DOC}/jds/${props.row17}`;
                // console.log(fileUrl);
                // navigate('/admin/jobRolePage', { state: { fileUrl: fileUrl } });
              }}
            />
          ) : (
            <CustomBtn
              img={viewSmIcon}
              color="#0875F4"
              onClick={() => {
                setShowView(true);
                // const fileUrl = `${process.env.REACT_APP_URL_DOC}/jds/${props.row17}`;
                // console.log(fileUrl);
                // navigate('/admin/jobRolePage', { state: { fileUrl: fileUrl } });
              }}
            />
          )}
          {/* <ModalViewJobRole
              isOpen={props.isModalOpenResume}
              onClose={props.closeModalResume}
            /> */}
          {/* <img
              src={downloadButton}px
              alt="download icon"
              onClick={handleDownload}
            /> */}
          {/* {userType !== "2" && (
              <img
                src={props.editIcon}
                alt="edit icon"
                // onClick={openModalEditJobRole}
                onClick={() => {
                  if (userType !== "2") {
                    openModalEditJobRole();
                  }
                }}
                editJobRole={editJobRole}
              />
            )} */}
          {userType !== '2' && (
            <CustomBtn
              img={editSmIcon}
              color="#48C885"
              // onClick={openModalEditJobRole}
              onClick={() => {
                // if (userType !== "2") {
                openModalEditJobRole();
              }}
              // }
              editJobRole={editJobRole}
            />
          )}

          {(isClickable && userType !== '2') && (
            <div className={`${(isClickable && userType !== '2') ? "" : "opacity-0"}`}>
              <CustomBtn
                img={deleteSmIcon}
                color="#FC7F29"
                onClick={() => {
                  if (isClickable && userType !== '2') {
                    openModal5(); // Trigger the function only if the condition is met
                  }
                }}
              />
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};

export const CampaignTable = (props) => {
  const navigate = useNavigate();
  let creator = sessionStorage.getItem('uid');
  console.log('creator', creator);
  console.log('props.creator', props.creator);
  const isClickable = creator === props.creator;
  let userType = sessionStorage.getItem('userType');
  const openModal6 = () => {
    if (isClickable) {
      props.setDeleteUid(props.uid);
      props.openDelete();
    } else {
      toast.info("You can't Delete this campaign !",{
        className: "custom-toast-info",
      });
    }
  };
  const getStatus = (launched, row13) => {
    console.log('launched', launched);
    console.log('row13', row13);

    if (row13 === 'Mass' && launched === 2) {
      console.log('inside getstatus');
      return 'Completed';
    }

    const statusMap = {
      0: 'Not Completed',
      1: 'Not Completed',
      2: 'Not Completed',
      3: 'Completed',
    };

    return statusMap[launched];
  };

  console.log(props.launched);
  return (
    <tr
      style={props.style}
      className="text-[16px] py-2 font-satoshi border-b border-solid cursor-pointer border-[#E2E8F0] "
    >
      <td className="text-left text-[#A6AEBA] px-8 ml-4">{`${(props.number + 1) >= 10 ? props.number + 1 : '0' + (props.number + 1)}`}</td>

      <td class="px-3 py-4 text-left">{props.row12}</td>
      {/* <td class="px-3 ">{props.row13}</td> */}
      {userType !== "3" && <td class="px-3">{props.row17}</td>}
      <td class="px-3 text-left">
        {String(props.row15).split('-').reverse().join('-')}
      </td>
      {!props.isDash && (
        <td class="px-3 text-left">
          {' '}
          {String(props.end).split('-').reverse().join('-')}
        </td>
      )}

      {!props.isAdmin && !props.isDash && <td class="px-3 text-left">{props.row14}</td>}
      {/* <td class="px-3 ">{props.row14}</td> */}

      {!props.isAdmin && !props.isDash && <td class="px-3 text-left">{props.row13}</td>}
      {/* <td class="px-3 ">{props.row13}</td> */}
      {/* <td className="px-3 ">{getStatus(props.launched, props.row13)}</td> */}

      {/* {userType !== "2" && <td class="px-6 py-4">{props.row19}</td>} */}
      {!props.isAdmin && (
        <td class="px-3 text-left">
          <p
            className={`p-2 rounded-[30px] border-[1px] flex items-center justify-center text-[16px] ${props.row18 === 'Completed'
              ? 'text-[#FC7F29] border-[#FC7F29]'
              : props.row18 === 'Upcoming'
                ? 'text-[#197FF5] border-[#197FF5]'
                : 'text-[#48C885] border-[#48C885]'
              }`}
          >
            {props.row18}
          </p>
        </td>
      )}
      {!props.isDash && (
        <td class="px-3 justify-start flex gap-2 pt-2 fill-available ">
          {userType === '2' ? (
            <CustomBtnBig
              img={viewSmIcon}
              color="#0875F4"
              onClick={() => {
                navigate('/admin/view-campaigns', {
                  state: {
                    uid: props.uid,
                    jr_name: props.row14,
                    start_date: props.row15,
                    end_date: props.end,
                    campaign_type: props.row13,
                    name: props.row12,
                    desc: props.description,
                    status: props.row18,
                  },
                });
              }}
            />
          ) : (
            <CustomBtn
              img={viewSmIcon}
              color="#0875F4"
              onClick={() => {
                navigate('/admin/view-campaigns', {
                  state: {
                    uid: props.uid,
                    jr_name: props.row14,
                    start_date: props.row15,
                    end_date: props.end,
                    campaign_type: props.row13,
                    name: props.row12,
                    desc: props.description,
                    status: props.row18,
                  },
                });
              }}
            />
          )}

          {isClickable && userType !== '2' && (
            <CustomBtn
              img={editSmIcon}
              color="#48C885"
              aria-disabled={!isClickable}
              onClick={() => {
                if (isClickable) {
                  navigate('/editcampaign/addCampaign', {
                    state: {
                      uid: props.uid,
                      jr_name: props.row14,
                      start_date: props.row15,
                      end_date: props.end,
                      campaign_type: props.row13,
                      name: props.row12,
                      jr_uid: props.jobRoleUid,
                      desc: props.description,
                      resumeOn: props.resumeOn,
                      codingOn: props.codingOn,
                      creator: props.creator,
                      skills: props.skills,
                    },
                  });
                }
              }}
            />
          )}

          {isClickable && userType !== '2' && (
            <CustomBtn
              img={deleteSmIcon}
              aria-disabled={!isClickable}
              color="#FC7F29"
              onClick={openModal6}
            />
          )}
        </td>
      )}
    </tr>
  );
};

export const ViewCampaignTable = (props) => {
  const [isModalOpen6, setIsModalOpen6] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [generateSelfAnalyticsPopup, setGenerateSelfAnalyticsPopup] =
    useState(false);
  console.log(props, 'ropssssssssssss');
  const navigate = useNavigate();
  const [showHover, setShowHover] = useState(false);
  const organization = sessionStorage.getItem('organization');
  const [messages, setMessages] = useState([]);
  //  console.log(props.row14)
  //   console.log(props.bulkCountExists, "bulkCountExists");
  const closeModal6 = () => {
    setIsModalOpen6(false);
  };
  const [redirectLink, setRedirectLink] = useState();
  const [count, setCount] = useState(0);

  const handleClick = (linkInfo) => {
    console.log(linkInfo, 'copyLink');
    if (linkInfo?.link) {
      let link = `${base_url}/interview/candidate/` + linkInfo?.link;
      copyToClipboard(link);
      toast.success('link copied', {
        className: "custom-toast-success",
      });
    } else {
      toast.success('generate your link first', {
        className: "custom-toast-success",
      });
    }
  };

  console.log(props.candidateId);
  const handleRegenerate = (id) => {
    console.log(id);
    props?.callApi();
    if (!props.candidateId.includes(id)) {
      props.setCandidateId((prev) => [...prev, id]);
    }
    generateUniqueLink(id);
  };
  // console.log('props.candVideo',props.candVideo)
  // const handleViewVideo = async () => {
  //   console.log('props.candVideo', props.candVideo);

  //   if (!props.candVideo || props.candVideo === "null") {
  //     const warningMessage = !props.candVideo ? "Recording not available." : "Recording is under progress.";
  //     toast.warning(warningMessage);
  //     return;
  //   }

  //   toast.warning("Recording is getting prepared to load.");

  //   const fullUrls = props.candVideo.map(videoUrl =>
  //     `${process.env.REACT_APP_URL_DOC.replace(/\/?$/, '/')}${videoUrl.replace(/^\//, '')}`
  //   );

  //   try {
  //     // Check if all URLs are valid
  //     for (const url of fullUrls) {
  //       const response = await fetch(url);
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok for URL: " + url);
  //       }
  //     }

  //     // Navigate to the video page with all video URLs once they are confirmed to be valid
  //     navigate("/videopage", { state: { fileUrls: fullUrls } });
  //   } catch (error) {
  //     // Handle errors if any of the URLs are not valid
  //     toast.error(`Failed to load video: ${error.message}`);
  //   }
  // };
  const handleVideoOpen = async () => {
    setLoader1(true);
    const response = await handleApiResponse(
      '/api/v1/interview/get_or_merge_video/',
      {
        candidate_uid: props.uid,
      },
      1
    );
    console.log('API Response:', response, response.data);
    if (response.data.status_code === 200) {
      try {
        console.log(response?.data?.data?.video);
        setLoader1(false);
        //window.open(props.candVideo);
        window.open(`videopage?video=${response?.data.data?.video}`, '_blank');
      } catch (error) {
        setLoader1(false);
        toast.error(`Failed to load video: ${error.message}`, {
          className: "custom-toast-error",
        });
      }
    } else if (response.data.status_code === 400) {
      setLoader1(false);
      toast.error(response.data.msg, {
        className: "custom-toast-error",
      });
    }
  };

  const handleViewVideo = async () => {
    console.log('props.candVideo', props.candVideo);

    if (
      !props.candVideo ||
      props.candVideo.length === 0 ||
      props.candVideo === '[null]'
    ) {
      const warningMessage =
        !props.candVideo || props.candVideo.length === 0
          ? 'Recording not available.'
          : 'Recording is under progress.';
      toast.warning(warningMessage, {
        className: "custom-toast-warning",
      });
      return;
    }

    const fullUrls = props.candVideo
      .map((videoUrl) => {
        if (!videoUrl) {
          console.error('Invalid video URL:', videoUrl);
          return null;
        }

        // return `${process.env.REACT_APP_URL_DOC}/${videoUrl}`;
        return `${videoUrl}`;
      })
      .filter((url) => url !== null);

    if (fullUrls.length === 0) {
      toast.warning('Recording not available.', {
        className: "custom-toast-warning",
      });
      return;
    }

    try {
      // const videoBlobs = await Promise.all(
      //   fullUrls.map(async (url) => {
      //     const response = await fetch(url);
      //     if (!response.ok) {
      //       throw new Error("Network response was not ok for URL: " + url);
      //     }
      //     return await response.blob();
      //   })
      // );

      // const videoUrls = videoBlobs.map(blob => URL.createObjectURL(blob));
      // console.log('videoUrls', videoUrls);

      // Use sessionStorage to pass video URLs to the new tab
      // sessionStorage.setItem('fileUrls', JSON.stringify(videoUrls));
      sessionStorage.setItem('fileUrls', JSON.stringify(fullUrls));

      // Open the video page in a new tab
      window.open('/videopage', '_blank');
    } catch (error) {
      toast.error(`Failed to load video: ${error.message}`, {
        className: "custom-toast-error",
      });
    }
  };

  const copyToClipboard = (link) => {
    const textarea = document.createElement('textarea');
    textarea.value = link;
    console.log(link);
    textarea.style.position = 'absolute';
    textarea.style.left = '-9999px';

    document.body.appendChild(textarea);
    textarea.select();

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        console.log('Link copied to clipboard:', link);
      } else {
      }
    } catch (error) {
      toast.error(`Error copying link to clipboard: ${error}`, {
        className: "custom-toast-error",
      });
    } finally {
      document.body.removeChild(textarea);
    }
  };
  console.log('uid', props.uid);
  const handleAnalyticsGeneration = async () => {

    try {
      setLoader1(true);
      const response = await handleApiResponse(
        '/api/v1/get_performance_status/',
        {
          task_id: props.taskId,
          uid: props.uid,
        },
        1
      );
      setLoader1(false);
      if (response?.code === 'token_not_valid') {
        navigate('/');
        sessionStorage.clear();
        return;
      }
      console.log(response?.data);
      console.log(response?.data?.data?.status);
      console.log(response?.data?.data?.status === 'SUCCESS');
      // console.log("isha", response?.data?.data?.result.overall_score)
      if (response?.data?.status_code === 200) {
        // console.log(response?.data?.data?.status==="PENDING")
        if (response?.data?.data?.status === 'PENDING') {
          toast.warning('Analytics is getting generated.', {
            className: "custom-toast-warning",
          });
        } else if (response?.data?.data?.status === 'SUCCESS') {
          navigate(
            `/admin/analytics?uid=${encodeURIComponent(
              props.taskId
            )}&candPhoto=${encodeURIComponent(
              props.candPhoto
            )}&candName=${encodeURIComponent(
              props.name
            )}&candCompany=${encodeURIComponent(
              props.candCompany
            )}&candJobRole=${encodeURIComponent(
              props.candJobRole
            )}&candId=${encodeURIComponent(props.uid)}&candExp=${encodeURIComponent(props.exp)}`
          );
        } else if (response?.data?.data?.status === 'FAILURE') {
          // toast.warning("Analytics generation failed.");
          setGenerateSelfAnalyticsPopup(true);
        } else {
          toast.warning('Interview not completed by candidate.', {
            className: "custom-toast-warning",
          });
        }
      } else if (response?.data.status_code === 400) {
        // setLoader(true);
        toast.warning('Interview is not completed.', {
          className: "custom-toast-warning",
        });
        // setGenerateSelfAnalyticsPopup(true);
      } else {
        toast.error(response?.msg, {
          className: "custom-toast-error",
        });
      }

    } catch (error) {
      // Handle errors here
    }
  };

  const downloadPDF = (messages) => {
    if (!messages || messages.length === 0) {
      toast.warning('Transcript not available.', {
        className: "custom-toast-warning",
      });
      return;
    }

    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 20;
    const contentWidth = pageWidth - 2 * margin;
    const maxLineWidth = contentWidth * 0.7;
    const lineHeight = 8;
    const fontSize = 10;
    const messageGap = 10;
    const headerFontSize = 14;
    const headerGap = 15;
    let y = margin + 10;

    const drawPageBorder = () => {
      doc.setDrawColor(0, 0, 0);
      doc.roundedRect(
        margin,
        margin,
        contentWidth,
        pageHeight - 2 * margin,
        10,
        10
      );
    };

    const addWatermark = () => {
      const watermarkText = 'clevercruit';
      const fontSize = 50;
      const angle = 45;

      const x = pageWidth / 2;
      const y = pageHeight / 2;

      doc.setTextColor(230, 230, 230);
      doc.setFontSize(fontSize);
      doc.setFont('helvetica', 'bold');

      // Calculate the text width to center it properly
      const textWidth = doc.getTextWidth(watermarkText);
      const textHeight = fontSize / 2; // Approximate height of the text

      // Adjust coordinates to center the text
      const centerX = x - textWidth / 2;
      const centerY = y + textHeight / 2;

      doc.text(watermarkText, centerX, centerY, { angle });
    };

    const addHeader = () => {
      const interviewer = `Candidate Name: ${props.name}`;
      const jobRole = `Job Role: ${props.jr_name}`;

      doc.setFontSize(headerFontSize);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(0, 0, 0);

      const interviewerWidth = doc.getTextWidth(interviewer);
      const jobRoleWidth = doc.getTextWidth(jobRole);

      const totalHeaderWidth = interviewerWidth + jobRoleWidth + 20; // 20 is a buffer space

      const interviewerX = margin + 10;
      const jobRoleX = pageWidth - margin - jobRoleWidth - 10;

      if (totalHeaderWidth > contentWidth) {
        // If the total width exceeds the content width, display in two lines
        doc.text(interviewer, interviewerX, y);
        y += headerFontSize + 5; // Move to the next line with some gap
        doc.text(jobRole, interviewerX, y);
      } else {
        doc.text(interviewer, interviewerX, y);
        doc.text(jobRole, jobRoleX, y);
      }

      doc.setFontSize(fontSize);
      doc.setFont('helvetica', 'normal');

      y += headerGap;
    };

    const replaceCodingQuest = (text) => {
      return text.replace(/Coding_quest #:/g, '');
    };

    const addContent = () => {
      let lastMessageType = null;

      messages.forEach((message, index) => {
        const isAI = message.is_ai;
        const sender = isAI ? 'AI' : 'Candidate';
        const text = replaceCodingQuest(message.msg);
        const lines = doc.splitTextToSize(text, maxLineWidth - 10);

        const blockHeight = lines.length * lineHeight;

        if (y + blockHeight + messageGap > pageHeight - margin) {
          doc.addPage();
          addWatermark();
          drawPageBorder();
          y = margin + 10;
          addHeader();
          lastMessageType = null;
        }

        const labelX = isAI
          ? margin + 5
          : pageWidth - margin - 5 - doc.getTextWidth(`${sender}:`);
        const labelY = y;

        doc.setFont('helvetica', 'bold');
        doc.setFontSize(8);
        doc.setTextColor(100);
        doc.text(`${sender}:`, labelX, labelY);

        y += 8;

        const rectX = isAI ? margin + 5 : pageWidth - maxLineWidth - margin - 5;
        const rectWidth = maxLineWidth;
        let rectY = y - 5;

        if (rectY + blockHeight + 10 > pageHeight - margin) {
          doc.addPage();
          addWatermark();
          drawPageBorder();
          y = margin + 10;
          rectY = y - 5;
        }

        if (isAI) {
          doc.setFillColor(255, 248, 240);
        } else {
          doc.setFillColor(230, 255, 230);
        }

        doc.roundedRect(rectX, rectY, rectWidth, blockHeight + 10, 5, 5, 'F');

        const textX = rectX + 5;
        doc.setTextColor(0);
        doc.setFontSize(fontSize);
        doc.setFont('helvetica', 'normal');
        lines.forEach((line, lineIndex) => {
          doc.text(line, textX, y + 5);
          y += lineHeight;
        });

        y += messageGap;
      });
    };

    drawPageBorder();
    addWatermark();
    addHeader();
    addContent();

    doc.save(`${props.name}.pdf`);
  };

  const generateUniqueLink = async (id) => {
    const reqData = {
      campaign_uid: props.campUid,
      candidate_uid: id,
    };
    const response = await handleApiResponse(
      '/api/v1/interview/generate_unique_link/',
      reqData,
      1
    );
    console.log('this is status', response?.data?.status_code);
    if (response?.data?.status_code === 200) {
      let link =
        `${base_url}/interview/candidate/` + response?.data?.data?.link;

      //navigator.clipboard.writeText(link)
      copyToClipboard(link);
      if (getLocal('mail_share_type') !== '1') {
        toast.success('link has been sent to email !', {
          className: "custom-toast-success",
        });
      }
      if (getLocal('mail_share_type') === '1') {
        toast.success('Regenerated and Link Copied.', {
          className: "custom-toast-success",
        });
      }
      setRedirectLink(link);
      setCount((prev) => prev + 1);
    } else {
      toast.error(response?.data?.msg, {
        className: "custom-toast-error",
      });
    }
  };

  const handleDownloadTranscript = async (e) => {
    try {
      const response = await handleApiResponse(
        '/api/v1/interview/data/get_candidate_transcript/',
        {
          candidate_uid: props.uid,
        },
        1
      );
      console.log('API Response:', response.data);
      if (response.data.status_code === 200) {
        console.log(response.data.data);
        const sortedMessages = response.data.data.sort((a, b) => a.id - b.id);
        setMessages(sortedMessages);
        downloadPDF(sortedMessages);
      }
    } catch (e) { }
  };
  console.log(props.counts);
  const allCountsGreaterOrEqualOne = props.counts.every((count) => count >= 1);
  return (
    <tr class="text-[14px] font-inter font-400 bg-white border-b border-solid text-[#718096] cursor-pointer relative" >
      {loader1 && <Loader />}
      {generateSelfAnalyticsPopup && (
        <ModalGenerateSelfAnalytics
          taskId={props.taskId}
          candPhoto={props.candPhoto}
          candUid={props.uid}
          name={props.name}
          candCompany={props.candCompany}
          candJobRole={props.candJobRole}
          onClose={() => {
            setGenerateSelfAnalyticsPopup(false);
          }}
        />
      )}

      <td className="px-6 py-2 relative">
        {props.candId}
        {/* <span
          className="absolute right-0 top-1/2 transform -translate-y-1/2 h-[30px] w-[1px] bg-[#F2F9FC]"
        /> */}
      </td>
      <td className="px-2 py-2 text-left relative">
        {props.candIntId || 'NA'}
        {/* <span
          className="absolute right-0 top-1/2 transform -translate-y-1/2 h-[30px] w-[1px] bg-[#F2F9FC]"
        /> */}
      </td>
      <th
        scope="row"
        className="px-2 py-2 relative text-left"
      >
        <div className='flex gap-2 items-center justify-start font-medium text-gray-900 whitespace-nowrap dark:text-white'>
          <span className="text-[14px] text-[#718096]">{props.name}</span>
        </div>
        {/* <span
          className="absolute right-0 top-1/2 transform -translate-y-1/2 h-[30px] w-[1px] bg-[#F2F9FC]"
        /> */}
      </th>

      <td className="px-2 py-2 text-left relative">
        {props.row12}
        {/* <span
          className="absolute right-0 top-1/2 transform -translate-y-1/2 h-[30px] w-[1px] bg-[#F2F9FC]"
        /> */}
      </td>

      {/* <td class="px-6 py-4">{organization}</td> */}
      {/* <td class=" px-2 py-2">{props.row13}</td> */}
      <td className="px-2 py-2 relative">
        <div className='flex justify-start items-center'>
          <p className={`w-[117px] h-[30px] flex items-center border-[1px] justify-center rounded-[30px] font-inter text-[16px] 
        ${props.row17 === 'Pending' ? 'text-[#0875F4] border-[#0875F4]' : 'text-[#FC7F29] border-[#FC7F29]'}`}
          >
            {props.row17}
          </p>
        </div>
        {/* <span
          className="absolute right-0 top-1/2 transform -translate-y-1/2 h-[30px] w-[1px] bg-[#F2F9FC]"
        /> */}
      </td>

      <td class="px-6 py-2 w-[10%]">
        <div className="w-full flex justify-start items-center gap-2">
          {(props.campType === 'Mass' && props.row17 === 'Pending') ? null : (
            props.row17 === 'Pending' ? (
              <div className="relative group">
                {/* <img
                src={icon4}
                alt="link icon"
                onClick={() => handleClick(props.linkInfo)}
              /> */}
                <CustomBtn
                  img={LinkIcon1}
                  color="#F78DF3"
                  onClick={() => handleClick(props.linkInfo)}
                />
                {/* Tooltip */}
                <div className="z-[999] absolute w-max top-[-2rem] left-auto right-[70px] transform translate-x-[50%] hidden group-hover:block">
                  <div className="bg-[#F78DF3] font-satoshi text-[14px] font-[500] fill-available text-white text-xs rounded-l-lg py-1 px-2 shadow-lg relative">
                    Copy Link
                    {/* Arrow */}
                    <div className="absolute right-0 top-full h-0 w-0 border-l-[8px] border-t-[8px] border-transparent border-t-[#F78DF3]"></div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="relative group">
                  {/* <img
                    src={icon1}
                    alt="eye icon"
                    onClick={handleAnalyticsGeneration}
                    className="cursor-pointer"
                  /> */}
                  <CustomBtn
                    img={viewSmIcon}
                    color="#197FF5"
                    onClick={handleAnalyticsGeneration}
                  />
                  {/* Tooltip */}
                  <div className="absolute w-max top-[-2rem] left-auto right-20 transform translate-x-[50%] hidden group-hover:block z-[999]">
                    <div className="bg-[#197FF5] font-satoshi text-[14px] font-[500] fill-available text-white text-xs rounded-l-lg py-1 px-2 shadow-lg relative">
                      Analytics Report
                      {/* Arrow */}
                      <div className="absolute right-0 top-full h-0 w-0 border-l-[8px] border-t-[8px] border-transparent border-t-[#197FF5]"></div>
                    </div>
                  </div>
                </div>

                <div className="relative group">
                  {/* <img src={icon2} onClick={handleVideoOpen} /> */}
                  <CustomBtn
                    img={VideoRecordIcon}
                    color="#FC7F29"
                    onClick={handleVideoOpen}
                  />
                  {/* Tooltip */}
                  <div className="z-[999] absolute w-max top-[-2rem] left-auto right-20 transform translate-x-[50%] hidden group-hover:block">
                    <div className="bg-[#FC7F29] font-satoshi text-[14px] font-[500] fill-available text-white text-xs rounded-l-lg py-1 px-2 shadow-lg relative">
                      Video Recording
                      {/* Arrow */}
                      <div className="absolute right-0 top-full h-0 w-0 border-l-[8px] border-t-[8px] border-transparent border-t-[#FC7F29]"></div>
                    </div>
                  </div>
                </div>

                <div className="relative group">
                  {/* <img
                    src={icon3}
                    alt="download icon"
                    onClick={() => handleDownloadTranscript()}
                  /> */}
                  <CustomBtn
                    img={DownloadIcon}
                    color="#48C885"
                    onClick={handleDownloadTranscript}
                  />
                  {/* Tooltip */}
                  <div className="z-[999] absolute w-max top-[-2rem] left-auto right-[70px] transform translate-x-[50%] hidden group-hover:block">
                    <div className="bg-[#48C885] font-satoshi text-[14px] font-[500] fill-available text-white text-xs rounded-l-lg py-1 px-2 shadow-lg relative">
                      Transcript
                      {/* Arrow */}
                      <div className="absolute right-0 top-full h-0 w-0 border-l-[8px] border-t-[8px] border-transparent border-t-[#48C885]"></div>
                    </div>
                  </div>
                </div>

                {/* {props.campType === 'Regular' && getLocal('mail_share_type') !== '1' && (
                  <div className="relative group">
                    <CustomBtn
                      img={LinkIcon1}
                      color="#F78DF3"
                      onClick={() => handleClick(props.linkInfo)}
                    />
                    <div className="z-[999] absolute w-max top-[-2rem] left-auto right-[70px] transform translate-x-[50%] hidden group-hover:block">
                      <div className="bg-[#F78DF3] font-satoshi text-[14px] font-[500] fill-available text-white text-xs rounded-l-lg py-1 px-2 shadow-lg relative">
                        Copy Link
                        <div className="absolute right-0 top-full h-0 w-0 border-l-[8px] border-t-[8px] border-transparent border-t-[#F78DF3]"></div>
                      </div>
                    </div>
                  </div>
                )} */}

                {/* {props.campType === 'Regular' && getLocal('mail_share_type') !== '1' && (
                  <div className="relative group">
                    <CustomBtn
                      img={RefreshIcon}
                      color="#222850"
                      onClick={() => handleRegenerate(props.uid)}
                    />
                    <div className="z-[999] absolute w-max top-[-2rem] left-auto right-20 transform translate-x-[50%] hidden group-hover:block">
                      <div className="bg-[#222850] font-satoshi text-[14px] font-[500] fill-available text-white text-xs rounded-l-lg py-1 px-2 shadow-lg relative">
                        Regenerate Link
                        <div className="absolute right-0 top-full h-0 w-0 border-l-[8px] border-t-[8px] border-transparent border-t-[#222850]"></div>
                      </div>
                    </div>
                  </div>
                )} */}

                {props.campType === 'Regular' && getLocal('mail_share_type') === '1' &&
                  (props.downloadLinksClicked || allCountsGreaterOrEqualOne) && (
                    <>
                      <img
                        src={LinkIcon}
                        alt="link icon"
                        onClick={() => handleClick(props.linkInfo)}
                        onMouseEnter={() => setShowHover(true)}
                        onMouseLeave={() => setShowHover(false)}
                      />
                      <button className="border-2 p-1" onClick={() => handleRegenerate(props.uid)}>
                        Re-generate Link
                      </button>
                    </>
                  )}
              </>
            )
          )}
        </div>
      </td>
    </tr>
  );
};
