import React, { useEffect, useState } from "react";
import { handleApiResponse } from "../../API/services";
import { useNavigate } from "react-router-dom";
import { eyeButton } from "../../assets/imgs";
import { ModalChangeCampaign } from "./modal";
import { getLocal } from "../../utils/localStorage";
import { CheckForInvalidToken } from "../../utils/helperFuntion";

export const Dropdown = (props) => {
  const getCampType = ["Regular Campaign", "Mass Campaign"];
  const open = () => {
    console.log("clicked");
    props.setToggle((prev) => ({
      ...prev,
      jobRole: false,
      CampType: !props.toggle.CampType,
    }));
    console.log("data => ", props.toggle.jobRole);
  };

  const handleToggleDropdown = () => {
    open();
  };
  const close = () => {
    props.setToggle((prev) => ({
      ...prev,
      jobRole: false,
      CampType: false,
    }));
  };

  const handleOption = (value) => {
    props?.setCampTypeValue(value);
    
    if (value === "Regular Campaign") {
      props.isRegular(true)
      props.setCampType(1);
    } else {
      props.setCampType(2);
      props.isRegular(false)
    }
    console.log(value);
    close();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Tab") {
      close();
    }
  };

  const getCampType1 = () => {
    const campType = sessionStorage.getItem("camp_type");
    if (campType === "1") {
      return "Regular";
    } else if (campType === "2") {
      return "Mass";
    }
    return null;
  };

  return (
    <>
      <div className="relative " onKeyDown={handleKeyDown}>
        <button
  id="dropdownDefaultButton"
  data-dropdown-toggle="dropdown"
  className={`border-[1px] border-[#22284E] w-[100%] h-[50px] mt-2 justify-between bg-[#FFFFFF] focus:outline-none font-medium rounded-[7.03px] text-[0.9rem] p-[0.42rem] text-center inline-flex items-center text-[#22284E]`}
  type="button"
  onClick={handleToggleDropdown}
>
          {props?.jobroleValue ||
            props?.campTypeValue ||
            getCampType1() ||
            props.holder}
            <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.410093 0.910734C0.73553 0.585297 1.26317 0.585297 1.5886 0.910734L5.99935 5.32148L10.4101 0.910734C10.7355 0.585297 11.2632 0.585297 11.5886 0.910734C11.914 1.23617 11.914 1.76381 11.5886 2.08925L6.5886 7.08925C6.26317 7.41468 5.73553 7.41468 5.41009 7.08925L0.410093 2.08925C0.0846564 1.76381 0.0846564 1.23617 0.410093 0.910734Z"
              fill="#1B3351"
            />
          </svg>
        </button>
        <div
          id="dropdown"
          className={`${
            props.toggle.CampType ? "block" : "hidden"
          } absolute left-0 mt-2 w-[784px] z-50 bg-[#FFFFFF] divide-y font-sans1 font-[500] text-[#1B3351] text-[0.9rem] rounded-[7.03px] shadow`}
        >
          <ul
            className="py-2 cursor-pointer"
            aria-labelledby="dropdownDefaultButton"
          >
            {getCampType.map((ele, index) => {
              return (
                <li
                  key={index}
                  className="flex justify-between px-4 py-2 hover:bg-gray-100"
                  onClick={() => handleOption(ele)}
                >
                  {ele}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export const DropdownJobRole = (props) => {
  // Initialize state
  console.log(props.jobroleValuebRole)
  const [selectedOption, setSelectedOption] = useState(
    sessionStorage.getItem("jrName") || null
  );
  const [searchInput, setSearchInput] = useState("");
  const [jd, setJd] = useState("");
  const navigate = useNavigate(); // Initialize the navigate function
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(props.jobroleValue);

  const handleToggleDropdown = (event) => {
    if (isButtonDisabled) {
      setIsPopupVisible(true);
    } else {
      if (props.data.length === 1) {
        getRole();
      }
      props.setToggle((prev) => ({
        ...prev,
        jobRole: !prev.jobRole,
        CampType: false,
      }));
    }
  };

  const close = () => {
    props.setToggle((prev) => ({
      ...prev,
      jobRole: false,
      CampType: false,
    }));
  };

  const handleSelectOption = (value) => {
    close();
    if (value.name === "Fetching...") {
      return;
    }
    setSelectedOption(value.name); // Set the selected option
    setJd(value.jd);
    props?.setjobroleValue(value.name);
    console.log(value.name);
    props?.setJobRoleUid(value.uid);
    sessionStorage.setItem("expFrom", value.experience_from);
    sessionStorage.setItem("expTo", value.experience_to);
    sessionStorage.setItem("jrName", value.name); // Store the selected job role name in local storage
  };

  const getRole = async () => {
    const reqData = {
      is_deleted: 0,
      // _limit: 10,
      // _page: 1,
    };
    const res = await handleApiResponse(
      "/api/v1/interview/jobrole_list/",
      reqData,
      1
    );
    const response = CheckForInvalidToken(res, navigate);
    if (response?.data.status_code === 200) {
      props.setData(response?.data?.data?.data);
    } else {
      props.setData(["Error"]);
    }
  };

  // const handleKeyDown = (e) => {
  //   if (e.key === "Tab") {
  //     close();
  //   }
  // };

  const handleEyeButtonClick = () => {
    if (selectedOption) {
      const jdUrl = `${process.env.REACT_APP_URL_DOC}/jds/${jd}`;
      navigate("/admin/jobRolePage", { state: { jdUrl } }); // Navigate to /job with the file URL
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const filteredOptions = props.data.filter((ele) =>
    ele.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  console.log(selectedOption)
  console.log(props?.jobroleValue)
  const handleYesButtonClick = (e) => {
    console.log('going here')
    e.preventDefault();
    setIsButtonDisabled(false);
    sessionStorage.setItem("isButtonDisabled", "false");
    setIsPopupVisible(false);
  };

  return (
    <>
      <div className="relative" 
      // onKeyDown={handleKeyDown}
      >
        {isPopupVisible && (
          <ModalChangeCampaign
            isOpen={isPopupVisible}
            onClose={() => setIsPopupVisible(false)}
            handleYesClick={handleYesButtonClick}
            // handleYesClick={() => console.log('testing')}
          />
        )}
        <button
          id="dropdownDefaultButton"
          data-dropdown-toggle="dropdown"
          className={`w-[100%] h-[50px] mt-[10px] border-[1px] border-[#22284E] justify-between bg-[#FFFFFF] focus:outline-none  rounded-[7.03px]  p-[0.42rem] text-center inline-flex items-center font-sans1 font-[500] text-[#1B3351] text-[0.9rem] ${
            isButtonDisabled ? "pointer-event-none bg-gray-100" : ""
          }`}
          type="button"
          onClick={handleToggleDropdown}
        >
          { selectedOption || props?.jobroleValue || props.holder}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.410093 0.910734C0.73553 0.585297 1.26317 0.585297 1.5886 0.910734L5.99935 5.32148L10.4101 0.910734C10.7355 0.585297 11.2632 0.585297 11.5886 0.910734C11.914 1.23617 11.914 1.76381 11.5886 2.08925L6.5886 7.08925C6.26317 7.41468 5.73553 7.41468 5.41009 7.08925L0.410093 2.08925C0.0846564 1.76381 0.0846564 1.23617 0.410093 0.910734Z"
              fill="#1B3351"
            />
          </svg>
        </button>
        <div
          id="dropdown"
          className={`${
            props.toggle.jobRole ? "block" : "hidden"
          } absolute left-0 mt-2 fill-available z-50 bg-[#FFFFFF] divide-y font-sans1 font-[500] text-[#1B3351] text-[0.9rem] rounded-[7.03px] shadow h-[10rem] overflow-auto`}
        >
          <ul
            className="py-2 cursor-pointer"
            aria-labelledby="dropdownDefaultButton"
          >
            {filteredOptions.length !== 0 ? (
              filteredOptions.map((ele) => (
                <li
                  key={ele.id}
                  className="flex justify-between px-4 py-2 hover:bg-gray-100"
                  onClick={() => handleSelectOption(ele)}
                >
                  {ele.name}
                </li>
              ))
            ) : (
              <p className="pl-2 text-white">No data found</p>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export const DropdownShareType = (props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOption = (option) => {
    console.log(`Selected option: ${option.label}`);
    props.setLinkTypeValue(option.value);
    props.setLinkTypeString(option.label);
    // Add your handling logic here, e.g., update a state or call a parent function
    setIsDropdownOpen(false);
  };

  // const getCampType = ['Regular', 'Mass'];
  // const open =()=>{
  //   console.log("clicked")
  //     props.setToggle((prev) => ({
  //       ...prev,
  //       // jobRole: false,
  //       CampType: !props.toggle.CampType,
  //     }));
  //     console.log("data => ",props.toggle.jobRole)
  // }

  // const handleToggleDropdown = () => {
  //   open();
  // };
  //  const close = () => {
  //    props.setToggle((prev) => ({
  //      ...prev,
  //      jobRole: false,
  //      CampType: false,
  //    }));
  //  };

  // // const handleOption = (value) => {
  // //   props?.setCampTypeValue(value);
  // //   if (value === 'Regular'){
  // //     props.setCampType(1);
  // //   }
  // //   else{
  // //     props.setCampType(2);
  // //   }
  // //   console.log(value)
  // //   close();
  // // };

  // const handleKeyDown = (e) => {
  //   if (e.key === 'Tab') {
  //     close();
  //   }
  // };

  const options = [
    { label: "Self", value: 1 },
    { label: "Platform", value: 2 },
  ];

  return (
    <>
      <div
        className="relative inline-block"
        onKeyDown={(e) => e.key === "Escape" && setIsDropdownOpen(false)}
      >
        <button
          id="dropdownDefaultButton"
          data-dropdown-toggle="dropdown"
          className="w-[31vw] mt-2 justify-between bg-[#FFFFFF] focus:outline-none rounded-[7.03px] text-sm px-[0.75rem] py-2.5 text-center inline-flex items-center font-sans1 font-[500] text-[#1B3351] text-[0.9rem]"
          type="button"
          onClick={handleToggleDropdown}
        >
          {props?.linkTypeString || props.holder}
          <svg
            className={`w-2.5 h-2.5 ms-3 transition-transform ${
              isDropdownOpen ? "rotate-180" : "rotate-0"
            }`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>
        <div
          id="dropdown"
          className={`${
            isDropdownOpen ? "block" : "hidden"
          } absolute left-0 mt-2 w-[31vw] z-50 bg-[#FFFFFF] divide-y font-sans1 font-[500] text-[#1B3351] text-[0.9rem] rounded-[7.03px] shadow`}
        >
          <ul
            className="py-2 cursor-pointer"
            aria-labelledby="dropdownDefaultButton"
          >
            {options.map((option, index) => (
              <li
                key={index}
                className="flex justify-between px-4 py-2 hover:bg-gray-100"
                onClick={() => handleOption(option)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export const DropdownCodingQuestion = (props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOption = (option) => {
    console.log(`Selected option: ${option.label}`);
    // props.setLinkTypeValue(option.value);
    // props.setLinkTypeString(option.label);
    // Add your handling logic here, e.g., update a state or call a parent function
    setIsDropdownOpen(false);
    if(option.label === "Yes"){
      props.setClickedButtonCoding(true)
    }else{
      props.setClickedButtonCoding(false)
    }
  };

  // const getCampType = ['Regular', 'Mass'];
  // const open =()=>{
  //   console.log("clicked")
  //     props.setToggle((prev) => ({
  //       ...prev,
  //       // jobRole: false,
  //       CampType: !props.toggle.CampType,
  //     }));
  //     console.log("data => ",props.toggle.jobRole)
  // }

  // const handleToggleDropdown = () => {
  //   open();
  // };
  //  const close = () => {
  //    props.setToggle((prev) => ({
  //      ...prev,
  //      jobRole: false,
  //      CampType: false,
  //    }));
  //  };

  // // const handleOption = (value) => {
  // //   props?.setCampTypeValue(value);
  // //   if (value === 'Regular'){
  // //     props.setCampType(1);
  // //   }
  // //   else{
  // //     props.setCampType(2);
  // //   }
  // //   console.log(value)
  // //   close();
  // // };

  // const handleKeyDown = (e) => {
  //   if (e.key === 'Tab') {
  //     close();
  //   }
  // };

  const options = [
    { label: "Yes", value: 1 },
    { label: "No", value: 2 },
  ];

  return (
    <>
      <div
        className="relative "
        onKeyDown={(e) => e.key === "Escape" && setIsDropdownOpen(false)}
      >
        <button
          id="dropdownDefaultButton"
          data-dropdown-toggle="dropdown"
          className="w-[100%] h-[50px] mt-[10px] justify-between border-[1px] border-[#22284E] bg-[#FFFFFF] focus:outline-none  rounded-[7.03px]  p-[0.42rem] text-center inline-flex items-center font-sans1 font-[500] text-[#1B3351] text-[0.9rem]"
          type="button"
          onClick={handleToggleDropdown}
        >
          {/* {props?.linkTypeString || "Yes"} */}
          {props?.clickedButtonCoding ? "Yes" : "No"}
          <svg
            className={`w-2.5 h-2.5 ms-3 transition-transform ${
              isDropdownOpen ? "rotate-180" : "rotate-0"
            }`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>
        <div
          id="dropdown"
          className={`${
            isDropdownOpen ? "block" : "hidden"
          } absolute left-0 mt-2 fill-available z-50 bg-[#FFFFFF] divide-y font-sans1 font-[500] text-[#1B3351] text-[0.9rem] rounded-[7.03px] shadow  overflow-auto`}
        >
          <ul
            className="py-2 cursor-pointer"
            aria-labelledby="dropdownDefaultButton"
          >
            {options.map((option, index) => (
              <li
                key={index}
                className="flex justify-between px-4 py-2 hover:bg-gray-100"
                onClick={() => handleOption(option)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export const DropdownFilter = ({ options, onOptionClick}) => {

  return (
    <div className="z-[10000] relative inline-block text-left">
        <div className="origin-top-right rounded-[10px] absolute right-0 mt-2 w-[180px] top-2 shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {options.map((option) => (
              <a
                key={option}
                className="font-poppins py-2 text-[14px] block px-4 text-[#718096] hover:bg-gray-100 hover:text-gray-900 border-b border-[#EFF5FF]"
                role="menuitem"
                onClick={() => onOptionClick(option)}
              >
                {option}
              </a>
            ))}
          </div>
        </div>
    </div>
  );
};

export const DropdownFilterMember = ({ options, onOptionClick}) => {

  return (
    <div className="z-[10000] relative inline-block text-left">
        <div className="origin-top-right rounded-[10px] absolute right-0 mt-2 w-[180px] top-2 shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1 cursor-pointer" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {options.map((option) => (
              <a
                key={option}
                className="font-poppins text-[14px] block px-4 text-[#718096] hover:bg-gray-100 hover:text-gray-900 border-b border-[#EFF5FF]"
                role="menuitem"
                onClick={() => onOptionClick(option)}
              >
                {option}
              </a>
            ))}
          </div>
        </div>
    </div>
  );
};
