import {
  builderButton,
  campaignbg,
  candidateButton,
  clevercruit,
  clevercruitlogo,
  detailsButton,
} from '../assets/imgs';
import { Loader } from '../components/loader';
import { CampaignHeader } from '../components/AdminComponents/campaignHeader';
import { Outlet } from 'react-router-dom';
import { CampaignButton } from '../components/buttons';
import React from 'react';
import { createContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const CampaignContext = createContext();

export const CampaignLayout = () => {
  let statuData = ['campdetails', 'questionBuilder', 'addCandidate'];

  const userStates = {
    campdetails: false,
    questionBuilder: false,
    addCandidate: false,
  };
  // userStates[statuData[currentAddCampaign]]=true

  const [isDivVisible, setDivVisible] = useState(userStates);
  const navigate = useNavigate();

  // Get the currentAddCampaign value from localStorage

  const toggleDivVisibility = (e) => {
    let name = e.currentTarget.name;
    // setDivVisible((prevVisible) => !prevVisible);
    setDivVisible({ ...userStates, [name]: !isDivVisible[name] });
  };

  // =========== TOGGLE PART ===========

  // Step 1: Initialize state from sessionStorage
  const [currentAddCampaign, setCurrentAddCampaign] = useState(() => {
    return parseInt(sessionStorage.getItem('currentAddCampaign'), 10) || 0;
  });

  const [hideCandidateToggle, sethideCandidateToggle] = useState(true);

  // Function to increment count
  const currentCampaign = (current = 0) => {
    setCurrentAddCampaign(current);
  };

  return (
    <div
      className=""
      style={{
        backgroundImage: `url(${campaignbg})`,
        backgroundSize: 'cover, cover', // Adjust sizes if necessary
        backgroundPosition: 'center, center', // Adjust positions if necessary
        backgroundRepeat: 'no-repeat, no-repeat', // Ensure images don't repeat
      }}
    >
      <div className="flex h-sm:h-full h-md2:h-[100vh] px-5 py-0">
        {/* Sidebar */}
        <aside
          className={`hidden lg:block max-lg:w-0 text-white p-4  lg:w-[25rem] transition-transform duration-300 transform -translate-x-full lg:translate-x-0`}
        >
          <img
            src={clevercruitlogo}
            className="w-[160px] h-[50px] ml-5 cursor-pointer"
            alt="Clevercraft Logo"
            onClick={() => {
              navigate('/admin/dashboard');
              window.location.reload();
            }}
          />

          <div className="flex-grow  flex flex-col gap-[10px]  mx-1  ml-[20px] mt-[13%] mb-6">
            <div className="relative w-full">
              <LayoutBtn text="Campaign Details" number={'01'} />

              <div
                className={`relative md:mt-[-23%] lg:mt-[-19%] left-0 w-full top-[-18%] bg-white p-4 pt-16 shadow-md rounded-[23.37px] transition-all duration-300 overflow-hidden z-10 ${
                  isDivVisible.campdetails || currentAddCampaign == 0
                    ? 'opacity-100 max-h-[430px] top-[27%]'
                    : 'opacity-0 max-h-0 top-[124px]'
                }`}
              >
                <p className={`text-[14px] font-satoshi text-[#667394]  `}>
                  Configure your interview campaign's core elements. Specify job
                  roles, required skills, and campaign duration. Choose campaign
                  type, set start and end dates, and decide on including coding
                  questions. This foundational step ensures your AI-powered
                  interviews align perfectly with your hiring objectives.
                </p>
              </div>
            </div>

            <div
              className={`relative  w-full mt-5 ${
                isDivVisible.campdetails || currentAddCampaign == 0 ? '' : ''
              }`}
            >
              <LayoutBtn text="Question Builder" number={'02'} />

              <div
                className={`relative md:mt-[-23%] lg:mt-[-19%] left-0 w-full  bg-white p-4 pt-16 shadow-md rounded-[23.37px] transition-all duration-300 overflow-hidden z-10 ${
                  isDivVisible.questionBuilder || currentAddCampaign == 1
                    ? 'opacity-100 max-h-[430px] top-[27%]'
                    : 'opacity-0 max-h-0 top-[124px]'
                }`}
              >
                <p className="text-[14px] font-satoshi text-[#667394]">
                  Design your interview question set using three flexible
                  options: AIgenerated questions for eficiency, AI-generated +
                  User-crafted for a mix of AI and human touch, or User-crafted
                  questions for complete customization. Set questions as
                  mandatory or optional, and easily add, edit, or remove
                  questions to create the ideal assessment.
                </p>
              </div>
            </div>

            <div
              className={`relative w-full mt-5 ${
                isDivVisible.questionBuilder || currentAddCampaign == 1
                  ? ''
                  : ''
              } ${!hideCandidateToggle ? 'hidden' : ''}`}
            >
              <LayoutBtn text="Add Candidate" number={'03'} />
              <div
                className={`relative md:mt-[-23%] lg:mt-[-19%] left-0 w-full  bg-white p-4 pt-16 shadow-md rounded-[23.37px] transition-all duration-300 overflow-hidden z-10 ${
                  isDivVisible.addCandidate || currentAddCampaign == 2
                    ? 'opacity-100 max-h-[430px] top-[27%]'
                    : 'opacity-0 max-h-0 top-[124px]'
                }`}
              >
                <p className="text-[14px] font-satoshi text-[#667394]">
                  Streamline candidate management with multiple options. Bulk
                  upload candidates via CSV file for large-scale hiring, or add
                  candidates individually for more focused recruitment. Review
                  and edit candidate details, and manage your candidate list
                  efortlessly within the platform.
                </p>
              </div>
            </div>
          </div>
        </aside>

        {/* Main Container */}
        <main className="flex-grow p-4">
          <div className="max-w-5xl md:ml-2 max-lg:ml-8">
            <div className="fill-available">
              <CampaignContext.Provider
                value={{ currentCampaign, sethideCandidateToggle }}
              >
                <Outlet />
              </CampaignContext.Provider>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export const LayoutBtn = (props) => {
  return (
    <div className="absolute z-[100] text-[#1B3351] w-full bg-white rounded-[410px] p-[3.5px] h-[91.5px] relative z-20">
      <div
        className="border-l-[1px] p-[10px] border-[#D9D9D9]  rounded-[410px] h-full w-full"
        style={{
          boxShadow: 'inset 0px 2.34px 7.01px rgba(0, 0, 0, 0.25)',
        }}
      >
        <div className="border-[#0875F4] border-[12px] bg-white w-full h-full rounded-[410px] flex justify-center items-center font-satoshi font-[1000] relative italic text-[21.69px]">
          {props.text}
          <div className="absolute shadow-2xl text-white font-satoshi font-[900] italic text-[18px] pl-[3%] flex  items-center w-[50px] h-[50px] border-[5px] border-white bg-[#1B3351] rounded-full left-[-4px]">
            {props.number}
          </div>
        </div>
      </div>
    </div>
  );
};

//   return (
//     <header
//       className="h-[100vh] w-full flex"
//       style={{
//         backgroundImage: `url(${campaignbg})`,
//         backgroundSize: "cover, cover", // Adjust sizes if necessary
//         backgroundPosition: "center, center", // Adjust positions if necessary
//         backgroundRepeat: "no-repeat, no-repeat", // Ensure images don't repeat
//       }}
//     >
//        <div className="custom-lg:max-w-[500px] custom-md1:flex hidden flex-col min-h-screen ">

//       <img src={clevercruitlogo} className="w-[108px] h-[16px] m-6" alt="Clevercraft Logo" />
//       <div className="flex-grow h-[602px] custom-lg:mt-[60%] flex flex-col gap-[21px] mx-6  ml-[20px] mt-[13%] mb-6">
//         <div className="relative">
//           <img
//             src={detailsButton}
//             className=" h-[87px] cursor-pointer relative z-20"
//             alt="Details Button"
//             name="campdetails"
//             onClick={toggleDivVisibility}
//           />
//           <div
//             className={`absolute left-0 w-full max-w-[430px] bg-white p-4 pt-16 shadow-md rounded-[23.37px] transition-all duration-300 overflow-hidden z-10 ${
//               isDivVisible.campdetails
//                 ? "opacity-100 max-h-[430px] top-[27%]"
//                 : "opacity-0 max-h-0 top-[124px]"
//             }`}
//           >
//             <p className="text-[14px] font-satoshi text-[#667394]">
//               Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder.
//             </p>
//           </div>
//         </div>

//         <div className={`relative ${isDivVisible.campdetails?"mt-[167px]":''}` }>
//           <img
//             src={builderButton}
//             className="w-full h-[87px] cursor-pointer relative z-20"
//             alt="Details Button"
//             onClick={toggleDivVisibility}
//             name="questionBuilder"

//           />
//           <div
//             className={`absolute left-0 w-full max-w-[430px] bg-white p-4 pt-16 shadow-md rounded-[23.37px] transition-all duration-300 overflow-hidden z-10 ${
//               isDivVisible.questionBuilder
//                 ? "opacity-100 max-h-[430px] top-[27%]"
//                 : "opacity-0 max-h-0 top-[124px]"
//             }`}
//           >
//             <p className="text-[14px] font-satoshi text-[#667394]">
//               Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder.
//             </p>
//           </div>
//         </div>

//         <div className={`relative ${isDivVisible.questionBuilder?"mt-[167px]":''}` }>
//           <img
//             src={candidateButton}
//             className="w-full h-[87px] cursor-pointer relative z-20"
//             alt="Details Button"
//             onClick={toggleDivVisibility}
//             name="addCandidate"
//           />
//           <div
//             className={`absolute left-0 w-full max-w-[430px] bg-white p-4 pt-16 shadow-md rounded-[23.37px] transition-all duration-300 overflow-hidden z-10 ${
//               isDivVisible.addCandidate
//                 ? "opacity-100 max-h-[430px] top-[27%]"
//                 : "opacity-0 max-h-0 top-[124px]"
//             }`}
//           >
//             <p className="text-[14px] font-satoshi text-[#667394]">
//               Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder.
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//       <div className="flex flex-col justify-center items-center fill-available custom-md2:p-0 p-8">
//         <div className="fill-available max-w-[826px] mr-4">
//           <Outlet />
//         </div>
//       </div>
//     </header>
//   );
// };
