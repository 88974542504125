import React, { useRef, useState, useEffect } from "react";
import {
  cameraIcon,
  closeButton,
  profileImage,
  uploadSettings,
} from "../../assets/imgs/index";
import { validateName } from "../../utils/validation";
import { useDispatch } from "react-redux";
import { InputBox } from "../pages/FillFormPage";
// import { addProfileRequest,addProfileSuccess,addProfileFailure ,fetchDataRequest,fetchDataSuccess,fetchDataFailure} from '../../Redux/Profile/actions.js';
import {
  handleFormDataApiResponse,
  handleApiResponse,
} from "../../API/services.js";
import { toast } from "react-toastify";
import { getLocal } from "../../utils/localStorage.js";
import { CustomButtonWhite } from "../../components/buttons.js";
import { useNavigate } from "react-router-dom";
function ProfilePage() {
  const [firstError, setFirstError] = useState(false);
  const [secondError, setSecondError] = useState(false);
  const [thirdError, setThirdError] = useState(false);
  const [imageUrl, setImageUrl] = useState(false);
  const fileInputRef = useRef(null);
  const [currentPassword, setCurrentPassword] = useState("");
  const [secondPassword, setSecondPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [nameError, setNameError] = useState("");
  const [departmentError, setDepartmentError] = useState("");
  const [number, setNumber] = useState("");
  const [emptyError, setEmptyError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const [userId, setUserId] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [organization, setOrganization] = useState("");
  const [department, setDepartment] = useState("");
  const [lastName, setLastName] = useState("");
  const [previousImageUrl, setPreviousImageUrl] = useState("");
  const [reset, setReset] = useState(false);
  const [userDetails, setUserDetials] = useState(true);
  const [editProfileChange, setEditProfileChange] = useState(false);
  const uid = sessionStorage.getItem("uid");

  const navigate = useNavigate();
  const base_url = process.env.REACT_APP_URL;
  console.log("--==--", imageUrl);

  console.log(currentPassword);
  console.log(newPassword);
  console.log(secondPassword);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await handleApiResponse("/api/v1/get_profile/", {}, 1);
        if (response?.code === "token_not_valid") {
          navigate("/");
          sessionStorage.clear();
          return;
        }
        console.log("API Response:", response?.data);
        if (response?.data?.status_code === 200) {
          console.log(reset);
          if (reset) {
            setFirstName("");
            setLastName("");
            setNumber("");
            setOrganization("");
            setDepartment("");
          } else {
            setFirstName(response?.data?.data?.first_name);
            setLastName(response?.data?.data?.last_name);
            setEmail(response?.data?.data?.email);
            setNumber(response?.data?.data?.mobile);
            setOrganization(response?.data?.data?.organization);
            setDepartment(response?.data?.data?.designation);
          }
          console.log(response?.data?.data?.profile_img);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [dispatch, previousImageUrl, firstName]);

  console.log(department);

  const handleDepartmentChange = (e) => {
    setDepartmentError("");
    setEmptyError("");
    setDepartment(e.target.value);
  };
  const handleNumberChange = (e) => {
    setPhoneError("");
    const inputValue = e.target.value;
    const isValidNumber = /^\d{0,10}$/.test(inputValue); // Check if input consists of only digits and has a maximum length of 10

    if (isValidNumber) {
      setEmptyError("");
      setNumber(inputValue);
    }
  };

  const handleSecondPasswordChange = (value) => {
    setSecondPassword(value);
  };

  const handleCurrentPasswordChange = (value) => {
    setCurrentPassword(value);
  };

  const handleNewPasswordChange = (value) => {
    setNewPassword(value);
  };

  const handleUploadClick = () => {
    setEditProfileChange(true);
    fileInputRef.current.click();
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    try {
      if (file) {
        const imageUrl = URL.createObjectURL(file);
        setImageUrl([imageUrl, file]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = async (e) => {
    console.log(
      currentPassword !== "" && newPassword !== "" && secondPassword !== ""
    );
    if (currentPassword !== "" && newPassword !== "" && secondPassword !== "") {
      console.log("going here");
      e.preventDefault();
      try {
        const formData = new FormData();
        formData.append("curr_password", currentPassword);
        formData.append("password", newPassword);
        formData.append("cpassword", secondPassword);
        const response = await handleFormDataApiResponse(
          "/api/v1/reset_password/",
          formData,
          1
        );
        console.log("API Response:", response?.data);
        if (response.data.status_code === 200) {
          toast.success("Password Updated",{
            className: "custom-toast-success"
          });
          navigate("/admin/dashboard");
        } else {
          toast.error(response?.data?.msg,{
            className: "custom-toast-error"
          });
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      e.preventDefault();
      setEmptyError("");
      setNameError("");
      setPhoneError("");
      setDepartmentError("");
      console.log(imageUrl);
      const file = imageUrl;
      console.log(file);
      // if (file) {
      try {
        const formData = new FormData();
        console.log(imageUrl);
        if (editProfileChange) {
          formData.append("profile_img", imageUrl[1]);
        }
        // formData.append("profile_img", imageUrl[1]);
        formData.append("designation", department);
        formData.append("mobile", number);
        console.log(uid);
        formData.append("uid", uid);
        const response = await handleFormDataApiResponse(
          "/api/v1/updateuser/",
          formData,
          1
        );
        console.log("API Response:", response?.data);

        if (response?.data.status_code === 200) {
          sessionStorage.setItem("designation", department);
          toast.success("Updated successfully.", {
            className: "custom-toast-success"
          });
          navigate(-1);
          sessionStorage.setItem(
            "profileImage",
            `${response?.data?.data?.profile_img.split("?")[0]}`
          );
          return 1;
        }
      } catch (error) {
        console.log(error);
      }
    }
    // }
    //  else {
    //   toast.error('Selected format for file is not correct.');
    //   console.error('No file selected.');
    // }
  };

  //
  const last_name = getLocal("last_name");
  const first_name = getLocal("first_name");

  return (
    <div className="h-screen text-[#00112D] p-12 gap-16 flex flex-col justify-center bg-[#E4E7EB]">
      <section className="flex justify-center  overflow-y-auto">
        <div className="z-3 flex flex-col w-[70%] h-fit justify-center mb-[1.323vw] mt-[1.323vw] ml-[2.646vw] bg-white z-5  rounded-[1.058vw] bg-gradient-to-r from-opacity-white to-white shadow-md p-8">
          <div className="flex justify-between">
            <div className=" font-[manrope] text-[#00112D] font-semibold text-[24px]">
              Account Setting
            </div>
            <div className="bg-[#F8FBFF] rounded-full w-[40px] h-[40px]">
              <img
                src={closeButton}
                className="cursor-pointer w-[40px] h-[40px]"
                alt="close button"
                onClick={() => {
                  navigate('/admin/dashboard');
                }}
              />
            </div>
          </div>
          <div className="mb-[0.794vw] font-satoshi text-[#00112D] text-[14px] font-normal">
            Manage your personal information and security settings
          </div>
          <div className="fill-available font-medium mb-8 flex justify-end gap-4 border-b-[1px] border-[#E8E8E8] ">
            <button
              className={`pb-3 w-[30%] border-b-2 font-satoshi text-4 font-medium font-[#22284E] ${
                userDetails ? 'border-[#22284E]' : 'border-white'
              }  `}
              onClick={() => {
                setUserDetials(true);
              }}
            >
              User Profile
            </button>
            <button
              className={`pb-3 w-[30%] border-b-2 font-satoshi text-4 font-medium font-[#22284E] ${
                !userDetails ? 'border-[#22284E]' : 'border-white'
              }  `}
              onClick={() => {
                setUserDetials(false);
              }}
            >
              User Password
            </button>
          </div>
          {/* <hr className="mb-[1.058vw] mx-[-2rem] " /> */}
          <div className="flex gap-4 p-4  bg-[#F8FBFF] rounded-[10px]">
            <section className="flex flex-col gap-4 justify-start items-center w-[57%] ">
              <div
                className=" relative w-32 h-32  rounded-full bg-[#E9F2FF] border-4  border-[#EFF5FF] shadow flex items-center  justify-center"
                onClick={handleUploadClick}
              >
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                  style={{ display: 'none' }}
                />
                <img
                  src={
                    imageUrl
                      ? imageUrl[0]
                      : sessionStorage.getItem('profileImage')
                      ? sessionStorage.getItem('profileImage')
                      : profileImage
                  }
                  alt=""
                  className=" h-[7.5rem] rounded-full"
                />
                <img
                  src={cameraIcon}
                  className="absolute bottom-[-3px] cursor-pointer right-[-13px]"
                />
              </div>
              <div className="flex flex-col items-center justify-center">
                <p className="font-bold font-satoshi font-medium text-[18px]">
                  {first_name} {last_name}
                </p>
                <p className="font-satoshi font-normal text-[13px]">
                  {department}
                </p>{' '}
                {/* this is designation not department pls don't change to designation unless fixing everywhere */}
              </div>
            </section>
            <section className="fill-available">
              {userDetails && (
                <section className="font-[manrope] font-normal text-[14px] text-[#1B3351]">
                  <div className="gap-[20px] grid grid-cols-2 grid-rows-2">
                      <div>
                        <div className="mb-[0.529vw] ">
                          First Name <span className="text-red-500">*</span>
                        </div>
                        <input
                          type="text"
                          placeholder="Please enter the first name"
                          className="cursor-not-allowed outline-0 p-[0.661vw] input-field fill-available h-[45px] text-[#22284E] bg-[#FAFBFC] rounded-[0.529vw] border-[1px] border-solid border-[#22284E]"
                          required
                          value={firstName}
                          readOnly
                        />
                      </div>

                      <div>
                        <div className="mb-[0.529vw] ">
                          Last Name <span className="text-red-500">*</span>
                        </div>
                        <input
                          type="text"
                          placeholder="Please enter the first name"
                          className="cursor-not-allowed outline-0 p-[0.661vw] input-field fill-available h-[45px] text-[#22284E] bg-[#FAFBFC] rounded-[0.529vw] border-[1px] border-solid border-[#22284E]"
                          required
                          value={lastName}
                          readOnly
                        />
                      </div>

                      <div>
                        <div className="mb-[0.529vw]  ">
                          Email ID <span className="text-red-500">*</span>
                        </div>
                        <input
                          type="email"
                          placeholder="Email Id"
                          className="cursor-not-allowed outline-0 p-[0.661vw] input-field fill-available h-[45px] text-[#22284E] bg-[#FAFBFC] rounded-[0.529vw] border-[1px] border-solid border-[#22284E]"
                          required
                          value={email}
                          readOnly
                        />
                      </div>

                      <div>
                        <div className="mb-[0.529vw]  ">
                          Mobile number <span className="text-red-500">*</span>
                        </div>

                        <input
                          type="tel"
                          placeholder="Please enter your phone number"
                          className=" outline-0 p-[0.661vw] input-field fill-available h-[45px] text-[#22284E] bg-[#FAFBFC] rounded-[0.529vw] border-[1px] border-solid border-[#22284E]"
                          required
                          value={number}
                          onChange={(e) => {
                            handleNumberChange(e);
                          }}
                        />
                        <div style={{ color: 'red' }}>{phoneError}</div>
                      </div>

                      <div>
                        <div className="mb-[0.529vw]  outline-0 font-medium">
                          Job Title<span className="text-red-500">*</span>
                        </div>
                        <input
                          type="text"
                          placeholder="Job Title"
                          className="cursor-not-allowed outline-0 p-[0.661vw] input-field fill-available h-[45px] text-[#22284E] bg-[#FAFBFC] rounded-[0.529vw] border-[1px] border-solid border-[#22284E]"
                          required
                          value={department}
                          readOnly
                        />
                        <div style={{ color: 'red' }}>{departmentError}</div>
                      </div>
                      <div>
                        <div className="mb-[0.529vw]  outline-0">
                          Organization Name{' '}
                          <span className="text-red-500">*</span>
                        </div>
                        <input
                          type="text"
                          placeholder="Enter organization name"
                          className="cursor-not-allowed p-[0.661vw] input-field fill-available h-[45px] text-[#22284E] bg-[#FAFBFC] rounded-[0.529vw] border-[1px] border-solid border-[#22284E]"
                          required
                          value={organization}
                          readOnly
                        />
                      </div>
                  </div>
                </section>
              )}
              {!userDetails && (
                <section className="font-[manrope] font-normal text-[14px] text-[#1B3351]">
                  <div
                    style={{
                      width: 'calc( 50% - 0.79rem)',
                    }}
                  >
                    <div className="mb-[0.529vw]">
                      Current Password <span className="text-red-500">*</span>
                    </div>

                    <input
                      placeholder="Please enter your current password"
                      className="outline-0 p-[0.661vw] input-field fill-available h-[45px] text-[#22284E] bg-[#FAFBFC] rounded-[0.529vw] border-[1px] border-solid border-[#22284E]"
                      onChange={(e) => {
                        handleCurrentPasswordChange(e.target.value);
                      }}
                      value={currentPassword}
                    />
                    {firstError && (
                      <div
                        style={{
                          color: 'red',
                          marginBottom: '10px',
                          textAlign: 'left',
                        }}
                      >
                        This field is required.
                      </div>
                    )}
                  </div>
                  <div className="gap-[20px] mt-[1.45rem] flex">
                    <div className="w-[50%]">
                      <div className="mb-[0.529vw] outline-0">
                        New Password <span className="text-red-500">*</span>
                      </div>
                      <input
                        type="text"
                        placeholder="Please enter the new password"
                        className="p-[0.661vw] input-field fill-available h-[45px] text-[#22284E] bg-[#FAFBFC] rounded-[0.529vw] border-[1px] border-solid border-[#22284E]"
                        onChange={(e) => {
                          handleNewPasswordChange(e.target.value);
                        }}
                        value={newPassword}
                      />
                      {secondError && (
                        <div
                          style={{
                            color: 'red',
                            marginBottom: '10px',
                            textAlign: 'left',
                          }}
                        >
                          This field is required.
                        </div>
                      )}
                    </div>
                    <div className=" w-[50%]">
                      <div className="mb-[0.529vw] outline-0 ">
                        Confirm New Password{' '}
                        <span className="text-red-500">*</span>
                      </div>
                      <input
                        type="text"
                        placeholder="Please re-enter the new password"
                        className="outline-0 p-[0.661vw] input-field fill-available h-[45px] text-[#22284E] bg-[#FAFBFC] rounded-[0.529vw] border-[1px] border-solid border-[#22284E]"
                        onChange={(e) => {
                          handleSecondPasswordChange(e.target.value);
                        }}
                        value={secondPassword}
                      />
                      {thirdError && (
                        <div
                          style={{
                            color: 'red',
                            marginBottom: '10px',
                            textAlign: 'left',
                          }}
                        >
                          This field is required.
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              )}

              <div className="gap-[20px] grid grid-cols-2 grid-rows-2 mt-8">
                <button
                  className={`w-full font-satoshi text-[18px] justify-end text-white h-[45px] rounded-lg bg-[#22284E]`}
                  onClick={handleSubmit}
                >
                  Save
                </button>
                {/* <CustomButtonWhite text={'Save'} onClick={handleSubmit} /> */}
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ProfilePage;
