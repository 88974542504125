import React, { useState } from 'react';
import { group1, clevercruit } from '../assets/imgs';
import { Outlet } from 'react-router-dom';
import { getLocal } from '../utils/localStorage';

const BackgroundLayout = () => {
  const fname = getLocal('candFName');
  const lname = getLocal('candLName');

  return (
    <div className="h-screen items-center">
      <div className=" flex flex-col h-screen text-white flex justify-center items-center">
       
        <div
          className=" w-[98vw] h-[96vh] overflow-hidden "
          style={{
            border: '1px solid',
            borderImageSource:
              'linear-gradient(170deg, rgba(0, 77, 244, 0) 13.7%, rgba(0, 77, 244, 0.4) 27.55%, #004DF4 36.4%, #26DDFF 53.88%, rgba(38, 221, 255, 0) 89.18%)',
            borderImageSlice: 1,
          }}
        >
          <div className="flex justify-around gap-4 min-h-[4.5rem]">
            <div className="w-full  flex items-center justify-between p-[12px]  border-x-[1.125rem] border-t-[1.125rem] border-[#286497] border-opacity-[27%] ">
              <div className="bg-[#11367D]  w-full pt-[1px] rounded-[8px]">
                <div
                  className="  shadow-2xl p-[13px] w-full rounded-t-[9px] rounded-b-[8px]  flex felx-col fill-available justify-between items-center relative"
                  style={{
                    background:
                      'linear-gradient(to bottom, #0A1D41 16%, #0C2F52 93%)',
                  }}
                >
                 
                  <img
                    className="pl-2 h-[19.5] w-[154px]"
                    src={clevercruit}
                    alt="clevercruit logo"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="  backdrop-blur bg-[rgb(40,100,151,0.27)] h-available flex flex-col items-center justify-start  rounded-b-[24px] overflow-auto ">
            <Outlet />
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default BackgroundLayout;
