import { Routes, Route } from 'react-router-dom';
import { Layout, SubLayout1 } from '../layouts/MainLayout';
import { Signup } from '../features/signup/Signup';
import {
  Certificate,
  LandingPage,
  StartPage,
} from '../features/pages/LandingPage';
import { FillForm } from '../features/pages/FillFormPage';
import { Evaluation } from '../features/pages/Evaluation';
import { Interview } from '../features/pages/Interview';
// import { Login } from '../features/login/Login';
import ProtectedRoute from './ProtectedRoute';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { clearLocal } from '../utils/localStorage';
import ErrorPage from '../features/AdminPages/404Page'; // Import ErrorPage
import BackgroundLayout from '../layouts/BackgroundLayout';
import Image from '../features/pages/Image';
import { LinkExpired } from '../layouts/LinkExpired';

export const UserRoute = () => {

  // const handleVisibilityChange = () => {
  //   console.log("document.hidden",document.hidden)
  // };
  // useEffect(() => {
  //   document.addEventListener("visibilitychange", handleVisibilityChange);
  //   // Clean up the event listener when the component is unmounted
  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, []);
  // const location = useLocation();
  // useEffect(() => {
  //   if (['/login', '/signup', '/'].includes(location.pathname)) {
  //     clearLocal();
  //   }
  // }, [location.pathname]);
  console.log(sessionStorage.getItem('allowed')==="false")

  return (
    <Routes>
    {sessionStorage.getItem('allowed')!=="false" ? (
      <Route exact path="/interview/candidate/:intLink" element={<Layout />}>
        <Route
          exact
          path="/interview/candidate/:intLink"
          element={<BackgroundLayout />}
        >
          <Route exact index element={<LandingPage />} />
          <Route exact path="start-interview" element={<StartPage />} />
        </Route>
        <Route exact path="details" element={<FillForm />} />
        <Route exact path="interview" element={<Interview />} />
        <Route exact path="image" element={<Image />} />
        <Route path="*" element={<ErrorPage />} />
        <Route exact path="certificate" element={<Certificate />} />
      </Route>
    ) : (
      <Route exact path="/interview/candidate/:intLink" element={<LinkExpired />}>
        </Route>
    )}
  
    {/* Other routes can be added here */}
  </Routes>
  
  );
};
