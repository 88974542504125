import { useState, useEffect } from 'react';
import { arrowIcon, userImg } from '../../assets/imgs';
import { AnalyticsPageBtn } from '../../components/buttons';
import {
  DurationCard,
  PersonalCard,
  PersonalCard1,
  ScoreCard,
} from '../../components/card';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { handleApiResponse } from '../../API/services';
import { toast } from 'react-toastify';
import { Loader } from '../../components/loader';
import { useSelector } from 'react-redux';
import jsPDF from 'jspdf';

export const Evaluation1 = () => {
  const navigate = useNavigate();
  const [isLeftOption, setIsLeftOption] = useState(true);
  const location = useLocation();
  const status = location?.state?.status || 'Completed';
  const [overall_score, setOverall_score] = useState('');
  const [suspicious, setSuspicious] = useState('');
  const [comprehensiveAbility, setComprehensiveAbility] = useState('');
  const [fluency, setFluency] = useState('');
  const [relevant_skills, setRelevantSkills] = useState('');
  const [description, setDescription] = useState('');
  const [stability, setStability] = useState('');
  const [teamColab, setTeamColab] = useState('');
  const [languageCommand, setLanguageCommand] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const searchParams = new URLSearchParams(location.search);
  const uuid = searchParams.get('uid');
  const startDate = new Date(start);
  const endDate = new Date(end);
  const queryParams = new URLSearchParams(location.search);
  const [isLoader, setLoader] = useState(true);
  const [messages, setMessages] = useState([]);
  // console.log(evalData);
  console.log(start);
  console.log(end);
  console.log(uuid);
  const timeDifferenceInMillis = endDate - startDate;
  // console.log(messages)
  // Convert milliseconds to minutes and seconds
  const minutes = Math.floor(timeDifferenceInMillis / (1000 * 60));
  const seconds = Math.round((timeDifferenceInMillis % (1000 * 60)) / 1000);
  const candPhoto = queryParams.get('candPhoto');
  const candName = queryParams.get('candName');
  const candCompany = queryParams.get('candCompany');
  const candJobRole = queryParams.get('candJobRole');
  const fullPhotoURL = `${candPhoto}`;

  console.log(fullPhotoURL);
  console.log(minutes);
  console.log(seconds);
  console.log(`Time difference: ${minutes} minutes and ${seconds} seconds`);

  const downloadPDF = (messages) => {
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10;
    const maxLineWidth = pageWidth - margin * 2;
    const lineHeight = 10;
    let y = 10;
    let sender = 'Candidate';

    console.log(messages);

    messages.forEach((message) => {
      y += 5;

      // Toggle sender between "AI" and "Interviewer" for each message
      sender = sender === 'AI' ? 'Candidate' : 'AI';
      const text = `${sender}: ${message.msg}`;

      // Split text to fit within the page width
      const lines = doc.splitTextToSize(text, maxLineWidth);

      lines.forEach((line) => {
        // Check if adding this line will exceed the page height
        if (y + lineHeight > pageHeight - margin) {
          doc.addPage();
          y = margin; // Reset y position for the new page
        }
        doc.text(line, margin, y);
        y += lineHeight; // Move y position for the next line
      });

      y += 5; // Add some space between messages
    });

    doc.save('chat_transcript.pdf');
  };

  const handleDownloadTranscript = async (e) => {
    try {
      const response = await handleApiResponse(
        '/api/v1/interview/data/get_candidate_transcript/',
        {
          candidate_uid: uuid,
        },
        1
      );
      if (response?.code === 'token_not_valid') {
        navigate('/');
        sessionStorage.clear();
        return;
      }
      console.log('API Response:', response.data);
      if (response.data.status_code === 200) {
        console.log(response.data.data);
        setMessages(response.data.data);
        downloadPDF(response.data.data);
        // dispatch(signupSuccess(response.data));
        // setLocal('adminToken', response.data.access);
        // setLocal('first_name', response.data.first_name);
        // setLocal('last_name', response.data.last_name);
        // setLocal('uid', response.data.uid);
        // setLocal('parentUid', response.data.parent);
        // setLocal(
        //   'notPermited',
        //   response?.data?.user_type === 3 ? true : false
        // );
        // if (!response?.data.is_email_verified) {
        //   setShowEmailVerification(true);
        // }
      }
    } catch (e) {}
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await handleApiResponse(
          '/api/v1/generate_performance/',
          {
            uid: uuid,
          },
          1
        );
        if (response?.code === 'token_not_valid') {
          navigate('/');
          sessionStorage.clear();
          return;
        }
        console.log(response?.data);
        if (response?.data.status_code === 200) {
          setLoader(false);
          console.log(response?.data?.data?.lang_command);
          setOverall_score(response?.data?.data?.overall_score);
          setTeamColab(response?.data?.data?.team_colab);
          setSuspicious(response?.data?.data?.suspicious);
          setComprehensiveAbility(response?.data?.data?.comprehensive_ability);
          setLanguageCommand(response?.data?.data?.lang_command);
          setRelevantSkills(response?.data?.data?.domain_experties);
          setStability(response?.data?.data?.candidate_stability);
          setStart(response?.data?.data?.interview_start);
          setEnd(response?.data?.data?.interview_end);
          setDescription(response?.data?.data?.description);
          setFluency(response?.data?.data?.fluency);
        } else if (response?.data.status_code === 400) {
          setLoader(false);
          toast.error('The interview is not completed by candidate. ',{
            className: "custom-toast-error"
          });
        } else {
          setLoader(false);
          toast.error(response?.msg,{
            className: "custom-toast-error"
          });
        }
        setLoader(false);
      } catch (error) {
        // Handle errors here
        setLoader(false);
      }
    };

    fetchData(); // Invoke the async function
  }, [overall_score, uuid]);
  console.log(overall_score);
  return (
    <main className="flex flex-col gap-[1.25rem] text-text-color-code1 fill-available mx-[1.5rem] ">
      {isLoader ? <Loader /> : ''}
      <section className="flex items-center fill-available justify-between ">
        <div className="flex">
          <img
            src={arrowIcon}
            alt="arrow icon"
            onClick={() => {
              navigate(-1);
            }}
            className="cursor-pointer"
          />
          <div className="flex flex-col">
            <p>
              {candName} Analytics
              {/* for {candCompany} (Job Role: {candJobRole} ) */}
            </p>
            {/* <p>Job Role: {candJobRole}</p> */}
          </div>
        </div>
        {/* <div className="flex gap-4">
          <AnalyticsPageBtn name={"Download"} onClick={()=>{handleDownloadTranscript()}} img={1} />
        </div> */}
      </section>
      <section className="flex justify-between fill-available gap-[1.5rem]">
        <div className="h-[42.4rem] w-[34rem]  bg-fill-color-code1 rounded-lg border-[0.05rem]">
          <div className="flex justify-left pl-[2rem] py-8 relative w-[34.5rem]">
            <img
              src={fullPhotoURL}
              alt="user img"
              className="w-[40%] h-[50%] ml-[8rem]"
            />
            <div className="p-2 px-6 rounded-3xl bg-[#F1F2F4] h-[2.2rem] text-sm absolute right-[2rem]">
              {status}
            </div>
          </div>
          <div className="mx-[1.5rem]">
            <p>Name : {candName}</p>
            <p className="mb-4">Job Role : {candJobRole}</p>
            <p className="pb-[0.5rem] text-sm font-semibold">
              Interview Summary
            </p>
            <article className="overflow-y-auto h-[21rem]">
              {description}
            </article>
          </div>
        </div>
        <div className="flex flex-col fill-available justify-between h-[42.4rem] ">
          <span className="flex justify-between">
            <ScoreCard score={overall_score} />
            <DurationCard min={minutes} sec={seconds} />
          </span>
          <span className="h-[32.2rem] bg-fill-color-code1 rounded-lg border-[0.05rem]">
            <div className="flex gap-8 p-4 px-8 border-b-[0.09rem]">
              <span className="relative">
                <p
                  className="cursor-pointer"
                  onClick={() => {
                    setIsLeftOption(true);
                  }}
                >
                  Communication Score
                </p>
                <div
                  className={`w-[10rem] left-[-2rem] bottom-[-1rem] border-[0.08rem] border-text-color-code1 absolute ${
                    isLeftOption ? 'block' : 'hidden'
                  }`}
                ></div>
              </span>
              <span className="relative">
                <p
                  className="cursor-pointer"
                  onClick={() => {
                    setIsLeftOption(false);
                  }}
                >
                  Job Readiness Score
                </p>
                <div
                  className={`w-[12rem] left-[-1rem] bottom-[-1rem] border-[0.08rem] border-text-color-code1 absolute ${
                    isLeftOption ? 'hidden' : 'block'
                  }`}
                ></div>
              </span>
            </div>
            <div
              className={`grid grid-cols-3 p-6 gap-4 fill-available ${
                isLeftOption ? 'block' : 'hidden'
              }`}
            >
              <PersonalCard
                per={Number(languageCommand).toFixed(2)}
                name={'Grammar'}
              />
              <PersonalCard per={Number(fluency).toFixed(2)} name={'Fluency'} />
              {/* <PersonalCard per={Number(comprehensiveAbility).toFixed(2)} name={"Comprehensive Ability"} /> */}
            </div>
            <div
              className={`grid grid-cols-2 p-6 gap-4  fill-available ${
                isLeftOption ? 'hidden' : 'block'
              }`}
            >
              <PersonalCard1
                per={suspicious}
                name={'Suspicious activity (Number of warnings)'}
              />
              <PersonalCard
                per={Number(relevant_skills).toFixed(2)}
                name={'Tech/Domain Skill'}
              />
              <PersonalCard
                per={Number(teamColab).toFixed(2)}
                name={'Team Work/Collaboration'}
              />
              <PersonalCard
                per={Number(stability).toFixed(2)}
                name={'Stability'}
              />
            </div>
          </span>
        </div>
      </section>
    </main>
  );
};
