import React, { useEffect, useState, useRef } from "react";
import {
  AnalyticsPageBtn,
  CustomButtonFilter,
  CustomButtonFilter1,
} from "../../components/buttons";
import { Table } from "../../components/AdminComponents/table";
import {
  arrowleft,
  arrowright,
  deleteIcon,
  editIcon,
  nodata,
  searchIcon,
  userImg,
} from "../../assets/imgs/index";
import {
  Modal,
  Modal1,
  Modal2,
  ModalAddingBulkMembers,
  ModalImportMembers,
} from "../../components/AdminComponents/modal";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
//import axios from "axios";
import { handleApiResponse } from "../../API/services";
import { toast } from "react-toastify";
import VideoPlayer from "../../utils/VideoPlayer";
import { useNavigate } from "react-router-dom";
import {
  DropdownFilter,
  DropdownFilterMember,
} from "../../components/AdminComponents/dropdown";
import PaginationComponent from "../../components/AdminComponents/pagination";

function Members() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [noPermission, setNoPermission] = useState();
  const [excelData, setExcelData] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [isSortingOpen, setIsSortingOpen] = useState(false);
  const [selectedSorting, setSelectedSorting] = useState(null);
  const [selectedSort, setSelectedSort] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const dropdownRef = useRef(null);

  console.log(selectedSort, "selectedSort");

  useEffect(() => {
    setNoPermission(sessionStorage.getItem("notPermited"));
  }, []);

  //const base_url = 'http://65.0.139.208:8000';
  const [openImport, setOpenImport] = useState(false);
  const itemsPerPage = 7;
  const [pageNo, setPageNo] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [filtertableData, setFilterTableData] = useState([]);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editData, setEditData] = useState("");
  const [openProcess, setOpenProcess] = useState(false);
  const navigate = useNavigate();
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [searchTerm, setSearchTerm] = useState(""); // Add searchTerm state
  const filteredData = filtertableData.filter((row) =>
    `${row.first_name} ${row.last_name}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const getUser = async (userLimit, pageNo) => {
    const reqData = {
      limit: userLimit,
      page: pageNo,
      is_deleted: 0,
    };
    const response = await handleApiResponse("/api/v1/userlist/", reqData, 1);
    if (response?.code === "token_not_valid") {
      navigate("/");
      sessionStorage.clear();
      return;
    }
    console.log(response);
    if (response.data.status_code === 200) {
      //console.log("user displayed", Object.values(response?.data?.data).flat())
      let dataToFilter = Object.values(response?.data?.data)?.flat()?.reverse();
      dataToFilter = dataToFilter.filter((ele) => {
        console.log(ele.user_type);
        if (ele.user_type !== 2) {
          return ele;
        }
      });
      console.log(dataToFilter);
      setTableData(dataToFilter);
      setFilterTableData(dataToFilter);
    }
  };

  useEffect(() => {
    getUser(itemsPerPage, pageNo);
  }, [
    pageNo,
    isModalOpen1,
    openDeleteConfirmation,
    openEdit,
    openImport,
    openProcess,
  ]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [uuid, setUuid] = useState("");
  const setuuid = (uuid) => {
    setUuid(uuid);
  };
  const checkUid = () => {
    if (uuid.length) {
      setOpenDeleteConfirmation(true);
      console.log("open");
    }
  };
  useEffect(() => {
    checkUid();
    console.log(uuid);
  }, [uuid]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleOptionClick = (status) => {
    setSelectedStatus(status);  
    setIsOpen(false)
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen); // Toggle the dropdown visibility
  };

  const openModal1 = () => {
    setIsModalOpen1(true);
  };
  const closeModalAdd = () => {
    setIsModalOpen1(false);
  };
  const closeModal1 = () => {
    setOpenEdit(false);
  };

  const handleSortToggle = () => {
    setIsSortOpen(!isSortOpen);
  };

  const getDataForPage = (dataSource, startIndex, endIndex) => {
    if (dataSource) {
      return dataSource.slice(startIndex, endIndex);
    }
    return [];
  };

  const handleSortOptionClick = (sortOption) => {
    setSelectedSort(sortOption);
    if (sortOption === "Active") {
      setFilterTableData(tableData.filter((item) => item.is_active));
    } else if (sortOption === "Inactive") {
      setFilterTableData(tableData.filter((item) => !item.is_active));
    } else {
      setFilterTableData(tableData);
    }
    setIsSortOpen(false);
  };

  const closeModal2 = () => {
    if (openDeleteConfirmation) {
      setOpenDeleteConfirmation(false);
      console.log("close");
    }
  };
  useEffect(() => {
    console.log("no permission ", noPermission);
  }, [noPermission]);

  const openImportPopup = () => {
    setOpenImport(true);
  };

  const closeImportMember = () => {
    setOpenImport(false);
  };

  const handleSearch = (e) => {
    setCurrentPage(1);
    setSearchTerm(e.target.value); // Update searchTerm on input change
  };

  const openProcessing = () => {
    if (excelData.length - 1 === 0) {
      closeProcessing();
      toast.warn("Empty file uploaded.",{
        className: "custom-toast-warn"
      });
    } else {
      setOpenProcess(true);
    }
  };
  const closeProcessing = () => {
    setOpenProcess(false);
    setExcelData([]);
  };

  const generateExcelFile = () => {
    const requiredHeaders = [
      "First Name",
      "Last Name",
      "Email",
      "Designation",
      "Department",
    ];

    // Create a new workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([]);

    // Append the headers
    XLSX.utils.sheet_add_aoa(ws, [requiredHeaders], { origin: "A1" });

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate Excel file and trigger download
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    saveAs(blob, "members_template.xlsx");
  };

  // Handler for the "Previous" button
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      handlePageChange(newPage);
      getUser(itemsPerPage, newPage); // Pass the new page number to getUser
    }
  };
  console.log(tableData);
  const totalPages = Math.ceil(tableData.length / itemsPerPage);
  // Handler for the "Next" button
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      handlePageChange(newPage);
      getUser(itemsPerPage, newPage); // Pass the new page number to getUser
    }
  };

  const handleClickOutside = (event) => {
    // Check if click is outside the dropdown element
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false); // Close dropdown if clicked outside
    }
  };

  useEffect(() => {
    // Add the event listener for clicks on the entire document
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <section
      className="min-w-[643px]"
      style={{
        height: "calc(96vh - 76px)",
      }}
    >
      <div className={`font-satoshi rounded-[30px] bg-[#FFFFFF]  flex flex-col h-full custom-sm:min-h-fit custom-sm-md:min-h-[10px] ${
    tableData.length === 0 ? 'fill-available-h' : ''
  }`}>
      {tableData.length > 0 ? (
        <>
        {openDeleteConfirmation && (
          <Modal2 uid={uuid} onClose={closeModal2} setUuid={setUuid} />
        )}

        {openEdit ? <Modal editData={editData} onClose={closeModal1} /> : null}

        {openImport && (
          <ModalImportMembers
            onClose={closeImportMember}
            onOpen={openProcessing}
            excelData={excelData}
            setExcelData={setExcelData}
          />
        )}
        {openProcess && (
          <ModalAddingBulkMembers
            onClose={closeProcessing}
            excelData={excelData}
            closeModal={closeImportMember}
          />
        )}

        <div className="flex justify-between p-[1rem] px-[1.8rem] max-h-[5rem]" onClick={closeModal2}>
          <div className="text-[24px] text-[#222850] font-[700]">
            <i>Member List</i>
          </div>
          {noPermission === "false" && (
            <div className="flex gap-2">
              <div className="relative">
                <input
                  placeholder="Search"
                  onChange={handleSearch}
                  className="font-poppins w-[261px] h-[36px] text-[12px] p-3 pl-8 outline-0  bg-[#E9F3FF] rounded-lg  placeholder-text-sm"
                  // value={search}
                  // onKeyDown={handleKeyDown}
                />
                <img
                  src={searchIcon}
                  alt="search icon"
                  className="absolute cursor-pointer top-[-0.4rem] left-0 w-[16px] h-[48.2px] ml-2"
                />
              </div>
              {/* <CustomButtonFilter1 text={"Filter"} onClick={toggleDropdown} /> */}

              <div className="relative">
                {/* <CustomButtonFilter1 text={"Filter"} onClick={toggleDropdown} /> */}
                {isDropdownOpen && (
                  <div className="">
                    <div
                      className="py-1 mt-2 absolute w-full h-full z-[1000]"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      <div className="rounded-lg bg-[#E8E8E8]">
                        <div className="px-2 bg-white relative z-1000">
                          <button
                            onClick={handleSortToggle}
                            className="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                          >
                            {selectedSort ? selectedSort : "Status"}
                          </button>
                          {isSortOpen && (
                            <div className="mt-2">
                              <div
                                className="py-1"
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="type-options-menu"
                              >
                                <div
                                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                                  role="menuitem"
                                  onClick={() =>
                                    handleSortOptionClick("Active")
                                  }
                                >
                                  Active
                                </div>
                                <div
                                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                                  role="menuitem"
                                  onClick={() =>
                                    handleSortOptionClick("Inactive")
                                  }
                                >
                                  Inactive
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/*
            <AnalyticsPageBtn
              name={'Download'}
              img={4}
              onClick={generateExcelFile}
            />
            <AnalyticsPageBtn
              name={'Import Member'}
              img={4}
              onClick={openImportPopup}
            />
            <AnalyticsPageBtn
              name={'Add Member'}
              img={5}
              onClick={openModal1}
            />
            <Modal1 isOpen={isModalOpen1} onClose={closeModalAdd} /> */}
            </div>
          )}
        </div>

        <div className="h-[90%] overflow-scroll">
              <div class="relative h-full flex flex-col justify-between ">
            <table class="font-inter w-full text-sm text-[#A6AEBA] text-left rtl:text-right">
              <thead class="font-[400] text-[15px] h-[54px] text-[#222850] bg-[#F7FAFC] border-b border-[#F4F5F6]">
                <tr>
                  <td scope="col" class="px-3 pl-6 py-3 text-left">
                    S.No
                  </td>
                  <td scope="col" class="px-3 py-3 text-left">
                    Full Name
                  </td>
                  <td
                    scope="col"
                    class="px-3 py-3 text-left"
                    style={{ lineHeight: "3" }}
                  >
                    Email ID
                  </td>
                  <td scope="col" class="px-3 py-3 text-left">
                    Job Title
                  </td>
                  <td scope="col" class="px-3 py-3 text-left">
                    Department
                  </td>

                  <td
                    scope="col"
                    ref={dropdownRef}
                    class="flex justify-start items-center min-w-max leading-[3] px-3 py-3 dropdown-container"
                  >
                    Member Status
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="12"
                      viewBox="0 0 23 12"
                      fill="none"
                      onClick={toggleDropdown} // Toggle the dropdown on SVG click
                      style={{ cursor: "pointer" }} // Add cursor pointer for better UX
                    >
                      <path
                        d="M7 4L11.5 8L16 4"
                        stroke="#222850"
                        strokeLinecap="round"
                      />
                    </svg>
                    {isOpen && (
                      <DropdownFilterMember
                        options={["Active", "Inactive", "All"]}
                        onOptionClick={handleOptionClick}
                      />
                    )}
                  </td>

                  {noPermission === "false" && (
                    <td scope="col" class="px-3 py-3 text-left">
                      Action
                    </td>
                  )}
                </tr>
              </thead>
              <tbody>
                {currentItems
                  ?.filter((rowData) => {
                    if (selectedStatus === "All") return true;
                    if (!selectedStatus) return true;
                    return rowData["status"] === 1
                      ? selectedStatus === "Active"
                      : selectedStatus === "Inactive";

                  })
                  .map((rowData, idx) => (
                    <Table
                      noPermission={noPermission}
                      sno={idx}
                      all={rowData}
                      name={`${rowData["first_name"]} ${rowData["last_name"]}`}
                      row12={rowData["email"]}
                      row13={rowData["designation"]}
                      row14={rowData["department"]}
                      row15={rowData["status"] === 1 ? "Active" : "Inactive"}
                      editIcon={editIcon}
                      deleteIcon={deleteIcon}
                      openModal={openModal}
                      closeModal={closeModal}
                      isModalOpen={isModalOpen}
                      campaigns={rowData["created_campaigns"]}
                      uid={rowData["uid"]}
                      setuuid={setuuid}
                      number={startIndex + idx + 1}
                      isModalOpen2={isModalOpen2}
                      setIsModalOpen2={setIsModalOpen2}
                      setOpenDeleteConfirmation={setOpenDeleteConfirmation}
                      setEditData={setEditData}
                      setOpenEdit={setOpenEdit}
                      style={{
                        backgroundColor: idx % 2 === 0 ? "#FFFFFF" : "#F9FBFD",
                      }}
                    />
                  )
                  
                  )}

                  {/* {currentItems.length ==0 && (<tr>
                      <td colSpan="100%" className="text-center py-4">
                          No member added yet
                      </td>
                  </tr>)} */}
              </tbody>
            </table>
            <div className="pagination p-[1rem] font-inter flex justify-between mt-4 h-full items-end">
              <div
                className="flex text-[#718096] text-[14px] border-[1px] border-[#D0D5DD] h-fit rounded-[3rem] pl-[0.8rem] pr-[1rem] py-2 w-[110px]  justify-center gap-2 cursor-pointer"
                onClick={goToPreviousPage}
                style={{
                  boxShadow: "0px 1px 2px 0px #1018280D",
                }}
              >
                <img
                  src={arrowleft}
                  alt="arrow left"
                  className="w-[20px] h-[20px]"
                />
                Previous
              </div>
              <PaginationComponent
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    visiblePages={3}  // Or any other number you prefer
                  />
              <div
                className="flex text-[#718096] text-[14px] border-[1px] border-[#D0D5DD] h-fit rounded-[3rem] pl-[1.8rem] pr-[0.8rem] py-2 w-[100px]  justify-center gap-2 cursor-pointer"
                onClick={goToNextPage}
                style={{
                  boxShadow: "0px 1px 2px 0px #1018280D",
                }}
              >
                Next
                <img
                  src={arrowright}
                  className="w-[20px] h-[20px]"
                  alt="arrow right"
                />
              </div>
            </div>
          </div>
        </div>
        </>
      ):(
        <div className="p-4 h-full font-[500] text-center justify-center items-center flex text-[36px] trailing-[57px] text-[#A0AEC0] flex-col gap-4">
      <img src={nodata} alt="no data" className='w-[135px] h-[135px]'/>
      No member added yet
    </div>
      )}
      </div>
    </section>
  );
}

export default Members;