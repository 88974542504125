import { InputBox, InputBox8 } from '../pages/FillFormPage';
import React, { useState, useEffect, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { handleApiResponse } from '../../API/services';
import { setLocal } from '../../utils/localStorage.js';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import openEye from '../../assets/imgs/openeye.svg';
import closedEye from '../../assets/imgs/closedEye.svg';
import {
  loginRequest,
  loginSuccess,
  loginFailure,
} from '../../redux/login/actions.js';
import OtpGenerator from '../../components/AdminComponents/OtpGenerator.js';

import {
  ModalCheckOTP,
  ModalForgetPassword,
  ModalSetPassword,
} from '../../components/AdminComponents/modal.js';
import axios from 'axios';
import {
  checkedcb,
  clevercruit,
  signinbg,
  signinvideo,
  uncheckedcb,
} from '../../assets/imgs/index.js';

export const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const initialvalues = {
    email: '',
    password: '',
  };
  const [formValues, setFormvalues] = useState(initialvalues);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const [showCheckOTP, setShowCheckOTP] = useState(false);
  const [showSetPassword, setShowSetPassword] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showEmailVerification, setShowEmailVerification] = useState(false);

  console.log(errors);

  const emailRegex = /^[^\s@]+([^\s@\.](\.[^\s@\.])?)*@[^\s@]+\.[^\s@]+$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxClick = () => {
    setIsChecked((prevChecked) => !prevChecked);
  };

  const call = async () => {
    console.log('-->>', sessionStorage.getItem('adminToken'));
    const reqData = {};
    const response = await handleApiResponse('/api/v1/get_credit/', reqData, 1);
    if (response?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    if (response.data.status_code === 200) {
      sessionStorage.setItem('total_credit', response.data.data.total_credits);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    if (value.trim() !== '') {
      setFormvalues({ ...formValues, [name]: value });
    } else {
      setFormvalues({ ...formValues, [name]: '' });
    }

    if (name === 'email') {
      validateEmail(value);
    } else if (name === 'password') {
      validatePassword(value);
    }
  };

  useEffect(() => {
    console.log('this is value', formValues);
  }, [formValues]);

  const handleBlur = (event) => {
    const { name, value } = event.target;
    setTouched({ ...touched, [name]: true });
    if (name === 'email' && value.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Email is required.',
      }));
    } else if (name === 'password' && value.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: 'Password is required.',
      }));
    }
  };

  const validateEmail = (email) => {
    if (!emailRegex.test(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Invalid email address.',
      }));
      return false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: '',
      }));
      return true;
    }
  };

  const validatePassword = (password) => {
    if (password?.length <= 4) {
      setErrors((prevError) => ({
        ...prevError,
        password: 'Password must be at least 8 characters long.',
      }));
      return false;
    } else if (!passwordRegex.test(password)) {
      setErrors((prevError) => ({
        ...prevError,
        password:
          'Password must include uppercase and lowercase letters, numbers, and symbols.',
      }));
      return false;
    } else {
      setErrors((prevError) => ({ ...prevError, password: '' }));
      return true;
    }
  };

  const handleKeyDown = (e) => {
    const key = e.key;
    if (key.match(/[^\w.@-]/)) {
      e.preventDefault();
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    // On component mount, check if there is saved user info
    const rememberedEmail = localStorage.getItem('email');
    const rememberedPassword = localStorage.getItem('password');
    const rememberedCheckbox = localStorage.getItem('rememberMe');

    if (
      rememberedEmail &&
      rememberedPassword &&
      rememberedCheckbox === 'true'
    ) {
      setFormvalues({ email: rememberedEmail, password: rememberedPassword });
      setIsChecked(true);
    }
  }, []);

  const handleLoginSubmit = async (e) => {
    console.log(formValues, 'formValues');
    const { email, password } = formValues;
    console.log('{ email, password }', { email, password });
    if (!validateEmail(email)) {
      return;
    }
    if (!validatePassword(password)) {
      return;
    }

    dispatch(
      loginRequest({
        email: email,
        password: password,
      })
    );
    console.log('{ email, password }', { email, password });
    console.log(email, password);
    try {
      console.log({
        email: email,
        password: password,
      });
      const response = await handleApiResponse('/api/v1/auth/login/', {
        email: email,
        password: password,
      });
      if (response?.code === 'token_not_valid') {
        navigate('/');
        sessionStorage.clear();
        return;
      }
      console.log('API Response:', response, response.data);
      if (response.status_code === 200) {
        dispatch(loginSuccess(response.data.user_type));
        console.log(response.data);
        setLocal('adminToken', response?.data?.access);
        setLocal('first_name', response?.data?.first_name);
        setLocal('last_name', response?.data?.last_name);
        setLocal('uid', response?.data?.uid);
        setLocal('parentUid', response?.data?.parent);
        setLocal('isResume', response?.data?.is_resume_upload_allow);
        setLocal('organization', response?.data?.organization);
        setLocal('companyId', response?.data?.id);
        setLocal('mail_share_type', response?.data?.mail_share_type);
        if (response?.data?.profile_img) {
          sessionStorage.setItem(
            'profileImage',
            `${response?.data?.profile_img.split('?')[0]}`
          );
        }

        call();
        setLocal('notPermited', response?.data?.user_type === 3 ? true : false);
        setLocal('userType', response?.data?.user_type);
        setLocal('designation', response?.data?.designation);
        setIsEmailVerified(response?.data.is_email_verified);
        console.log(response?.data);
        if (response?.data?.is_deleted) {
          toast.error('Access Revoked', {
            toastStyle: {
              borderLeft: '5px solid #f44336',
            },
          });
          return;
        }
        if (response?.data.is_email_verified) {
          toast.success('Login successful.', {
            className: 'custom-toast-success',
          });
          navigate('/admin/dashboard');
        } else {
          setShowEmailVerification(true);
        }
      } else if (response.status_code === 400) {
        dispatch(loginFailure(response.msg));
        setErrors((prevError) => ({
          ...prevError,
          password: 'Invalid credentials: email or password is incorrect.',
        }));
        // setPasswordError(
        //   "Invalid credentials: email or password is incorrect."
        // );
      }
    } catch (error) {
      dispatch(loginFailure(error));
      console.error('API Error:', error);
    }
    if (isChecked) {
      localStorage.setItem('email', email);
      localStorage.setItem('password', password);
      localStorage.setItem('rememberMe', true);
    } else {
      localStorage.removeItem('email');
      localStorage.removeItem('password');
      localStorage.setItem('rememberMe', false);
    }
  };

  useEffect(() => {
    console.log('checking');
    if (sessionStorage.getItem('adminToken')) {
      console.log('first');
      navigate('/admin/dashboard');
    }
  }, []);

  return (
    <div
      className="flex justify-center items-center min-h-fit"
      style={{
        backgroundColor: '#000D26',
        height: '100vh',
      }}
    >
      {showEmailVerification ? (
        <div className="z-50 fixed top-0 left-0 w-full h-full flex items-center justify-center backdrop-blur">
          <OtpGenerator
            email={formValues.email}
            setShowEmailVerification={setShowEmailVerification}
            setVerified={isEmailVerified}
          />
        </div>
      ) : null}
      {showForgetPassword && (
        <ModalForgetPassword
          setShowForgetPassword={setShowForgetPassword}
          setShowCheckOTP={setShowCheckOTP}
          email={formValues.email}
          setEmail={setFormvalues}
          handleChange={handleChange}
        />
      )}
      {showCheckOTP && (
        <ModalCheckOTP
          setShowCheckOTP={setShowCheckOTP}
          email={formValues.email}
          setShowSetPassword={setShowSetPassword}
        />
      )}
      {showSetPassword && (
        <ModalSetPassword
          setShowSetPassword={setShowSetPassword}
          email={formValues.email}
        />
      )}
      <div className="flex flex-col  h-screen min-h-fit">
        <div className=" ml-[32px] mt-[32px] text-white">
          <img
            src={clevercruit}
            alt="clevercruit logo"
            className="w-[126px] h-[19px]"
          />
        </div>
        <div className="w-[463px] text-center font-satoshi py-4 mx-[81px] flex flex-col custom-sm:justify-start custom-sm-md:justify-center custom-sm:h-auto custom-sm-md3:h-[98%]">
          <p className="text-white text-[36px] font-medium">
            {/* Super Admin Sign in */}
            Sign in
          </p>
          <p className="text-white text-[20px] tracking-2 mb-[32px]">
            Access your account
          </p>
          <div className="font-normal mb-[20px] ">
            <InputBox8
              head={'Email ID'}
              name={'email'}
              value={formValues.email}
              onChange={handleChange}
              holder={'Enter Email ID'}
              // onChange={handleEmailChange}
              onKeyDown={handleKeyDown}
              handleBlur={handleBlur}
            />
          </div>
          {(touched.email && errors.email && (
            <div
              style={{ color: 'red', marginBottom: '10px', textAlign: 'left' }}
            >
              {errors.email}
            </div>
          )) ||
            (errors.email && (
              <div
                style={{
                  color: 'red',
                  marginBottom: '10px',
                  textAlign: 'left',
                }}
              >
                {errors.email}
              </div>
            ))}
          <div className="w-auto flex font-normal mb-[10px] relative">
            <span className="flex flex-col w-screen">
              <div className="flex">
                <label className="text-white mb-[8px]" NamehtmlFor="">
                  Password
                </label>
                <span className="text-red-500">*</span>
              </div>
              <div className="w-full max-h-[52px] max-w-md rounded-[15px] bg-white relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter password"
                  name="password"
                  value={formValues.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleLoginSubmit();
                    }
                  }}
                  className="flex w-[394px] max-h-[52px] p-3.5 rounded-[15px] border-2 bg-[#000D26] text-white placeholder-white placeholder-opacity-50 focus:outline-none"
                />
              </div>
            </span>
            <button
              onClick={togglePasswordVisibility}
              className="mr-[10px] mt-[15px] absolute inset-y-0 right-4 h-full flex items-center px-2"
            >
              {showPassword ? (
                <img src={openEye} alt="view password" />
              ) : (
                <img src={closedEye} alt="hide password" />
              )}
            </button>
          </div>
          {(touched.password && errors.password && (
            <div
              style={{ color: 'red', marginBottom: '10px', textAlign: 'left' }}
            >
              {errors.password}
            </div>
          )) ||
            (errors.password && (
              <div
                style={{
                  color: 'red',
                  marginBottom: '10px',
                  textAlign: 'left',
                }}
              >
                {errors.password}
              </div>
            ))}
          <div className="flex justify-between pr-6">
            <div className="text-white flex items-center text-[13px]">
              {' '}
              <img
                src={isChecked ? checkedcb : uncheckedcb}
                alt="checkbox"
                className="mr-2"
                onClick={handleCheckboxClick}
              />{' '}
              Remember me
            </div>
            <div className="text-right font-medium text-[#FF4C55]">
              <span
                onClick={() => {
                  if (!errors.email) {
                    setShowForgetPassword(true);
                  }
                }}
                className="font-medium text-[12px] cursor-pointer"
              >
                Forgot Password
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between pr-4 mt-[20px]">
            <p className="justify-start flex font-normal text-white text-[16px]">
              Create an account?
              <NavLink to="admin/signup" className="font-bold ml-2">
                Sign Up
              </NavLink>
            </p>
            <button
              className="justify-end font-[500] font-satoshi text-[#FFFFFF] h-[52px] w-[156px] rounded-[16px] bg-gradient-to-r from-[#00C3FD] via-[#0194FE] to-[#018CF0]"
              onClick={() => {
                handleLoginSubmit();
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      <div className="justify-end items-end m-2 flex flex-col fill-available mr-4 relative custom-md:block hidden">
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '100vh',
            overflow: 'hidden',
          }}
        >
          <video
            autoPlay
            loop
            muted
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '100%',
              height: '95vh',
              borderRadius: '20px',
              objectFit: 'cover',
              marginRight: '10px',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <source src={signinvideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <video
          autoPlay
          loop
          muted
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '100%',
            height: '96%',
            marginBottom: '10px',
            borderRadius: '20px',
            objectFit: 'cover',
            marginRight: '10px',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <source src={signinvideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {/* <Box1 /> */}
      </div>
    </div>
  );
};
