import React, { useEffect, useRef, useState } from 'react';
import { ScoreCard } from '../../components/AdminComponents/card';
import { Tooltip } from 'react-tooltip';
import {
  Modal,
  Modal1,
  Modal2,
  ModalAddingBulkMembers,
  ModalImportMembers,
} from '../../components/AdminComponents/modal';
import {
  membersGraph,
  coloredCircle,
  circle,
  campaignGraph,
  shortLogo,
  clockSvg,
  tickIcon,
  watchIcont,
  profileImage,
  optionIcon,
  pencilIcon,
  designbg,
  orangebg,
  greenbg,
  totalWave,
  completeWave,
  ongoingWave,
  hrBack,
  tearShape,
  searchIcon,
  closeButton,
  nodata,
} from '../../assets/imgs/index';
import {
  CampaignProgressBar,
  SingleCircularProgressBar,
  TripleCircularProgressBar,
} from '../../components/AdminComponents/progressBar';
import { handleApiResponse } from '../../API/services';
import { useNavigate } from 'react-router-dom';
import { FaPencilAlt } from 'react-icons/fa';
import { CampaignTable, Table } from '../../components/AdminComponents/table';
import { CustomButtonFilter1 } from '../../components/buttons';
import {
  ModalAddJobRole,
  ModalDeleteCampaign,
} from '../../components/AdminComponents/modal';

export const Dashboard = () => {
  const user = String(sessionStorage.getItem('userType'));
  return user === '2' ? <SuperAdminDashboard /> : <AdminDashoard />;
};

const AdminDashoard = () => {
  const last_name = sessionStorage.getItem('last_name');
  const first_name = sessionStorage.getItem('first_name');
  const [openAddJobRole, setOpenAddJobRole] = useState(false);
  const [kPIData, setKPIData] = useState(null);
  const navigate = useNavigate();
  const callKPI = async () => {
    const reqData = {}; //no data required for this KPI
    const response = await handleApiResponse(
      '/api/v1/interview/data/get_dashboard_kpi/',
      reqData,
      1
    );
    if (response?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    if (response?.data.status_code === 200) {
      console.log(response.data.data);
      setKPIData(response.data.data);
    }
  };

  useEffect(() => {
    callKPI();
  }, []);

  useEffect(() => {
    sessionStorage.removeItem('uploadResume');
    sessionStorage.removeItem('excelData');
    sessionStorage.removeItem('prevEmail');
    sessionStorage.removeItem('singleAddCandidate');
    sessionStorage.removeItem('checkedFilteredData');
    sessionStorage.removeItem('hrQuestion');
    sessionStorage.removeItem('camp_type');
    sessionStorage.removeItem('coding_ques');
    sessionStorage.removeItem('endDate1');
    sessionStorage.removeItem('createdDate');
    sessionStorage.removeItem('skills');
    sessionStorage.removeItem('campName');
    sessionStorage.removeItem('JobRole');
    sessionStorage.removeItem('selectedCheckboxes');
    sessionStorage.removeItem('savedAllSelcted');
    sessionStorage.removeItem('JobRole');
    sessionStorage.removeItem('jrName');
    sessionStorage.removeItem('currentAddCampaign');
  }, []);

  return (
    <div className=" flex bg-[#EFF5FF] gap-[15px] h-sm:h-auto h-md:h-hrDashboard">
      {openAddJobRole && (
        <div className="absolute inset-0">
          <ModalAddJobRole
            head={'Add'}
            isOpen={openAddJobRole}
            onClose={() => setOpenAddJobRole(false)}
          />
        </div>
      )}
      <section className="custom-sm:w-auto custom-md1:w-[65%] custom-sm:flex custom-sm:flex-col custom-sm:gap-8">
        <div
          className="w-full flex flex-col justify-end gap-[23px] pl-[40px] min-h-[160px] custom-sm:m-[-15px]  custom-md2:mt-[-15px] custom-md2:ml-[-15px] custom-sm:h-[30%] custom-lg:h-[30%]  custom-sm:bg-cover"
          style={{
            backgroundImage: `url(${hrBack})`,
            backgroundRepeat: 'no-repeat',
            opacity: '0.8',
            width: 'calc(100% + 52px)',
          }}
        >
          <div className="flex flex-col gap-[14.5px]">
            <span className="text-[32px] leading-[0] font-satoshi font-bold text-[#222850] flex gap-2">
              Hi
              <span className="font-bold italic text-[#F5BB43]">
                {first_name} {last_name}
                <span className="text-[#222850]">,</span>
              </span>
            </span>
            <span className="text-[24px] font-bold font-satoshi">
              Welcome to Your Clevercruit HR Command Center!
            </span>
          </div>
          <div className="w-[26rem]">
            <span className="text-[14px] text-[#718096]">
              Manage your AI-powered recruitment campaigns and job roles
              efficiently from this dashboard. Your hiring process starts here!
            </span>
          </div>
        </div>
        <section className="w-full flex  gap-[20px]  ml-[12px] h-[14%]">
          <AddActionButton
            name={'Create New Campaign'}
            onClick={() => navigate('../../campaign/addCampaign')}
          />
          <AddActionButton
            name={'Define New Job Role'}
            onClick={() => setOpenAddJobRole(true)}
          />
        </section>
        <section className="custom-sm:mt-[0px] custom-lg2:mt-[20px] h-md3:h-[59%] h-sm:h-[53%] ml-[12px]">
          <ViewCampaignAdmin />
        </section>
      </section>
      <section className="w-[35%] min-w-[24rem] h-full flex flex-col h-sm:gap-[3.6rem] h-md2:justify-start  h-md:gap-12 h-md1:gap-2 h-md:overflow-y-scroll h-md3:overflow-visible custom-md2:max-h:auto custom-lg:max-h:700px">
        <DashboardCard
          header={'Active Campaigns'}
          text1={kPIData?.ongoing_campaigns}
          text2={'Ongoing recruitment drives'}
          text3={'Lorem ipsum is a placeholder'}
          imgSrc={ongoingWave}
        />

        <DashboardCard
          header={'Completed Campaigns'}
          text1={
            kPIData?.total_campaigns -
            (kPIData?.ongoing_campaigns + kPIData?.upcoming_campaigns)
          }
          text2={'Successfully concluded campaigns'}
          text3={'Lorem ipsum is a placeholder'}
          imgSrc={completeWave}
        />

        <DashboardCard
          header={'Upcoming Campaigns'}
          text1={kPIData?.upcoming_campaigns}
          text2={'Scheduled future campaigns'}
          text3={'Lorem ipsum is a placeholder'}
          imgSrc={totalWave}
        />
      </section>
    </div>
  );
};

const AddActionButton = (props) => {
  return (
    <div className=" flex items-center justify-center pl-[0.1rem] border-[10px] border-white rounded-[60px] h-fit ">
      <button
        className="relative flex custom-sm:w-[15rem]  custom-md:w-[18rem] items-center bg-[#181D3C] text-white font-medium rounded-[60px] shadow-md"
        onClick={props.onClick}
      >
        <div className="w-[50%]">
          <img src={tearShape} alt="" className="ml-[-2px] relative" />
          <div className="absolute custom-md:top-[9px] custom-sm:top-[8px] left-[3%] custom-md:w-[3.5rem] custom-sm:w-[3rem] custom-md:h-[3.5rem] custom-sm:h-[3rem] bg-white rounded-full flex items-center justify-center shadow-lg">
            <span className="text-[#181D3C] text-[30.26px]">+</span>
          </div>
        </div>
        <div className="w-[35%]  py-3 flex justify-start ">
          <span className="text-[15px] custom-md:text-[18px] ml-[-64px] min-w-[13rem] font-satoshi font-bold">
            {props.name}
          </span>
        </div>
      </button>
    </div>
  );
};

const DashboardCard = (props) => {
  return (
    <div className=" h-md1-h-[23rem] h-md2:h-[17.8rem] w-full p-[25px] bg-white relative rounded-[30px] min-w-[293px] h-sm:h-[15.2rem] h-md1:h-auto overflow-hidden">
      <img
        src={props.imgSrc}
        alt="wave"
        className="right-0 top-0 absolute z-[1] rounded-[30px] h-[105%] "
      />
      <p className=" font-normal text-[28px] font-[scada] text-[#1B3351]">
        {props.header}
      </p>
      <div className="flex flex-col mt-[10px]">
        <p className="font-[inter] text-[#0875F4] text-[32px]">{props.text1}</p>
        <div className="flex flex-col font-satoshi">
          <p className="text-[18px] text-[#00112D] font-normal">
            {props.text2}
          </p>
          {/* <p className="text-[13] font-medium text-[#C1C1C1] ">{props.text3}</p> */}
        </div>
      </div>
    </div>
  );
};

const SuperAdminDashboard = () => {
  const navigate = useNavigate();
  const [kPIData, setKPIData] = useState(null);
  const [memberData, setMemberData] = useState([]);
  const [campDetails, setCampDetails] = useState([]);
  const [highestNumber, setHighestNumber] = useState(0);
  console.log('first', highestNumber);
  const [viewCampComp, setViewCampComp] = useState(false);
  const callKPI = async () => {
    const reqData = {}; //no data required for this KPI
    const response = await handleApiResponse(
      '/api/v1/interview/data/get_dashboard_kpi/',
      reqData,
      1
    );
    if (response?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    if (response.data.status_code === 200) {
      console.log(response.data.data);
      setKPIData(response.data.data);
      setCampDetails(response.data.data.campaigns_info);
    }
  };

  const getUser = async () => {
    const reqData = {
      is_deleted: 0,
    };
    const response = await handleApiResponse('/api/v1/userlist/', reqData, 1);
    if (response?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    console.log(response);
    if (response.data.status_code === 200) {
      console.log(
        'user displayed ih',
        Object.values(response?.data?.data).flat()
      );
      let dataToFilter = response?.data?.data?.data;
      console.log('ih 2', dataToFilter[2]?.user_type === 3);
      let highestNumber = dataToFilter.filter((ele) => ele?.user_type === 3);
      console.log('ih3', highestNumber);
      highestNumber = Math.max(
        ...highestNumber.map((ele) => {
          return ele.created_campaigns;
        })
      );
      console.log('ih', highestNumber);
      setHighestNumber(highestNumber + 2);
      setMemberData(
        dataToFilter.filter((member) => member?.user_type === 3).reverse()
      );
    }
  };

  useEffect(() => {
    callKPI();
    getUser();
  }, []);

  return (
    <div
      className="text-[#222850] fill-available flex flex-col gap-4 justify-start  "
      style={{ height: 'calc(96vh - 75px)' }}
    >
      {!viewCampComp && (
        <>
          <section className="flex justify-between gap-4 h-[332px] fill-availabe ">
            <HelperCard2 name={'Campaign Analytics'} KPIData={kPIData} />
            {/* <HelperCard3 name={'Total Campaigns'} number={"206"} KPIData={kPIData} backgroundImage={designbg} />
            <HelperCard3 name={'Completed Campaigns'} number={"206"} KPIData={kPIData} backgroundImage={orangebg} />
            <HelperCard3 name={'Ongoing Campaigns'} number={"206"} KPIData={kPIData} backgroundImage={greenbg}/> */}

            <CampaignComposition
              memberData={memberData.filter((ele, index) => {
                if (index < 9) {
                  return ele;
                }
              })}
              number={highestNumber}
              setViewCampComp={setViewCampComp}
            />
          </section>
          <section
            className="flex justify-between gap-4 max-h-[530px]"
            style={{
              height: ' calc(100% - 352px) ',
            }}
          >
            <div className="custom-md:w-[72%]">
              <ViewCampaign />
            </div>
            <div className="w-[35%]">
              <ViewMemeber
                getUser={getUser}
                memberData={memberData.filter((ele, index) => {
                  if (index < 5) {
                    return ele;
                  }
                })}
              />
            </div>
          </section>
        </>
      )}
      {viewCampComp && (
        <>
          <section className="flex justify-between gap-4 h-[85vh] fill-available">
            <BigCampaignComposition
              memberData={memberData}
              number={highestNumber}
              setViewCampComp={setViewCampComp}
            />
          </section>
        </>
      )}
    </div>
  );
};

const CampaignComposition = ({ memberData, number, setViewCampComp }) => {
  console.log('<=>', number);
  console.log('<=>', number);
  const [hover, setHover] = useState(false);
  const [yAxis, setYAxis] = useState([]);
  let highestNumber = 0;

  // Set the highest number to a fixed value (for testing) or dynamic based on input
  if (number) {
    highestNumber = number; // Example input
  }

  // Round up the highestNumber to the nearest multiple of 10
  if (highestNumber % 10 !== 0) {
    highestNumber = highestNumber + (10 - (highestNumber % 10));
  }

  // Ensure we have exactly 10 intervals on the y-axis
  useEffect(() => {
    let y_axis = [];
    const steps = 4; // 9 intervals, making it a total of 10 labels
    let stepSize = Math.ceil(highestNumber / steps); // Dynamically calculate step size

    // Round stepSize to nearest multiple of 5 or 10
    if (stepSize % 5 !== 0) {
      stepSize = stepSize + (5 - (stepSize % 5));
    }

    // Generate the Y-axis labels
    for (let i = 0; i <= steps; i++) {
      y_axis.push((i * stepSize).toString());
    }

    setYAxis(y_axis.reverse()); // Reverse the yAxis so the highest number appears at the top
  }, [highestNumber]);

  return (
    <div className="flex flex-col bg-white w-full px-5 rounded-3xl min-w-[372px] ">
      <header className="flex flex-col justify-between py-[1rem]">
        <div className="fill-available flex justify-between ">
          <i className="font-satoshi  font-bold text-[24px]">
            Campaign Composition
          </i>
          <button
            className="font-satoshi text-[#718096]"
            onClick={() => setViewCampComp(true)}
          >
            View all
          </button>
        </div>
        <p className="text-[18px] font-satoshi font-semibold text-[#718096]">
          Campaign distribution across team members
        </p>
      </header>
      <main className=" mb-4 relative overflow-x-hidden overflow-y-visible">
        <div className="flex flex-col gap-4 ">
          {yAxis.length &&
            yAxis.map((ele, index) => {
              return (
                <div className="flex items-center gap-4 " key={index}>
                  <div className="min-w-4 font-satoshi text-[#718096] font-semibold">
                    {ele}
                  </div>

                  <div
                    className={`border-[1.2px] fill-available h-0  ${
                      parseInt(ele) === 0
                        ? 'ml-3 border-[#E9E9E9] '
                        : 'ml-3 border-[#E3F1FF] border-dashed'
                    }`}
                  ></div>
                </div>
              );
            })}
        </div>
        {/* 10rem = 20 camp */}
        {/* 0.5 rem = 1 camp */}
        {/* 10 rem = yAxis[0] camp */}
        {/* 1/parseInt(yAxis[0])*10 rem = 1 camp */}
        <div className="flex justify-start fill-available gap-[2.5rem] pl-12 pr-4 custom-sm:gap-[10px] custom-md:gap-[2.5rem] ">
          {memberData.length > 0 &&
            yAxis.length > 0 &&
            memberData?.map((ele, index) => {
              return (
                <div
                  className=" flex justify-center relative items-center mt-[-0.7rem] border-black w-12 h-12"
                  onMouseEnter={() => setHover(index)}
                  onMouseLeave={() => setHover()}
                >
                  {ele?.profile_img ? (
                    <img
                      className="w-7 custom-sm:hidden custom-md2:flex h-7 rounded-full"
                      src={ele?.profile_img}
                      alt=""
                    />
                  ) : (
                    <InitialsPic name={memberData[index]?.first_name} />
                  )}

                  <div
                    className={` absolute bottom-[3rem] h-[0rem] border-[0.2rem] border-[#E3F1FF]`}
                    style={{
                      height: `${(
                        (ele.created_campaigns / yAxis[0]) *
                        2.5 *
                        4
                      ).toFixed(2)}rem`,
                    }}
                  >
                    <div className="w-[24px] h-[24px] flex text-white justify-center top-[-1rem] left-[-0.7rem] rounded-full bg-[#0875F4] absolute">
                      {ele.created_campaigns}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </main>
    </div>
  );
};

const InitialsPic = ({ name }) => {
  const [col, setCol] = useState(0); // Initialize with a default value (e.g., 0)

  const colors = [
    ['rgba(30, 30, 30, 1)', 'rgba(30, 30, 30, 0.8)'],
    ['rgba(215, 63, 228, 1)', 'rgba(215, 63, 228, 0.8)'],
    ['rgba(251, 59, 82, 1)', 'rgba(251, 59, 82, 0.8)'],
    ['rgba(90, 101, 236, 1)', 'rgba(90, 101, 236, 0.8)'],
    ['rgba(244, 130, 130, 1)', 'rgba(244, 130, 130, 0.8)'],
    ['rgba(72, 200, 133, 1)', 'rgba(72, 200, 133, 0.8)'],
  ];

  const getModValue = (name) => {
    const asciiValue = name?.slice(0, 1)?.toUpperCase().charCodeAt(0); // Get ASCII value
    const sumOfDigits = Math.floor(asciiValue / 10) + (asciiValue % 10); // Sum of two digits
    const modValue = sumOfDigits % 6; // Mod with 6, ensuring result is between 0 and 5
    setCol(modValue);
  };

  useEffect(() => {
    if (name) {
      getModValue(name);
    }
  }, [name]);

  return (
    <div
      className="w-5 text-white border-[1px] text-center flex justify-center items-center custom-sm:hidden custom-md2:block w-[24px] h-[24px] rounded-full"
      style={{
        backgroundColor: `${colors[col][1]}`,
        borderColor: `${colors[col][0]}`,
      }}
    >
      {name?.slice(0, 1)?.toUpperCase()}
    </div>
  );
};
const BigCampaignComposition = ({ memberData, number, setViewCampComp }) => {
  const [hover, setHover] = useState(false);
  const [yAxis, setYAxis] = useState([]);
  let highestNumber = 0;
  const scrollContainerRef = useRef(null); // Reference for the scroll container

  // Set the highest number to a fixed value (for testing) or dynamic based on input
  if (number) {
    highestNumber = number; // Example input
  }

  // Round up the highestNumber to the nearest multiple of 10
  if (highestNumber % 10 !== 0) {
    highestNumber = highestNumber + (10 - (highestNumber % 10));
  }

  // Ensure we have exactly 10 intervals on the y-axis
  useEffect(() => {
    let y_axis = [];
    const steps = 9; // 9 intervals, making it a total of 10 labels
    let stepSize = Math.ceil(highestNumber / steps); // Dynamically calculate step size

    // Round stepSize to nearest multiple of 5 or 10
    if (stepSize % 5 !== 0) {
      stepSize = stepSize + (5 - (stepSize % 5));
    }

    // Generate the Y-axis labels
    for (let i = 0; i <= steps; i++) {
      y_axis.push((i * stepSize).toString());
    }

    setYAxis(y_axis.reverse()); // Reverse the yAxis so the highest number appears at the top
  }, [highestNumber]);

  // Set the scroll to the bottom and left after content is rendered
  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight; // Scroll to bottom
      scrollContainerRef.current.scrollLeft = 0; // Scroll to left
    }
  }, [yAxis, memberData]); // Trigger whenever yAxis or memberData is updated

  return (
    <div className="flex flex-col justify-between pb-6 bg-white w-full h-full px-8 rounded-3xl">
      <div className="flex flex-col">
        <header className="flex justify-between py-[1.375rem] pb-[18px]">
          <i className="font-satoshi font-bold text-[24px]">
            Campaign Composition
          </i>
          <div className="bg-[#F8FBFF] rounded-full w-[40px] h-[40px]">
            <img
              src={closeButton}
              className="cursor-pointer w-[40px] h-[40px]"
              alt="close button"
              onClick={() => setViewCampComp(false)}
            />
          </div>
        </header>
        <p className="text-[18px] mb-[28px] font-satoshi font-semibold text-[#718096]">
          Campaign distribution across team members
        </p>
      </div>
      <main
        ref={scrollContainerRef} // Attach the scroll container reference
        className="h-[27rem] relative w-full overflow-x-scroll"
      >
        <div className="w-fit max-w-fit min-w-full">
          <div className="flex flex-col gap-4">
            {yAxis.length &&
              yAxis.map((ele, index) => {
                return (
                  <span className="flex items-center gap-4" key={index}>
                    <p className="font-satoshi text-[#718096] font-semibold">
                      {ele}
                    </p>
                    <div
                      className={`border-[1px] fill-available h-0 ${
                        parseInt(ele) === 0
                          ? 'ml-3 border-[#E9E9E9]'
                          : 'ml-2 border-[#E3F1FF] border-dashed'
                      }`}
                    ></div>
                  </span>
                );
              })}
          </div>
          <div className="flex justify-start fill-available gap-[2.5rem] pl-12 pr-4 custom-sm:gap-[10px] custom-md:gap-[0px] h-[1.7rem]">
            {memberData.length > 0 &&
              yAxis.length > 0 &&
              memberData.map((ele, index) => {
                return (
                  <div
                    key={index}
                    className="flex relative justify-center items-center mt-[-0.7rem] border-black w-12 h-12"
                    onMouseEnter={() => setHover(index)}
                    onMouseLeave={() => setHover()}
                  >
                    <span
                      className={`${
                        hover === index ? '' : 'hidden'
                      } absolute top-10`}
                    >
                      {memberData[index]?.first_name}
                    </span>
                    {ele?.profile_img ? (
                      <img
                        className="w-7 custom-sm:hidden custom-md2:flex h-7 rounded-full"
                        src={ele?.profile_img}
                        alt=""
                      />
                    ) : (
                      <InitialsPic name={memberData[index]?.first_name} />
                    )}
                    <div
                      className="absolute bottom-[3rem] h-[0%] border-[0.2rem] border-[#E3F1FF]"
                      style={{
                        height: `${(
                          (ele.created_campaigns / yAxis[0]) *
                          2.5 *
                          9
                        ).toFixed(2)}rem`,
                      }}
                    >
                      <div className="w-6 h-6 flex text-white justify-center top-[-1rem] left-[-0.7rem] rounded-full bg-[#0875F4] absolute">
                        {ele.created_campaigns}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </main>
    </div>
  );
};

const ViewMemeber = ({ memberData, getUser }) => {
  const [isActive, setActive] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editData, setEditData] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  console.log('hi mem', memberData);

  const closeModal = () => {
    setOpenEdit(false);
  };

  useEffect(() => {
    if (!openEdit) {
      getUser();
    }
  }, [openEdit]);

  const handleEditData = (index) => {
    setEditData(memberData[index]);
    setOpenEdit(true);
    // getUser()
  };

  return (
    <>
      {openEdit ? <Modal editData={editData} onClose={closeModal} /> : null}

      <div className="flex flex-col h-[100%] bg-white w-full px-5 rounded-3xl min-w-[272px] custom-sm:min-h-[300px] custom-md:min-h-[170px] ">
        {memberData.length > 0 ? (
          <>
            <header className="flex justify-between  py-[1rem]">
              <i className="font-satoshi  font-bold text-[24px]">
                Members List
              </i>
              <button
                className="font-satoshi text-[#718096]"
                onClick={() => {
                  navigate('../members');
                }}
              >
                View all
              </button>
            </header>
            <main className="overflow-auto ">
              <div className="flex flex-col gap-[12px] ">
                {memberData.length > 0 &&
                  memberData.map((member, index) => {
                    return (
                      <div
                        className={`flex justify-between rounded-3xl px-3 cursor-pointer py-3 ${
                          isActive === index ? 'bg-[#EFF5FF]' : null
                        }`}
                        key={index}
                        onMouseEnter={() => setActive(index)}
                        onMouseLeave={() => setActive()}
                      >
                        <div className="flex gap-2">
                          <span className="flex">
                            <img
                              className="w-12 h-12 rounded-full"
                              src={`${
                                member?.profile_img
                                  ? member?.profile_img
                                  : profileImage
                              }`}
                              alt=""
                            />
                          </span>
                          <span className="flex flex-col">
                            <b>
                              {member?.first_name} {member?.last_name}
                            </b>
                            <p>{member?.designation}</p>
                          </span>
                        </div>
                        {isActive === index ? (
                          <div className="flex gap-2 items-center">
                            <div className="cursor-pointer group">
                              <img
                                data-tooltip-id="edit-tooltip" // Link with Tooltip component via id
                                data-tooltip-content="Edit this member" // Tooltip content
                                className="w-5 "
                                src={pencilIcon}
                                alt="pencil"
                                onClick={() => {
                                  handleEditData(index);
                                }}
                              />
                              <Tooltip
                                id="edit-tooltip"
                                place="top"
                                effect="solid"
                                delayShow={100}
                                delayHide={100}
                                type="light"
                                className="tooltip-common"
                              />
                            </div>

                            {/* <span className="border-[0.02rem] border-slate-300 mt-[0.5rem] h-[70%] "></span>
                      <img
                        className="w-5 cursor-pointer"
                        src={optionIcon}
                        alt="option"
                      /> */}
                          </div>
                        ) : null}
                      </div>
                    );
                  })}

                {/* {memberData.length == 0 && (
              <div colspan="100% h-100" class="text-center py-4">
                No Member added yet
              </div>
            )} */}
              </div>
            </main>
          </>
        ) : (
          <div className="p-4 h-full font-[500] text-center justify-center items-center flex text-[36px] trailing-[57px] text-[#A0AEC0] flex-col gap-4">
            <img src={nodata} alt="no data" className="w-[135px] h-[135px]" />
            No member added yet
          </div>
        )}
      </div>
    </>
  );
};

const DropdownMenu = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(props.option1);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false); // Close the dropdown
    props.onClick(option); // Call the parent function with the selected option
  };

  return (
    <div className="relative inline-block text-left">
      {/* Button to toggle dropdown */}
      <button
        onClick={toggleDropdown}
        className="font-poppins h-[36px] rounded-[8px] text-white bg-[#0875F4] px-2 text-[12px] flex items-center justify-between"
      >
        {selectedOption}
        <svg
          className={`w-4 h-4 ml-2 transition-transform ${
            isOpen ? 'rotate-180' : ''
          }`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <div className="absolute mt-2 w-[7.014vw] bg-white rounded-md shadow-lg z-10">
          <ul className="py-1 text-[12px]">
            <li
              onClick={() => handleSelect(props.option1)} // Correctly handle option click
              className="cursor-pointer px-4 py-2 hover:bg-gray-100 text-[#0875F4]"
            >
              {props.option1}
            </li>
            <li
              onClick={() => handleSelect(props.option2)} // Correctly handle option click
              className="cursor-pointer px-4 py-2 hover:bg-gray-100 text-[#0875F4]"
            >
              {props.option2}
            </li>
            <li
              onClick={() => handleSelect(props.option3)} // Correctly handle option click
              className="cursor-pointer px-4 py-2 hover:bg-gray-100 text-[#0875F4]"
            >
              {props.option3}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

const ViewCampaignAdmin = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState(''); // State to hold search query
  const [searchResults, setSearchResults] = useState([]); // State for search results
  const [deleteUid, setDeleteUid] = useState();
  const [isDeleteOpen, setIsDeleteOpen] = useState();

  const openDelete = () => {
    setIsDeleteOpen(true);
  };
  const closeDelete = () => {
    setIsDeleteOpen(false);
    getUser();
  };
  const [filterDays, setFilterDays] = useState(null);
  const displayData = searchResults.length > 0 ? searchResults : tableData;
  console.log(filterDays);
  const isWithinLastNDays = (dateString, days) => {
    const currentDate = new Date();
    const targetDate = new Date(currentDate);
    targetDate.setDate(currentDate.getDate() - days);

    const rowDate = new Date(dateString);
    return rowDate >= targetDate && rowDate <= currentDate;
  };

  const filteredData = displayData.filter((rowData) => {
    if (!filterDays) return true; // If no filter is applied, show all data
    return (
      isWithinLastNDays(rowData.start_date?.slice(0, 10), filterDays) || // Check start date
      isWithinLastNDays(rowData.end_date?.slice(0, 10), filterDays) // Check end date
    );
  });
  console.log(filteredData);
  // Fetch default campaign list
  const getUser = async () => {
    const reqData = {
      is_deleted: 0,
    };
    const response = await handleApiResponse(
      '/api/v1/interview/campaign_list/',
      reqData,
      1
    );
    if (response?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    if (response?.data?.status_code === 200) {
      const userid = sessionStorage.getItem('uid');
      console.log(
        '<>',
        Object.values(response.data.data.data).flat()[0].uid,
        userid
      );
      setTableData(
        Object.values(response.data.data.data)
          .flat()
          .filter((camp) => {
            console.log(camp.creator === userid, camp.creator, userid);
            if (camp.creator === userid) {
              return camp;
            }
          })
      );
    }
  };

  // Determine campaign status based on dates
  const findStatus = (start, end) => {
    const monthMap = {
      Jan: '1',
      Feb: '2',
      Mar: '3',
      Apr: '4',
      May: '5',
      Jun: '6',
      Jul: '7',
      Aug: '8',
      Sep: '9',
      Oct: '10',
      Nov: '11',
      Dec: '12',
    };
    function isSecDateBigger(date1, date2, pos) {
      let [month1, day1, year1] = date1.split(' ');
      let [year2, month2, day2] = date2.split('-').map(Number);
      month1 = monthMap[month1];
      month1 = Number(month1);
      day1 = Number(day1);
      year1 = Number(year1);
      if (day1 === day2 && month1 === month2 && year1 === year2 && pos) {
        return true;
      }
      if (year1 > year2) return true;
      if (year1 < year2) return false;
      if (month1 > month2) return true;
      if (month1 < month2) return false;
      return day1 > day2;
    }

    const todayDate = new Date();
    let thisDate = String(todayDate).slice(4, 15);
    let result = isSecDateBigger(thisDate, start, 1);
    if (result) {
      const res = isSecDateBigger(thisDate, end);
      if (res) return 'Completed';
      else return 'Ongoing';
    } else return 'Upcoming';
  };

  useEffect(() => {
    getUser();
  }, []);

  // Handle search functionality
  const handleSearch = async (text) => {
    const reqData = {
      q: text, // Pass the search query
      is_deleted: 0,
    };
    const response = await handleApiResponse(
      '/api/v1/interview/campaign_list/',
      reqData,
      1
    );
    if (response?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    if (response.data.status_code === 200) {
      setSearchResults(response.data.data.data);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      handleSearch(); // Trigger search on Enter key press
    }
  };
  console.log(filterDays, 'filter days');
  const handleOptionClick = (option) => {
    console.log(option, 'iption');
    if (option === 'Last 30 days') {
      setFilterDays(30);
    } else if (option === 'Last 60 days') {
      setFilterDays(60);
    } else {
      setFilterDays(null); // No filter
    }
  };

  return (
    <div className="flex flex-col bg-white  w-full h-full min-w-[384px] custom-sm:min-h-[300px]   custom-md:min-h-[170px] custom-sm:max-h-[712px]  px-8 rounded-3xl ">
      {filteredData.length > 0 ? (
        <>
          {isDeleteOpen && (
            <ModalDeleteCampaign
              isOpen={openDelete}
              deleteUid={deleteUid}
              onClose={closeDelete}
            />
          )}
          <header className="flex justify-between  py-[1rem]">
            <i className="font-satoshi font-bold text-[24px]">Campaign List</i>
            <div className="flex gap-2 justify-center items-center">
              <div className="relative">
                <input
                  placeholder="Search"
                  onChange={(e) => {
                    handleSearch(e.target.value);
                  }} // Update search query
                  onKeyDown={handleKeyDown} // Search on Enter key press
                  className="font-poppins w-[18.125vw] h-[36px] text-[12px] p-5 pl-8 outline-0  bg-[#E9F3FF] rounded-lg placeholder-text-sm"
                />
                <img
                  src={searchIcon}
                  alt="search icon"
                  className="absolute cursor-pointer top-0 left-0 w-[16px] h-[40.2px] ml-2"
                  onClick={handleSearch} // Search on click
                />
              </div>
              <DropdownMenu
                option1={'Last 30 days'}
                option2={'Last 60 days'}
                option3={'All'}
                onClick={handleOptionClick}
              />
            </div>
          </header>
          <main className="overflow-y-scroll mb-6 rounded-[16px] border-[1px] border-[#E4E7EB]">
            <table class="w-full text-sm text-[#A6AEBA] text-left rtl:text-right ">
              <thead class="text-[15px] h-[54px] text-[#222850] font-[inter] font-normal bg-[#F7FAFC] border-b border-[#E4E7EB]">
                <tr>
                  <td scope="col" className="px-6 text-left">
                    S.No.
                  </td>
                  <td scope="col" className="text-left px-3">
                    Campaign Name
                  </td>
                  <td scope="col" className="text-left px-3">
                    Start Date
                  </td>
                  <td scope="col" className="text-left px-3">
                    End Date
                  </td>
                  <td scope="col" className="text-left px-3">
                    Action
                  </td>
                </tr>
              </thead>
              <tbody>
                {filteredData?.map((rowData, index) => (
                  <CampaignTable
                    key={index}
                    number={index} // Display correct serial number starting from 1
                    row12={rowData?.name}
                    row13={rowData?.campaign_type === 1 ? 'Regular' : 'Mass'}
                    row14={rowData?.jr_name}
                    row17={rowData?.creator_name}
                    row15={rowData?.start_date?.slice(0, 10)}
                    end={rowData?.end_date?.slice(0, 10)}
                    row18={findStatus(
                      rowData?.start_date?.slice(0, 10),
                      rowData?.end_date?.slice(0, 10)
                    )}
                    isDash={false}
                    isAdmin={true}
                    creator={rowData?.creator}
                    openDelete={openDelete}
                    setDeleteUid={setDeleteUid}
                    skills={rowData?.required_skills}
                    description={rowData?.description}
                    jobRoleUid={rowData.jobrole}
                    uid={rowData.uid}
                    style={{
                      backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F9FBFD',
                    }}
                  />
                ))}
              </tbody>
            </table>
          </main>
        </>
      ) : (
        <div className="p-4 h-full font-[500] text-center justify-center items-center flex text-[36px] trailing-[57px] text-[#A0AEC0] flex-col gap-4">
          <img src={nodata} alt="no data" className="w-[135px] h-[135px]" />
          No campaign added yet
        </div>
      )}
    </div>
  );
};

const ViewCampaign = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const getUser = async (userLimit, pageNo) => {
    const reqData = {
      is_deleted: 0,
      _limit: 7,
      _page: 1,
    };
    const response = await handleApiResponse(
      '/api/v1/interview/campaign_list/',
      reqData,
      1
    );
    if (response?.code === 'token_not_valid') {
      navigate('/');
      sessionStorage.clear();
      return;
    }
    console.log(response);
    if (response.data.status_code === 200) {
      console.log('camp displayed', Object.values(response.data.data).flat());
      console.log('response.data.data.total', response.data.data.total);

      setTableData(Object.values(response.data.data.data).flat());
    }
  };

  const findStatus = (start, end) => {
    const monthMap = {
      Jan: '1',
      Feb: '2',
      Mar: '3',
      Apr: '4',
      May: '5',
      Jun: '6',
      Jul: '7',
      Aug: '8',
      Sep: '9',
      Oct: '10',
      Nov: '11',
      Dec: '12',
    };
    function isSecDateBigger(date1, date2, pos) {
      // console.log(date1, date2);
      let [month1, day1, year1] = date1.split(' ');
      let [year2, month2, day2] = date2.split('-').map(Number);
      month1 = monthMap[month1];

      month1 = Number(month1);
      day1 = Number(day1);
      year1 = Number(year1);
      // console.log("today", year1, month1, day1);
      // console.log("start", year2, month2, day2);

      if (day1 === day2 && month1 === month2 && year1 === year2 && pos) {
        // console.log("true");
        return true;
      }

      if (year1 > year2) {
        return true;
      } else if (year1 < year2) {
        return false;
      }

      if (month1 > month2) {
        return true;
      } else if (month1 < month2) {
        return false;
      }

      return day1 > day2;
    }

    const todayDate = new Date();
    let thisDate = String(todayDate).slice(4, 15);
    let result = isSecDateBigger(thisDate, start, 1);
    if (result) {
      const res = isSecDateBigger(thisDate, end);
      if (res) {
        return 'Completed';
      } else {
        return 'Ongoing';
      }
    } else {
      return 'Upcoming';
    }
  };

  useEffect(() => {
    getUser();
  }, []);
  return (
    <div className="flex flex-col bg-white fill-available-h w-full min-w-[384px] custom-sm:min-h-[300px] custom-md:min-h-[170px] rounded-3xl ">
      {tableData.length > 0 ? (
        <>
          <header className="flex justify-between px-5  py-[1rem]">
            <i className="font-satoshi  font-bold text-[24px]">Campaign List</i>
            <button
              className="font-satoshi text-[#718096]"
              onClick={() => navigate('../campaign')}
            >
              View all
            </button>
          </header>
          <main className="overflow-y-scroll mb-6">
            <table class="w-full text-sm text-[#A6AEBA] text-left rtl:text-right ">
              <thead class="text-[15px] h-[54px] text-[#222850] bg-[#F7FAFC] border-b border-[#E4E7EB]">
                <tr>
                  <td scope="col" class="px-6">
                    S.No.
                  </td>
                  <td scope="col" class="px-3 text-left">
                    Campaign Name
                  </td>

                  <td scope="col" class="px-3 text-left">
                    Created By
                  </td>

                  <td scope="col" class="px-3 text-left">
                    Start Date
                  </td>

                  <td scope="col" class="px-3 text-left">
                    Progress
                  </td>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((rowData, index) => (
                  <CampaignTable
                    key={index}
                    number={0 + index}
                    row12={rowData?.name}
                    row17={rowData?.creater_name}
                    row15={rowData?.start_date?.slice(0, 10)}
                    row18={findStatus(
                      rowData?.start_date?.slice(0, 10),
                      rowData?.end_date.slice(0, 10)
                    )}
                    isDash={true}
                  />
                ))}

                {tableData.length == 0 && (
                  <tr>
                    <td colspan="100%" class="text-center py-4">
                      No campaign added yet
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </main>
        </>
      ) : (
        <div className="p-4 h-full font-[500] text-center justify-center items-center flex text-[36px] trailing-[57px] text-[#A0AEC0] flex-col gap-4">
          <img src={nodata} alt="no data" className="w-[135px] h-[135px]" />
          No campaign added yet
        </div>
      )}
    </div>
  );
};

const HelperCard2 = ({ name, KPIData }) => {
  const total = KPIData?.total_campaigns;
  const ongoing = KPIData?.ongoing_campaigns;
  const upcoming = KPIData?.upcoming_campaigns;
  const completed = total - (upcoming + ongoing);
  return (
    <div className="flex flex-col bg-white px-4 rounded-3xl h-fit">
      <header className="flex w-full justify-between  py-[1rem] pb-4 mx-[0.8rem]">
        <i className="font-satoshi  font-bold text-[24px]">{name}</i>
      </header>
      <main>
        <div className=" h-[54px] mx-[0.8rem]  flex font-satoshi gap-4 mb-[1rem] text-[24px] ">
          <img src={shortLogo} alt="logo" />
          <div className="flex flex-col ">
            <i
              className="text-[24px] opacity-[50%] "
              style={{ lineHeight: '1' }}
            >
              Total Campaigns
            </i>
            <i className="font-bold text-[28px]">{total}</i>
          </div>
        </div>
        <section className="mx-[0.8rem] flex flex-col relative justify-between h-[42px] w-[320px]">
          <div className="text-[#222850] text-[14px]">
            {' '}
            {completed === 0 ? 0 : ((completed / total) * 100).toFixed(0)}%
          </div>
          <div
            className="absolute text-[#222850] text-[14px]"
            style={{
              left: `${
                ongoing === 0
                  ? 306
                  : (310 + 10 - (ongoing / total) * 300).toFixed(1)
              }px`,
            }}
          >
            {' '}
            {ongoing === 0 ? 0 : ((ongoing / total) * 100).toFixed(0)}%
          </div>
          <span className="absolute mt-[22px]">
            <svg
              className="absolute bottom-0"
              width="330"
              height="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="3"
                y1="10"
                x2={completed === 0 ? 6 : (completed / total) * 300}
                y2="10"
                stroke="#48C885"
                stroke-width="6"
                stroke-linecap="round"
              />
            </svg>
            <svg
              className="absolute bottom-0 relative"
              width="330"
              height="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="310"
                y1="10"
                x2={ongoing === 0 ? 313 : 310 + 10 - (ongoing / total) * 300} //added 10px more space
                y2="10"
                stroke="#FC7F29"
                stroke-width="6"
                stroke-linecap="round"
              />
            </svg>
          </span>
        </section>
        <section className="bg-[#EFF5FF] mt-[8px] mb-4 p-2 rounded-3xl w-full flex justify-around items-center ">
          <div className="flex flex-col items-center gap-[10px] w-[50%]">
            <div className="p-2 w-[2.5rem]  rounded-full bg-[#FC7F29] ">
              <img src={watchIcont} alt="" />
            </div>
            <span className="flex flex-col text-center leading-normal">
              <p className="font-bold">{ongoing}</p>
              <p className="text-[#6A6C6B]">Ongoing</p>
            </span>
          </div>

          <div className="border-[0.04rem] border-[#E9EAEC] h-[6rem] my-2"></div>

          <div className="flex flex-col items-center gap-[10px] w-[50%] ">
            <div className="p-2 w-[2.5rem] rounded-full bg-[#48C885] ">
              <img src={tickIcon} alt="" />
            </div>
            <span className="flex flex-col text-center leading-normal">
              <p className="font-bold">{completed}</p>
              <p className="text-[#6A6C6B]">Completed</p>
            </span>
          </div>
        </section>
      </main>
    </div>
  );
};

const HelperCard3 = ({ backgroundImage, name, KPIData, number }) => {
  const total = KPIData?.total_campaigns;
  const ongoing = KPIData?.ongoing_campaigns;
  const upcoming = KPIData?.upcoming_campaigns;
  const completed = total - (upcoming + ongoing);
  return (
    <div
      className="flex h-fit gap-8 fill-available flex-col bg-white px-8 py-8 rounded-3xl"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right center',
        backgroundSize: 'contain',
      }}
    >
      <header className="flex fill-available justify-between  py-2 font-scada text-[20px]">
        {name}
      </header>
      <div className="text-semibold text-[#0875F4] text-3xl">{number}</div>
    </div>
  );
};
