import {
  builderButton,
  campaignbg,
  candidateButton,
  clevercruit,
  clevercruitlogo,
  detailsButton,
} from "../assets/imgs";
import { CampaignHeader } from "../components/AdminComponents/campaignHeader";
import { Outlet } from "react-router-dom";
import { CampaignButton } from "../components/buttons";
import React from "react";
import { useState ,createContext} from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { LayoutBtn } from "./CampaignLayout";

export const CampaignContextEdit = createContext();

export const EditCampaignLayout = () => {
  const userStates={campdetails:false,questionBuilder:false,addCandidate:false}

  const [isDivVisible, setDivVisible] = useState(userStates);

  const toggleDivVisibility = (e) => {
    let name=e.currentTarget.name
    // setDivVisible((prevVisible) => !prevVisible);
    setDivVisible({...userStates,[name]:!isDivVisible[name]});

  };

  // Step 1: Initialize state from sessionStorage
  const [currentAddCampaign, setCurrentAddCampaign] = useState(() => {
    return parseInt(sessionStorage.getItem("currentAddCampaign"), 10) || 0;
  });

  const [hideCandidateToggleEdit , sethideCandidateToggleEdit ]= useState(true)
  const navigate = useNavigate();

  // Function to increment count
  const currentCampaignEdit = (current) => {
    setCurrentAddCampaign(current);
  };
  

  return (
    <div
      className=""
      style={{
        backgroundImage: `url(${campaignbg})`,
        backgroundSize: 'cover, cover', // Adjust sizes if necessary
        backgroundPosition: 'center, center', // Adjust positions if necessary
        backgroundRepeat: 'no-repeat, no-repeat', // Ensure images don't repeat
      }}
    >
      <div className="flex h-sm:h-full h-md2:h-[100vh] px-5 py-0">
        {/* Sidebar */}
        <aside
          className={`hidden lg:block max-lg:w-0 text-white p-4  lg:w-[25rem] transition-transform duration-300 transform -translate-x-full lg:translate-x-0`}
        >
          <img
            src={clevercruitlogo}
            className="w-[160px] h-[50px] ml-5 cursor-pointer"
            alt="Clevercraft Logo"
            onClick={() => {
              navigate('/admin/dashboard');
              window.location.reload();
            }}
          />

          <div className="flex-grow  flex flex-col gap-[3px] mx-1 xl:mx-6 xl:gap-[10px] ml-[20px] mt-[13%] mb-6">
            <div className="relative w-full">
              <LayoutBtn text="Campaign Details" number={'01'} />
              {/* <div className="shadow-inside border-[10px] border-[#fff] p-3 h-[88px] bg-[#ffff] cursor-pointer relative z-20">

                  </div> */}
              <div
                className={`relative md:mt-[-23%] lg:mt-[-19%] left-0 w-full  bg-white p-4 pt-16 shadow-md rounded-[23.37px] transition-all duration-300 overflow-hidden z-10 ${
                  isDivVisible.campdetails || currentAddCampaign == 0
                    ? 'opacity-100 max-h-[430px] top-[27%]'
                    : 'opacity-0 max-h-0 top-[124px]'
                }`}
              >
                <p className={`text-[14px] font-satoshi text-[#667394]  `}>
                Configure your interview campaign's core elements. Specify job
roles, required skills, and campaign duration. Choose campaign type, set start and
end dates, and decide on including coding questions. This foundational step
ensures your AI-powered interviews align perfectly with your hiring objectives.
                </p>
              </div>
            </div>

            <div
              className={`relative  w-full mt-5 ${
                isDivVisible.campdetails || currentAddCampaign == 0
                  ? ''
                  : ''
              }`}
            >
              <LayoutBtn text="Question Builder" number={'02'} />
              <div
                className={`relative md:mt-[-23%] lg:mt-[-19%] left-0 w-full bg-white p-4 pt-16 shadow-md rounded-[23.37px] transition-all duration-300 overflow-hidden z-10 ${
                  isDivVisible.questionBuilder || currentAddCampaign == 1
                    ? 'opacity-100 max-h-[430px] top-[27%]'
                    : 'opacity-0 max-h-0 top-[124px]'
                }`}
              >
                <p className="text-[14px] font-satoshi text-[#667394]">
                Design your interview question set using three flexible options: AIgenerated questions for eficiency, AI-generated + User-crafted for a mix of AI and
human touch, or User-crafted questions for complete customization. Set questions
as mandatory or optional, and easily add, edit, or remove questions to create the
ideal assessment.
                </p>
              </div>
            </div>

            <div
              className={`relative w-full mt-5 ${
                isDivVisible.questionBuilder || currentAddCampaign == 1
                  ? ''
                  : ''
              } ${!hideCandidateToggleEdit ? 'hidden' : ''}`}
            >
              <LayoutBtn text="Add Candidate" number={'03'} />
              <div
                className={`relative md:mt-[-23%] lg:mt-[-19%]  left-0 w-full  bg-white p-4 pt-16 shadow-md rounded-[23.37px] transition-all duration-300 overflow-hidden z-10 ${
                  isDivVisible.addCandidate || currentAddCampaign == 2
                    ? 'opacity-100 max-h-[430px] top-[27%]'
                    : 'opacity-0 max-h-0 top-[124px]'
                }`}
              >
                <p className="text-[14px] font-satoshi text-[#667394]">
                Streamline candidate management with multiple options. Bulk
upload candidates via CSV file for large-scale hiring, or add candidates individually 
for more focused recruitment. Review and edit candidate details, and manage your
candidate list efortlessly within the platform.
                </p>
              </div>
            </div>
          </div>
        </aside>

        {/* Main Container */}
        <main className="flex-grow p-4">
          <div className="max-w-5xl md:ml-2 max-lg:ml-8">
            <div className="fill-available">
              <CampaignContextEdit.Provider
                value={{ currentCampaignEdit, sethideCandidateToggleEdit }}
              >
                <Outlet />
              </CampaignContextEdit.Provider>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
