import { useContext, useState, useEffect, useRef, useCallback } from "react";
import "../../assets/css/style.css";
import * as XLSX from "xlsx";
import { RiCalendar2Line, RiDeleteBin6Line } from "react-icons/ri";
import { CgMenuLeftAlt } from "react-icons/cg";
import { PiUpload } from "react-icons/pi";
import { PiDownloadLight } from "react-icons/pi";
import { AiOutlinePlus } from "react-icons/ai";
import { saveAs } from "file-saver";
import {
  activeRadio,
  inactiveRadio,
  edityIcon,
  delteyIcon,
  DeleteIcon,
  editSmIcon,
  deleteSmIcon,
  saveBtn,
  shortlistIcon,
} from "../../assets/imgs";
import { CustomBtn } from "../../components/buttons";
import arrowTooltip from "../../assets/imgs/ArrowTooltip.svg";
import downloadFormat from "../../assets/imgs/downloadFormat.svg";
import browseicon1 from "../../assets/imgs/browseicon1.svg";

import {
  Backbutton,
  Button,
  CampaignButton,
  CustomButton,
  Nextbutton,
} from "../../components/buttons";
import {
  DeleteQues,
  resumeUpload,
  AddQues,
  Ellipse,
  LinkIcon,
  deleteIcon,
  editIcon,
  calendar,
} from "../../assets/imgs";
import { useDispatch } from "react-redux";
import {
  Dropdown,
  DropdownCodingQuestion,
  DropdownJobRole,
} from "../../components/AdminComponents/dropdown";
import { Calander, Calander2 } from "../../components/AdminComponents/calander";
import {
  ModalAddCandidate,
  ModalAddConfirmationPopup,
  ModalAddHrQuestions,
  ModalAddJobRole,
  ModalCandidateConfirmationPopup,
  ModalChangeCampaign,
  ModalDeleteCampaign,
  ModalDeleteJobRole,
  ModalDeleteQuestionPopup,
  ModalImportMembers,
} from "../../components/AdminComponents/modal";
import { AnalyticsPageBtn } from "../../components/buttons";
import { getLocal, setLocal } from "../../utils/localStorage";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { validateName } from "../../utils/validation";
import { fetchData, handleApiResponse } from "../../API/services";
import { Loader } from "../../components/loader";
import { Tooltip } from "react-tooltip";
import { FaInfoCircle } from "react-icons/fa";
import { set } from "date-fns";
import { useOutletContext } from "react-router-dom";
import { CampaignContext } from "../../layouts/CampaignLayout";
import { CampaignContextEdit } from "../../layouts/EditCampaignLayout";

export const AddCampaign = () => {
  // const outletData = useOutletContext();
  const { currentCampaign } = useContext(CampaignContext) || {};
  const { currentCampaignEdit } = useContext(CampaignContextEdit) || {};

  const location = useLocation();
  const campUid = location?.state?.uid || "";
  const jr_name = location?.state?.jr_name || "";
  const start_date = location?.state?.start_date || "";
  const end_date = location?.state?.end_date || "";
  const campaign_type = location?.state?.campaign_type || "";
  const name = location?.state?.name || "";
  const jr_uid = location?.state?.jr_uid;
  const desc = location?.state?.desc;
  const resumeOn = location?.state?.resumeOn;
  const codingOn = location?.state?.codingOn;
  const skills = location?.state?.skills;
  const navigate = useNavigate();
  console.log(location?.state);
  const editData = [
    campUid,
    jr_name,
    start_date,
    end_date,
    campaign_type,
    name,
    jr_uid,
    desc,
    resumeOn,
    codingOn,
    skills,
  ];
  const [current, setCurrent] = useState(() => {
    const storedValue = sessionStorage.getItem("currentAddCampaign");
    return storedValue ? JSON.parse(storedValue) : 0;
  });
  const [isNextAcitvated, setIsNextActivated] = useState(false);
  const [campaignType, setCampaignType] = useState(1);
  const [allReqData, setAllReqData] = useState([null, null, null, null]);
  const [uploadFile, setUploadFile] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [singleAddCandidate, setSingleAddCandidate] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const [selectAll, setSelectAll] = useState(
    sessionStorage.getItem("savedAllSelcted")
      ? sessionStorage.getItem("savedAllSelcted")
      : false
  );
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const shouldHide = current === 3 && getLocal("mail_share_type") === "1";
  const [backClicked, setBackClicked] = useState(false);
  const [prevEmail, setPrevEmail] = useState();

  console.log(allReqData);
  console.log(selectedData);
  const step = [
    { name: "Campaign Details" },
    { name: "Question Builder" },
    { name: "Candidate Details" },
  ];

  useEffect(() => {
    let statuData = ["campdetails", "questionBuilder", "addCandidate"];

    console.log("editData", editData);
    setLocal("currentAddCampaign", JSON.stringify(current));
    if (currentCampaign) {
      currentCampaign(current);
    }
    if (currentCampaignEdit) {
      currentCampaignEdit(current);
    }

    // outletData(statuData[current])
  }, [current]);

  useEffect(() => {
    console.log("all req data", allReqData);
  }, [current]);

  return (
    <main
      className="flex flex-col gap-4  text-text-color-code1 add-member-animation "
      style={{
        height: `calc(100vh - 14px)`,
      }}
    >
      <section
        className="main-section flex flex-col  text-sm px-[21px] gap-[16px] py-[28px] bg-white rounded-[21px] overflow-auto"
        style={{
          boxShadow:
            "0px 5.63px 11.25px 0px #00000014, 0px 0px 2.81px 0px #0000000A",
          height: "calc(100vh - 85px)",
        }}
      >
        {/* <p className="text-base">Step:{current + 1}</p> */}
        <div className="flex flex-col gap-[10px] tracking-[2%]">
          <p className="text-[#1B3351] text-[23.91px] leading-[40.8px] font-figtree font-semibold italic pb-[7.03px] border-b-[2px] border-[#1A7EF3]">
            {step[current]?.name}  <div className="text-[#1B3351] font-satoshi italic font-[500] text-[16px]">Set up your AI-powered interview campaign in 3 easy steps.</div>
          </p>
          {/* <p>
            {current === 3
              ? allReqData[0]
                ? allReqData[0]?.description
                : desc
              : ''}
          </p> */}
        </div>
        <div className="fill-available tracking-[2%]">
          {current === 0 ? (
            <CampaignForm
              campaignType={campaignType}
              setCampaignType={setCampaignType}
              isNextAcitvated={isNextAcitvated}
              setIsNextActivated={setIsNextActivated}
              current={current}
              setCurrent={setCurrent}
              allReqData={allReqData}
              setAllReqData={setAllReqData}
              editData={editData}
              backClicked={backClicked}
            />
          ) : current === 1 ? (
            <QuestionBuilder
              campaignType={campaignType}
              isNextAcitvated={isNextAcitvated}
              setIsNextActivated={setIsNextActivated}
              current={current}
              setCurrent={setCurrent}
              setAllReqData={setAllReqData}
              jr_uid={jr_uid}
            />
          ) : current === 2 ? (
            <AddCandidate
              isNextAcitvated={isNextAcitvated}
              setIsNextActivated={setIsNextActivated}
              current={current}
              setCurrent={setCurrent}
              setAllReqData={setAllReqData}
              uploadFile={uploadFile}
              setUploadFile={setUploadFile}
              excelData={excelData}
              setExcelData={setExcelData}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              selectedData={selectedData}
              singleAddCandidate={singleAddCandidate}
              setSingleAddCandidate={setSingleAddCandidate}
              setSelectedData={setSelectedData}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
              selectedCheckboxes={selectedCheckboxes}
              setSelectedCheckboxes={setSelectedCheckboxes}
              editData={editData}
              prevEmail={prevEmail}
              setPrevEmail={setPrevEmail}
            />
          ) : current === 3 ? (
            <ReviewCandidate
              isNextAcitvated={isNextAcitvated}
              setIsNextActivated={setIsNextActivated}
              current={current}
              allReqData={allReqData}
              prevEmail={prevEmail}
            />
          ) : (
            <AddCandidate
              isNextAcitvated={isNextAcitvated}
              setIsNextActivated={setIsNextActivated}
              current={current}
              setCurrent={setCurrent}
              setAllReqData={setAllReqData}
            />
          )}
        </div>
      </section>

      <div className="flex gap-2 justify-end mr-4">
        <div
          // className={`${current === 0 ? 'hidden' : 'block'}`}
          onClick={() => {
            setBackClicked(true);
            setCurrent(current - 1);
            window.scrollTo(0, 0);
            if (current === 0) {
              navigate("../../admin/dashboard");
              console.log("first");
            }
          }}
        >
          <Backbutton />
        </div>
        <div
          className={shouldHide ? "hidden" : "block"}
          onClick={(e) => {
            e.preventDefault();
            console.log(campaignType);

            if (current === 2) {
              sessionStorage.removeItem("isButtonDisabled");
            }
            setIsNextActivated(true);
            setBackClicked(false);
          }}
        >
          <Nextbutton campaignType={campaignType} current={current} />
        </div>
      </div>
    </main>
  );
};

export const InputBox = ({
  onKeyDown,
  name,
  type = "text",
  holder,
  value,
  onChange,
  error,
}) => {
  return (
    <span className="flex flex-col ">
      <label htmlFor="">{name}</label>
      <input
        type={type}
        id={name?.toLowerCase().replace(/\s+/g, "")}
        placeholder={holder}
        className="p-3 rounded-lg outline-0 mt-2 placeholder-text-sm placeholder-tracking-[0.6px]"
        onChange={onChange}
        value={value}
        onKeyDown={onKeyDown}
      />
      {error && <div className="text-red-500"> {error} </div>}
    </span>
  );
};

export const TextBoxCustomNBuild = ({
  onKeyDown,
  name,
  type = "text",
  holder,
  value,
  onChange,
  error,
  regenerate,
  mandatory,
  edit,
  selectedOption,
  isHr,
}) => {
  return (
    <span className="flex flex-col ">
      <label
        htmlFor=""
        className={
          name.includes("HR ")
            ? "text-green-600 font-bold"
            : "text-blue-600 font-bold"
        }
      >
        {name}
      </label>
      {selectedOption === "option3" ? (
        <textarea
          type={type}
          id={name?.toLowerCase().replace(/\s+/g, "")}
          placeholder={holder}
          className={`p-3 rounded-lg outline-0 mt-2 placeholder-text-sm placeholder-tracking-[0.6px] ${regenerate ? "text-green-600" : mandatory ? "text-blue-600" : ""
            }
          `}
          onChange={onChange}
          value={value}
          onKeyDown={onKeyDown}
          disabled={!edit && !isHr}
        />
      ) : (
        <textarea
          type={type}
          id={name?.toLowerCase().replace(/\s+/g, "")}
          placeholder={holder}
          className={`p-3 rounded-lg outline-0 mt-2 placeholder-text-sm placeholder-tracking-[0.6px] ${regenerate ? "text-green-600" : mandatory ? "text-blue-600" : ""
            }
          `}
          onChange={onChange}
          value={value}
          onKeyDown={onKeyDown}
        />
      )}
      {error && <div className="text-red-500"> {error} </div>}
    </span>
  );
};

export const InputBox1 = ({
  onKeyDown,
  name,
  type = "text",
  holder,
  value,
  onChange,
}) => {
  return (
    <span className="flex flex-col ">
      <label htmlFor="">{name}</label>
      <input
        type={type}
        id={name.toLowerCase().replace(/\s+/g, "")}
        placeholder={holder}
        className="p-3 outline-0 border-[#F1F2F4] bg-[#FBFBFB] rounded-lg mt-2 placeholder-text-sm"
        onChange={onChange}
        value={value}
        onKeyDown={onKeyDown}
      />
    </span>
  );
};

const CampaignForm = ({
  isNextAcitvated,
  setIsNextActivated,
  current,
  setCurrent,
  editData,
  setAllReqData,
  campaignType,
  setCampaignType,
  backClicked,
  allReqData,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  let creatorId = location?.state?.creator;
  let creator = sessionStorage.getItem("uid");
  let userType = sessionStorage.getItem("userType");
  const [getCampaign, setCampaign] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    handleGetCampaigndata();
  }, []);

  const handleGetCampaigndata = async () => {
    const reqData = {
      is_deleted: 0,
      creator: "",
    };
    const response = await handleApiResponse(
      "/api/v1/interview/campaign_list/",
      reqData,
      1
    );
    if (response?.code === "token_not_valid") {
      navigate("/");
      sessionStorage.clear();
      return;
    }
    setCampaign(response?.data?.data?.data);
  };

  const [campName, setCampName] = useState(
    editData[5]
      ? editData[5]
      : allReqData[0]
        ? allReqData[0].name
        : getLocal("campName")
  );

  const getLocalSkills = () => {
    const skills = getLocal("skills");
    return skills ? skills : [];
  };

  const initialSkills = editData[10]
    ? editData[10]
    : allReqData[0]
      ? allReqData[0]?.required_skills
      : "";

  const [campSkills, setCampSkills] = useState(() => {
    if (Array.isArray(initialSkills)) {
      return initialSkills;
    } else if (typeof initialSkills === "string") {
      const localSkills = getLocal("skills");
      if (Array.isArray(localSkills)) {
        return localSkills;
      } else if (localSkills) {
        return localSkills.split(",").map((skill) => skill.trim());
      } else {
        return [];
      }
    } else {
      return getLocalSkills();
    }
  });

  const [jobRoleUid, setJobRoleUid] = useState(
    editData[6]
      ? editData[6]
      : allReqData[0]
        ? allReqData[0]?.jobrole
        : getLocal("roleId")
  );
  const [campType, setCampType] = useState(() => {
    const localCampType = getLocal("camp_type");
    if (localCampType) {
      return localCampType === "1" ? 1 : 2;
    }

    if (editData[4]) {
      return editData[4] === "Regular" ? 1 : 2;
    }

    if (allReqData[0]) {
      return allReqData[0].campaign_type === 1 ? "Regular" : "Mass";
    }

    return null;
  });
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const [startDate, setStartDate] = useState(() => {
    const localStartDate = getLocal("createdDate");
    const initialStartDate =
      editData[2] ||
      (allReqData[0] ? allReqData[0].start_date : localStartDate);
    return formatDate(initialStartDate);
  });

  const [endDate, setEndDate] = useState(() => {
    const localEndDate = getLocal("endDate1");
    const initialEndDate =
      editData[3] || (allReqData[0] ? allReqData[0].end_date : localEndDate);
    return formatDate(initialEndDate);
  });

  const [isModalOpen3, setIsModalOpen3] = useState(false);

  const [endDateError, setEndDateError] = useState(false);

  const [clickedButtonResume, setClickedButtonResume] = useState(
    editData[8]
      ? editData[8]
      : allReqData[0]
        ? allReqData[0]?.is_candidate_resume_allow
        : false
  );
  const [clickedButtonCoding, setClickedButtonCoding] = useState(
    editData[9]
      ? editData[9]
      : allReqData[0]
        ? allReqData[0]?.is_coding_quest_allow
        : getLocal("coding_ques")
          ? getLocal("coding_ques")
          : false
  );
  const [isOlderDate, setIsOlderDate] = useState(true);
  const [uid, setUid] = useState(
    editData[0] ? editData[0] : allReqData[0] ? allReqData[0]?.uid : null
  );

  console.log(creator, creatorId, "creatorId", editData[0]);

  const [toggle, setToggle] = useState({
    jobRole: false,
    CampType: false,
  });
  const isResume = sessionStorage.getItem("isResume");

  const [campaignError, setCampaignError] = useState({
    campName: false,
    campNameErrorMsg: "",
    jobRole: false,
    campType: false,
    startDate: false,
    endDate: false,
    // description: false,
  });

  console.log(campaignError, "campaignError");
  const [jobroleValue, setjobroleValue] = useState(
    editData[1] ? editData[1] : allReqData[0] ? allReqData[0]?.jrName : ""
  );
  console.log(
    editData[1] ? editData[1] : allReqData[0] ? allReqData[0]?.jrName : ""
  );
  console.log(jobroleValue);
  console.log(allReqData[0]?.jrName);
  const [campTypeValue, setCampTypeValue] = useState(
    editData[4]
      ? editData[4]
      : allReqData[0]
        ? allReqData[0]?.campaign_type === 1
          ? "Regular"
          : "Mass"
        : ""
  );
  const [data, setData] = useState([{ name: "Fetching..." }]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  useEffect(() => {
    if (editData[0]?.length !== 0) {
      console.log("edit data in campForm", editData);
      sessionStorage.setItem("campaignUid", editData[0]);
    }
  }, []);

  useEffect(() => {
    setCampaignType(campType);
  }, [campType]);

  useEffect(() => {
    try {
      if (allReqData[0]) {
        console.log("the name of the camp *******", allReqData[0]?.start_date);
        console.log("this is the start date ******", startDate);
      }
    } catch (e) {
      console.log(e);
    }
  }, [backClicked]);

  const handleYesClick = (e) => {
    e.preventDefault();
    setClickedButtonResume(true);
  };

  const handleNoClick = (e) => {
    e.preventDefault();
    setClickedButtonResume(false);
  };

  const handleYes1Click = (e) => {
    e.preventDefault();
    setClickedButtonCoding(true);
  };

  const handleNo1Click = (e) => {
    e.preventDefault();
    setClickedButtonCoding(false);
  };

  useEffect(() => {
    if (jobRoleUid) {
      console.log(jobRoleUid);
      sessionStorage.setItem("roleId", jobRoleUid);
      sessionStorage.setItem("roleName", jobroleValue);
    }
  }, [jobRoleUid]);

  const handleCampNameChange = (e) => {
    let name = e.target.value.trimStart();

    if (name.trim() === "") {
      setCampaignError((prevState) => ({
        ...prevState,
        campName: true,
        campNameErrorMsg: "Fill a valid Campaign name",
      }));
    } else {
      const nameExists = getCampaign.some(
        (campaign) => campaign.name.toLowerCase() === name.toLowerCase()
      );

      if (nameExists) {
        setCampaignError((prevState) => ({
          ...prevState,
          campName: true,
          campNameErrorMsg:
            "This campaign name already exists. Please choose a different name.",
        }));
      } else {
        setCampaignError((prevState) => ({
          ...prevState,
          campName: false,
          campNameErrorMsg: "",
        }));
      }
    }

    setCampName(name);
  };

  const handleReqSkillsChange = (e) => {
    const skills = e.target.value;
    setCampSkills(skills);
  };

  const handleKeyDown1 = (e) => {
    const key = e.key;
    if (!key.match(/[a-zA-Z\s]/)) {
      e.preventDefault();
    }
  };

  // const handleKeyDown = (e) => {
  //   const key = e.key;

  //   // Get the current value of the input
  //   const currentValue = e.target.value;

  //   // Check if the first character is a space
  //   if (currentValue.length === 0 && key === " ") {
  //     e.preventDefault();
  //     return;
  //   }

  //   // Allow only alphabetic characters and spaces
  //   if (!key.match(/[a-zA-Z0-9\s]/)) {
  //     e.preventDefault();
  //   }
  // };

  const handleKeyDown = (e) => {
    const key = e.key;
    const currentValue = e.target.value;

    // Prevent the first character from being a space
    if (currentValue?.length === 0 && key === " ") {
      e.preventDefault();
    }
  };

  const openModalAddJobRole = () => {
    setIsModalOpen3(true);
  };

  const closeModalAddJobRole = () => {
    setIsModalOpen3(false);
  };

  console.log(campSkills.length);

  // const requiredSkills = campSkills.split(',').map(skill => skill.trim());
  console.log(typeof campSkills);
  const sendData = async () => {
    let requiredSkills = [];

    // Ensure campSkills is a string
    if (typeof campSkills === "string") {
      requiredSkills = campSkills.split(",").map((skill) => skill.trim());
    } else {
      console.error("campSkills is not a string:", campSkills);
      requiredSkills = campSkills;
      // Handle the case where campSkills is not a string
      // For example, initialize requiredSkills with an empty array or some default value
    }

    console.log(requiredSkills, "requiredSkills", campSkills);
    const reqData = {
      name: campName,
      jobrole: jobRoleUid,
      campaign_type: campType,
      start_date: startDate + "T00:00:00Z",
      end_date: endDate + "T23:59:00Z",
      is_candidate_resume_allow: true,
      is_coding_quest_allow: clickedButtonCoding,
      // description: desc,
      ...(campSkills.length > 0 && {
        required_skills:
          requiredSkills.length > 0 ? requiredSkills : campSkills,
      }),
    };

    const editReqData = {
      name: campName,
      jobrole: jobRoleUid,
      campaign_type: campType,
      start_date: startDate + "T00:00:00Z",
      end_date: endDate + "T23:59:00Z",
      uid: uid,
      is_candidate_resume_allow: true,
      is_coding_quest_allow: clickedButtonCoding,
      // description: desc,
      ...(campSkills.length > 0 && {
        required_skills:
          requiredSkills.length > 0 ? requiredSkills : campSkills,
      }),
    };

    try {
      clearError();
      const response = await handleApiResponse(
        "/api/v1/interview/add_campaign/",
        uid !== undefined || creator === creatorId ? editReqData : reqData,
        1
      );
      if (response?.code === "token_not_valid") {
        navigate("/");
        sessionStorage.clear();
        return;
      }

      if (response?.data?.status_code === 200) {
        setAllReqData((prev) => {
          let data = prev;
          if (data) {
            reqData["jrName"] = jobroleValue;
            reqData["uid"] = response?.data?.data?.uid;

            data[0] = reqData;
          }
          return data;
        });
        setCampaignType(response?.data?.data?.campaign_type);
        toast.success(`${uid ? "edited" : "added"}`, {
          className: "custom-toast-success",
        });
        clearError();
        sessionStorage.setItem("campaignUid", response?.data?.data?.uid);
        sessionStorage.setItem("campName", response?.data?.data?.name);
        sessionStorage.setItem("jrName", response?.data?.data?.jr_name);
        sessionStorage.setItem(
          "camp_type",
          response?.data?.data?.campaign_type
        );
        sessionStorage.setItem("skills", response?.data?.data?.required_skills);
        sessionStorage.setItem("createdDate", response?.data?.data?.start_date);
        sessionStorage.setItem("endDate1", response?.data?.data?.end_date);
        sessionStorage.setItem(
          "coding_ques",
          response?.data?.data?.is_coding_quest_allow
        );
        setCurrent(1);
      } else {
        const errorMsg = response?.data?.msg;
        if (
          errorMsg?.jobrole &&
          Array.isArray(errorMsg.jobrole) &&
          errorMsg.jobrole[0]
        ) {
          toast.error("Job role does not exist", {
            className: "custom-toast-error",
          });
        } else {
          toast.error("Failed", {
            className: "custom-toast-error",
          });
        }
      }
    } catch (error) {
      console.error("Error sending data:", error);
      toast.error("An unexpected error occurred.", {
        className: "custom-toast-error",
      });
    }
  };

  const isDataValidToSend = () => {
    let isValid = 1;
    let errorsInData = Object.values(campaignError);
    if (errorsInData.includes(true)) {
      isValid = 0;
    }
    if (!isOlderDate) {
      isValid = 0;
    }
    if (!campName) {
      console.log("set campName");
      setCampaignError((prevState) => ({
        ...prevState,
        campName: true,
      }));
      isValid = 0;
    }

    if (!jobRoleUid) {
      console.log("set jobRole");
      setCampaignError((prevState) => ({
        ...prevState,
        jobRole: true,
      }));
      isValid = 0;
    }

    if (!campType) {
      console.log("set campType");
      setCampaignError((prevState) => ({
        ...prevState,
        campType: true,
      }));
      isValid = 0;
    }
    if (!startDate) {
      console.log("set start_date");
      setCampaignError((prevState) => ({
        ...prevState,
        start_date: true,
      }));
      isValid = 0;
    }
    if (!endDate) {
      console.log("set end_date");
      setCampaignError((prevState) => ({
        ...prevState,
        end_date: true,
      }));
      isValid = 0;
    }

    // if (!desc) {
    //   console.log("set end_date");
    //   setCampaignError((prevState) => ({
    //     ...prevState,
    //     description: true,
    //   }));
    //   isValid = 0;
    // }

    return isValid;
  };

  const clearError = () => {
    setCampaignError({
      campName: false,
      jobRole: false,
      campType: false,
      startDate: false,
      endDate: false,
      // description: false,
    });
  };

  useEffect(() => {
    setCampaignError((prevState) => ({
      ...prevState,
      start_date: false,
    }));
  }, [startDate]);

  useEffect(() => {
    setCampaignError((prevState) => ({
      ...prevState,
      end_date: false,
    }));
  }, [endDate]);

  useEffect(() => {
    setCampaignError((prevState) => ({
      ...prevState,
      jobRole: false,
    }));
  }, [jobRoleUid, jobroleValue]);

  useEffect(() => {
    setCampaignError((prevState) => ({
      ...prevState,
      campType: false,
    }));
  }, [campType]);

  useEffect(() => {
    if (isNextAcitvated) {
      console.log("this ran");
      if (current === 0) {
        console.log("this is 0");
        console.log(isDataValidToSend());
        if (isDataValidToSend()) {
          sendData();
        }
      }
      setIsNextActivated(false);
    }
  }, [isNextAcitvated]);

  const Error = ({ msg }) => {
    return <div className="text-red-500">{msg}</div>;
  };

  const closeToggle = () => {
    setToggle((prev) => ({
      ...prev,
      jobRole: false,
      CampType: false,
    }));
  };

  // console.log(isPopupVisible);
  // useEffect(() => {
  //   if (jobroleValue) {
  //     setIsPopupVisible(true);
  //   }
  // }, [jobroleValue]);
  // console.log(startDate);
  console.log(
    "clickedButtonCoding",
    clickedButtonCoding,
    typeof clickedButtonCoding,
    clickedButtonCoding === (false || "false")
  );
  // console.log(typeof(clickedButtonCoding));

  const { sethideCandidateToggle } = useContext(CampaignContext) || {};
  const { sethideCandidateToggleEdit } = useContext(CampaignContextEdit) || {};

  if (campType == 1) {
    if (sethideCandidateToggleEdit) {
      sethideCandidateToggleEdit(true);
    }
  } else {
    if (sethideCandidateToggleEdit) {
      sethideCandidateToggleEdit(false);
    }
  }

  const handleToggle = (value) => {
    if (sethideCandidateToggle) {
      sethideCandidateToggle(value); // Set hideCandidate to the passed value
    }
    if (sethideCandidateToggleEdit) {
      sethideCandidateToggleEdit(value);
    }
  };

  return (
    <form action="" className=" flex flex-col gap-6 fill-available">
      {/* {isPopupVisible && (
        <ModalChangeCampaign
          isOpen={isPopupVisible}
          // deleteUid={deleteUid}
          // onClose={}
        />
      )} */}
      {toggle.jobRole || toggle.CampType ? (
        <div
          className="absolute z-10 w-[100vw] h-[100vh] top-0 left-0"
          onClick={closeToggle}
        ></div>
      ) : null}

      <div className="w-full">
        <div className="flex mb-5">
          <div className="w-[50%] max-lg:w-[100%] flex flex-col ">
            <label
              htmlFor=""
              className="font-sans1 font-[500] text-[#1B3351] text-[0.9rem]"
            >
              Campaign Name
              <span className="text-red-500"> *</span>
            </label>
            <input
              type="text"
              placeholder="Enter campaign name (e.g., Software Engineer 2024)"
              value={campName}
              className="w-100 h-[50px] border-[#22284E]  border-[1px] p-[0.42rem] rounded-[7.03px] placeholder:text-[#A0AEC0] font-sans1 outline-0 mt-[10px] placeholder:text-[0.9rem] font-[400] placeholder:tracking-[0.6px]  text-[#1B3351] text-[0.9rem]"
              onChange={handleCampNameChange}
            // onKeyDown={handleKeyDown1}
            />
            {campaignError.campNameErrorMsg === ""
              ? campaignError["campName"] && (
                <Error msg={"Fill a valid Campaign name"} />
              )
              : campaignError?.campName && (
                <Error msg={campaignError.campNameErrorMsg} />
              )}
          </div>
          <div className="w-[50%] max-lg:w-[100%] flex flex-col ms-5 mx-lg:ms-0">
            <div className="flex justify-between">
              <label
                htmlFor=""
                className="font-sans1 font-[500] text-[#1B3351] text-[0.9rem]"
              >
                Job Role<span className="text-red-500"> *</span>
              </label>
              <div
                className="font-[500] text-[#E03137] text-[0.9rem] cursor-pointer"
                onClick={openModalAddJobRole}
              >
                Add Job Role
              </div>
              {isModalOpen3 && (
                <ModalAddJobRole
                  onClose={closeModalAddJobRole}
                  setData={setData}
                  setjobroleValue={setjobroleValue}
                  setJobRoleUid={setJobRoleUid}
                />
              )}
            </div>

            <span className="font-sans1 font-[500] text-[#1B3351] text-[0.9rem]">
              <DropdownJobRole
                holder="Select Job Role"
                jobroleValue={jobroleValue}
                setjobroleValue={setjobroleValue}
                setJobRoleUid={setJobRoleUid}
                toggle={toggle}
                setToggle={setToggle}
                setData={setData}
                data={data}
                isPopupVisible={isPopupVisible}
              // setIsPopupVisible={setIsPopupVisible}
              />
            </span>
            {campaignError["jobRole"] && (
              <Error msg={"Fill a valid Job Role"} />
            )}
          </div>
        </div>

        {/* Campaign Type */}
        <div className="w-[100%] flex flex-col mb-5">
          <label
            htmlFor=""
            className="font-sans1 font-[500] text-[#1B3351] text-[0.9rem]"
          >
            Campaign Type<span className="text-red-500"> *</span>
          </label>
          <span className="font-sans1 font-[500] text-[#1B3351] text-[0.9rem]">
            <Dropdown
              holder="Select campaign type"
              campTypeValue={campTypeValue}
              setCampTypeValue={setCampTypeValue}
              setCampType={setCampType}
              isRegular={handleToggle}
              toggle={toggle}
              setToggle={setToggle}
            />
          </span>
          {campaignError["campType"] && (
            <Error msg={"Fill a valid Campaign Type"} />
          )}
        </div>

        {/* Technical Skills  */}
        <div className="w-[100%] flex flex-col mb-5">
          <div className="flex items-center ">
            <label
              htmlFor=""
              className="font-sans1 font-[500] text-[#1B3351] text-[0.9rem]"
            >
              Required technical Skills (optional)
            </label>
            <FaInfoCircle
              data-tooltip-id="skillsInfo"
              className="ml-1  cursor-pointer text-gray-500"
            />
            <Tooltip id="skillsInfo" place="top" effect="solid">
              Here you can mention the in-focus and non-negotiable technical skills for the
              job role, in case there are any, for the platform to be more focused on these.
            </Tooltip>
          </div>
          <input
            id="skills"
            type="text"
            placeholder="Enter skills, separated by commas"
            className="w-100 h-[50px] border-[1px] border-[#22284E] p-[0.42rem] mt-2 rounded-[7.03px] outline-0 placeholder:text-[0.9rem] placeholder:tracking-[0.6px] font-sans1 font-[500] text-[#1B3351] text-[0.9rem]"
            onChange={handleReqSkillsChange}
            value={campSkills}
          />
        </div>

        {/*  Campaign Date  */}
        <div className="flex  mb-5 campaign-datepicker">
          <div className="w-[50%] max-lg:w-[100%] flex flex-col ">
            <label
              htmlFor=""
              className="font-sans1 font-[500] text-[#1B3351] text-[0.9rem]"
            >
              Campaign Start Date
              <span className="text-red-500"> *</span>
            </label>
            <Calander
              setStartDate={setStartDate}
              endDate={endDate}
              editStartDate={
                allReqData[0]
                  ? allReqData[0]?.start_date
                  : editData[2]?.length
                    ? editData[2]
                    : getLocal("createdDate")
                      ? getLocal("createdDate")
                      : ""
              }
            />
            {campaignError["start_date"] && (
              <Error msg={"Fill a valid Start Date"} />
            )}
          </div>
          <div className="w-[50%] max-lg:w-[100%] flex flex-col ms-5 mx-lg:ms-0">
            <label
              htmlFor=""
              className="font-sans1 font-[500] text-[#1B3351] text-[0.9rem]"
            >
              Campaign End Date<span className="text-red-500"> *</span>
            </label>
            {startDate || editData[2] ? (
              <Calander2
                setEndDate={setEndDate}
                startDate={startDate}
                setIsOlderDate={setIsOlderDate}
                editEndDate={
                  allReqData[0]
                    ? allReqData[0]?.end_date
                    : editData[3]?.length
                      ? editData[3]
                      : getLocal("endDate1")
                        ? getLocal("endDate1")
                        : ""
                }
              />
            ) : (
              <span>
                <span className="relative">
                  <input
                    className="w-[100%] h-[50px] mt-[10px] p-[0.42rem] outline-0 rounded-[7.03px] border-[1px] border-[#22284E] font-sans1 font-[500] text-[#1B3351] text-[0.9rem]"
                    type="text"
                    placeholder="Select end date"
                    readOnly
                    onClick={() => setEndDateError(true)}
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-400 pointer-events-none">
                    <img src={calendar} />
                  </div>
                </span>

                {endDateError && <Error msg={"First fill Start Date"} />}
              </span>
            )}
            {campaignError["end_date"] && isOlderDate && (
              <Error msg={"Fill a valid End Date"} />
            )}

            {!isOlderDate && (
              <Error msg={"End date cannot be older than start date"} />
            )}
          </div>
        </div>

        {/* Do you want coding Question?  */}
        <div className="w-[100%] flex flex-col mb-5">
          <div className="flex font-sans1 font-[500] text-[#1B3351] text-[0.9rem]">
            Include Coding Assessment?{" "}
          </div>
          <DropdownCodingQuestion
            campTypeValue={campTypeValue}
            setCampTypeValue={setCampTypeValue}
            setCampType={setCampType}
            toggle={toggle}
            setToggle={setToggle}
            clickedButtonCoding={clickedButtonCoding}
            setClickedButtonCoding={setClickedButtonCoding}
          />
        </div>
      </div>
    </form>
  );
};

const QuestionBuilder = ({
  isNextAcitvated,
  setIsNextActivated,
  current,
  setCurrent,
  setAllReqData,
  campaignType,
}) => {
  const navigate = useNavigate();
  const [resData, setResData] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [buttonContent, setButtonContent] = useState("Generate Questions");
  const [multipleError, setmMultipleError] = useState("");
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(null);
  console.log(confirmDelete, "confirmDelete");
  const [questions, setQuestions] = useState([]);
  const defaultQuestion = {
    id: 1,
    name: "Question 1",
    question: "",
    answer: "",
    QError: "",
    AError: "",
    isHr: true,
    addedHrQuestion: true,
    mandatory: false,
  };
  const [questionDeletePopup, setQuestionDeletePopup] = useState(false);
  const [isQuestGenerate, setQuestGenerate] = useState(false);
  const [loader, setLoader] = useState(false);
  const [hint, setHint] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [questions2, setQuestions2] = useState([{ id: 1, name: "Question 1" }]);
  const [questions1, setQuestions1] = useState([]);
  const [questions3, setQuestions3] = useState([]);
  const [hrQuesCount, setHrQuesCount] = useState(0);
  const [deletedQuestions, setDeletedQuest] = useState([]);
  const [checkedQuestions, setCheckedQuestions] = useState([]);
  const [count, setCount] = useState(0);
  const [questionsAllowed, setQuestionsAllowed] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [blendedmandatoryCount, setBlendedMandatoryCount] = useState(0);
  const [editIndex, setEditIndex] = useState(null);
  const [excelData, setExcelData] = useState([]);
  const [builderOption, setBuilderOption] = useState();
  const [builderMandatory, setBuilderMandatory] = useState(false);

  console.log(questions, "questions");

  const getId = async () => {
    const reqData = {
      campaign: sessionStorage.getItem("campaignUid"),
    };
    const response = await handleApiResponse(
      "/api/v1/interview/question/get_question/",
      reqData,
      1
    );
    console.log(response, "response");
    if (response?.data?.status_code === 200) {
      setIsUpdate(response?.data?.data?.uid);
    }
  };

  const getQuestions = async () => {
    const reqData = {
      campaign: sessionStorage.getItem("campaignUid"),
    };
    const response = await handleApiResponse(
      "/api/v1/interview/question/get_question/",
      reqData,
      1
    );
    if (response?.data?.status_code === 200) {
      setIsUpdate(response?.data?.data?.uid);
      console.log(response?.data?.data);
      setSelectedOption(`option${response?.data?.data?.quest_type}`);

      const prevQuestion = response?.data?.data?.quest;
      if (response?.data?.data?.quest_type === 1) {
        const updatedQuestions = prevQuestion.map((item, index) => ({
          id: index + 1,
          name: `Question ${index + 1}`,
          question: item.question,
          answer: item.answer,
          QError: "",
          AError: "",
          mandatory: item.mandatory,
        }));
        setQuestions1(updatedQuestions);
      } else if (response?.data?.data?.quest_type === 2) {
        const updatedQuestions = prevQuestion.map((item, index) => ({
          id: index + 1,
          name: `Question ${index + 1}`,
          question: item.question,
          answer: item.answer,
          QError: "",
          AError: "",
          isHr: item.isHr,
          mandatory: item.mandatory,
          addedHrQuestion: item.addedHrQuestion,
        }));
        setQuestions(updatedQuestions);
      } else if (response?.data?.data?.quest_type === 3) {
        let count = prevQuestion.filter((item) => item.mandatory);
        setBlendedMandatoryCount(count?.length);
        const hrQuestion = prevQuestion.filter((item) => item.mandatory);
        const nonHrQuestion = prevQuestion.filter((item) => !item.mandatory);
        const updatedQuestions = nonHrQuestion.map((item, index) => ({
          id: index + 1,
          name: `Question ${index + 1}`,
          question: item.question,
          answer: item.answer,
          QError: "",
          AError: "",
          isHr: item?.isHr,
          isEdit: item?.isEdit,
          mandatory: item.mandatory,
        }));
        const updatedHrQuestions = hrQuestion.map((item, index) => ({
          id: index + 1,
          name: `Question ${index + 1}`,
          question: item.question,
          answer: item.answer,
          QError: "",
          AError: "",
          isHr: item?.isHr,
          isEdit: item?.isEdit,
          mandatory: item.mandatory,
        }));
        setQuestions3([...updatedQuestions, ...updatedHrQuestions]);
      }
    }
  };

  useEffect(() => {
    setAllReqData((prev) => {
      let data = prev;
      data[1] = selectedOption;
      return data;
    });
  }, [selectedOption]);

  useEffect(() => {
    getId();
    if (
      getLocal("isButtonDisabled") === null ||
      getLocal("isButtonDisabled") !== "false"
    ) {
      console.log(getLocal("isButtonDisabled"));
      getQuestions();
    }
  }, []);

  const handleAddQuestion = () => {
    sessionStorage.setItem("hrQuestion", "option2");
    const newId = questions?.length + 1;
    setQuestions([
      ...questions,
      {
        id: newId,
        name: `Question ${newId}`,
        question: "",
        answer: "",
        QError: "",
        AError: "",
        isHr: true,
        mandatory: false,
        addedHrQuestion: true,
      },
    ]);
  };

  const handleUploadQuestionAnswer = async (data) => {
    console.log("Question and Answer", data);
    sessionStorage.setItem("hrQuestion", "option2");
    console.log("received data", data);
    if (data.length === 0) {
      return;
    }
    const newId = questions?.length;

    const dataToSet = data.map((ele, index) => {
      return {
        id: newId + index,
        name: `Question ${ele[0]}`,
        question: `${ele[1]}`,
        answer: `${ele[3]}`,
        QError: "",
        AError: "",
        isHr: true,
        mandatory: false,
        addedHrQuestion: true,
      };
    });

    console.log(dataToSet);

    if (newId === 0 || (newId === 1 && questions[0]?.question?.length === 0)) {
      setQuestions([...dataToSet]);
    } else {
      setQuestions([...questions, ...dataToSet]);
    }
  };

  const handleAddQuestion3 = () => {
    if (blendedmandatoryCount < 3) {
      const newId = blendedmandatoryCount + 1;
      setBlendedMandatoryCount(newId);
      setQuestions3([
        ...questions3,
        {
          name: `Question ${newId}`,
          question: "",
          answer: "",
          QError: "",
          AError: "",
          gen: "user",
          isHr: true,
          mandatory: true,
          isEdit: false,
        },
      ]);
    } else {
      toast.warning(
        "Maximum 3 Hr or Mandatory Questions are allowed to add !",
        {
          className: "custom-toast-warning",
        }
      );
    }
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setDeletedQuest([]);
    setButtonContent("Generate Questions");
    setCheckedQuestions([]);
    sessionStorage.removeItem("hrQuestion");
    setCount(0);
    setBuilderOption();
    setBuilderMandatory();
    setmMultipleError("");
    // handleTabChange();
    if (selectedOption === "option1") {
      setQuestions1(questions1);
      setQuestions3([]);
      setQuestions([]);
    } else if (selectedOption === "option2") {
      setQuestions1([]);
      setQuestions3([]);
      setQuestions(questions.length ? questions : [defaultQuestion]);
    } else if (selectedOption === "option3") {
      setQuestions1([]);
      setQuestions([]);
      setQuestions3(questions3);
    }
  };

  // const handleTabChange = async () => {
  //   const data = {
  //     quest: [],
  //     campaign: sessionStorage.getItem("campaignUid"),
  //     quest_type:
  //       selectedOption === "option1" ? 1 : selectedOption === "option2" ? 2 : 3,
  //     quest_count: 0,
  //     uid: isUpdate,
  //   };
  //   const response = await handleApiResponse(
  //     "/api/v1/interview/question/add_question/",
  //     data,
  //     1
  //   );
  //   if (response?.data?.status_code === 200) {
  //     if (campaignType === 1) {
  //       getId();
  //     }
  //   }
  // };

  const handleDeleteQuestion = (index) => {
    if (selectedOption === "option1" && questions1?.length > 0) {
      if (!checkedQuestions[index]) {
        const confirmDelete = () => {
          setDeletedQuest((prev) => [...prev, questions1[index]]);
          const updatedQuestions1 = questions1.filter(
            (_, idx) => idx !== index
          );
          setCount(count + 1);
          setQuestionsAllowed(true);
          setQuestions1(updatedQuestions1);
        };

        setQuestionDeletePopup(true);
        setConfirmDelete(() => confirmDelete);
      }
    } else if (selectedOption === "option3" && questions3?.length > 0) {
      if (!checkedQuestions[index]) {
        const isHRQuestion = questions3[index].mandatory;
        const updatedQuestions3 = questions3.filter((_, idx) => idx !== index);

        const confirmDelete = () => {
          if (isHRQuestion) {
            setBlendedMandatoryCount(blendedmandatoryCount - 1);
            let hrIndex = 1;
            updatedQuestions3?.forEach((item) => {
              if (item.mandatory) {
                item.name = `Question ${hrIndex}`;
                hrIndex += 1;
              }
            });
          }
          setCount(count + 1);
          setQuestionsAllowed(true);
          setQuestions3(updatedQuestions3);
        };

        setQuestionDeletePopup(true);
        setConfirmDelete(() => confirmDelete);
      }
    }
  };

  const addQuestions = (q, a, index) => {
    if (checkedQuestions?.length) {
      if (selectedOption === "option1") {
        questions1.map((item, ind) => {
          if (ind === checkedQuestions[index - 1]) {
            item.question = q;
            item.answer = a;
            item.regenerate = true;
          }
          if (Number(index) === checkedQuestions?.length) {
            setCheckedQuestions([]);
          }
          return item;
        });
      } else {
        questions3.map((item, ind) => {
          if (ind === checkedQuestions[index - 1]) {
            item.question = q;
            item.answer = a;
            item.regenerate = true;
          }
          if (Number(index) === checkedQuestions?.length) {
            setCheckedQuestions([]);
          }
          return item;
        });
      }

      setCount(count + 1);
      setQuestionsAllowed(true);
      setLoader(false);
    } else if (builderOption === 1) {
      const newId = questions1?.length;
      setQuestions1([
        ...questions1,
        {
          id: newId,
          name: `Question ${newId}`,
          question: q,
          answer: a,
          QError: "",
          AError: "",
          regenerate: false,
          mandatory: false,
        },
      ]);
      setCount(count + 1);
      setQuestionsAllowed(true);
      setLoader(false);
    } else if (builderOption === 3) {
      const newId = questions3?.length;
      const nonMandatoryQuestion = questions3.filter(
        (item) => !item?.mandatory
      );
      const mandatoryQuestion = questions3.filter((item) => item?.mandatory);

      setQuestions3([
        ...nonMandatoryQuestion,
        {
          id: newId,
          name: `Question ${newId}`,
          question: q,
          answer: a,
          QError: "",
          AError: "",
          regenerate: false,
          mandatory: false,
          isEdit: false,
          isHr: false,
        },
        ...mandatoryQuestion,
      ]);

      setCount(count + 1);
      setQuestionsAllowed(true);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (resData) {
      console.log(resData.trim(), "works");
      let data = JSON.parse(resData.trim());
      console.log(data.data, "works");
      addQuestions(
        data?.data?.question,
        data?.data?.answer,
        data?.data?.current_question
      );
    }
  }, [resData]);

  const handleGenerateQuestions = async (option, mandatory) => {
    let data = {
      jobrole: sessionStorage.getItem("roleId"),
      campaign_uid: sessionStorage.getItem("campaignUid"),
    };

    let mandatoryIndices = [];
    let blendedMandatoryIndices = [];

    if (questions1) {
      questions1?.forEach((item, index) => {
        if (item?.mandatory) {
          mandatoryIndices?.push(index);
        }
      });
    }

    if (questions3) {
      questions3?.forEach((item, index) => {
        if (item?.mandatory) {
          blendedMandatoryIndices?.push(index);
        }
      });
    }

    let filteredCheckedQuestions = checkedQuestions?.filter((item) => {
      return !mandatoryIndices?.includes(item);
    });

    let BlendedFilteredCheckedQuestions = checkedQuestions?.filter((item) => {
      return !blendedMandatoryIndices?.includes(item);
    });

    if (option === 1) {
      data["question"] = [...questions1];
      data["deleted_count"] =
        checkedQuestions?.length > 0 && mandatory === true
          ? 0
          : checkedQuestions?.length > 0 && mandatory === false
            ? 0
            : checkedQuestions?.length !== filteredCheckedQuestions?.length
              ? 0
              : filteredCheckedQuestions?.length
                ? filteredCheckedQuestions?.length
                : 20 - questions1?.length;
      data["total_question"] = 20 - questions1?.length;
    } else if (option === 3) {
      let fill = questions3.filter((item) => !item.isHr);
      const sentQuestion = fill.map((item, index) => ({
        question: item.question,
        answer: item.answer,
        isHr: item.gen ? true : false,
      }));

      data["question"] = [...sentQuestion];
      data["deleted_count"] =
        checkedQuestions?.length > 0 && mandatory === true
          ? 0
          : checkedQuestions?.length > 0 && mandatory === false
            ? 0
            : BlendedFilteredCheckedQuestions?.length !== checkedQuestions?.length
              ? 0
              : BlendedFilteredCheckedQuestions?.length
                ? BlendedFilteredCheckedQuestions?.length
                : 20 - questions3?.length;

      data["total_question"] = 20 - questions3?.length;
    }

    const commonElementsCount = checkedQuestions?.filter((item) =>
      mandatoryIndices?.includes(item)
    )?.length;

    const commonElementsCount1 = checkedQuestions?.filter((item) =>
      blendedMandatoryIndices?.includes(item)
    )?.length;

    let mandatoryWarningShown = false;
    const showWarningNonMadatory = () => {
      if (!mandatoryWarningShown) {
        toast.warning(
          "Select only non-mandated questions to make them mandated !",
          {
            className: "custom-toast-warning",
          }
        );
        mandatoryWarningShown = true;
      }
    };

    const showWarning = () => {
      if (!mandatoryWarningShown) {
        toast.warning(`Maximum limit of 20 questions and answers reached!`, {
          className: "custom-toast-warning",
        });
        mandatoryWarningShown = true;
      }
    };
    if (
      data["question"].length === 0 ||
      buttonContent === "Regenerate Question"
    ) {
      setLoader(true);
    }

    if (commonElementsCount > 0 || commonElementsCount1 > 0) {
      showWarningNonMadatory();
      setCheckedQuestions([]);
      setLoader(false);
    } else if (data["deleted_count"] === 0) {
      showWarning();
      setLoader(false);
    } else {
      fetchData(setResData, data);
      setBuilderOption(option);
      setBuilderMandatory(mandatory);
    }
  };

  const handleMandatory = (option, mandatory) => {
    console.log(option, mandatory, "mandatory");
    let mandatoryIndices = [];
    let blendedMandatoryIndices = [];

    if (questions1) {
      questions1?.forEach((item, index) => {
        if (item?.mandatory) {
          mandatoryIndices?.push(index);
        }
      });
    }

    if (questions3) {
      questions3?.forEach((item, index) => {
        if (item?.mandatory) {
          blendedMandatoryIndices?.push(index);
        }
      });
    }

    let filteredCheckedQuestions = checkedQuestions?.filter((item) => {
      return !mandatoryIndices?.includes(item);
    });

    let BlendedFilteredCheckedQuestions = checkedQuestions?.filter((item) => {
      return !blendedMandatoryIndices?.includes(item);
    });

    const newQuestions = [];
    if (option === 1) {
      let mandatoryWarningShown = false;
      let nonMandatoryWarningShown = false;
      const showWarningNonMadatory = () => {
        if (!mandatoryWarningShown) {
          toast.warning(
            "Select only non-mandated questions to make them mandated !",
            {
              className: "custom-toast-warning",
            }
          );
          mandatoryWarningShown = true;
        }
      };

      const showWarning = () => {
        if (!mandatoryWarningShown) {
          toast.warning(
            "Select mandated questions to make them non-mandated !",
            {
              className: "custom-toast-warning",
            }
          );
          mandatoryWarningShown = true;
        }
      };
      const showWarningRegenerate = () => {
        if (!mandatoryWarningShown) {
          toast.warning(
            "Perform the non-mandated action to regenerate the question !",
            {
              className: "custom-toast-warning",
            }
          );
          mandatoryWarningShown = true;
        }
      };
      const commonElementsCount = checkedQuestions.filter((item) =>
        mandatoryIndices.includes(item)
      )?.length;

      // console.log(commonElementsCount);

      const updatedQuestions = questions1.map((item, index) => {
        if (
          checkedQuestions.includes(index) &&
          checkedQuestions?.length + mandatoryIndices?.length <= 3 &&
          mandatory &&
          commonElementsCount === 0
        ) {
          return {
            name: `Question ${index + 1}`,
            question: item.question,
            answer: item.answer,
            QError: "",
            AError: "",
            regenerate: false,
            mandatory: true,
          };
        } else if (
          checkedQuestions.includes(index) &&
          checkedQuestions?.length > 3 &&
          mandatory
        ) {
          if (!mandatoryWarningShown) {
            toast.warning("A maximum of 3 mandated questions can be added !", {
              className: "custom-toast-warning",
            });
            mandatoryWarningShown = true;
          }
          return item;
        } else if (
          checkedQuestions.includes(index) &&
          checkedQuestions?.length + mandatoryIndices?.length > 3 &&
          mandatory &&
          commonElementsCount === 0
        ) {
          if (!nonMandatoryWarningShown) {
            toast.warning("A maximum of 3 mandatory questions can be added !", {
              className: "custom-toast-warning",
            });
            nonMandatoryWarningShown = true;
          }
          return item;
        } else if (
          checkedQuestions.includes(index) &&
          mandatoryIndices.length > 0 &&
          mandatory !== undefined &&
          !mandatory
        ) {
          return {
            name: `Question ${index + 1}`,
            question: item.question,
            answer: item.answer,
            QError: "",
            AError: "",
            regenerate: item.regenerate,
            mandatory: false,
          };
        } else if (
          checkedQuestions.includes(index) &&
          filteredCheckedQuestions.length > 0 &&
          newQuestions.length > 0 &&
          mandatory === undefined
        ) {
          const newQuestion = newQuestions.shift();
          return {
            name: `Question ${index + 1}`,
            question: newQuestion.question,
            answer: newQuestion.answer,
            QError: "",
            AError: "",
            regenerate: true,
            mandatory: false,
          };
        } else {
          commonElementsCount > 0 &&
            builderMandatory &&
            showWarningNonMadatory();
          checkedQuestions?.length === filteredCheckedQuestions?.length &&
            mandatory !== undefined &&
            !mandatory &&
            showWarning();

          commonElementsCount > 0 &&
            mandatory === undefined &&
            showWarningRegenerate();
          return {
            name: `Question ${index + 1}`,
            question: item.question,
            answer: item.answer,
            QError: "",
            AError: "",
            regenerate: item.regenerate,
            mandatory: item.mandatory,
          };
        }
      });

      const updateGenerateQues = newQuestions.map((item, index) => ({
        name: `Question ${index + 1}`,
        question: item.question,
        answer: item.answer,
        QError: "",
        AError: "",
        regenerate: false,
        mandatory: false,
      }));
      setQuestions1([...updatedQuestions, ...updateGenerateQues]);
    } else {
      let warningShown = false;

      const updatedQuestions = questions3.map((item, index) => {
        const showWarning = () => {
          if (!warningShown) {
            toast.warning("A maximum of 3 mandatory questions can be added !", {
              className: "custom-toast-warning",
            });
            warningShown = true;
          }
        };

        const showWarningNonMadatorys = () => {
          if (!warningShown) {
            toast.warning(
              "Select mandated questions to make them non-mandated !", {
              className: "custom-toast-warning",
            }
            );
            warningShown = true;
          }
        };

        const showWarningRegenerate = () => {
          if (!warningShown) {
            toast.warning(
              "Perform the non-mandated action to regenerate the question !", {
              className: "custom-toast-warning",
            }
            );
            warningShown = true;
          }
        };

        const showWarningNonMadatory = () => {
          if (!warningShown) {
            toast.warning(
              "Select only non-mandated questions to make them mandated !", {
              className: "custom-toast-warning",
            }
            );
            warningShown = true;
          }
        };

        const showConfirmationMandatory = () => {
          if (!warningShown) {
            toast.success("Selected question mandated and moved to Hr !", {
              className: "custom-toast-success",
            });
            warningShown = true;
          }
        };

        const commonElementsCount = checkedQuestions.filter((item) =>
          blendedMandatoryIndices.includes(item)
        )?.length;
        console.log("first", commonElementsCount, mandatory);

        if (
          checkedQuestions.includes(index) &&
          checkedQuestions?.length + blendedMandatoryIndices?.length <= 3 &&
          mandatory &&
          commonElementsCount === 0 &&
          !item.edit
        ) {
          setBlendedMandatoryCount(
            checkedQuestions?.length + blendedMandatoryIndices?.length
          );
          console.log("first", item);
          mandatory &&
            blendedMandatoryIndices?.length < 3 &&
            showConfirmationMandatory();
          return {
            name: item.isHr ? item.name : `Question ${index + 1}`,
            question: item.question,
            answer: item.answer,
            QError: "",
            AError: "",
            isEdit: item.isEdit || false,
            isHr: item.isHr ? true : false,
            regenerate: false,
            mandatory: true,
          };
        } else if (
          checkedQuestions.includes(index) &&
          checkedQuestions?.length + blendedMandatoryIndices?.length > 3 &&
          mandatory
        ) {
          // console.log("second");
          if (
            checkedQuestions?.length + blendedMandatoryIndices?.length > 3 &&
            commonElementsCount === 0 &&
            !item.isEdit
          ) {
            showWarning();
          } else {
            showWarningNonMadatory();
          }
          return item;
        } else if (
          checkedQuestions.includes(index) &&
          blendedMandatoryIndices.length > 0 &&
          mandatory !== undefined &&
          !mandatory
        ) {
          const commonElementsCount = checkedQuestions.filter((item) =>
            blendedMandatoryIndices.includes(item)
          )?.length;
          setBlendedMandatoryCount(blendedmandatoryCount - commonElementsCount);
          // console.log("third");
          return {
            name: `Question ${index + 1}`,
            question: item.question,
            answer: item.answer,
            QError: "",
            AError: "",
            isEdit: false,
            regenerate: item.regenerate,
            mandatory: false,
            isHr: false,
          };
        } else if (
          checkedQuestions.includes(index) &&
          BlendedFilteredCheckedQuestions.length > 0 &&
          newQuestions.length > 0 &&
          mandatory === undefined
        ) {
          // console.log("fourth");
          const newQuestion = newQuestions.shift();
          return {
            name: `Question ${index + 1}`,
            question: newQuestion.question,
            answer: newQuestion.answer,
            QError: "",
            AError: "",
            isEdit: false,
            regenerate: true,
            mandatory: false,
            isHr: false,
          };
        } else {
          let fill = questions3.filter((item) => item?.isEdit);
          let fill1 = questions3.filter((item) => item?.isHr);
          if (
            item?.edit &&
            !item.isEdit &&
            commonElementsCount === 0 &&
            fill?.length + fill1?.length < 3
          ) {
            if (blendedmandatoryCount < 3 && !item.mandatory) {
              setBlendedMandatoryCount(blendedMandatoryIndices?.length + 1);
            }
            // console.log("fifth", item);

            item?.edit &&
              blendedmandatoryCount === 3 &&
              !item.mandatory &&
              showWarningNonMadatory();

            return {
              name: `Question ${index + 1}`,
              question: item.question,
              answer: item.answer,
              QError: "",
              AError: "",
              isEdit:
                fill?.length + fill1?.length === 3 ||
                  (blendedmandatoryCount === 3 && !item.mandatory)
                  ? false
                  : true,
              regenerate: item.regenerate,
              mandatory:
                fill?.length + fill1?.length === 3 ||
                  (blendedmandatoryCount === 3 && !item.mandatory)
                  ? false
                  : true,
              isHr: false,
            };
          } else {
            console.log("sixth");
            mandatory && commonElementsCount > 0 && showWarningNonMadatory();
            checkedQuestions?.length ===
              BlendedFilteredCheckedQuestions?.length &&
              mandatory !== undefined &&
              !mandatory &&
              showWarningNonMadatorys();
            // mandatory &&
            //   item?.edit &&
            //   item?.isEdit &&
            //   showWarningNonMadatory();
            // item?.edit &&
            //   fill?.length + fill1?.length === 3 &&
            //   showWarningNonMadatory();

            mandatory === undefined &&
              commonElementsCount > 0 &&
              showWarningRegenerate();
            return {
              name: `Question ${index + 1}`,
              question: item.question,
              answer: item.answer,
              QError: "",
              AError: "",
              isEdit: item.isEdit || false,
              regenerate: item.regenerate,
              mandatory: item.mandatory ? item.mandatory : false,
              isHr: item.isHr || false,
            };
          }
        }
      });

      const mandatoryQuestions = updatedQuestions.filter(
        (item) => item.mandatory
      );

      let mandatoryCount = 1;
      mandatoryQuestions?.forEach((item) => {
        if (item.mandatory) {
          item.name = `Question ${mandatoryCount}`;
          mandatoryCount++;
        }
      });

      const filterUpdatedQuestions = updatedQuestions.filter(
        (item) => !item.mandatory
      );

      const updateGenerateQues = newQuestions.map((item, index) => ({
        name: `Question ${index + 1}`,
        question: item.question,
        answer: item.answer,
        QError: "",
        AError: "",
        regenerate: false,
        mandatory: false,
        isEdit: false,
        isHr: false,
      }));

      setQuestions3([
        ...filterUpdatedQuestions,
        ...updateGenerateQues,
        ...mandatoryQuestions,
        // ...hrQuestion,
      ]);
    }

    setCheckedQuestions([]);
    setCount(count + 1);
    setQuestionsAllowed(true);
    // setLoader(false);
    setQuestGenerate(true);
    setButtonContent("Regenerate Questions");
    setDeletedQuest([]);
  };

  // const handleHrAnswerInput = (e, Aid) => {
  //   setQuestions((prev) => {
  //     let data = prev.map((item) => {
  //       if (item.id === Aid) {
  //         return { ...item, AError: "", answer: e.target.value };
  //       }
  //       return item;
  //     });
  //     return data;
  //   });
  // };

  const handleHrAnswerInput = useCallback((e, Aid) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((item) =>
        item.id === Aid ? { ...item, AError: "", answer: e.target.value } : item
      )
    );
  }, []);

  // const handleBlendedAnswerInput = (e, Aid, hr) => {
  //   let newValue = e.target.value;
  //   setQuestions3((prev) => {
  //     let data = prev.map((item, idx) => {
  //       if (idx === Aid) {
  //         return {
  //           ...item,
  //           AError: "",
  //           edit: true,
  //           answer: newValue,
  //         };
  //       }
  //       return item;
  //     });
  //     return data;
  //   });
  // };

  const handleBlendedAnswerInput = useCallback((e, Aid, hr) => {
    let newValue = e.target.value;
    setQuestions3((prevQuestions) =>
      prevQuestions.map((item, idx) =>
        idx === Aid
          ? { ...item, AError: "", edit: true, answer: newValue }
          : item
      )
    );
  }, []);

  // const handleHrQuestionInput = (e, Qid) => {
  //   setQuestions((prev) => {
  //     let data = prev.map((item) => {
  //       if (item.id === Qid) {
  //         return { ...item, QError: '', question: e.target.value };
  //       }
  //       return item;
  //     });
  //     return data;
  //   });
  // };

  const handleHrQuestionInput = useCallback((e, Qid) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((item) =>
        item.id === Qid
          ? { ...item, QError: "", question: e.target.value }
          : item
      )
    );
  }, []);

  // const handleBlendedQuestionInput = (e, Qid, hr) => {
  //   let newValue = e.target.value;
  //   setQuestions3((prev) => {
  //     let data = prev.map((item, idx) => {
  //       if (idx === Qid) {
  //         return {
  //           ...item,
  //           QError: "",
  //           edit: true,
  //           question: newValue,
  //         };
  //       }
  //       return item;
  //     });
  //     return data;
  //   });
  // };

  const handleBlendedQuestionInput = useCallback((e, Qid, hr) => {
    let newValue = e.target.value;
    setQuestions3((prevQuestions) =>
      prevQuestions.map((item, idx) =>
        idx === Qid
          ? { ...item, QError: "", edit: true, question: newValue }
          : item
      )
    );
  }, []);

  const setErrors = () => {
    if (selectedOption === "option2") {
      questions.map((ele) => {
        if (!ele.answer) {
          ele.AError = "Answer cannot be empty";
        }
        if (!ele.question) {
          ele.QError = "Question cannot be empty";
        }
      });
      console.log(questions);
    }
    if (selectedOption === "option3") {
      questions3.map((ele) => {
        if (!ele.answer) {
          ele.AError = "Answer cannot be empty";
        }
        if (!ele.question) {
          ele.QError = "Question cannot be empty";
        }
      });
    }
  };

  const apiCall = async () => {
    if (questionsAllowed) {
      const uploadQuestions = questions.map(
        ({ question, answer, isHr, mandatory, addedHrQuestion }) => ({
          question,
          answer,
          isHr,
          mandatory,
          addedHrQuestion,
        })
      );

      const uploadBlendedQuestions = questions3.map(
        ({ question, answer, isHr, mandatory, isEdit, name }) => ({
          name,
          question,
          answer,
          isHr,
          mandatory,
          isEdit,
        })
      );
      const uploadAiQuestions = questions1.map(
        ({ question, answer, mandatory, name }) => ({
          question,
          answer,
          mandatory,
          name,
        })
      );
      const reqDataAI = {
        quest: uploadAiQuestions,
        campaign: sessionStorage.getItem("campaignUid"),
        quest_type: "1",
        quest_count: questions.length,
      };
      const reqDataAIUpdate = {
        quest: uploadAiQuestions,
        campaign: sessionStorage.getItem("campaignUid"),
        quest_type: "1",
        quest_count: questions.length,
        uid: isUpdate,
      };

      const reqDataBlended = {
        quest: uploadBlendedQuestions,
        campaign: sessionStorage.getItem("campaignUid"),
        quest_type: "3",
        quest_count: questions.length,
      };
      const reqDataBlendedUpdate = {
        quest: uploadBlendedQuestions,
        campaign: sessionStorage.getItem("campaignUid"),
        quest_type: "3",
        quest_count: questions.length,
        uid: isUpdate,
      };

      // setAllReqData((prev) => {
      //   let newData = [...prev, reqData];
      //   return newData;
      // });
      console.log(isUpdate);
      if (isUpdate) {
        let reqData = {
          quest: uploadQuestions,
          campaign: sessionStorage.getItem("campaignUid"),
          quest_type: "2",
          quest_count: questions.length,
          uid: isUpdate,
        };
        if (selectedOption === "option1") {
          reqData = reqDataAIUpdate;
        } else if (selectedOption === "option3") {
          reqData = reqDataBlendedUpdate;
        }
        console.log(reqData);
        if (reqData.quest.length) {
          const response = await handleApiResponse(
            "/api/v1/interview/question/add_question/",
            reqData,
            1
          );
          if (response?.data?.status_code === 200) {
            if (campaignType === 1) {
              getId();
              // toast.success("Questions updated",{className: "custom-toast-success",});
              // setCurrent(2);
            } else {
              getId();
              setIsNextActivated(false);
              // toast.success("Campaign Updated successfully.",{className: "custom-toast-success",});
              // navigate("/admin/campaign");
            }
          } else {
            try {
              toast.error(response?.data?.msg?.jobrole[0], {
                className: "custom-toast-error"
              });
              console.log("error", response?.data?.msg?.jobrole[0]);
            } catch {
              toast.error(response?.data?.msg, {
                className: "custom-toast-error"
              });
            }
          }
        }
        // else {
        //   toast.warning("No question are generated/added");
        // }
      } else {
        let reqData = {
          quest: uploadQuestions,
          campaign: sessionStorage.getItem("campaignUid"),
          quest_type: "2",
          quest_count: questions.length,
        };
        if (selectedOption === "option1") {
          reqData = reqDataAI;
        } else if (selectedOption === "option3") {
          reqData = reqDataBlended;
        }
        console.log(reqData);
        if (reqData.quest.length) {
          const response = await handleApiResponse(
            "/api/v1/interview/question/add_question/",
            reqData,
            1
          );
          if (response?.data?.status_code === 200) {
            console.log("first");
            if (campaignType === 1) {
              getId();
              // toast.success("Questions Added");
              // setCurrent(2);
            } else {
              getId();
              setIsNextActivated(false);
              // toast.success("Campaign Created successfully.");
              // navigate("/admin/campaign");
            }
          } else {
            try {
              toast.error(response?.data?.msg?.jobrole[0], {
                className: "custom-toast-error"
              });
              console.log("error", response?.data?.msg?.jobrole[0]);
            } catch {
              toast.error(response?.data?.msg, {
                className: "custom-toast-error"
              });
            }
          }
        } else {
          toast.warning("There should be exact 20 Questions", {
            className: "custom-toast-warning"
          });
        }
      }
    }
  };

  useEffect(() => {
    if (count > 0) {
      apiCall();
    }
  }, [count]);

  const handleCheckboxChange = (index) => {
    if (checkedQuestions.includes(index)) {
      setCheckedQuestions((prevChecked) =>
        prevChecked.filter((item) => item !== index)
      );
    } else {
      setCheckedQuestions((prevChecked) => [...prevChecked, index]);
    }
  };

  const hintGeneration = async () => {
    try {
      const response = await handleApiResponse(
        "/api/v1/interview/question/get_hint/",
        {
          jobrole_uid: getLocal("roleId"),
        },
        1
      );
      console.log("API Response:", response.data);
      if (response?.data?.status_code === 200) {
        setHint(response?.data?.data?.hint_msg);
      } else if (response.status_code === 400 && response.status === false) {
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  useEffect(() => {
    hintGeneration();
  }, []);

  useEffect(() => {
    if (checkedQuestions.length) {
      setButtonContent("Regenerate Question");
    }
    if (checkedQuestions.length === 0) {
      setButtonContent("Generate Question");
    }
    if (isNextAcitvated) {
      if (current === 1) {
        if (selectedOption) {
          setErrors();
          let errorCollection = questions.filter((e) => e.QError || e.AError);
          let errorCollection2 = questions3.filter((e) => e.QError || e.AError);

          if (errorCollection.length === 0 && errorCollection2.length === 0) {
            if (selectedOption === "option3" && questions3.length < 15) {
              if (questions3.length < 1) {
                toast.info("Generate Blended Question", { className: "custom-toast-info" });
              } else {
                toast.info(
                  `A minimum of 15 questions is required for Blended Question. You currently have ${questions3.length} questions.`, {
                  className: "custom-toast-info"
                }
                );
              }
            } else if (
              (selectedOption === "option3" &&
                hrQuesCount + blendedmandatoryCount < 1) ||
              hrQuesCount + blendedmandatoryCount > 3
            ) {
              toast.warning(
                "minimum 1 and maximum 3 HR or Mandatory questions allowed !",
                {
                  className: "custom-toast-warning"
                }
              );
            } else if (selectedOption === "option2" && questions.length < 15) {
              if (questions.length < 1) {
                toast.info("minimum 1 HR question", {
                  className: "custom-toast-info"
                });
              } else {
                toast.info(
                  `A minimum of 15 questions is required for HR Question. You currently have ${questions.length} questions.`, {
                  className: "custom-toast-info"
                }
                );
              }
            } else if (selectedOption === "option1" && questions1.length < 15) {
              if (questions1.length < 1) {
                toast.info("Generate Ai Question", {
                  className: "custom-toast-info"
                });
              } else {
                toast.info(
                  `A minimum of 15 questions is required for AI Question. You currently have ${questions1.length} questions.`, {
                  className: "custom-toast-info"
                }
                );
              }
            } else {
              setmMultipleError("");
              setIsComponentVisible(true);
              let hr = questions3.filter((item) => item?.isHr);
              if (
                (selectedOption === "option3" && hr?.length > 0) ||
                (selectedOption === "option2" && questions?.length > 0)
              ) {
                setCount(count + 1);
                setQuestionsAllowed(true);
                if (selectedOption === "option2" && questions?.length > 0) {
                  sessionStorage.removeItem("hrQuestion");
                }
              }
            }
          } else {
            const questionWithError = errorCollection
              .map((ele) => ele.name)
              .join(", ");
            console.log("question with error ", questionWithError);
            // setmMultipleError(`Error in ${questionWithError}`);
            // setmMultipleError(
            //   `please fill HR questions and answers ${questionWithError}`
            // );
            setmMultipleError(`please fill HR questions and answers`);
          }
        } else {
          toast.warning("Please Select the Question type", {
            className: "custom-toast-warning"
          });
        }
      }
      setIsNextActivated(false);
    }
  }, [isNextAcitvated, checkedQuestions, questionsAllowed]);

  const handleCopy = (e) => {
    e.preventDefault();
    console.log("worked");
  };

  const closeModal5 = () => {
    setIsComponentVisible(false);
  };

  const closeModalDeleteQuestion = () => {
    setQuestionDeletePopup(false);
    setButtonContent("Generate Questions");
    setCheckedQuestions([]);
  };

  const handleHrQuestionMandatory = (mandatory) => {
    const selectedQuestions = questions.filter((_, index) =>
      checkedQuestions.includes(index)
    );

    const mandatoryQuestionsCount = questions.filter(
      (question) => question.mandatory
    ).length;

    let warningShown = false;

    if (mandatory) {
      const alreadyMandatoryCount = selectedQuestions.filter(
        (question) => question.mandatory
      ).length;

      if (
        mandatoryQuestionsCount -
        alreadyMandatoryCount +
        selectedQuestions.length >
        3
      ) {
        toast.warning("Maximum limit of 3 mandatory questions reached", {
          className: "custom-toast-warning"
        });
      } else {
        const updatedHrQuestions = questions.map((item, index) => {
          if (checkedQuestions.includes(index)) {
            if (item.mandatory) {
              if (!warningShown) {
                toast.warning("Question is already mandatory", {
                  className: "custom-toast-warning"
                });
                warningShown = true;
              }
            } else {
              item.mandatory = true;
            }
          }
          return item;
        });
        setQuestions(updatedHrQuestions);
        setCount(count + 1);
        setQuestionsAllowed(true);
      }
    } else {
      const updatedHrQuestions = questions.map((item, index) => {
        if (checkedQuestions.includes(index)) {
          item.mandatory = false;
        }
        return item;
      });
      setQuestions(updatedHrQuestions);
    }
    setCheckedQuestions([]);
  };

  const handleDeleteQuestion1 = (id) => {
    const confirmDelete = () => {
      const updatedQuestions = questions.filter(
        (question) => question.id !== id
      );
      const renumberedQuestions = updatedQuestions.map((item, index) => ({
        ...item,
        name: `Question ${index + 1}`,
      }));

      setQuestions(renumberedQuestions);
    };

    setQuestionDeletePopup(true);
    setConfirmDelete(() => confirmDelete);
  };

  const handleEditBlendedQuestAns = (index) => {
    if (questions3[index].isHr) {
      setCount(count + 1);
      setQuestionsAllowed(true);
      setQuestions3((prev) => {
        let data = prev.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              edit: false,
            };
          }
          return item;
        });
        return data;
      });
    } else {
      handleMandatory(3, true);
    }
    setEditIndex(null);
  };

  let filterAiMadated = questions1?.filter((item) => item?.mandatory);
  let filterHrMadated = questions?.filter((item) => item?.mandatory);

  const handleEditClick = (index) => {
    if (blendedmandatoryCount < 3) {
      if (editIndex === index) {
        setEditIndex(null);
      } else {
        setEditIndex(index);
      }
    } else {
      if (!questions3[index].mandatory) {
        toast.warning("A maximum of 3 mandated questions can be edit !", {
          className: "custom-toast-warning"
        });
      } else {
        if (editIndex === index) {
          setEditIndex(null);
        } else {
          setEditIndex(index);
        }
      }
    }
  };

  const handleDownloadFormat = () => {
    const data = [
      ["Question No.", "Question", "Answer No.", "Answer"],
      [1, "Q1", 1, "A1"],
      [2, "Q2", 2, "A2"],
      [3, "Q3", 3, "A3"],
      [4, "Q4", 4, "A4"],
      [5, "Q5", 5, "A5"],
    ];

    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(data);

    XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet1");

    XLSX.writeFile(workBook, "Clevercruit_Question_and_Answer.xlsx");
  };

  return (
    <section className="gap-4 fill-available">
      {questionDeletePopup && (
        <ModalDeleteQuestionPopup
          onConfirm={confirmDelete}
          onClose={closeModalDeleteQuestion}
        />
      )}
      {isComponentVisible && (
        <ModalAddConfirmationPopup
          // uid={uuid}
          apiCall={apiCall}
          onClose={closeModal5}
          setQuestionsAllowed={setQuestionsAllowed}
          selectedOption={selectedOption}
          questions3={questions3?.length}
          questions1={questions1?.length}
          filterAiMadated={filterAiMadated?.length}
          hrQuesCount={hrQuesCount}
          questions={questions?.length}
          filterHrMadated={filterHrMadated?.length}
          blendedmandatoryCount={blendedmandatoryCount}
          setCurrent={setCurrent}
          campaignType={campaignType}
        // setUuid={setUuid}
        />
      )}
      <div className="flex font-sans1 text-[16px] font-[600] text-[#1B3351] flex-col gap-[10px] bg-[#F5F8FF] rounded-[20px] p-[20px]">
        <p>
          Select Questions Types<span className="text-red-500">*</span>
        </p>
        <div className="flex justify-between items-center  mb-3 flex-wrap">
          <div className="lg:w-6/12 xl:w-4/12 px-3 mb-3">
            <label className="flex items-center text-[14px] px-3 w-full  h-[42px] justify-center border-[1px] border-[#22284E] rounded-[100px]">
              <input
                type="radio"
                name="options"
                value="option1"
                checked={selectedOption === "option1"}
                onChange={handleOptionChange}
                className="mr-2 form-radio text-gray-500 focus:border-gray-300 focus:ring-gray-300 hidden"
              />
              <span className="flex items-center ">
                <span
                  className={`w-5 h-5 mr-2 cursor-pointer ${selectedOption === "option1"
                      ? "border-[5px] border-blue-500 bg-[#fff]"
                      : "border-2 border-[#BFC6D1]"
                    } rounded-full`}
                ></span>
                <span className="text-gray-700 font-semibold">AI-Generated only</span>
              </span>
            </label>
          </div>

          <div className="lg:w-6/12 xl:w-4/12 px-3 mb-3">
            <label className="flex items-center text-[14px] w-full px-3  h-[42px] justify-center border-[1px] border-[#22284E] rounded-[100px]">
              <input
                type="radio"
                name="options"
                value="option3"
                checked={selectedOption === "option3"}
                onChange={handleOptionChange}
                className="mr-2 form-radio text-gray-500 focus:border-gray-300 focus:ring-gray-300 hidden"
              />
              <span className="flex items-center">
                <span
                  className={`w-5 h-5 mr-2 cursor-pointer ${selectedOption === "option3"
                      ? "border-[5px] border-blue-500 bg-[#fff]"
                      : "border-2 border-[#BFC6D1]"
                    } rounded-full`}
                ></span>
                <span className="text-gray-700 font-semibold">
                  AI-generated + User-crafted
                </span>
              </span>
            </label>
          </div>

          <div className="lg:w-6/12 xl:w-4/12 px-3 mb-3">
            <label className="flex items-center text-[14px] w-full px-3  h-[42px] justify-center border-[1px] border-[#22284E] rounded-[100px]">
              <input
                type="radio"
                name="options"
                value="option2"
                checked={selectedOption === "option2"}
                onChange={handleOptionChange}
                className="mr-2 form-radio text-gray-500 focus:border-gray-300 focus:ring-gray-300 hidden"
              />
              <span className="flex items-center">
                <span
                  className={`w-5 h-5 mr-2 cursor-pointer ${selectedOption === "option2"
                      ? "border-[5px] border-blue-500 bg-[#fff]"
                      : "border-2 border-[#BFC6D1]"
                    } rounded-full`}
                ></span>
                <span className="text-gray-700 font-semibold">
                  User-crafted only
                </span>
              </span>
            </label>
          </div>

        </div>
      </div>

      {selectedOption && (
        <div className="font-sans1 font-[14px] font-medium mt-[15px] flex gap-[10px] sm:justify-start md:justify-end pr-[20px] flex-wrap">
          {selectedOption === "option2" && (
            <>
              <div className="ease-in duration-100 h-fit border-[#222850] inline-block border-[1px] hover:bg-[#222850]  rounded-[12px] p-[3.51px]">
                <button
                  onClick={handleDownloadFormat}
                  className="bg-[#F5F8FF] overflow-hidden p-[6px] text-[#222850] border-[#22284E1A] border-[1px] h-max rounded-[8px] w-[163px]"
                >
                  Download Format
                </button>
              </div>
              <div className="ease-in duration-100 h-fit border-[#222850] inline-block border-[1px] hover:bg-[#222850]  rounded-[12px] p-[3.51px]">
                <button
                  onClick={() => setOpenImport(true)}
                  className="bg-[#F5F8FF] overflow-hidden p-[6px] text-[#222850] border-[#22284E1A] border-[1px] h-max rounded-[8px] w-[163px]"
                >
                  Upload Quesitons
                </button>
              </div>
            </>
          )}

          <div className="ease-in duration-100 h-fit border-[#222850] inline-block border-[1px] hover:bg-[#222850]  rounded-[12px] p-[3.51px]">
            <button
              onClick={() => {
                if (selectedOption === "option2") {
                  handleHrQuestionMandatory(false);
                } else {
                  handleMandatory(selectedOption === "option1" ? 1 : 3, false);
                }
              }}
              className="bg-[#F5F8FF] overflow-hidden p-[6px] text-[#222850] border-[#22284E1A] border-[1px] h-max rounded-[8px] w-[163px]"
            >
              Make Non-mandatory
            </button>
          </div>

          <div className="ease-in duration-100 h-fit border-[#222850] inline-block border-[1px] hover:bg-[#222850]  rounded-[12px] p-[3.51px]">
            <button
              onClick={() => {
                if (selectedOption === "option2") {
                  handleHrQuestionMandatory(true);
                } else {
                  handleMandatory(selectedOption === "option1" ? 1 : 3, true);
                }
              }}
              className="bg-[#F5F8FF] text-[#222850] p-[6px] border-[#22284E1A] border-[1px] rounded-[8px] w-[163px]"
            >
              Set as Mandatory
            </button>
          </div>

          {selectedOption === "option2" ? (
            <div className="ease-in duration-100 h-fit border-[#222850] inline-block border-[1px] hover:bg-[#222850]  rounded-[12px] p-[3.51px]">
              <button
                onClick={handleAddQuestion}
                className="bg-[#F5F8FF] text-[#222850] p-[6px] border-[#22284E1A] border-[1px] rounded-[8px] w-[163px]"
              >
                Add Question
              </button>
            </div>
          ) : (
            selectedOption === "option3" &&
            questions3.length > 0 && (
              <div className="ease-in duration-100 h-fit border-[#222850] inline-block border-[1px] hover:bg-[#222850]  rounded-[12px] p-[3.51px]">
                <button
                  onClick={handleAddQuestion3}
                  className="bg-[#F5F8FF] text-[#222850] p-[6px] border-[#22284E1A] border-[1px] rounded-[8px] w-[163px]"
                >
                  Add Question
                </button>
              </div>
            )
          )}
          {selectedOption !== "option2" && (
            <div className="ease-in duration-100 h-fit border-[#222850] inline-block border-[1px] hover:bg-[#222850]  rounded-[12px] p-[3.51px]">
              <button
                onClick={() =>
                  handleGenerateQuestions(selectedOption === "option1" ? 1 : 3)
                }
                className="bg-[#F5F8FF] text-[#222850] p-[6px] border-[#22284E1A] border-[1px] rounded-[8px] w-[163px]"
              >
                {buttonContent}
              </button>
            </div>
          )}
        </div>
      )}

      <div
        className="overflow-y-auto mt-[15px] pb-5"
        id="questions-list"
        style={{
          height: `calc(100vh - 390px)`,
        }}
      >
        {selectedOption === "option1" &&
          questions1.map((question, index) => (
            <QuestionFormat
              key={index}
              selectedOption={selectedOption}
              qestno={index}
              question={question}
              checkedQuest={checkedQuestions.includes(index)}
              handleCheckboxChange={handleCheckboxChange}
              handleDeleteQuestion={() => handleDeleteQuestion(index)}
            />
          ))}
        {selectedOption === "option3" &&
          questions3.map((question, index) => {
            const isEditing = editIndex === index;
            return (
              <QuestionFormat
                key={index}
                selectedOption={selectedOption}
                qestno={index}
                question={question}
                checkedQuest={checkedQuestions.includes(index)}
                handleCheckboxChange={handleCheckboxChange}
                handleDeleteQuestion={() => handleDeleteQuestion(index)}
                onChange={(e) =>
                  handleBlendedQuestionInput(e, index, question?.isHr)
                }
                onChange1={(e) =>
                  handleBlendedAnswerInput(e, index, question?.isHr)
                }
                disabled={!isEditing && !question.isHr}
                error={question.QError}
                regenerate={question.regenerate}
                mandatory={question.mandatory}
                edit={isEditing}
                isHr={question.isHr}
                handleEditClick={() => handleEditClick(index)}
                handleEditBlendedQuestAns={() =>
                  handleEditBlendedQuestAns(index)
                }
                multipleError={multipleError}
              />
            );
          })}
        {selectedOption === "option2" &&
          questions.map((question, index) => (
            <QuestionFormat
              key={index}
              selectedOption={selectedOption}
              qestno={index}
              question={question}
              checkedQuest={checkedQuestions.includes(index)}
              onChange={(e) => handleHrQuestionInput(e, question.id)}
              onChange1={(e) => handleHrAnswerInput(e, question.id)}
              handleCheckboxChange={handleCheckboxChange}
              handleDeleteQuestion={() => handleDeleteQuestion1(question.id)}
            />
          ))}
        {selectedOption === "option2" && multipleError && (
          <div className="mt-4 text-center text-red-500 max-h-[5rem] overflow-auto">
            {multipleError}
          </div>
        )}

        {openImport && (
          <ModalAddHrQuestions
            onClose={setOpenImport}
            excelData={excelData}
            setExcelData={handleUploadQuestionAnswer}
          />
        )}
      </div>
      {loader && <Loader />}

      {/* {selectedOption === "option2" && (
        <>
          <div className="mt-4 ">
            <p>
          (Questions will be completely AI generated The purpose of lorem ipsum
          is to create a natural looking block of text (sentence, paragraph,
          page, etc.) that doesn't distract from the layout. A practice not
          without controversy, laying out pages with meaningless filler text can
          be very useful when the focus is meant to be on design, not content.)
        </p>
            <p>
              <b>NOTE </b> : These are the types of questions that will be
              generated by AI: introduction, conclusion, deep dive and
              follow-ups.
            </p>
          </div>
          <div className="mt-4">
            <p>
              <b>HINT</b> : {hint}
            </p>
          </div>
        </>
      )} */}
      {/* {selectedOption === "option1" && (
        <>
          <div className="flex mt-4 gap-4 items-center justify-between mb-4">
            {questions1?.length > 0 && (
              <>
                <div className="flex">
                  <div onClick={() => handleMandatory(1, true)}>
                    <Button name={"Make Mandatory"} />
                  </div>
                  <FaInfoCircle
                    data-tooltip-id="Make Mandatory"
                    className="ml-2 cursor-pointer text-gray-500"
                  />
                  <Tooltip id="Make Mandatory" place="top" effect="solid">
                    Clicking on this will make the selected questions mandatory.
                  </Tooltip>
                </div>

                <div className="flex">
                  <div
                    // onClick={() => handleGenerateQuestions(1, false)}
                    onClick={() => handleMandatory(1, false)}
                  >
                    <Button name={"Not Mandatory"} />
                  </div>
                  <FaInfoCircle
                    data-tooltip-id="Not Mandatory"
                    className="ml-2 cursor-pointer text-gray-500"
                  />
                  <Tooltip id="Not Mandatory" place="top" effect="solid">
                    Clicking on this will make the mandatory questions not
                    mandatory.
                  </Tooltip>
                </div>
              </>
            )}
            <div className="flex">
              <div onClick={() => handleGenerateQuestions(1)}>
                <Button name={buttonContent} />
              </div>
              <FaInfoCircle
                data-tooltip-id="skillsInfo"
                className="ml-2 cursor-pointer text-gray-500"
              />
              <Tooltip id="skillsInfo" place="top" effect="solid">
                Clicking on the Generate Question button will generate questions
                from AI. Next, you can regenerate the selected questions from AI
                again by clicking Regenerate Questions.
              </Tooltip>
            </div>
          </div>
          <div className=" mt-2 flex h-[60vh] flex-col overflow-y-scroll">
            <>
              {questions1?.map((question, ind) => {
                return (
                  <div className="flex" key={ind}>
                    <div className="flex items-baseline">
                      <input
                        type="checkbox"
                        checked={checkedQuestions.includes(ind)}
                        onChange={() => handleCheckboxChange(ind)}
                        className="mr-4 mt-1"
                      />
                    </div>
                    <div className="w-[80%]">
                      <label
                        htmlFor=""
                        className={`${question.mandatory && "text-blue-600"}`}
                      >
                        {question.mandatory
                          ? "AI Question " + (ind + 1) + " (Mandated)"
                          : "AI Question " + (ind + 1)}
                      </label>
                      <textarea
                        type="text"
                        className={`p-3 rounded-lg outline-0 mt-2 placeholder-text-sm placeholder-tracking-[0.6px] fill-available ${
                          question?.regenerate
                            ? "text-green-600"
                            : question?.mandatory
                            ? "text-blue-600"
                            : ""
                        }`}
                        value={question?.question}
                        onCopy={(e) => handleCopy(e)}
                        onCut={(e) => handleCopy(e)}
                        onPaste={(e) => handleCopy(e)}
                        readOnly
                      />
                      <textarea
                        type="text"
                        className={`w-full h-[7rem] p-3 rounded-lg outline-0 mt-2 placeholder-text-sm placeholder-tracking-[0.6px] ${
                          question?.regenerate
                            ? "text-green-600"
                            : question?.mandatory
                            ? "text-blue-600"
                            : ""
                        }`}
                        value={question?.answer}
                        onCopy={(e) => handleCopy(e)}
                        onCut={(e) => handleCopy(e)}
                        onPaste={(e) => handleCopy(e)}
                        readOnly
                      />
                    </div>
                    <div className="flex items-center">
                      <img
                        src={DeleteQues}
                        className="delete button cursor-pointer h-[2.7rem]"
                        alt="Delete"
                        onClick={() => handleDeleteQuestion(ind)}
                      />
                    </div>
                  </div>
                );
              })}

              {loader && <Loader />}
            </>
          </div>
        </>
      )} */}
      {/* {selectedOption === "option2" && (
        <>
          <div className="flex justify-between">
            <div className="text-[1.111vw] font-[600] mt-10">HR Questions</div>
            <>
              <div className="flex gap-3">
                <AnalyticsPageBtn
                  name={"Download Format 123"}
                  img={4}
                  onClick={handleDownloadFormat}
                />
                <div onClick={() => setOpenImport(true)}>
                  <Button name={"Upload Questions"} />
                </div>
                <div onClick={() => handleHrQuestionMandatory(true)}>
                  <Button name={"Make Mandatory"} />
                </div>
                <FaInfoCircle
                  data-tooltip-id="Make Mandatory"
                  className="ml-2 cursor-pointer text-gray-500"
                />
                <Tooltip id="Make Mandatory" place="top" effect="solid">
                  Clicking on this will make the selected questions mandatory.
                </Tooltip>
              </div>

              <div className="flex">
                <div onClick={() => handleHrQuestionMandatory(false)}>
                  <Button name={"Not Mandatory"} />
                </div>
                <FaInfoCircle
                  data-tooltip-id="Not Mandatory"
                  className="ml-2 cursor-pointer text-gray-500"
                />
                <Tooltip id="Not Mandatory" place="top" effect="solid">
                  Clicking on this will make the mandatory questions not
                  mandatory.
                </Tooltip>
              </div>
            </>
            <div onClick={handleAddQuestion}>
              <Button name={"Add Question"} />
            </div>
          </div>
          {openImport && (
            <ModalAddHrQuestions
              onClose={setOpenImport}
              excelData={excelData}
              setExcelData={handleUploadQuestionAnswer}
            />
          )}
          <div className="mt-2 flex h-[58vh] flex-col overflow-y-scroll">
            {questions?.map((question, ind) => (
              <div key={question.id} className="flex">
                <div className="flex items-baseline">
                  <input
                    type="checkbox"
                    checked={checkedQuestions.includes(ind)}
                    onChange={() => handleCheckboxChange(ind)}
                    className="mr-4 mt-1"
                  />
                </div>
                <div className="w-[80%]">
                  <TextBoxCustomNBuild
                    name={
                      question.mandatory
                        ? question.name + " (mandated)"
                        : question.name
                    }
                    onChange={(e) => handleHrQuestionInput(e, question.id)}
                    error={question.QError}
                    value={question.question}
                    mandatory={question.mandatory}
                  />
                  <textarea
                    type="text"
                    value={question.answer}
                    onChange={(e) => handleHrAnswerInput(e, question.id)}
                    className={`w-full h-[7rem] p-3 rounded-lg outline-0 mt-2 ${
                      question.mandatory && "text-blue-600"
                    } placeholder-text-sm placeholder-tracking-[0.6px]`}
                  />
                  {question.AError && (
                    <div className="text-red-500">{question.AError}</div>
                  )}
                </div>
                <div className="flex items-center">
                  <img
                    src={DeleteQues}
                    className="delete button cursor-pointer h-[2.7rem]"
                    alt="Delete"
                    onClick={() => handleDeleteQuestion1(question.id)}
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )} */}
      {/* {selectedOption === "option3" && (
        <>
          <div className="flex justify-between">
            <div className="text-[1.111vw] font-[600] mt-10">
              Blended Questions
            </div>
            <div className="flex gap-2 mt-4">
              {questions3?.length > 0 && (
                <>
                  <div className="flex">
                    <div onClick={() => handleMandatory(3, true)}>
                      <Button name={"Make Mandatory"} />
                    </div>
                    <FaInfoCircle
                      data-tooltip-id="Make Mandatory Blended"
                      className="ml-2 cursor-pointer text-gray-500"
                    />
                    <Tooltip
                      id="Make Mandatory Blended"
                      place="top"
                      effect="solid"
                    >
                      Clicking on this will make the selected questions
                      mandatory.
                    </Tooltip>
                  </div>
                  <div className="flex">
                    <div onClick={() => handleMandatory(3, false)}>
                      <Button name={"Non Mandatory"} />
                    </div>
                    <FaInfoCircle
                      data-tooltip-id="Non Mandatory Blended"
                      className="ml-2 cursor-pointer text-gray-500"
                    />
                    <Tooltip
                      id="Non Mandatory Blended"
                      place="top"
                      effect="solid"
                    >
                      Clicking on this will make the mandatory questions not
                      mandatory.
                    </Tooltip>
                  </div>
                </>
              )}
              {questions3?.length > 0 && (
                <div onClick={handleAddQuestion3}>
                  <Button name={"Add Question"} />
                </div>
              )}

              <div className="flex">
                <div onClick={() => handleGenerateQuestions(3)}>
                  <Button name={buttonContent} />
                </div>
                <FaInfoCircle
                  data-tooltip-id="skillsInfo"
                  className="ml-2 cursor-pointer text-gray-500"
                />
                <Tooltip id="skillsInfo" place="top" effect="solid">
                  Clicking on the Generate Question button will generate
                  questions from AI. Next, you can regenerate the selected
                  questions from AI again by clicking Regenerate Questions.
                </Tooltip>
              </div>
            </div>
          </div>
          <div className=" mt-2 flex h-[58vh] flex-col overflow-y-scroll">
            <>
              {questions3?.map((question, ind) => {
                const isEditing = editIndex === ind;
                return (
                  <div className="flex">
                    <div className="flex items-baseline">
                      {!question.isHr && (
                        <input
                          type="checkbox"
                          checked={checkedQuestions.includes(ind)}
                          onChange={() => handleCheckboxChange(ind)}
                          className="mr-4 mt-1"
                        />
                      )}
                    </div>
                    <div
                      key={ind}
                      className={`w-[80%] ${!question.isHr ? "" : "ml-8"}`}
                    >
                      <TextBoxCustomNBuild
                        name={
                          question.mandatory &&
                          !question.isHr &&
                          question.isEdit
                            ? "HR " + question.name + "(AI Question Modified)"
                            : question.mandatory && !question.isHr
                            ? "HR " + question.name + "(AI Question Mandated)"
                            : question.isHr
                            ? "HR " + question.name
                            : "AI " + "Question " + (ind + 1)
                        }
                        checkedQuestions={checkedQuestions.includes(ind)}
                        value={question.question}
                        onChange={(e) =>
                          handleBlendedQuestionInput(e, ind, question?.isHr)
                        }
                        error={question.QError}
                        regenerate={question.regenerate}
                        mandatory={question.mandatory}
                        edit={isEditing}
                        selectedOption={selectedOption}
                        isHr={question.isHr}
                      />
                      <textarea
                        type="text"
                        className={`w-full h-[7rem] p-3 rounded-lg outline-0 mt-2 placeholder-text-sm placeholder-tracking-[0.6px] ${
                          question?.regenerate
                            ? "text-green-600"
                            : question?.mandatory
                            ? "text-blue-600"
                            : ""
                        }`}
                        value={question.answer}
                        onChange={(e) =>
                          handleBlendedAnswerInput(e, ind, question?.isHr)
                        }
                        disabled={!isEditing && !question.isHr}
                      />
                      {question.AError && (
                        <div className="text-red-500"> {question.AError} </div>
                      )}
                    </div>

                    <div className="flex items-center justify-center flex-col gap-4 ms-5 mt-10 cursor-pointer">
                      <img
                        src={DeleteQues}
                        // className="delete button cursor-pointer h-[2.7rem]"
                        alt="Delete"
                        onClick={() => {
                          handleDeleteQuestion(ind);
                        }}
                      />
                      {!question.isHr && (
                        <img
                          src={editIcon}
                          alt="editIcon"
                          onClick={() => handleEditClick(ind)}
                        />
                      )}

                      {question.edit && (
                        <button
                          className="mt-3"
                          onClick={() => handleEditBlendedQuestAns(ind)}
                        >
                          save
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          </div>
          {loader && <Loader />}
        </>
      )} */}
      {/* {multipleError && (
        <div className="mt-4 text-center text-red-500 max-h-[5rem] overflow-auto">
          {multipleError}
        </div>
      )} */}
    </section>
  );
};

const QuestionFormat = (props) => {
  // Separate refs for question and answer textareas
  const questionRef = useRef(null);
  const answerRef = useRef(null);

  // Auto-resize the textarea when the content changes
  const handleTextAreaResize = (textarea) => {
    if (textarea) {
      textarea.style.height = "20px"; // Reset height to auto
      textarea.style.height = `${textarea.scrollHeight}px`; // Set height to fit content
    }
  };

  // Use an effect to auto-resize when content is pre-loaded (if needed)
  useEffect(() => {
    if (questionRef.current) {
      questionRef.current.value = props?.question?.question || "";
      handleTextAreaResize(questionRef.current);
    }
  }, [props?.question?.question]);
  // useEffect(() => {
  //   handleTextAreaResize(questionRef.current); // Adjust for the question textarea on mount
  // }, [props?.question?.question]);
  // Check the disabled condition
  const isDisabled =
    props.selectedOption === "option3" && !props.edit && !props.isHr;

  // Focus the textarea if it's not disabled
  useEffect(() => {
    if (!isDisabled && questionRef.current) {
      questionRef.current.focus();
      const length = questionRef.current.value.length;
      questionRef.current.setSelectionRange(length, length);
    }
  }, [isDisabled]); // Run the effect when 'isDisabled' changes

  useEffect(() => {
    if (answerRef.current) {
      answerRef.current.value = props?.question?.answer || "";
      handleTextAreaResize(answerRef.current);
    }
  }, [props?.question?.answer]);

  // useEffect(() => {
  //   handleTextAreaResize(answerRef.current); // Adjust for the answer textarea on mount
  // }, [props?.question?.answer]);

  console.log(props, "props");
  const name =
    props.question.mandatory && !props.question.isHr && props.question.isEdit
      ? `HR ${props.question.name} (Ai Question Modified)`
      : props.question.mandatory && !props.question.isHr
        ? `HR ${props.question.name} (Ai Question Mandated)`
        : props.question.isHr
          ? `HR ${props.question.name}`
          : `Ai Question ${props.questno + 1}`;
  const name1 = props.question.mandatory
    ? props.question.name + " (mandated)"
    : props.question.name;

  return (
    <>
      <div>
        <div
          key={props.qestno}
          className="flex justify-between border-[#A0AEC0] border-b-[1px] pb-[4px] mt-[8px]"
        >
          <div className="flex ">
            <label className="flex items-baseline">
              <input
                type="checkbox"
                checked={props.checkedQuest}
                onChange={() => props.handleCheckboxChange(props.qestno)}
                className="mr-4 mt-1 hidden"
              />
              <span className="flex items-center">
                <span
                  className={`w-5 h-5 mr-2 cursor-pointer ${props.checkedQuest
                      ? "border-[5px] border-blue-500"
                      : "border-2 border-[#BFC6D1]"
                    } rounded-full`}
                ></span>
              </span>
            </label>

            {props.selectedOption === "option1" ? (
              <div
                className={`font-sans1 text-[18px]
          ${props?.question?.regenerate
                    ? "text-green-600"
                    : props?.question?.mandatory
                      ? "text-blue-600"
                      : "text-[#1B3351]"
                  }
            `}
              >
                {/* Ai Question ({props?.qestno})&nbsp; */}
                {props.question.mandatory
                  ? `Ai Question (${props.qestno + 1 < 10
                    ? "0" + (props.qestno + 1)
                    : props.qestno + 1
                  }) (Mandated)`
                  : `Ai Question (${props.qestno + 1 < 10
                    ? "0" + (props.qestno + 1)
                    : props.qestno + 1
                  })`}
                &nbsp;
                {/* <span className="text-[#E03137]">*</span> */}
              </div>
            ) : props.selectedOption === "option2" ? (
              <div
                className={`font-sans1 text-[18px]
                ${name1.includes("HR ") ? "text-green-600 " : "text-blue-600"}
            `}
              >
                {props.question.mandatory
                  ? props.question.name + " (mandated)"
                  : props.question.name}
                &nbsp;
                {/* <span className="text-[#E03137]">*</span> */}
              </div>
            ) : (
              <div
                className={`font-sans1 text-[18px]
        ${name.includes("HR ") ? "text-green-600 " : "text-blue-600"}
          `}
              >
                {props.question.mandatory &&
                  !props.question.isHr &&
                  props.question.isEdit
                  ? "HR " + props.question.name + "(Ai Question Modified)"
                  : props.question.mandatory && !props.question.isHr
                    ? "HR " + props.question.name + "(Ai Question Mandated)"
                    : props.question.isHr
                      ? "HR " + props.question.name
                      : `Ai Question (${props.qestno + 1 < 10
                        ? "0" + (props.qestno + 1)
                        : props.qestno + 1
                      })`}
                &nbsp;
                {/* <span className="text-[#E03137]">*</span> */}
              </div>
            )}
          </div>
          <div className="flex gap-[10px] me-4">
            {props.selectedOption !== "option1" && !props.question.isHr && (
              <CustomBtn
                img={editSmIcon}
                color="#48C885"
                onClick={props.handleEditClick}
              />
            )}

            <CustomBtn
              img={deleteSmIcon}
              color="#FC7F29"
              onClick={props.handleDeleteQuestion}
            />

            {props.question.edit && (
              <CustomBtn
                img={saveBtn}
                color="#0875f4"
                onClick={props.handleEditBlendedQuestAns}
              />
            )}
          </div>
        </div>

        {props.selectedOption === "option1" ? (
          <>
            <p
              className={`font-sans1 text-[15px] font-[500] leading-[24px] mt-[9px] mb-[9px]
      ${props?.question?.regenerate
                  ? "text-green-600"
                  : props?.question?.mandatory
                    ? "text-blue-600"
                    : "text-[#1B3351]"
                }
        `}
            >
              {props?.question?.question}
            </p>
            <div
              className={`border-[1px] border-[#22284E] rounded-[10px] pt-[12px] pr-[16px] pl-[16px] pb-[12px] font-sans1 text-[16px] font-[500] leading-[25.6px] mt-[9px] mb-[16px]
      ${props?.question?.regenerate
                  ? "text-green-600"
                  : props?.question?.mandatory
                    ? "text-blue-600"
                    : "text-[#1B3351]"
                }
        `}
            >
              {props?.question?.answer}
            </div>
          </>
        ) : (
          <>
            <textarea
              className={`focus:outline-none  bg-transparent font-sans1 text-[15px] font-[500] leading-[24px] mt-[9px] ps-4 mb-[1px]
     ${props?.question?.regenerate
                  ? "text-green-600"
                  : props?.question?.mandatory
                    ? "text-green-600"
                    : "text-[#1B3351]"
                }
       `}
              style={{ width: "-webkit-fill-available", height: "20px" }}
              onChange={(e) => {
                props.onChange(e);
                handleTextAreaResize(questionRef.current);
              }}
              disabled={
                props.selectedOption === "option3" && !props.edit && !props.isHr
              }
              placeholder="Enter Your Question here"
              ref={questionRef}
            >
              {props?.question?.question}
            </textarea>
            {props.error && <div className="text-red-500"> {props.error} </div>}
            <textarea
              className={`focus:outline-none bg-transparent border-[1px] border-[#22284E] rounded-[10px] pt-[12px] pr-[16px] pl-[16px] pb-[12px] font-sans1 text-[16px] font-[500] leading-[25.6px] mt-[9px] mb-[9px]
      ${props?.question?.regenerate
                  ? "text-green-600"
                  : props?.question?.mandatory
                    ? "text-green-600"
                    : "text-[#1B3351]"
                }
        `}
              style={{ width: "-webkit-fill-available", height: "150px" }}
              onChange={(e) => {
                props.onChange1(e);
                handleTextAreaResize(answerRef.current);
              }}
              disabled={props.disabled}
              placeholder="Enter Your Answer here"
              ref={answerRef}
            >
              {props?.question?.answer}
            </textarea>
            {props.question.AError && (
              <div className="text-red-500">{props.question.AError}</div>
            )}
          </>
        )}
      </div>
    </>
  );
};

const UploadResumeOnly = ({
  uploadResume,
  setUploadResume,
  setErrorFormat,
  excelData,
  setExcelData,
  selectedCheckboxes,
  setSelectedCheckboxes,
  selectAll,
  setSelectAll,
  editData,
  prevEmail,
  setPrevEmail,
  setEmptyError,
  setIncorrectError,
  setSelectedData,
  setIsNewCandidatesAdded,
  selectedData,
  setIsNextActivated,
  newCandidatesAdded,
  singleAddCandidate,
  setSingleAddCandidate,
  isNextAcitvated,
  isVisibleConfirmation,
  setIsVisibleConfirmation,
  handleCallApi,
  checkedFilteredData,
  setCheckedFilteredData,
  filteredData,
  setFilteredData,
  generateExcelFile,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [rowToDelete, setRowToDelete] = useState(null);
  const [forceRender, setForceRender] = useState(false);
  const [hasEmailError, setHasEmailError] = useState(false);
  const [sameCandidate, setSameCandidate] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [questionDeletePopup, setQuestionDeletePopup] = useState(false);
  const [isModalAddCandidateOpen, setIsModalAddCandidateOpen] = useState(false);

  let uploadResumeArray = JSON.parse(sessionStorage.getItem("uploadResume")) || [];

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const filterData = () => {
      const combinedData = [...singleAddCandidate, ...(excelData || [])];

      const filtered = combinedData.filter(
        ({ first_name, last_name, email, mobile }) => {
          const combinedFields =
            `${first_name} ${last_name} ${email} ${mobile}`.toLowerCase();
          return combinedFields.includes(searchTerm.toLowerCase());
        }
      );

      const checkedData = filtered.filter(
        (item) =>
          !checkedFilteredData.some(
            (checkedItem) => checkedItem.email === item.email
          )
      );

      setFilteredData(checkedData);
    };

    filterData();
  }, [singleAddCandidate, excelData, searchTerm, checkedFilteredData]);

  useEffect(() => {
    const getCandidateList = async () => {
      const reqData = {
        campaign: getLocal("campaignUid"),
        is_deleted: 0,
      };
      const response = await handleApiResponse(
        "/api/v1/interview/candidate_list/",
        reqData,
        1
      );
      if (response?.code === "token_not_valid") {
        navigate("/");
        sessionStorage.clear();
        return;
      }
      const transformedData = response?.data?.data?.data.map((candidate) => ({
        first_name: candidate.first_name,
        last_name: candidate.last_name,
        email: candidate.email,
        mobile: candidate.mobile,
        is_selected: candidate.is_selected === 1,
        uid: candidate.uid,
        initiallySelected: candidate.is_selected === 1,
      }));

      let checkedData = transformedData
        .map((item, index) => ({ ...item, originalIndex: index }))
        .filter((item) => item.is_selected)
        .sort((a, b) => b.originalIndex - a.originalIndex);
      // let checkedData = transformedData.filter((item) => item.is_selected);

      let unCheckedData = transformedData.filter((item) => !item.is_selected);

      const savedExcelData = sessionStorage.getItem("excelData");
      const savedExcelList = JSON.parse(savedExcelData);
      const savedCheckedData = sessionStorage.getItem("checkedFilteredData");
      const savedCheckList = JSON.parse(savedCheckedData);

      console.log(savedCheckList, "savedCheckList", checkedData);
      if (transformedData?.length > 0) {
        if (savedExcelList?.length > 0 && savedExcelList !== null) {
          setExcelData(savedExcelList);
        } else {
          setExcelData(unCheckedData);
        }
        if (savedCheckList?.length > 0 && savedCheckList !== null) {
          setCheckedFilteredData(savedCheckList);
        } else {
          setCheckedFilteredData(checkedData);
        }

        setSelectedData(checkedData);
      } else if (savedExcelData !== null) {
        try {
          setExcelData(JSON.parse(savedExcelData));
        } catch (error) {
          console.error("Failed to parse savedExcelData:", error);
        }
      } else {
        setExcelData([]);
      }
    };

    getCandidateList();
  }, [getLocal("campaignUid")]);

  const callApi = async () => {
    const reqData = {
      campaign: editData[0],
    };
    const data = [
      { email: "example@email.com" },
      { email: "example2@email.com" },
    ];
    const email = data.map((ele) => ele.email);
    setPrevEmail(email);
  };

  useEffect(() => {
    if (editData[0]) {
      callApi();
    }
  }, []);


  const handleExtractSelectedData = () => {
    const selectedData = [];
    let emailError = false;

    filteredData?.forEach((row, rowIndex) => {
      let first_name, last_name, email, mobile, is_selected, uid;
      console.log(row, "roewsaaaaaaaaa");

      if (Array.isArray(row)) {
        [first_name, last_name, email, mobile] = row;
        is_selected = selectedCheckboxes[row.email] ? 1 : 0;
        uid = undefined;
      } else if (typeof row === "object" && row !== null) {
        first_name = row.first_name;
        last_name = row.last_name;
        email = row.email;
        mobile = row.mobile;
        is_selected = row.is_selected ? 1 : 0;
        uid = row.uid;
      } else {
        console.error("Unexpected row type:", row);
        return;
      }

      if (!email) {
        emailError = true;
      }

      selectedData.push({
        first_name,
        last_name,
        email,
        mobile,
        campaign: sessionStorage.getItem("campaignUid"),
        organization: sessionStorage.getItem("parentUid"),
        is_selected,
        uid,
      });
    });

    setHasEmailError(emailError);
    console.log(selectedData, "selectedData");

    if (emailError) {
      return [];
    }

    return selectedData;
  };

  useEffect(() => {
    const updatedSelectedData = handleExtractSelectedData();
    setSelectedData(updatedSelectedData);
  }, [selectedCheckboxes, filteredData, singleAddCandidate]);

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    const updatedExcelData = excelData.map((row) => ({
      ...row,
      is_selected: !isChecked,
    }));

    const updatedSingleAddCandidate = singleAddCandidate.map((row) => ({
      ...row,
      is_selected: !isChecked,
    }));

    const updatedFilteredData = [
      ...updatedSingleAddCandidate,
      ...updatedExcelData,
    ];

    sessionStorage.setItem("excelData", JSON.stringify(updatedExcelData));
    sessionStorage.setItem(
      "singleAddCandidate",
      JSON.stringify(updatedSingleAddCandidate)
    );

    setFilteredData(updatedFilteredData);
    setSingleAddCandidate(updatedSingleAddCandidate);

    const updatedCheckboxes = updatedFilteredData.reduce((acc, row) => {
      acc[row.email] = !isChecked;
      return acc;
    }, {});

    sessionStorage.setItem(
      "selectedCheckboxes",
      JSON.stringify(updatedCheckboxes)
    );

    setSelectedCheckboxes(updatedCheckboxes);

    setSelectAll(isChecked);
    sessionStorage.setItem("savedAllSelcted", JSON.stringify(isChecked));
    setExcelData(updatedExcelData);
  };

  const handleCheckboxChange = (rowIndex, isChecked, currentRow, email) => {
    setSelectedCheckboxes((prev) => {
      let updatedCheckBoxes;
      if (!isChecked) {
        const { [email]: removed, ...rest } = prev;

        updatedCheckBoxes = rest;
      } else {
      }
      updatedCheckBoxes = {
        ...prev,
        [email]: isChecked,
      };
      sessionStorage.setItem(
        "selectedCheckboxes",
        JSON.stringify(updatedCheckBoxes)
      );
      return updatedCheckBoxes;
    });

    const updatedRow = { ...currentRow, is_selected: isChecked };

    setFilteredData((prev) =>
      prev.map((row, index) => (index === rowIndex ? updatedRow : row))
    );

    setExcelData((prev) => {
      const updatedData = prev.map((row) =>
        row.email === email ? updatedRow : row
      );
      sessionStorage.setItem("excelData", JSON.stringify(updatedData));
      return updatedData;
    });

    setSingleAddCandidate((prev) => {
      const updatedData = prev.map((row) =>
        row.email === email ? updatedRow : row
      );
      sessionStorage.setItem("singleAddCandidate", JSON.stringify(updatedData));
      return updatedData;
    });

    // setExcelData((prev) => {
    //   const newData = prev.map((row, index) =>
    //     index === rowIndex ? updatedRow : row
    //   );
    //   sessionStorage.setItem("excelData", JSON.stringify(newData));
    //   return newData;
    // });

    // setSingleAddCandidate((prev) =>
    //   prev.filter((candidate) => candidate.email !== email)
    // );
    // setExcelData((prev) =>
    //   prev.filter((candidate) => candidate.email !== email)
    // );
  };

  const handleCheckboxChange1 = (rowIndex, isChecked, currentRow, email) => {
    // setSearchTerm("");
    setSelectedCheckboxes((prev) => ({
      ...prev,
      [rowIndex]: isChecked,
    }));

    const updatedRow = { ...currentRow, is_selected: isChecked };

    if (isChecked) {
      // Add to checkedFilteredData and remove from filteredData
      setCheckedFilteredData((prev) => {
        const newCheckedFilteredData = [
          updatedRow,
          ...prev.filter((row) => row.email !== email),
        ];
        sessionStorage.setItem(
          "checkedFilteredData",
          JSON.stringify(newCheckedFilteredData)
        );
        return newCheckedFilteredData;
      });
      setFilteredData((prev) => {
        const newFilteredData = prev.filter((row) => row.email !== email);
        sessionStorage.setItem("filteredData", JSON.stringify(newFilteredData));
        return newFilteredData;
      });
    } else {
      // Remove from checkedFilteredData and add to filteredData
      setCheckedFilteredData((prev) => {
        const newCheckedFilteredData = prev.filter(
          (row) => row.email !== email
        );
        sessionStorage.setItem(
          "checkedFilteredData",
          JSON.stringify(newCheckedFilteredData)
        );
        return newCheckedFilteredData;
      });
      setFilteredData((prev) => {
        const newFilteredData = [
          updatedRow,
          ...prev.filter((row) => row.email !== email),
        ];
        sessionStorage.setItem("excelData", JSON.stringify(newFilteredData));
        return newFilteredData;
      });
    }
  };

  const handleSortList = () => {
    sessionStorage.setItem("sortlist", true);
    const selectedEmails = Object.entries(selectedCheckboxes)
      .filter(([_, isChecked]) => isChecked)
      .map(([email]) => email);

    // Filter and sort filteredData based on selectedEmails
    const newCheckedFilteredDataList = filteredData.filter((row) =>
      selectedEmails.includes(row.email)
    );

    const updatedFilteredData = newCheckedFilteredDataList.map((row) => ({
      ...row,
      is_selected: true,
    }));

    setCheckedFilteredData((prevChecked) => {
      const newCheckedFilteredData = [...prevChecked, ...updatedFilteredData];
      sessionStorage.setItem(
        "checkedFilteredData",
        JSON.stringify(newCheckedFilteredData)
      );
      return newCheckedFilteredData;
    });

    setFilteredData((prev) => prev.filter((row) => !row.is_selected));
    setSearchTerm("");
  };

  const handleDropResume = (e) => {
    e.preventDefault();
    const permissibleExtensions = ["xlsx"];
    const files = Array.from(e.dataTransfer.files);
    // permissibleExtensions.includes(
    //   files[0].name.split('.').pop().toLowerCase()
    // )

    if (files.length > 0) {
      setcandidateFilename(files[0].name);
      let extension = files[0].name.split(".").pop().toLowerCase();
      if (extension == "xlsx") {
        setUploadResume(files[0]);
        handleUpload(files[0]);
      } else {
        toast.warning("Invalid file type. Please upload a XLSX file.", {
          className: "custom-toast-warning"
        });
      }
    } else {
      toast.warning("Invalid file type. Please upload a XLSX file.", {
        className: "custom-toast-warning"
      });
    }
  };

  useEffect(() => {
    const savedUploadResume = sessionStorage.getItem("uploadResume");
    const savedExcelData = sessionStorage.getItem("excelData");
    const savedPrevEmail = sessionStorage.getItem("prevEmail");
    const savedSingleAddCandidate =
      sessionStorage.getItem("singleAddCandidate");
    const savedSelectedCheckboxes =
      sessionStorage.getItem("selectedCheckboxes");
    const savedCheckedFilteredData = sessionStorage.getItem(
      "checkedFilteredData"
    );

    sessionStorage.setItem("sortedData", true);

    if (savedUploadResume !== null) {
      setUploadResume(savedUploadResume);
    }
    if (savedExcelData !== null) {
      try {
        const parsedExcelData = JSON.parse(savedExcelData);
        // setExcelData(parsedExcelData);

        // Filter data based on checkedFilteredData
        if (savedCheckedFilteredData !== null) {
          const parsedCheckedFilteredData = JSON.parse(
            savedCheckedFilteredData
          );
          setCheckedFilteredData(parsedCheckedFilteredData);
          setExcelData(
            parsedExcelData.filter(
              (row) =>
                !parsedCheckedFilteredData.some(
                  (checkedRow) => checkedRow.email === row.email
                )
            )
          );
        } else {
          setExcelData(parsedExcelData);
        }
      } catch (error) {
        console.error("Failed to parse excelData:", error);
      }
    }
    if (savedPrevEmail !== null) {
      try {
        setPrevEmail(JSON.parse(savedPrevEmail));
      } catch (error) {
        console.error("Failed to parse prevEmail:", error);
      }
    }
    if (savedSingleAddCandidate !== null) {
      try {
        setSingleAddCandidate(JSON.parse(savedSingleAddCandidate));
      } catch (error) {
        console.error("Failed to parse singleAddCandidate:", error);
      }
    }
    if (savedSelectedCheckboxes !== null) {
      const parsedCheckboxes = JSON.parse(savedSelectedCheckboxes);
      if (parsedCheckboxes && typeof parsedCheckboxes === "object") {
        setSelectedCheckboxes((prev) => ({ ...prev, ...parsedCheckboxes }));
      } else {
        console.error("Parsed checkboxes are not valid:", parsedCheckboxes);
      }
    }
  }, []);

  const handleUploadResume = (e) => {
    const file = e.target.files[0];
    const savedUploadResume = sessionStorage.getItem("uploadResume");

    if (savedUploadResume !== file.name) {
      if (file.name.endsWith(".xlsx")) {
        setUploadResume(file);
        handleUpload(file);
      }
    }
  };

  const handleUpload = (file) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const firstRow = parsedData[0];
      const expected = ["First Name", "Last Name", "Email", "Mobile"];

      // Validate headers
      if (JSON.stringify(firstRow) !== JSON.stringify(expected)) {
        // setExcelData([]);
        setUploadResume(false);
        setErrorFormat(true);
        // setSelectedCheckboxes({});
        // sessionStorage.removeItem("excelData");
        // sessionStorage.removeItem("prevEmail");
        toast.error(
          "Invalid file format. Please ensure the headers are correct.",
          {
            className: "custom-toast-error"
          }
        );
        return;
      }

      setErrorFormat(false);

      const dataRows = parsedData.slice(1);

      if (dataRows.length === 0) {
        setExcelData([]);
        setFilteredData([]);
        setUploadResume(false);
        sessionStorage.removeItem("excelData");
        sessionStorage.removeItem("prevEmail");
        // setSelectedCheckboxes({});
        // sessionStorage.removeItem("selectedCheckboxes");
        toast.error(
          "The file contains no data. Please ensure there are entries below the headers.",
          {
            className: "custom-toast-error"
          }
        );
        return;
      }

      const validRows = [];
      const errorMessagesSet = new Set();

      const isValidName = (name) =>
        typeof name === "string" && name.trim() !== "";

      const isValidMobile = (mobile) => typeof mobile === "number";

      const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

      const uploadedEmailsSet = new Set();

      dataRows.forEach((row, index) => {
        const [firstName, lastName, email, mobile] = row;

        const errors = {
          firstName: !isValidName(firstName)
            ? "Invalid or empty first name"
            : "",
          lastName: !isValidName(lastName) ? "Invalid or empty last name" : "",
          email: !isValidEmail(email) ? "Invalid or empty email" : "",
        };

        if (!firstName || !lastName || !email) {
          errors.emptyFields = "All fields are required";
        }

        if (uploadedEmailsSet.has(email)) {
          errors.email =
            "You can't upload it. Duplicate email found in the upload file.";
        } else {
          uploadedEmailsSet.add(email);
        }

        if (!Object.values(errors).some((error) => error)) {
          validRows.push({
            first_name: firstName,
            last_name: lastName,
            email: email,
            mobile: mobile,
            is_selected: true,
          });
        } else {
          Object.values(errors)
            .filter(Boolean)
            .forEach((errorMessage) => errorMessagesSet.add(errorMessage));
        }
      });

      const aggregatedErrorMessages = Array.from(errorMessagesSet).join("\n");

      if (aggregatedErrorMessages) {
        toast.error(`${aggregatedErrorMessages}`, {
          className: "custom-toast-error"
        });
        return;
      }

      const existingEmails = [
        ...(filteredData?.map((ele) =>
          typeof ele === "object" ? ele.email : ele[2]
        ) || []),
        ...(checkedFilteredData?.map((ele) =>
          typeof ele === "object" ? ele.email : ele[2]
        ) || []),
        ...(JSON.parse(sessionStorage.getItem("excelData") || "[]").map(
          (ele) => ele.email
        ) || []),
      ];
      const combinedExistingEmails = new Set([...existingEmails]);
      const uniqueNewCandidates = validRows.filter(
        (row) => !combinedExistingEmails.has(row.email)
      );
      const notUniqueNewCandidates = validRows.filter((row) =>
        combinedExistingEmails.has(row.email)
      );

      if (uniqueNewCandidates?.length === 0) {
        toast.warn(
          "All uploaded candidates have duplicate emails. No new candidates were added.", {
          className: "custom-toast-warn"
        }
        );
        return;
      }

      if (notUniqueNewCandidates?.length > 0) {
        toast.warn(
          `${notUniqueNewCandidates?.length} upload candidate have duplicate email. Can't be import`, {
          className: "custom-toast-warn"
        }
        );
      }

      setExcelData((prevData) => {
        const prevDataArray = Array.isArray(prevData) ? prevData : [];
        const newData = [...prevDataArray, ...uniqueNewCandidates];

        if (newData.length > 500) {
          toast.warn("Cannot upload more than 500 entries in total.", {
            className: "custom-toast-warn"
          });
          return prevDataArray;
        }
        console.log(newData, "newData", selectedCheckboxes);
        let uploadSelected = newData.reduce((acc, item) => {
          acc[item.email] = true;
          return acc;
        }, {});

        setSelectedCheckboxes((prev) => {
          const updatedSelectedCheckboxes = {
            ...prev,
            ...uploadSelected,
          };

          sessionStorage.setItem(
            "selectedCheckboxes",
            JSON.stringify(updatedSelectedCheckboxes)
          );

          return updatedSelectedCheckboxes;
        });

        let uploadNewData = newData.map((item) => ({
          ...item,
          is_selected: true,
        }));

        sessionStorage.setItem("excelData", JSON.stringify(uploadNewData));
        return uploadNewData;
      });

      setUploadResume(file);
      let uploadResumeArray =
        JSON.parse(sessionStorage.getItem("uploadResume")) || [];
      uploadResumeArray.push(file.name);
      sessionStorage.setItem("uploadResume", JSON.stringify(uploadResumeArray));
      sessionStorage.setItem(
        "prevEmail",
        JSON.stringify([
          ...existingEmails,
          ...uniqueNewCandidates.map((row) => row.email),
        ])
      );
    };

    reader.readAsArrayBuffer(file);
  };

  const handleRemoveResume = () => {
    setExcelData([]);
    setCheckedFilteredData([]);
    const currentFileName = uploadResume ? uploadResume.name : null;

    setUploadResume(null);
    setSelectedCheckboxes({});
    sessionStorage.removeItem("uploadResume");
    sessionStorage.removeItem("excelData");
    sessionStorage.removeItem("prevEmail");
    sessionStorage.removeItem("checkedFilteredData");
    sessionStorage.removeItem("selectedCheckboxes");
    sessionStorage.removeItem("savedAllSelcted");
    const fileInput = document.getElementById("resume-upload");
    if (fileInput) {
      fileInput.value = "";
    }
    const handleUploadResumeAgain = (e) => {
      const file = e.target.files[0];
      if (file && file.name === currentFileName) {
        setUploadResume(file);
      } else {
        setUploadResume(file);
      }
    };
    fileInput.addEventListener("change", handleUploadResumeAgain, {
      once: true,
    });
    setTimeout(() => {
      setForceRender((prev) => !prev);
    }, 0);
  };

  useEffect(() => {
    const dataToSort = Array.isArray(excelData) ? excelData : [];
    const candidates = Array.isArray(singleAddCandidate)
      ? singleAddCandidate
      : [];

    const sortedExcelData = dataToSort.sort((a, b) => {
      if (
        typeof a === "object" &&
        a !== null &&
        typeof b === "object" &&
        b !== null
      ) {
        return (b.is_selected ? 1 : 0) - (a.is_selected ? 1 : 0);
      }
      return 0;
    });

    let combinedData = [...candidates, ...sortedExcelData];

    const uniqueEmails = new Set();
    let filteredData = combinedData.filter((item) => {
      if (uniqueEmails.has(item?.email)) {
        return false;
      } else {
        uniqueEmails.add(item?.email);
        return true;
      }
    });

    filteredData = filteredData.filter((item) =>
      checkedFilteredData.every(
        (checkedItem) => checkedItem.email !== item.email
      )
    );

    setFilteredData(filteredData);
  }, [excelData, singleAddCandidate, checkedFilteredData]);

  const handleDeleteRow = async (id, email) => {
    setSearchTerm("");
    if (id && email) {
      try {
        const response = await handleApiResponse(
          "/api/v1/interview/update_candidate/",
          {
            uid: id,
            is_deleted: 1,
          },
          1
        );
        if (response?.code === "token_not_valid") {
          navigate("/");
          sessionStorage.clear();
          return;
        }
        if (response?.data?.status_code === 200) {
          toast.success("Candidate Deleted.", {
            className: "custom-toast-success"
          });
        } else if (response.status_code === 400) {
          toast.error("An error occured while deleting.", {
            className: "custom-toast-error"
          });
        }
      } catch (error) {
        console.error("API Error:", error);
      }

      const updatedExcelData = excelData?.filter((item) =>
        Array.isArray(item) ? item[2] !== email : item.email !== email
      );

      setExcelData(updatedExcelData);

      const updatedSingleAddCandidate = singleAddCandidate?.filter((item) =>
        Array.isArray(item) ? item[2] !== email : item.email !== email
      );

      setSingleAddCandidate(updatedSingleAddCandidate);

      const updatedChecked = checkedFilteredData?.filter((item) =>
        Array.isArray(item) ? item[2] !== email : item.email !== email
      );

      setCheckedFilteredData(updatedChecked);
      sessionStorage.setItem(
        "checkedFilteredData",
        JSON.stringify(updatedChecked)
      );
    } else {
      const updatedExcelData = excelData?.filter((item) =>
        Array.isArray(item) ? item[2] !== email : item.email !== email
      );

      setExcelData(updatedExcelData);

      sessionStorage.setItem("excelData", JSON.stringify(updatedExcelData));

      const updatedSingleAddCandidate = singleAddCandidate?.filter((item) =>
        Array.isArray(item) ? item[2] !== email : item.email !== email
      );

      setSingleAddCandidate(updatedSingleAddCandidate);

      sessionStorage.setItem(
        "singleAddCandidate",
        JSON.stringify(updatedSingleAddCandidate)
      );

      const updatedChecked = checkedFilteredData?.filter((item) =>
        Array.isArray(item) ? item[2] !== email : item.email !== email
      );

      setCheckedFilteredData(updatedChecked);
      sessionStorage.setItem(
        "checkedFilteredData",
        JSON.stringify(updatedChecked)
      );

      toast.success("Candidate Deleted.", {
        className: "custom-toast-success"
      });
    }
  };

  const closeModalDeleteQuestion = () => {
    setQuestionDeletePopup(false);
  };

  const confirmDelete = () => {
    handleDeleteRow(rowToDelete.id, rowToDelete.email);
    setQuestionDeletePopup(false);
  };

  const handleDeleteClick = (id, email, first_name, last_name) => {
    setRowToDelete({ id, email, first_name, last_name });
    setQuestionDeletePopup(true);
  };

  const handleCancelVisibleBtn = () => {
    setIsVisibleConfirmation(false);
  };

  const openModalAddCandidate = () => {
    setIsModalAddCandidateOpen(true);
  };

  const closeModalAddCandidate = () => {
    setIsModalAddCandidateOpen(false);
  };

  let selectedFilteredData = filteredData?.filter((item) => item?.is_selected);
  const [candidateFilename, setcandidateFilename] = useState(false);

  return (
    <section className="flex flex-col gap-4 fill-available">
      <div className="flex flex-col fill-available">
        {questionDeletePopup && (
          <ModalDeleteQuestionPopup
            onConfirm={confirmDelete}
            onClose={closeModalDeleteQuestion}
            firstName={rowToDelete?.first_name}
            lastName={rowToDelete?.last_name}
            email={rowToDelete?.email} // If you still need the email
          />
        )}
        {isVisibleConfirmation && (
          <ModalCandidateConfirmationPopup
            totatCandidates={filteredData?.length + checkedFilteredData?.length}
            checkedFilteredData={
              checkedFilteredData?.length > 0
                ? location.pathname === "/editcampaign/addCampaign"
                  ? checkedFilteredData?.length + selectedFilteredData?.length
                  : checkedFilteredData?.length + selectedFilteredData?.length
                : selectedFilteredData?.length
            }
            handleCallApi={handleCallApi}
            onClose={handleCancelVisibleBtn}
          />
        )}
        <div className="bg-[#F5F8FF] rounded-2xl p-4">
          <h4 className="text-[1rem] font-sans1 text-[#22284E]">
            Upload list of candidates
          </h4>
          {isModalAddCandidateOpen && (
            <ModalAddCandidate
              head={"Add"}
              isOpen={isModalAddCandidateOpen}
              onClose={closeModalAddCandidate}
              excelData={excelData}
              setSingleAddCandidate={setSingleAddCandidate}
              setSelectedCheckboxes={setSelectedCheckboxes}
              selectedCheckboxes={selectedCheckboxes}
            />
          )}
          <div className="mt-3 ">
            <div className="w-100 flex items-center">
              <div
                className="w-[75%] me-5 p-4 pe-1 flex justify-between items-center h-[50px] border-[#22284E] border-solid border-[1px] bg-no text-[#A0AEC0] rounded-lg"
                onDrop={(e) => {
                  handleDropResume(e, setcandidateFilename);
                }}
                onDragOver={(e) => e.preventDefault()}
              >
                <p>
                  {Array.isArray(uploadResumeArray) &&
                    uploadResumeArray.length > 0
                    ? uploadResumeArray.slice().reverse().join(", ").length > 65
                      ? `${uploadResumeArray
                        .slice()
                        .reverse()
                        .join(", ")
                        .slice(0, 65)}...`
                      : uploadResumeArray.slice().reverse().join(", ")
                    : "Upload a CSV file with candidate details"}
                </p>
                <label
                  htmlFor="resume-upload"
                  className="text-[1rem] cursor-pointer border-2 h-[2.5rem] w-[10rem] text-center bg-[#22284E] text-white flex justify-center items-center rounded-lg"
                >
                  <img
                    className="h-[20px] w-[20px]"
                    src={browseicon1}
                    alt="browseicon1"
                  />
                  <span className="text-[1rem] font-sans1 font-[600] ms-1">
                    Browse
                  </span>
                  <input
                    type="file"
                    id="resume-upload"
                    accept=".xlsx"
                    className="hidden"
                    onChange={(e) => {
                      handleUploadResume(e);
                      if (e.target.files) {
                        setcandidateFilename(e.target.files[0].name);
                      }
                    }}
                  />
                </label>
              </div>
              <div className="w-[25%] ">
                
                <button
                  onClick={generateExcelFile}
                  className="flex justify-center items-center w-[100%] h-[50px] border-[#22284E] border-solid border-[1px] bg-no text-[#22284E] rounded-lg px-4 py-3"
                >
                  <img
                    className="h-[20px] w-[20px]"
                    src={downloadFormat}
                    alt="downloadFormat"
                  />
                  <span className="text-[1rem] font-sans1 font-[600] ms-1">
                    Download Format
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <button
              className="flex justify-center items-center w-[100%] h-[50px] border-[#22284E] border-solid border-[1px] bg-no text-[#22284E] rounded-lg px-4 py-3"
              onClick={openModalAddCandidate}
            >
              <AiOutlinePlus className="text-[1.2rem]" />
              <span className="text-[1rem] font-sans1 font-[600] ms-1 mt-0 capitalize">
                Add candidate manually
              </span>
            </button>
          </div>
        </div>

        {checkedFilteredData?.length > 0 && (
          <div
            className="rounded-2xl mt-5"
            style={{ boxShadow: "0px 0px 4px 0px #22284E40" }}
          >
            <section
              className={`overflow-auto sorted-cd-list p-4`}
              style={{
                height: `${filteredData?.length > 0
                    ? "calc(35vh - 100px)"
                    : "calc(55vh - 100px)"
                  }`,
              }}
            >
              <table
                className="w-full overflow-hidden  rounded-2xl "
                style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px" }}
              >
                <thead className="bg-[#F5F8FF] text-[0.833vw] text-[600] rounded-2xl">
                  <tr className="py-4 rounded-tl-2xl border-b-[1px] border-[#E2E8F0]">
                    <th className="rounded-tl-2xl text-[14px] font-[500] font-inter text-[#222850] text-left p-4 flex items-center ">
                      
                      <span className="w-[6rem] text-[14px] min-w-[81px] font-[500] font-inter text-[#222850] text-left capitalize ms-1">
                        Selected 
                      </span>
                    </th>
                    <th className="text-[14px] font-[500] font-inter  text-[#222850] text-left p-4 ">
                      First Name
                    </th>
                    <th className="text-[14px] font-[500] font-inter  text-[#222850] text-left p-4">
                      Last Name
                    </th>
                    <th className="text-[14px] font-[500] font-inter  text-[#222850] text-left p-4">
                      Email
                    </th>
                    <th className="text-[14px] font-[500] font-inter  text-[#222850] text-left p-4">
                      Mobile
                    </th>
                    <th className="rounded-tr-2xl text-[14px] font-[500] font-inter  text-[#222850] text-left p-4">
                      Action
                    </th>
                  </tr>
                  
                </thead>
                <tbody>
                  {checkedFilteredData?.map((row, rowIndex) => {
                    const email = Array.isArray(row) ? row[2] : row.email;
                    return (
                      <>
                        <tr
                          className={`${rowIndex % 2 != 0
                              ? "bg-[#F9FBFD] border-t-[1px] border-b-[1px] border-[#E2E8F0]"
                              : ""
                            }`}
                        >
                          <td className=" text-[1rem] font-[400] font-inter text-[#718096] text-left p-4">
                            <div className="custome-check-box">
                              <input
                                className="h-[15px] w-[15px] bg-[#0875F480] border-[#222850] cursor-pointer"
                                type="checkbox"
                                checked={
                                  selectedCheckboxes[email] ||
                                  row.initiallySelected ||
                                  row.is_selected ||
                                  false
                                }
                                onChange={(e) =>
                                  handleCheckboxChange1(
                                    rowIndex,
                                    e.target.checked,
                                    row,
                                    email
                                  )
                                }
                                disabled={row.initiallySelected}
                              />
                              <span class="checkmark"></span>
                            </div>
                          </td>
                          <td className=" text-[1rem] font-[400] font-inter text-[#718096] text-left p-4">
                            {row?.first_name}
                          </td>
                          <td className="text-[14px] font-[400] font-inter text-[#718096] text-left p-4">
                            {row?.last_name}
                          </td>
                          <td className="text-[14px] font-[400] font-inter text-[#718096] text-left p-4">
                            {row?.email}
                          </td>
                          <td className="text-[14px] font-[400] font-inter text-[#718096] text-left p-4">
                            {row?.mobile}
                          </td>
                          <td className=" text-[1rem] font-[400] font-inter text-[#718096] text-left p-4">
                            <button
                              onClick={() =>
                                location.pathname === "/campaign/addCampaign"
                                ? handleDeleteClick(row?.uid, row?.email, row?.first_name, row?.last_name)
                                : handleDeleteClick(row?.uid, row?.email, row?.first_name, row?.last_name)
                           
                              }
                              className="bg-[#FC7F29] text-[1rem] text-white rounded-lg p-2"
                            >
                              {/* <RiDeleteBin6Line className="text-[1rem]" /> */}
                              <img src={DeleteIcon} />
                            </button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </section>
          </div>
        )}
        {filteredData?.length > 0 && (
          <div
            className="rounded-2xl mt-5"
            style={{ boxShadow: "0px 0px 4px 0px #22284E40" }}
          >
            <section
              className={`overflow-auto  unsorted-cd-list p-4`}
              style={{
                height: `${checkedFilteredData?.length > 0
                    ? "calc(35vh - 100px)"
                    : "calc(55vh - 100px)"
                  }`,
              }}
            >
              <div>
                <div>
                  <div className="flex justify-between items-center pt-0 pb-3">
                    <h4 className="capitalize text-[#1B3351] text-[1.5rem] font-figtree font-semibold italic">
                      candidate list
                    </h4>
                    <div class="add-candidate-sort-btn">
                      <div className="relative tool-tip-box">
                        <div>
                          <div className="tool-tip absolute left-[-255px] top-[-15px]">
                            <div className="bg-none w-[220px] h-[100px] font-handlee text-[1rem] text-[#22284E]">
                              After selecting the candidates, click on this
                              button to shortlist them for the campaign
                            </div>
                          </div>
                          <img
                            className="absolute left-[-65px] top-[23px]"
                            src={arrowTooltip}
                            alt="arrowtooltip"
                          />
                        </div>
                      </div>
                      {JSON.parse(selectAll) && (
                        <button
                          onClick={handleSortList}
                          className="rounded-lg h-10 px-4 bg-[#197FF5] text-white text-base flex items-center space-x-[6px]"
                        >
                          <img src={shortlistIcon} alt="shortlist icon" />
                          <span className="font-[inter] font-normal text-[16px]">
                            Shortlist
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <table
                className="w-full overflow-hidden border-[1px] border-solid  rounded-2xl"
                style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px" }}
              >
                <thead className="bg-[#F5F8FF] text-[0.833vw] text-[600] rounded-2xl">
                  <tr className="py-3  rounded-tl-2xl rounded-tr-2xl border-b-[1px] border-[#E2E8F0]">
                    <th className="rounded-tl-2xl text-[14px] font-[500] font-inter text-[#222850] text-left p-4 flex items-center">
                      <div className="custome-check-box un-select">
                        <input
                          className="h-[15px] w-[15px] bg-[#0875F480] border-[#222850] cursor-pointer"
                          id="default-checkbox"
                          checked={JSON.parse(selectAll)}
                          onChange={handleSelectAllChange}
                          type="checkbox"
                          value=""
                        />
                        <span class="checkmark"></span>
                      </div>
                      <span className="text-[14px] min-w-[81px] font-[500] font-inter text-[#222850] text-left capitalize ms-1">
                        unselect all
                      </span>
                    </th>
                    <th className="text-[14px] font-[500] font-inter text-[#222850] text-left p-4 ">
                      First Name
                    </th>
                    <th className="text-[14px] font-[500] font-inter text-[#222850] text-left p-4">
                      Last Name
                    </th>
                    <th className="text-[14px] font-[500] font-inter text-[#222850] text-left p-4">
                      Email
                    </th>
                    <th className="text-[14px] font-[500] font-inter text-[#222850] text-left p-4">
                      Mobile
                    </th>
                    <th className="rounded-tr-2xl text-[14px] font-[500] font-inter text-[#222850] text-left p-4">
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {filteredData?.map((row, rowIndex) => {
                    const email = Array.isArray(row) ? row[2] : row?.email;
                    return (
                      <>
                        <tr
                          className={`py-3 ${rowIndex % 2 != 0
                              ? "bg-[#F9FBFD] border-t-[1px] border-b-[1px] border-[#E2E8F0]"
                              : ""
                            }`}
                        >
                          <td className=" text-[1rem] font-[400] font-inter text-[#718096] text-left p-4">
                            <div className="custome-check-box">
                              <input
                                className=" bg-[#0875F480] border-[rgba(8, 117, 244, 0.5)] cursor-pointer"
                                type="checkbox"
                                checked={selectedCheckboxes[email] || false}
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    rowIndex,
                                    e.target.checked,
                                    row,
                                    email
                                  )
                                }
                                disabled={sameCandidate.includes(email)}
                              />
                              <div class="checkmark"></div>
                            </div>
                          </td>
                          <td className="text-[14px] font-[400] font-inter text-[#718096] text-left p-4">
                            {row.first_name}
                          </td>
                          <td className="text-[14px] font-[400] font-inter text-[#718096] text-left p-4">
                            {row.last_name}
                          </td>
                          <td className="text-[14px] font-[400] font-inter text-[#718096] text-left p-4">
                            {row.email}
                          </td>
                          <td className="text-[14px] font-[400] font-inter text-[#718096] text-left p-4">
                            {row.mobile}
                          </td>
                          <td className=" text-[1rem] font-[400] font-inter text-[#718096] text-left p-4">
                            <button
                              onClick={() =>
                                location.pathname === "/campaign/addCampaign"
                                ? handleDeleteClick(row?.uid, row?.email, row?.first_name, row?.last_name)
                                : handleDeleteClick(row?.uid, row?.email, row?.first_name, row?.last_name)
                           
                              }
                              className="bg-[#FC7F29] text-[1rem] text-white rounded-lg p-2"
                            >
                              {/* <RiDeleteBin6Line className="text-[1rem]" /> */}
                              <img src={DeleteIcon} />
                            </button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </section>
          </div>
        )}
      </div>
    </section>
  );
};

const AddCandidate = ({
  isNextAcitvated,
  setIsNextActivated,
  current,
  setCurrent,
  setAllReqData,
  uploadFile,
  setUploadFile,
  excelData,
  setExcelData,
  singleAddCandidate,
  setSingleAddCandidate,
  selectedData,
  setSelectedData,
  setSelectedCheckboxes,
  selectedCheckboxes,
  selectAll,
  setSelectAll,
  editData,
  prevEmail,
  setPrevEmail,
  filteredData,
  setFilteredData,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [errorFormat, setErrorFormat] = useState();
  const [emptyError, setEmptyError] = useState("");
  const [incorrectError, setIncorrectError] = useState("");
  const [isModalAddCandidateOpen, setIsModalAddCandidateOpen] = useState(false);
  const [newCandidatesAdded, setIsNewCandidatesAdded] = useState([]);
  const [isVisibleConfirmation, setIsVisibleConfirmation] = useState(false);
  const [checkedFilteredData, setCheckedFilteredData] = useState([]);

  const selectedFilteredData = filteredData?.filter(
    (item) => item?.is_selected
  );
  const filteredDataSelected = filteredData?.filter(
    (item) => item?.is_selected
  );

  useEffect(() => {
    if (isNextAcitvated) {
      if (current === 2) {
        if (location.pathname === "/editcampaign/addCampaign") {
          if (
            checkedFilteredData?.length !== 0 ||
            (selectedFilteredData?.length !== 0 &&
              JSON.parse(selectAll) !== true)
          ) {
            setIsVisibleConfirmation(true);
          } else {
            toast.warning(
              "Select and Sort list candidate to proceed to the next step.",
              {
                className: "custom-toast-warning"
              }
            );
          }
        } else {
          if (
            checkedFilteredData?.length !== 0 ||
            (selectedFilteredData?.length !== 0 &&
              JSON.parse(selectAll) !== true)
          ) {
            setIsVisibleConfirmation(true);
          } else {
            if (filteredData?.length === 0) {
              toast.warning(
                "Import or add a candidate to proceed to the next step.",
                {
                  className: "custom-toast-warning"
                }
              );
            } else {
              toast.warning(
                "Select and Sortlist candidate to proceed to the next step.",
                {
                  className: "custom-toast-warning"
                }
              );
            }
          }
        }
      }
      setIsNextActivated(false);
    }
  }, [isNextAcitvated]);

  const callApi = async () => {
    let checkedData = checkedFilteredData.map((item) => ({
      ...item,
      is_selected: item.is_selected ? 1 : 0,
      campaign: sessionStorage.getItem("campaignUid"),
      organization: sessionStorage.getItem("parentUid"),
      uid: item.uid ? item.uid : undefined,
    }));

    let allData = [...checkedData, ...selectedData];

    let filterData = allData.filter((item) => item.uid);
    let filterData1 = allData.filter((item) => !item.uid);

    // console.log("allData", filterData, filterData1);

    try {
      if (location.pathname === "/editcampaign/addCampaign") {
        if (filterData?.length > 0) {
          const updateEndpoint = "/api/v1/interview/update_candidate/";
          const updateResponse = await handleApiResponse(
            updateEndpoint,
            filterData,
            1
          );
          if (updateResponse?.code === "token_not_valid") {
            navigate("/");
            sessionStorage.clear();
            return;
          }

          if (updateResponse?.data?.status_code === 200) {
            toast.success("Campaign successfully updated", {
              className: "custom-toast-success"
            });
            let combinedData = [];
            console.log("selectedFilteredDataselectedFilteredData", selectedFilteredData)
            selectedFilteredData?.map((item) => {
              if (!item?.initiallySelected) {
                combinedData.push(item?.uid);
              }
            });

            if (combinedData?.length > 0) {
              const reqDataLink = {
                candidate_list: combinedData,
              };

              const responseLink = await handleApiResponse(
                "/api/v1/interview/generate_unique_link/",
                reqDataLink,
                1
              );
              if (responseLink?.code === "token_not_valid") {
                navigate("/");
                sessionStorage.clear();
                return;
              }
              if (responseLink?.data?.status_code === 200) {
                filterData1?.length <= 0 &&
                  toast.success("Email has been shared with the candidates.", {
                    className: "custom-toast-success"
                  });
                navigate("/admin/view-campaigns", {
                  state: {
                    uid: sessionStorage.getItem("campaignUid"),
                    campaign_type:
                      sessionStorage.getItem("camp_type") === "1"
                        ? "Regular"
                        : "Mass",
                    name: sessionStorage.getItem("campName"),
                    jr_name: sessionStorage.getItem("jrName"),
                    start_date: sessionStorage
                      .getItem("createdDate")
                      ?.split("T")[0],
                    end_date: sessionStorage.getItem("endDate1")?.split("T")[0],
                  },
                });
                clearsessionStorageAndNavigate();
              } else {

                // toast.error("There was an error sending the email.", {
                //   className: "custom-toast-error"
                // });


              }
            } else {
              navigate("/admin/view-campaigns", {
                state: {
                  uid: sessionStorage.getItem("campaignUid"),
                  campaign_type:
                    sessionStorage.getItem("camp_type") === "1"
                      ? "Regular"
                      : "Mass",
                  name: sessionStorage.getItem("campName"),
                  jr_name: sessionStorage.getItem("jrName"),
                  start_date: sessionStorage
                    .getItem("createdDate")
                    ?.split("T")[0],
                  end_date: sessionStorage.getItem("endDate1")?.split("T")[0],
                },
              });
              clearsessionStorageAndNavigate();
            }
          } else {
            toast.error(
              updateResponse?.data?.message || "Error updating candidates",
              {
                className: "custom-toast-error"
              }
            );
          }
        }

        if (filterData1?.length > 0) {
          const addEndpoint = "/api/v1/interview/add_candidate/";
          const addResponse = await handleApiResponse(
            addEndpoint,
            filterData1,
            1
          );
          if (addResponse?.code === "token_not_valid") {
            navigate("/");
            sessionStorage.clear();
            return;
          }

          handleResponse(addResponse);
        }
      } else {
        if (filterData1.length > 0) {
          const addEndpoint = "/api/v1/interview/add_candidate/";
          const response = await handleApiResponse(addEndpoint, filterData1, 1);
          if (response?.code === "token_not_valid") {
            navigate("/");
            sessionStorage.clear();
            return;
          }
          handleResponse(response);
        }
      }
    } catch (error) {
      console.error("Error occurred while calling API:", error);
      toast.error("Failed to process candidates. Please try again.", {
        className: "custom-toast-error"
      });
    }
  };

  const handleResponse = async (response) => {
    if (response?.data?.status_code === 200) {
      sessionStorage.removeItem("currentAddCampaign");
      sessionStorage.removeItem("roleId");
      if (location.pathname !== "/editcampaign/addCampaign") {
        toast.success("Campaign successfully added", {
          className: "custom-toast-success"
        });
      }

      let combinedData = [];

      response?.data?.data?.filter((item) => {
        if (item?.is_selected === 1) {
          combinedData.push(item?.uid);
        }
      });

      if (combinedData?.length > 0) {
        const reqDataLink = {
          candidate_list: combinedData,
        };

        const responseLink = await handleApiResponse(
          "/api/v1/interview/generate_unique_link/",
          reqDataLink,
          1
        );
        if (responseLink?.code === "token_not_valid") {
          navigate("/");
          sessionStorage.clear();
          return;
        }

        if (responseLink?.data?.status_code === 200) {
          toast.success("Email has been shared with the candidates.", {
            className: "custom-toast-success"
          });
            navigate("/admin/view-campaigns", {
              state: {
                uid: sessionStorage.getItem("campaignUid"),
                campaign_type:
                  sessionStorage.getItem("camp_type") === "1"
                    ? "Regular"
                    : "Mass",
                name: sessionStorage.getItem("campName"),
                jr_name: sessionStorage.getItem("jrName"),
                start_date: sessionStorage
                  .getItem("createdDate")
                  ?.split("T")[0],
                end_date: sessionStorage.getItem("endDate1")?.split("T")[0],
              },
            });
            clearsessionStorageAndNavigate();
        } else {
          toast.error("There was an error sending the email.", {
            className: "custom-toast-error"
          });
        }
      } else {
        navigate("/admin/view-campaigns", {
          state: {
            uid: sessionStorage.getItem("campaignUid"),
            campaign_type:
              sessionStorage.getItem("camp_type") === "1" ? "Regular" : "Mass",
            name: sessionStorage.getItem("campName"),
            jr_name: sessionStorage.getItem("jrName"),
            start_date: sessionStorage.getItem("createdDate")?.split("T")[0],
            end_date: sessionStorage.getItem("endDate1")?.split("T")[0],
          },
        });
        clearsessionStorageAndNavigate();
      }
    }
    // else {
    //   toast.error(
    //     "Duplicate emails are not acceptable.A candidate with this email already exists."
    //   );
    //   // toast.error(response?.data?.message);
    // }
  };

  const clearsessionStorageAndNavigate = () => {
    sessionStorage.removeItem("campaignUid");
    sessionStorage.removeItem("campName");
    sessionStorage.removeItem("jrName");
    sessionStorage.removeItem("camp_type");
    sessionStorage.removeItem("skills");
    sessionStorage.removeItem("createdDate");
    sessionStorage.removeItem("endDate1");
    sessionStorage.removeItem("coding_ques");
    sessionStorage.removeItem("desc");
  };

  const generateExcelFile = () => {
    const requiredHeaders = ["First Name", "Last Name", "Email", "Mobile"];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([]);

    XLSX.utils.sheet_add_aoa(ws, [requiredHeaders], { origin: "A1" });

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    saveAs(blob, "add_candidate_template.xlsx");
  };

  const openModalAddCandidate = () => {
    setIsModalAddCandidateOpen(true);
  };

  const closeModalAddCandidate = () => {
    setIsModalAddCandidateOpen(false);
  };

  const handleAddCandidate = (newCandidate) => {
    setExcelData((prevData) => {
      if (!uploadFile && prevData?.length === 0) {
        const newData = [
          ["First Name", "Last Name", "Email", "Mobile"],
          newCandidate,
        ];
        return newData;
      } else {
        const updatedData = [...prevData];
        updatedData.splice(1, 0, newCandidate);
        return updatedData;
      }
    });
  };

  return (
    <section className="fill-available flex flex-col gap-4">
      {/* {isModalAddCandidateOpen && (
        <ModalAddCandidate
          head={"Add"}
          isOpen={isModalAddCandidateOpen}
          onClose={closeModalAddCandidate}
          setExcelData={setExcelData}
          excelData={excelData}
          setSingleAddCandidate={setSingleAddCandidate}
          setSelectedCheckboxes={setSelectedCheckboxes}
          selectedCheckboxes={selectedCheckboxes}
          uploadFile={uploadFile}
          setUploadFile={setUploadFile}
          setSelectedData={(newCandidate) => {
            setSelectedData((prev) => [newCandidate, ...prev]);
            setIsNewCandidatesAdded((prev) => [newCandidate, ...prev]);
          }}
          onAddCandidate={handleAddCandidate}
        />
      )} */}
      {/* {renderTable()} */}
      <div className="self-end flex gap-2">
        {/* <AnalyticsPageBtn
          name={"Download Format"}
          img={1}
          onClick={generateExcelFile}
        />
        <FaInfoCircle
          data-tooltip-id="skillsInfo"
          className=" cursor-pointer text-gray-500 mr-2"
        />
        <Tooltip id="skillsInfo" place="top" effect="solid">
          All fields are mandatory to filled in the format.
        </Tooltip>
        <AnalyticsPageBtn
          name={"Add Candidate"}
          img={5}
          onClick={openModalAddCandidate}
        /> */}
      </div>
      <div className="container">
        <UploadResumeOnly
          setUploadResume={setUploadFile}
          uploadResume={uploadFile}
          setErrorFormat={setErrorFormat}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          setEmptyError={setEmptyError}
          setIncorrectError={setIncorrectError}
          excelData={excelData}
          setExcelData={setExcelData}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          selectedCheckboxes={selectedCheckboxes}
          setSelectedCheckboxes={setSelectedCheckboxes}
          editData={editData}
          prevEmail={prevEmail}
          setPrevEmail={setPrevEmail}
          newCandidatesAdded={newCandidatesAdded}
          setIsNewCandidatesAdded={setIsNewCandidatesAdded}
          setIsNextActivated={setIsNextActivated}
          isNextAcitvated={isNextAcitvated}
          singleAddCandidate={singleAddCandidate}
          setSingleAddCandidate={setSingleAddCandidate}
          isVisibleConfirmation={isVisibleConfirmation}
          setIsVisibleConfirmation={setIsVisibleConfirmation}
          handleCallApi={callApi}
          checkedFilteredData={checkedFilteredData}
          setCheckedFilteredData={setCheckedFilteredData}
          generateExcelFile={generateExcelFile}
        />
      </div>
      {errorFormat &&
        (selectedData?.length < 1 || checkedFilteredData.length < 1) && (
          <div className="text-red-500">File not in right format</div>
        )}
      {emptyError &&
        (selectedData?.length < 1 || checkedFilteredData.length < 1) && (
          <div className="text-red-500">No candidate has been selected</div>
        )}

      {incorrectError &&
        (selectedData?.length < 1 || checkedFilteredData.length < 1) && (
          <div className="text-red-500">File not selected.</div>
        )}
    </section>
  );
};

const ReviewCandidate = ({
  isNextAcitvated,
  current,
  allReqData,
  setIsNextActivated,
  prevEmail,
}) => {
  const [candidates, setCandidates] = useState([]);
  const [roleName, setRoleName] = useState(
    sessionStorage.getItem("roleName") ? sessionStorage.getItem("roleName") : ""
  );
  const [start, setStart] = useState(
    sessionStorage.getItem("expFrom") ? sessionStorage.getItem("expFrom") : ""
  );
  const [end, setEnd] = useState(
    sessionStorage.getItem("expTo") ? sessionStorage.getItem("expTo") : ""
  );
  const navigate = useNavigate();
  const [qtype, setQType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [campaign_type, setCampaign_type] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [hasGeneratedLinks, setHasGeneratedLinks] = useState(false);

  useEffect(() => {
    const linkType = sessionStorage.getItem("mail_share_type");
    if (linkType === "1") {
      setIsVisible(true);
    }
  }, []);

  useEffect(() => {
    console.log(allReqData[0]);
    setStartDate(allReqData[0]?.start_date);
    setEndDate(allReqData[0]?.end_date);
    setCampaign_type(allReqData[0]?.campaign_type === 1 ? "Regular" : "Mass");

    console.log(allReqData[1]);
    setQType(() => {
      return allReqData[1] === "option1"
        ? "AI Questions"
        : allReqData[1] === "option2"
          ? "HR Questions"
          : allReqData[1] === "option3"
            ? "Blended Questions"
            : null;
    });

    setCandidates(allReqData[2]);
  }, [allReqData]);

  const downloadLinks = async () => {
    callApi1();
    if (!hasGeneratedLinks) {
      const reqData = {
        campaign_uid: getLocal("campaignUid"),
      };
      const response = await handleApiResponse(
        "/api/v1/interview/generate_unique_link/",
        reqData,
        1
      );
      if (response?.code === "token_not_valid") {
        navigate("/");
        sessionStorage.clear();
        return;
      }

      if (response?.data?.status_code === 200) {
        const dataArray = response?.data?.data;
        const formattedData = dataArray.map((item) => ({
          first_name: item.candidate.first_name,
          last_name: item.candidate.last_name,
          emailid: item.candidate.email,
          links:
            `${process.env.REACT_APP_FRONEND}/interview/candidate/` + item.link,
        }));
        const worksheet = XLSX.utils.json_to_sheet(formattedData);

        // Create a new workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Links");

        // Generate a buffer
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        // Create a blob from the buffer
        const blob = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });

        // Save the file
        saveAs(blob, "links.xlsx");
      }
    }
  };

  const callApi1 = async () => {
    const reqData = candidates;
    const response = await handleApiResponse(
      "/api/v1/interview/add_candidate/",
      reqData,
      1
    );
    if (response?.code === "token_not_valid") {
      navigate("/");
      sessionStorage.clear();
      return;
    }
    if (response?.data?.status_code === 200) {
      sessionStorage.removeItem("currentAddCampaign");
      sessionStorage.removeItem("roleId");
      sessionStorage.removeItem("campaignUid");
      sessionStorage.removeItem("campName");
      sessionStorage.removeItem("jrName");
      sessionStorage.removeItem("camp_type");
      sessionStorage.removeItem("skills");
      sessionStorage.removeItem("createdDate");
      sessionStorage.removeItem("endDate1");
      sessionStorage.removeItem("coding_ques");
      sessionStorage.removeItem("desc");
      toast.success("Campaign successfully added", {
        className: "custom-toast-success"
      });
      navigate("/admin/campaign");
    } else {
      toast.error(response?.data?.message, {
        className: "custom-toast-error"
      });
    }
  };

  const callApi = async () => {
    const reqData = candidates;
    console.log(reqData);
    if (!reqData.hasOwnProperty("organization")) {
      // Add organization key to reqData
      reqData.organization = sessionStorage.getItem("parentUid");
    }
    if (!reqData.hasOwnProperty("campaign")) {
      // Add campaign key to reqData
      reqData.campaign = sessionStorage.getItem("campaignUid");
    }
    const response = await handleApiResponse(
      "/api/v1/interview/add_candidate/",
      reqData,
      1
    );
    if (response?.code === "token_not_valid") {
      navigate("/");
      sessionStorage.clear();
      return;
    }
    if (response?.data?.status_code === 200) {
      sessionStorage.removeItem("currentAddCampaign");
      sessionStorage.removeItem("roleId");
      toast.success("Campaign successfully added", {
        className: "custom-toast-success"
      });
      const reqData = {
        campaign_uid: sessionStorage.getItem("campaignUid"),
      };
      const response = await handleApiResponse(
        "/api/v1/interview/generate_unique_link/",
        reqData,
        1
      );
      if (response?.code === "token_not_valid") {
        navigate("/");
        sessionStorage.clear();
        return;
      }
      console.log(response?.data);
      if (response?.data?.status_code === 200) {
        toast.success("Email has been shared to the candidates.", {
          className: "custom-toast-success"
        });
        sessionStorage.removeItem("campaignUid");
        sessionStorage.removeItem("campName");
        sessionStorage.removeItem("jrName");
        sessionStorage.removeItem("camp_type");
        sessionStorage.removeItem("skills");
        sessionStorage.removeItem("createdDate");
        sessionStorage.removeItem("endDate1");
        sessionStorage.removeItem("coding_ques");
        sessionStorage.removeItem("desc");
      }
      navigate("/admin/campaign");
    } else {
      toast.error(response?.data?.message, {
        className: "custom-toast-error"
      });
    }
  };

  const generateUniqueLink = async (id) => {
    const reqData = {
      campaign_uid: sessionStorage.getItem("campaignUid"),
      candidate_uid: id,
    };
    const response = await handleApiResponse(
      "/api/v1/interview/generate_unique_link/",
      reqData,
      1
    );
    if (response?.code === "token_not_valid") {
      navigate("/");
      sessionStorage.clear();
      return;
    }
    console.log("this is status", response?.data?.status_code);
    if (response?.data?.status_code === 200) {
      let link =
        `${process.env.REACT_APP_FRONEND}/interview/candidate/` +
        response?.data?.data?.link;

      //navigator.clipboard.writeText(link)
      // copyToClipboard(link);

      toast.success("link has been sent to email !", {
        className: "custom-toast-success"
      });
      // setRedirectLink(link);
      // setCount((prev) => prev + 1);
    } else {
      toast.error(response?.data?.msg, {
        className: "custom-toast-error"
      });
    }
  };

  // const handleRegenerate = (id) => {
  //   // console.log(id);
  //   // props?.callApi();
  //   // if (!props.candidateId.includes(id)) {
  //   //   props.setCandidateId((prev) => [...prev, id]);
  //   // }
  //   generateUniqueLink(id);
  // };

  useEffect(() => {
    if (isNextAcitvated) {
      console.log("this ran");
      if (current === 3) {
        console.log("this is 3", allReqData);
        callApi();
      }
      setIsNextActivated(false);
    }
  }, [isNextAcitvated]);
  return (
    <section className="fill-available flex flex-col gap-4">
      <div className="text-[1.389vw] text-[600] tracking-[2%] mb-4">
        {roleName} ({start}-{end} Years)
      </div>
      <div className="flex gap-8 mb-4">
        <div className="flex gap-2">
          <img src={Ellipse} alt="ellipse" />
          <div>{roleName}</div>
        </div>
        <div className="flex gap-2">
          <img src={Ellipse} alt="ellipse " />
          <div>{campaign_type}</div>
        </div>
        <div className="flex gap-2">
          <img src={Ellipse} alt="ellipse " />
          <div>
            {startDate} to {endDate}
          </div>
        </div>
        <div className="flex gap-2">
          <img src={Ellipse} alt="ellipse " />
          <div>{qtype}</div>
        </div>
      </div>
      {isVisible && (
        <div className="self-end">
          <AnalyticsPageBtn
            name={"Download Links"}
            img={1}
            onClick={downloadLinks}
          />
        </div>
      )}
      <div>
        <div className="bg-[#FFFFFF] mt-2 p-4 rounded-lg">
          <p className="mt-4 mb-4 text-[1.111vw] font-medium leading-[2%]">
            Added Candidate
          </p>
          <div className="grid grid-cols-2 h-[27rem] overflow-auto">
            {candidates &&
              candidates.map((e) => {
                return (
                  <div className="w-[22vw] h-fit mt-8 border-2 p-2 rounded-lg border-[#A6AEBA33] flex justify-between">
                    <p> ({e.email})</p>
                    <img src={LinkIcon} alt="Link" className="w-4 h-4" />
                  </div>
                );
              })}
            {prevEmail &&
              prevEmail.map((e) => {
                return (
                  <div className="w-[22vw] h-fit mt-8 border-2 p-2 rounded-lg border-[#A6AEBA33] flex justify-between">
                    <p> ({e})</p>
                    <img src={LinkIcon} alt="Link" className="w-4 h-4" />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};
