import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Label } from 'recharts';

const SuspiciousChart = ({ totalData }) => {
  const data = [
    {
      name: "Don't manipulate",
      value: Number(totalData?.dontManPer),
      color: '#d57d00',
    },
    { name: 'No face', value: Number(totalData?.noFacePer), color: '#975ced' },
    {
      name: 'Multi-face',
      value: Number(totalData?.multiface),
      color: '#9c9898',
    },
  ];

  if (totalData?.total === 0) {
    data.push({
      name: 'overAll',
      value: Number(totalData?.overAll),
      color: '#cddd98',
    });
  }

  const title = totalData?.total;
  return (
    <div style={{position: 'relative' }}>
      <PieChart width={200} height={200}>
        <Pie
          data={data}
          dataKey="value"
          innerRadius="55%"
          outerRadius="80%"
          startAngle={65}
          endAngle={-300}
          cornerRadius={18}
          labelLine={false}
          paddingAngle={0.1}
          strokeWidth={10}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
          <Label
            value={title}
            position="center"
            fill="#333"
            style={{
              fontSize: "30px",
              fontWeight: 'bold',
              // textAnchor: 'middle',
              fontFamily: "Inter",
            }}
          />
        </Pie>
        {/* <Tooltip /> */}
      </PieChart>
    </div>
  );
};

export default SuspiciousChart;
