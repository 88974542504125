import { BrowserRouter } from "react-router-dom";
import { UserRoute } from "./routing/UserRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import {store,persistor} from "./redux/store"
import { AdminRoute } from "./routing/AdminRoute";
import { PersistGate } from "redux-persist/integration/react";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/main.css"

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <UserRoute />
            <AdminRoute />
          </PersistGate>
        </Provider>
      </BrowserRouter>
      <ToastContainer toastStyle={{ backgroundColor: '#00112D', color:'white' }} 
      closeButton={<CustomCloseButton />}
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={true}
      pauseOnHover={true}
      />
    </div>
  );
}

const CustomCloseButton = ({ closeToast }) => (
  <span onClick={closeToast} style={{ color: "#A6AEBA", cursor: "pointer" }}>
    x
  </span>
);

export default App;