import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleApiResponse } from '../../API/services';
import { toast } from 'react-toastify';
import { close, otpbg } from '../../assets/imgs';
import { NextButton } from '../buttons';
import { setLocal } from '../../utils/localStorage';

function OtpGenerator(props) {
  const formRef = useRef(null);
  const inputsRef = useRef([]);
  const navigate = useNavigate();
  const [wrongOTP, setWrongOTP] = useState('');
  const [otp, setOtp] = useState(null);
  const [timer, setTimer] = useState(30);
  const [hovered, setHovered] = useState(false);
  const [isResendDisabled, setIsResendDisabled] = useState(false);

  const handleHover = () => {
    setHovered(!hovered);
  };

  useEffect(() => {
    let countdown;
    if (isResendDisabled) {
      countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(countdown);
            setIsResendDisabled(false);
            return 30;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => clearInterval(countdown);
  }, [isResendDisabled]);

  useEffect(() => {
    console.log(props.setVerified)
    if (!props.setVerified) {
      const fetchData = async () => {
        try {
          const response = await handleApiResponse('/api/v1/generateotp/', {
            email: props.email,
            otp_type : 3
          });
          console.log('API Response:', response); 
          if (response?.code === 'token_not_valid') {
            navigate('/');
            sessionStorage.clear();
            return;
          }
          if (response.status_code === 200) {
            setOtp(response?.data.otp);
          } else if (response.status_code === 400) {
            // handle error
          }
        } catch (error) {
          console.error('API Error:', error);
        }
      };

      fetchData();
    }
  }, [props.setVerified]);

  const fetchData1 = async () => {
    setHovered(false);
    try {
      const response = await handleApiResponse('/api/v1/generateotp/', {
        email: props.email,
        otp_type : 3
      });
      console.log('API Response:', response);
      if (response?.code === 'token_not_valid') {
        navigate('/');
        sessionStorage.clear();
        return;
      }
      if (response.status_code === 200) {
        setIsResendDisabled(true);
        setOtp(response?.data.otp);
      } else if (response.status_code === 400) {
        // handle error
      }
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  useEffect(() => {
    const form = formRef.current;
    const inputs = inputsRef.current;
    const submit = form.querySelector('button');

    const handleKeyDown = (e) => {
      const index = inputs.indexOf(e.target);

      if (
        !/^[0-9]{1}$/.test(e.key) &&
        e.key !== 'Backspace' &&
        e.key !== 'Delete' &&
        e.key !== 'Tab' &&
        !e.metaKey
      ) {
        e.preventDefault();
      }

      if (e.key === 'Backspace' && index > 0) {
        e.preventDefault();
        inputs[index].value = '';
        inputs[index - 1].focus();
      }

      // Detect Ctrl+V and trigger paste event
      if (e.ctrlKey && e.key === 'v') {
        e.preventDefault();
        navigator.clipboard.readText().then((text) => {
          const digits = text.split('');
          inputs.forEach((input, index) => {
            input.value = digits[index] || '';
          });
          submit.focus();
        });
      }
    };

    const handleInput = (e) => {
      const { target } = e;
      const index = inputs.indexOf(target);
      if (target.value) {
        if (index < inputs.length - 1) {
          inputs[index + 1].focus();
        } else {
          submit.focus();
        }
      }
    };

    const handleFocus = (e) => {
      e.target.select();
    };

    const handlePaste = (e) => {
      e.preventDefault();
      const text = e.clipboardData.getData('text');
      if (!new RegExp(`^[0-9]{${inputs.length}}$`).test(text)) {
        return;
      }
      const digits = text.split('');
      inputs.forEach((input, index) => {
        input.value = digits[index] || '';
      });
      submit.focus();
    };

    inputs.forEach((input) => {
      input.addEventListener('input', handleInput);
      input.addEventListener('keydown', handleKeyDown);
      input.addEventListener('focus', handleFocus);
      input.addEventListener('paste', handlePaste);
    });

    // return () => {
    //   inputs.forEach((input) => {
    //     input.removeEventListener("input", handleInput);
    //     input.removeEventListener("keydown", handleKeyDown);
    //     input.removeEventListener("focus", handleFocus);
    //     input.removeEventListener("paste", handlePaste);
    //   });
    // };
  }, []);

  const setClose = () => {
    props.setShowEmailVerification(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userOTP = inputsRef.current.map((input) => input.value).join('');
    if (userOTP.length === 0) {
      toast.warning('Enter Otp.',{
        className: "custom-toast-success",
      });
      return;
    }
    console.log('verifing');
    const reqData = {
      email: props.email,
      otp: userOTP,
    };
    console.log(props.setVerified);
    if (props.setVerified) {
      const response = await handleApiResponse('/api/v1/verifyemail/', reqData);
      if (response?.code === 'token_not_valid') {
        navigate('/');
        sessionStorage.clear();
        return;
      }
      if (response.status_code === 200) {
        setClose();
        if (props.setVerified) {
          props.setVerified(true);
        }
        toast.success('Verified.',{
          className: "custom-toast-success",
        });
        // const tkn = response?.data?.access;
        // console.log(tkn);
        // localStorage.setItem("accesTKN", tkn);
      } else {
        toast.error('Enter correct OTP.',{
          className: "custom-toast-error",
        });
      }
    }
    console.log(props.setVerified)
    if (!props.setVerified) {
      const response = await handleApiResponse('/api/v1/verifyotp/', reqData);
      if (response?.code === 'token_not_valid') {
        navigate('/');
        sessionStorage.clear();
        return;
      }
      console.log(response.status_code)
      if (response.status_code === 200) {
        // setClose();
        if (props.setVerified) {
          props.setVerified(true);
        }
        console.log('navigating................')
        console.log(response?.data?.access)
        setLocal("adminToken", response?.data?.access);
        setLocal("first_name", response?.data?.first_name);
        setLocal("last_name", response?.data?.last_name);
        // setLocal("last_name", response?.data?.last_name)
        setLocal("uid", response?.data?.uid);
        setLocal("parentUid", response?.data?.parent);
        setLocal("isResume", response?.data?.is_resume_upload_allow);
        setLocal("organization", response?.data?.organization);
        setLocal("companyId", response?.data?.id);
        setLocal("mail_share_type", response?.data?.mail_share_type);
        navigate('/admin/dashboard');
        toast.success('Verified.',{
          className: "custom-toast-success",
        });
        // const tkn = response?.data?.access;
        // console.log(tkn);
        // localStorage.setItem("accesTKN", tkn);
      } else {
        toast.error('Enter correct OTP.',{
          className: "custom-toast-error",
          });
      }
    }
  };

  return (
    <div
      className="absolute text-[#00112D] max-w-md mx-auto text-center bg-[#FFFFFF] px-4 sm:px-8 py-10 rounded-xl shadow backdrop-blur"
    >
      <div
        className="flex justify-end cursor-pointer"
        onClick={(e) => setClose()}
      >
        <img src={close} alt="close button" />
      </div>
      <header className="mb-8">
        <h1 className="text-2xl text-[#00112D] font-semibold mb-1">
          Email Verification
        </h1>
        <p className="text-[15px] text-[#00112D]">
          Enter your OTP for verification sent on mail
        </p>
      </header>
      <form id="otp-form" ref={formRef} onSubmit={handleSubmit}>
        <div className="flex items-center justify-center gap-3">
          {Array.from({ length: 6 }, (_, index) => (
            <input
              key={index}
              type="text"
              className="w-14 h-14 text-center text-[#00112D] text-2xl font-extrabold border border-[#22284E] hover:border-[#A6AEBA] appearance-none rounded-[10px] p-4 outline-none focus:border-[#A6AEBA] focus:ring-2 focus:ring-[#A6AEBA]"
              maxLength={1}
              ref={(el) => (inputsRef.current[index] = el)}
            />
          ))}
        </div>
        <div className="mt-[21px]">
          {wrongOTP && <div className="text-red-500 mb-4">{wrongOTP}</div>}
          <button
            type="submit"
            className="w-full inline-flex justify-center whitespace-nowrap rounded-[16px] text-sm font-medium text-white"
          >
            <NextButton />
          </button>
        </div>
      </form>
      <div
        className={`w-[384px] text-[14px] h-[52px] rounded-[16px] text-[#22284E] mt-4 flex justify-center items-center cursor-pointer border-2 border-[#22284E]`}
        onClick={isResendDisabled ? null : fetchData1}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {isResendDisabled ? `Resend OTP in ${timer}s` : 'Resend OTP'}
      </div>
    </div>
  );
}

export default OtpGenerator;
