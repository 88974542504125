import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';

const AudioRecorderWithWaveform = (props) => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [recordingProgress, setRecordingProgress] = useState(0);
  const mediaRecorderRef = useRef(null);
  const canvasRef = useRef(null);
  const chunksRef = useRef([]);
  const analyserRef = useRef(null);
  const animationFrameRef = useRef(null);
  const audioContextRef = useRef(null); // To hold the AudioContext

  useEffect(() => {
    if (props.startRec) {
      startRecording();
    }
    return () => {
      if (mediaRecorderRef.current && isRecording) {
        mediaRecorderRef.current.stream
          .getTracks()
          .forEach((track) => track.stop());
      }
    };
  }, [props.startRec]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = async () => {
        const blob = new Blob(chunksRef.current, { type: 'audio/webm' });
        setAudioBlob(blob);
        chunksRef.current = [];

        // Analyze audio levels after recording stops
        const isMicrophoneWorking = await analyzeAudio(blob);
        if (!isMicrophoneWorking) {
          console.warn('Microphone may not have captured any audio.');
        } else {
          visualizeAudio(blob, isMicrophoneWorking); // Automatically visualize the audio after recording ends
        }
      };

      setIsRecording(true);
      mediaRecorderRef.current.start();

      let progress = 0;
      const timer = setInterval(() => {
        progress += 1;
        setRecordingProgress(progress);

        if (progress >= 5) {
          clearInterval(timer);
          stopRecording();
        }
      }, 1000);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setRecordingProgress(0);
    }
  };

  const analyzeAudio = async (blob) => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)();
    }

    try {
      const arrayBuffer = await blob.arrayBuffer();
      const audioBuffer = await audioContextRef.current.decodeAudioData(
        arrayBuffer
      );

      // Check if audioBuffer length is significant
      if (audioBuffer.length === 0) {
        return false; // No audio data
      }

      const channelData = audioBuffer.getChannelData(0);

      // Calculate RMS
      const rms = Math.sqrt(
        channelData.reduce((sum, value) => sum + value * value, 0) /
          channelData.length
      );

      // Set thresholds
      const silenceThreshold = 0.0001; // Adjust as needed for silence
      const whisperThreshold = 0.0025; // Adjust for whisper detection
      const normalThreshold = 0.005; // Adjust for normal speaking detection

      // Determine microphone status based on RMS
      if (rms < silenceThreshold) {
        console.log('voice pattern nill', rms);
        toast.warn('No audio recorded. Please check your microphone.');
        props.setIsTransitionVisible(false);
        return false; // Mic was effectively off
      } else if (rms < whisperThreshold) {
        console.log('voice pattern whisper', rms);
        return true; // Mic detected whisper
      } else if (rms < normalThreshold) {
        console.log('voice pattern normal', rms);
        return true; // Mic detected normal speech
      } else {
        console.log('voice pattern loud', rms);
        return true; // Loud sound detected
      }
    } catch (error) {
      console.error('Error decoding audio data:', error);
      return false; // Return false if there was an error decoding
    }
  };

  const visualizeLiveAudio = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;

    const bufferLength = analyserRef.current.fftSize;
    const dataArray = new Uint8Array(bufferLength);

    const drawWaveform = () => {
      analyserRef.current.getByteTimeDomainData(dataArray);

      ctx.clearRect(0, 0, width, height);
      ctx.beginPath();
      ctx.moveTo(0, height / 2);

      for (let i = 0; i < bufferLength; i++) {
        const amplitude = dataArray[i] / 128.0 - 1.0; // Normalize amplitude
        const y = (amplitude * height) / 2;
        const x = (i / bufferLength) * width;

        ctx.lineTo(x, height / 2 + y);
      }

      ctx.strokeStyle = 'rgb(59, 130, 246)'; // Tailwind blue-500
      ctx.lineWidth = 2;
      ctx.stroke();

      animationFrameRef.current = requestAnimationFrame(drawWaveform);
    };

    drawWaveform();
  };

  const visualizeAudio = async (blob, isMicrophoneWorking) => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)();
    }

    try {
      const arrayBuffer = await blob.arrayBuffer();
      const audioBuffer = await audioContextRef.current.decodeAudioData(
        arrayBuffer
      );

      const source = audioContextRef.current.createBufferSource();
      analyserRef.current = audioContextRef.current.createAnalyser();
      analyserRef.current.fftSize = 2048;

      source.buffer = audioBuffer;

      // Connect the source to the analyser, but not to the destination (no audio output)
      source.connect(analyserRef.current);

      source.onended = () => {
        // When the audio finishes, we stop the waveform and notify the parent
        cancelAnimationFrame(animationFrameRef.current);
        if (isMicrophoneWorking) {
          props.setIsFinished(true);
        }
      };

      source.start();
      visualizeLiveAudio(); // Start waveform animation
    } catch (error) {
      console.error('Error visualizing audio:', error);
    }
  };

  return (
    <div className="space-y-4">
      <div className="w-full h-24 bg-white rounded-[16px] p-2 shadow-md overflow-hidden">
        <div className="border-black border-[1px] rounded-[10.12px]">
          <canvas
            ref={canvasRef}
            width={314}
            height={78}
            className="w-full h-full"
          />
        </div>
      </div>
    </div>
  );
};

export default AudioRecorderWithWaveform;
