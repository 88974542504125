import React from "react";
import { PieChart, Pie, Cell, Label, ResponsiveContainer } from "recharts";

const TeamWorkChart = ({ totalData }) => {
  const data = [
    {
      name: "Score",
      value: Number(totalData?.progres),
      color: totalData?.ProgColor,
    },
    {
      name: "",
      value: Number(totalData?.total),
      color: totalData?.withoutProgColor,
    },
  ];

  const title = `${Number(totalData?.progres)}%`;

  return (
    <ResponsiveContainer width="90%" height="90%">
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          innerRadius="80%"
          outerRadius="100%"
          startAngle={60}
          endAngle={-295}
          cornerRadius={15}
          labelLine={false}
          paddingAngle={5}
          strokeWidth={15}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} strokeWidth={0} />
          ))}
          <Label
            value={title}
            position="center"
            fill="#333"
            style={{
              fontSize: "30px",
              fontWeight: "bold",
              fontFamily: "Inter",
            }}
          />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default TeamWorkChart;
