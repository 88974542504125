import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AIImage,
  AIMockImg,
  ainonspeaking,
  closeBtnIcon,
} from "../assets/imgs";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-dracula";
import "ace-builds/src-noconflict/theme-github";
export const NewCodeEditor = ({
  messages,
  setOpenEditor,
  setMessages,
  socket,
  timer,
  setOpenCodeFirstTime,
  setLoader,
  setIsSpeaking,
}) => {
  const editorRef = useRef();
  const language = ["javascript", "java", "python", "c++"];
  const [langId, setLangId] = useState("");
  const [output, setOutput] = useState("");
  const [isExecuting, setIsExecuting] = useState(false);
  const [countdown, setCountdown] = useState(
    sessionStorage.getItem("countD")
      ? JSON.parse(sessionStorage.getItem("countD"))
      : { minutes: 30, seconds: 0 }
  );
  const [videoOn, setVideoOn] = useState(true);
  const [videoStream, setVideoStream] = useState(null);
  const [current, setCurrent] = useState(
    sessionStorage.getItem("curr")
      ? parseInt(sessionStorage.getItem("curr"))
      : 0
  );
  const [code, setCode] = useState(
    sessionStorage.getItem("code")
      ? JSON.parse(sessionStorage.getItem("code"))
      : ["", "", ""]
  );
  const [codingQuestions, setCodingQuestions] = useState(
    sessionStorage.getItem("codingQuestion")
      ? JSON.parse(sessionStorage.getItem("codingQuestion"))
      : messages
  );

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return { min: minutes, sec: seconds };
  };

  useEffect(() => {
    sessionStorage.setItem("code", JSON.stringify(code));
  }, [code]);

  useEffect(() => {
    if (messages.length) {
      if (messages[0].length) {
        console.log(messages, "==<<>>==");
        sessionStorage.setItem("codingQuestion", JSON.stringify(messages));
      }
    }
  }, []);

  // useEffect(() => {
  //   setCodingQuestions(messages);
  //   setOpenCodeFirstTime(false);
  // }, [messages.length]);

  useEffect(() => {
    if (codingQuestions[current]) {
      const commonLng = language.find((item) =>
        codingQuestions[current].toLowerCase().includes(item.toLowerCase())
      );

      setLangId(commonLng || "other");
    }
  }, [codingQuestions, current]);

  const nextQuestion = () => {
    setCurrent((prev) => prev + 1);
    setOutput("");
    sessionStorage.setItem("curr", current + 1);
    setLangId("");
  };

  const prevQuestion = () => {
    setCurrent((prev) => prev - 1);
    setOutput("");
    sessionStorage.setItem("curr", current - 1);
    setLangId("");
  };

  const sendtoBackend = async () => {
    const ques = codingQuestions.map((ele, idx) => {
      return { q: codingQuestions[idx], a: code[idx] };
    });
    const reqData = {
      text: { isCoding: true, questions: ques },
      time: formatTime(timer),
    };
    console.log(reqData);
    return reqData;
  };

  useEffect(() => {
    if (countdown.minutes === 0 && countdown.seconds === 0) {
      sendData();
      setOpenEditor(false);
      sessionStorage.removeItem("openEditor");
    }
  }, [countdown]);

  const sendData = async () => {
    const reqData = await sendtoBackend();
    if (socket) {
      socket.send(JSON.stringify(reqData));
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: "Code has been submitted",
          isUser: true,
        },
      ]);
      sessionStorage.removeItem("openEditor");
      setOpenEditor(false);
      setLoader(true);
      setIsSpeaking(false);
    } else {
      console.error("Socket is null or undefined.");
    }
  };

  useEffect(() => {
    console.log("Access webcam");
    let stream = null;

    if (
      videoOn &&
      navigator.mediaDevices &&
      navigator.mediaDevices.getUserMedia
    ) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((streamObj) => {
          setVideoStream(streamObj);
          stream = streamObj;
        })
        .catch((error) => {
          console.error("Error accessing webcam:", error);
        });
    }

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
        setVideoStream(null);
      }
    };
  }, [videoOn]);

  const renderVideo = useMemo(() => {
    if (!videoStream) return null;
    return (
      <video
        className="w-[100%] h-[50vh] object-cover"
        autoPlay={true}
        ref={(videoRef) => {
          if (videoRef) {
            videoRef.srcObject = videoStream;
          }
        }}
      />
    );
  }, [videoStream]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevTimer) => {
        if (prevTimer.minutes === 0 && prevTimer.seconds === 0) {
          clearInterval(interval);
          sessionStorage.setItem(
            "countD",
            JSON.stringify({ minutes: 0, seconds: 0 })
          );
          return { minutes: 0, seconds: 0 };
        } else if (prevTimer.seconds === 0) {
          sessionStorage.setItem(
            "countD",
            JSON.stringify({ minutes: prevTimer.minutes - 1, seconds: 59 })
          );
          return { minutes: prevTimer.minutes - 1, seconds: 59 };
        } else {
          sessionStorage.setItem(
            "countD",
            JSON.stringify({
              minutes: prevTimer.minutes,
              seconds: prevTimer.seconds - 1,
            })
          );
          return { minutes: prevTimer.minutes, seconds: prevTimer.seconds - 1 };
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [setOpenEditor]);

  const executeCPP = async (code) => {
    try {
      setIsExecuting(true);
      setOutput("Executing...");
      const reqBody = {
        compiler: "gcc-13.2.0",
        title: "",
        description: "",
        code: code,
        codes: [],
        options: "warning,boost-1.83.0-gcc-13.2.0,gnu++2b,cpp-no-pedantic",
        stdin: "",
        "compiler-option-raw": "",
        "runtime-option-raw": "",
      };

      const res = await fetch("https://wandbox.org/api/compile.ndjson", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      });

      const reader = res.body.getReader();
      let decoder = new TextDecoder("utf-8");
      let buffer = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });

        const parts = buffer?.split("\n");
        buffer = parts.pop();

        for (const part of parts) {
          const json = JSON.parse(part);
          if (json.type === "StdOut" || json.type === "CompilerMessageE") {
            const outputData = json.data;
            setOutput(outputData);
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsExecuting(false);
    }
  };

  const execute = async (code) => {
    try {
      setIsExecuting(true);
      setOutput("Executing...");
      const reqBody = {
        language: langId,
        version:
          langId === "python"
            ? "3.10.0"
            : langId === "javascript"
            ? "18.15.0"
            : "15.0.2",
        files: [
          {
            name: "main",
            content: code,
          },
        ],
      };
      const res = await fetch("https://emkc.org/api/v2/piston/execute", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      });
      const data = await res.json();
      console.log(data);
      if (data?.run?.stdout) {
        setOutput(data.run.stdout);
      } else if (data?.run?.stderr) {
        // Extracting only the NameError from stderr
        const errorLines = data?.run?.stderr?.split("\n");
        const nameError = errorLines.find((line) => line.includes("NameError"));
        setOutput(nameError || data.run.stderr);
      } else {
        setOutput("Execution error");
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setIsExecuting(false);
    }
  };

  const handleRun = async () => {
    const codeSnippet = code[current];
    if (langId === "54") {
      await executeCPP(codeSnippet);
    } else {
      await execute(codeSnippet);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      const { selectionStart, selectionEnd } = e.target;
      const newCode =
        code[current].substring(0, selectionStart) +
        "    " +
        code[current].substring(selectionEnd);
      setCode((prevCode) => {
        const newCodeArray = [...prevCode];
        newCodeArray[current] = newCode;
        return newCodeArray;
      });

      e.target.setSelectionRange(selectionStart + 4, selectionStart + 4);
    }
  };

  const setCodeInput = useCallback(
    (code) => {
      setCode((prevCode) => {
        const newCodeArray = [...prevCode];
        newCodeArray[current] = code;
        return newCodeArray;
      });
    },
    [current, setCode]
  );

  const handleCopy = (e) => {
    const selectedText = window.getSelection().toString();
    e.clipboardData.setData("text/plain", `INTERNAL_COPY:${selectedText}`);
    e.preventDefault();
  };

  useEffect(() => {
    const editor = editorRef.current?.editor;

    if (editor) {
      editor.on("keydown", handleKeyDown);

      editor.on("copy", handleCopy);
      editor.on("paste", handlePaste);
    }

    return () => {
      if (editor) {
        editor.off("keydown", handleKeyDown);
        editor.off("copy", handleCopy);
        editor.off("paste", handlePaste);
      }
    };
  }, []);

  const handlePaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text");

    if (pastedData.startsWith("INTERNAL_COPY:")) {
      const cleanedData = pastedData.replace("INTERNAL_COPY:", "");
      document.execCommand("insertText", false, cleanedData);
    } else {
      document.execCommand("insertText", false, pastedData);
    }
  };

  const getAceMode = (langId) => {
    switch (langId) {
      case "python":
        return "python";
      case "java":
        return "java";
      case "javascript":
        return "javascript";
      case "54":
        return "c_cpp";
      default:
        return "text";
    }
  };

  return (
    <section className="absolute flex right-[0rem] top-[0rem] w-[100vw] h-[100vh] bg-[#1e1e1e] z-40 text-white">
      {/* <article className="relative w-[30%] h-full bg-[#252526] border-r-2 border-[#333] flex flex-col justify-between">
        <span className="flex flex-col p-4">
          <span className="relative mb-12">
            {current !== 0 && (
              <button
                type="button"
                className="absolute left-0 text-white bg-[#007acc] p-2 rounded"
                onClick={prevQuestion}
              >
                Back
              </button>
            )}
            {current !== 2 && (
              <button
                type="button"
                className="absolute right-0 text-white bg-[#007acc] p-2 rounded"
                onClick={nextQuestion}
              >
                Next
              </button>
            )}
          </span>
          <div className="text-[#d4d4d4]">{codingQuestions[current]}</div>
        </span>
        {renderVideo}
        <img
          src={ainonspeaking}
          alt="image"
          className="absolute bottom-0 right-0 w-40 h-40"
        />
      </article> */}
      <article className="relative w-[30%] h-full bg-[#252526] border-r-2 border-[#333] flex flex-col justify-between">
        <span className="flex flex-col p-2 h-[30%]">
          <span className="relative mb-12">
            {current !== 0 && (
              <button
                type="button"
                className="absolute left-0 text-white bg-[#007acc] px-6 py-2 rounded"
                onClick={prevQuestion}
              >
                Back
              </button>
            )}
            {current !== 2 && (
              <button
                type="button"
                className="absolute right-0 text-white bg-[#007acc] px-6 py-2 rounded"
                onClick={nextQuestion}
              >
                Next
              </button>
            )}
          </span>
          <div className="text-[#d4d4d4] overflow-auto p-2">
            {codingQuestions[current]}
          </div>
        </span>

        <div className="flex justify-start my-4">
          <img src={ainonspeaking} alt="image" className="w-32 h-32" />
        </div>

        {renderVideo}
      </article>

      <span className="w-[70%] h-full">
        {/* <article className="flex flex-col h-[10%] justify-between">
          <div className="flex justify-between items-center bg-[#007acc] p-2">
            <span>
              Timer: {countdown.minutes}:
              {countdown.seconds < 10
                ? `0${countdown.seconds}`
                : countdown.seconds}
            </span>
            
            <div className="flex gap-5">
              <span className="cursor-pointer text-black outline-0 bg-[#3c3c3c] text-white">
                {langId === "python" && "Python"}
                {langId === "java" && "Java"}
                {langId === "javascript" && "Javascript"}
                {langId === "54" && "C++"}
                {!language.includes(langId) && "Other"}
              </span>
              {language.includes(langId) && (
              <span
                className="cursor-pointer bg-slate-200 text-black rounded-md p-4"
                onClick={handleRun}
              >
                {isExecuting ? "Executing..." : "Run"}
              </span>
              )}
              {current === 2 && (
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    sendData();
                  }}
                >
                  Submit
                </span>
              )}
            </div>
          </div>
        </article> */}

        <article className="flex flex-col h-[12%] justify-between">
          <div className="flex justify-between items-center bg-[#007acc] p-2 rounded-md shadow-md">
            <span className="text-white font-semibold text-lg">
              Timer: {countdown.minutes}:
              {countdown.seconds < 10
                ? `0${countdown.seconds}`
                : countdown.seconds}
            </span>
            <span flex gap-5 items-center>
              For coding question you will be given 30 minutes.
            </span>

            <div className="flex gap-5 items-center">
              <span className="cursor-pointer text-white px-4 py-2 rounded-md">
                {langId === "python" && "Python"}
                {langId === "java" && "Java"}
                {langId === "javascript" && "Javascript"}
                {langId === "54" && "C++"}
                {!language.includes(langId) && "Other"}
              </span>

              {language.includes(langId) && (
                <span
                  className="cursor-pointer bg-slate-200 text-black rounded-md px-6 py-2 font-semibold transition hover:bg-slate-300"
                  onClick={handleRun}
                >
                  {isExecuting ? "Executing..." : "Run"}
                </span>
              )}
              {current === 2 && (
                <span
                  className="cursor-pointer bg-green-500 text-white rounded-md px-6 py-2 font-semibold transition hover:bg-green-600"
                  onClick={() => sendData()}
                >
                  Submit
                </span>
              )}
            </div>
          </div>
        </article>

        <article className="h-[63%] mb-2 flex text-white">
          <div className="w-full">
            {/* <textarea
              className="p-2 w-full h-full bg-[#1e1e1e] text-white outline-0"
              value={code[current]}
              onChange={(e) => {
                setCodeInput(e.target.value);
              }}
              onKeyDown={handleKeyDown}
              onCopy={handleCopy}
              onPaste={(e) => {
                e.preventDefault();

                const clipboardData = e.clipboardData || window.clipboardData;
                const pastedData = clipboardData.getData("text");
                if (pastedData.startsWith("INTERNAL_COPY:")) {
                  const cleanedData = pastedData.replace("INTERNAL_COPY:", "");
                  document.execCommand("insertText", false, cleanedData);
                }
              }}
            ></textarea> */}
            <AceEditor
              mode={getAceMode(langId)}
              theme="dracula"
              value={code[current]}
              onChange={(newCode) => setCodeInput(newCode)}
              name="codeEditor"
              width="100%"
              height="500px"
              fontSize={16}
              setOptions={{
                showLineNumbers: true,
                tabSize: 2,
              }}
              editorProps={{ $blockScrolling: true }}
              ref={editorRef}
            />
          </div>
        </article>

        {language.includes(langId) && (
          <article className="pl-5 pb-5 pt-3 h-[23.8%] bg-[#252526] overflow-x-auto">
            <p className="pb-3 font-bold">OUTPUT</p>
            <p className="whitespace-pre-line text-[#d4d4d4]">{output}</p>
          </article>
        )}
      </span>
    </section>
  );
};

export default NewCodeEditor;
