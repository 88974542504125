import {
    clevercruitlogo,
    expired,
    linkexpiredbg,
    notFound,
} from '../../assets/imgs';


 const ErrorPage = () => {
    const currentDate = new Date().toISOString().split("T")[0].replace(/-/g, "/");

    const [year, month, day] = currentDate.split("/");
    const formateDate = `${day}/${month}/${year}`;

    return (
      <main
        className=" flex flex-col text-[#1B3351] items-center justify-around absolute w-screen"
        style={{
          backgroundImage: `url(${notFound})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100vh',
        }}
      >
        <section className="flex flex-col items-center">
           <div className="absolute flex items-start"
            style={{ top: '8%', left: '4%' }}
            >

            <img
              src={clevercruitlogo}
              alt="clevercruit logo"
              className="w-[11vw]"
            />
            <div className="text-[1.5vw] font-satoshi font-[300] ml-2">
              <span className="flex">
                AI video interview -{' '}
                <div className="font-[500]"> {formateDate}</div>
              </span>
            </div>
          </div>
          <p className=" text-[4.306vw] flex justify-center items-center flex-col font-semibold font-satoshi absolute bottom-[28%]">
            <b>404-Error</b>
            <p className=" text-[2.306vw] ">PAGE NOT FOUND</p>
          </p>
          <div className="absolute bottom-[19%] text-[1.944vw] font-[500]">
            Your search has ventured beyond the known universe.
          </div>
        </section>
      </main>
    );
};

export default ErrorPage;