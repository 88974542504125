import React, { useState, useEffect } from "react";
import {
  AnalyticsPageBtn,
  CustomButtonFilter1,
} from "../../components/buttons";
import {
  deleteIcon,
  editIcon,
  userImg,
  searchIcon,
  arrowleft,
  arrowright,
  nodata,
} from "../../assets/imgs/index";
import {
  ModalAddJobRole,
  ModalViewJobRole,
  ModalViewJobRole1,
} from "../../components/AdminComponents/modal";
import { JobRoleTable } from "../../components/AdminComponents/table";
import { handleApiResponse } from "../../API/services";
import { ModalDeleteJobRole } from "../../components/AdminComponents/modal";
import { useNavigate } from "react-router-dom";
import PaginationComponent from "../../components/AdminComponents/pagination";

function JobRole() {
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpenResume, setIsModalOpenResume] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [isModalOpen5, setIsModalOpen5] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openEditConfirmation, setOpenEditConfirmation] = useState(false);
  const [search, setSearch] = useState("");
  const [uuid, setUuid] = useState("");
  const [openWhat, setOpenWhat] = useState(false);
  const navigate = useNavigate();
  const itemsPerPage = 7;
  const userType = sessionStorage.getItem("userType");

  const getUser = async (userLimit, pageNo) => {
    const reqData = {
      limit: userLimit,
      page: pageNo,
      is_deleted: 0,
    };
    const response = await handleApiResponse(
      "/api/v1/interview/jobrole_list/",
      reqData,
      1
    );
    if (response?.code === "token_not_valid") {
      navigate("/");
      sessionStorage.clear();
      return;
    }
    if (response.data.status_code === 200) {
      setTableData(Object.values(response.data.data).flat());
    }
  };

  useEffect(() => {
    getUser(itemsPerPage, pageNo);
  }, [pageNo, isModalOpen3, openDeleteConfirmation, openEditConfirmation]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Filtered data based on search input
  const filteredData = tableData.filter((rowData) => {
    const row14Value = `${rowData.experience_from} - ${rowData.experience_to} years`;
    return (
      rowData.name.toLowerCase().includes(search.toLowerCase()) ||
      rowData.description.toLowerCase().includes(search.toLowerCase()) ||
      row14Value.toLowerCase().includes(search.toLowerCase()) ||
      (rowData.created_date &&
        new Date(rowData.created_date)
          .toISOString()
          .split("T")[0]
          .includes(search.toLowerCase())) ||
      rowData.creater_name.toLowerCase().includes(search.toLowerCase())
    );
  });

  const currentPageData = filteredData.slice(startIndex, endIndex);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const openModalAddJobRole = () => {
    setIsModalOpen3(true);
  };

  const closeModal5 = () => {
    setOpenDeleteConfirmation(false);
  };

  const openModalResume = () => {
    setIsModalOpenResume(true);
  };

  const openModalEditJobRole = () => {
    setIsModalOpen4(true);
  };

  const closeModalResume = () => {
    setIsModalOpenResume(false);
  };

  const closeModalAddJobRole = () => {
    setOpenEditConfirmation(false);
    setIsModalOpen3(false);
  };

  const closeModalEditJobRole = () => {
    setIsModalOpen4(false);
  };

  const openModalViewJobRole = () => {
    setIsModalOpen2(true);
  };

  const closeModalViewJobRole = () => {
    setIsModalOpen2(false);
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      handlePageChange(newPage);
      getUser(itemsPerPage, newPage); // Pass the new page number to getUser
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      handlePageChange(newPage);
      getUser(itemsPerPage, newPage); // Pass the new page number to getUser
    }
  };

  const checkUid = () => {
    if (uuid.length) {
      if (openWhat.length) {
        setOpenDeleteConfirmation(true);
      } else {
        setOpenEditConfirmation(true);
      }
      console.log("open");
    }
  };

  const handleSearchInputChange = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1); // Reset to first page on search input change
  };

  useEffect(() => {
    checkUid();
    console.log("this is useEffect", uuid);
  }, [uuid]);

  return (
    <section
      className="min-w-[643px]"
      style={{
        height: "calc(96vh - 76px)",
      }}
    >
      <div
        className={`font-satoshi rounded-[30px] bg-[#FFFFFF] flex flex-col h-full custom-sm:min-h-fit custom-sm-md:min-h-[10px] ${
          tableData.length === 0 ? "" : ""
        }`}
      >
        {openDeleteConfirmation && (
          <ModalDeleteJobRole
            uid={uuid}
            onClose={closeModal5}
            setUuid={setUuid}
          />
        )}
        {openEditConfirmation && (
          <ModalAddJobRole
            head={"Edit"}
            openEditConfirmation={openEditConfirmation}
            isOpen={isModalOpen3}
            onClose={closeModalAddJobRole}
          />
        )}
        {tableData.length > 0 ? (
          <>
            <div className="flex justify-between p-[1rem] custom-sm:h-[20%] custom-md2:h-[15%] max-h-[5rem]">
              <div>
                <div className="text-[24px] text-[#222850] font-[700]">
                  <i>Job Role</i>
                </div>
              </div>
              <div className="flex justify-center items-center gap-2">
                <div className="flex gap-2 justify-center items-center">
                  <div className="relative">
                    <input
                      placeholder="Search"
                      onChange={handleSearchInputChange}
                      value={search}
                      className="font-poppins w-[261px] h-[36px] text-[12px] p-5 pl-8 outline-0  bg-[#E9F3FF] rounded-lg placeholder-text-sm"
                      // onKeyDown={handleKeyDown}
                    />
                    <img
                      src={searchIcon}
                      alt="search icon"
                      className="absolute cursor-pointer top-0 left-0 w-[16px] h-[40.2px] ml-2"
                    />
                  </div>
                  {/* <CustomButtonFilter1 text={'Filter'} /> */}
                </div>
                {/* {userType !== '2' && (
              <AnalyticsPageBtn
                name={'Add Job Role'}
                img={5}
                onClick={openModalAddJobRole}
              />
            )} */}
              </div>
              {isModalOpen3 && (
                <ModalAddJobRole
                  head={"Add"}
                  isOpen={isModalOpen3}
                  onClose={closeModalAddJobRole}
                />
              )}
              <ModalViewJobRole
                isOpen={isModalOpen2}
                onClose={closeModalViewJobRole}
              />
            </div>
            <div className="h-[90%] overflow-scroll">
              <div className="relative  h-full flex flex-col justify-between ">
                <table class="font-inter w-full text-sm text-[#A6AEBA] text-left rtl:text-right">
                  <thead class="font-[400] text-[14px] h-[54px] text-[#222850] bg-[#F7FAFC] border-b border-[#F4F5F6]">
                    <tr className="text-[#222850] text-[15px] font-normal">
                      <td scope="col" class="px-6 text-left">
                        S.No.
                      </td>
                      <td scope="col" class="px-3 text-left">
                        Job Role
                      </td>
                      <td scope="col" class="px-3 text-left">
                        Description
                      </td>
                      <td scope="col" class="px-3 text-left">
                        Experience
                      </td>
                      {/* {userType !== "2" && (  <th scope="col" class="px-6 py-3">
                  Edited By
                </th>
                )} */}
                      <td scope="col" class="px-3 text-left">
                        Created On
                      </td>
                      <td scope="col" class="px-3 text-left">
                        Created By
                      </td>
                      {userType === "3" ? (
                        <td scope="col" class="px-3 text-left">
                          Action
                        </td>
                      ) : (
                        <td scope="col" class="px-3 text-left">
                          Action
                        </td>
                      )}
                    </tr>
                  </thead>
                  {currentPageData.map((rowData, index) => (
                    <JobRoleTable
                      setOpenEditConfirmation={setOpenEditConfirmation}
                      isModalOpen5={isModalOpen5}
                      setIsModalOpen5={setIsModalOpen5}
                      numberint={index}
                      icon={userImg}
                      number={startIndex + index + 1}
                      row12={rowData.name}
                      row13={rowData.description}
                      row14={`${rowData.experience_from} - ${rowData.experience_to} years`}
                      row15={
                        rowData.created_date
                          ? new Date(rowData.created_date)
                              .toISOString()
                              .split("T")[0]
                          : "N/A"
                      }
                      row16={rowData.creater_name}
                      row17={rowData.jd}
                      editIcon={editIcon}
                      deleteIcon={deleteIcon}
                      openModal={(uid) => {
                        openModalEditJobRole();
                        console.log("Open modal for uid:", uid);
                      }}
                      closeModal={closeModalEditJobRole}
                      openModal1={openModalViewJobRole}
                      openModalResume={openModalResume}
                      closeModalResume={closeModalResume}
                      closeModal1={closeModalViewJobRole}
                      isModalOpenResume={isModalOpenResume}
                      isModalOpen4={isModalOpen4}
                      isModalOpen2={isModalOpen2}
                      uid={rowData.uid}
                      setuuid={setUuid}
                      setOpenWhat={setOpenWhat}
                      setOpenDeleteConfirmation={setOpenDeleteConfirmation}
                      rowData={rowData}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#FFFFFF" : "#F9FBFD",
                      }}
                    />
                  ))}
                </table>
                {/* <div className="pagination flex justify-end mt-4">
            {[...Array(totalPages).keys()].map((page) => (
              <button
                key={page + 1}
                onClick={() => handlePageChange(page + 1)}
                className={`px-3 py-2 mx-1 border rounded-md ${
                  currentPage === page + 1
                    ? 'bg-[#A6AEBA1A] text-[#A6AEBA]'
                    : 'bg-white text-[#A6AEBA]'
                }`}
              >
                {page + 1}
              </button>
            ))}
          </div> */}
                <div className=" font-inter p-[1rem] pagination flex justify-between mt-4 h-full items-end">
                  <div
                    className="flex text-[#718096] text-[14px] border-[1px] border-[#D0D5DD] h-fit rounded-[3rem] pl-[0.8rem] pr-[1rem] py-2 w-[110px] justify-center gap-2 cursor-pointer"
                    onClick={goToPreviousPage}
                    style={{
                      boxShadow: "0px 1px 2px 0px #1018280D",
                    }}
                  >
                    <img
                      src={arrowleft}
                      alt="arrow left"
                      className="w-[20px] h-[20px]"
                    />
                    Previous
                  </div>
                  <PaginationComponent
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    visiblePages={3}  // Or any other number you prefer
                  />

                  <div
                    className="flex text-[#718096] text-[14px] border-[1px] border-[#D0D5DD] h-fit rounded-[3rem] pl-[1.8rem] pr-[0.8rem] py-2 w-[100px] justify-center gap-2 cursor-pointer"
                    onClick={goToNextPage}
                    style={{
                      boxShadow: "0px 1px 2px 0px #1018280D",
                    }}
                  >
                    Next
                    <img
                      src={arrowright}
                      className="w-[20px] h-[20px]"
                      alt="arrow right"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="p-4 h-full font-[500] text-center justify-center items-center flex text-[36px] trailing-[57px] text-[#A0AEC0] flex-col gap-4">
            <img src={nodata} alt="no data" className="w-[135px] h-[135px]" />
            No job role added yet
          </div>
        )}
      </div>
    </section>
  );
}

export default JobRole;
