import { Routes, Route } from 'react-router-dom';
import { AdminLayout } from '../layouts/AdminLayout';
import { CampaignLayout } from '../layouts/CampaignLayout';
import { EditCampaignLayout } from '../layouts/EditCampaignLayout';
import { Dashboard } from '../features/AdminPages/dashboard';
import Members from '../features/AdminPages/members';
import JobRole from '../features/AdminPages/jobRole';
import ProfilePage from '../features/AdminPages/profilePage';
import Campaign from '../features/AdminPages/campaign';
import { AddCampaign } from '../features/AdminPages/addCampaign';
import ViewCampaigns from '../features/AdminPages/viewCampaigns';
import { HrSignup } from '../features/AdminPages/signup';
import { Evaluation } from '../features/AdminPages/Evaluation';
import { Evaluation1 } from '../features/AdminPages/Evaluation1';
import { Signup } from '../features/signup/Signup';
import { useEffect } from 'react';
import { clearLocal } from '../utils/localStorage';
import { useLocation } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import { SetPassword } from '../features/AdminPages/SetPassword';
import ErrorPage from '../features/AdminPages/404Page';
import VideoPage from '../features/AdminPages/VideoPage';
import JobRolePage from '../features/AdminPages/JobRolePage';
import { NewAnalytics } from '../components/AdminComponents/NewAnalytics';
import ScreenRecorder from '../utils/ScreenRecorder';

export const AdminRoute = () => {
  const location = useLocation();
  useEffect(() => {
    if (['/login', '/admin/signup', '/'].includes(location.pathname)) {
      //clearLocal();
    }
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Signup />} />
      <Route path="admin/signup" element={<HrSignup />} />
      <Route
        path="profile"
        element={<ProtectedRoute Component={ProfilePage} />}
      />
      <Route path="not-found" element={<ErrorPage />} />

      <Route path="admin/videopage" element={<VideoPage />} />

      <Route path="/admin" element={<AdminLayout />}>
        <Route
          path="dashboard"
          element={<ProtectedRoute Component={Dashboard} />}
        />
        <Route
          path="jobRolePage"
          element={<ProtectedRoute Component={JobRolePage} />}
        />
        <Route
          path="members"
          element={<ProtectedRoute Component={Members} />}
        />
        <Route
          path="jobrole"
          element={<ProtectedRoute Component={JobRole} />}
        />
        <Route
          path="campaign"
          element={<ProtectedRoute Component={Campaign} />}
        />
        <Route
          path="view-campaigns"
          element={<ProtectedRoute Component={ViewCampaigns} />}
        />
        {/* <Route
          path="analytics"
          element={<ProtectedRoute Component={Evaluation} />}
        /> */}
        <Route
          path="analytics1"
          element={<ProtectedRoute Component={Evaluation1} />}
        />
      </Route>
      <Route
        path="/admin/analytics"
        element={<ProtectedRoute Component={NewAnalytics} />}
      />

      <Route path="/campaign" element={<CampaignLayout />}>
        <Route
          path="addCampaign"
          element={<ProtectedRoute Component={AddCampaign} />}
        />
      </Route>

      <Route path="/editcampaign" element={<EditCampaignLayout />}>
        <Route
          path="addCampaign"
          element={<ProtectedRoute Component={AddCampaign} />}
        />
      </Route>

      <Route path="/:org/invitation/:token" element={<SetPassword />} />
      <Route path="/interview/*" />

      {/* Catch-all route for undefined paths */}
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};
