import React, { useState, useEffect } from 'react';
import { mic } from '../assets/imgs';

export const AudioLevels = ({ audioStream }) => {
  const [audioLevels, setAudioLevels] = useState([]);
  const [multiplier, setMultiplier] = useState([])

  useEffect(() => {
    if (!audioStream) return;

    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioCtx.createAnalyser();
    const source = audioCtx.createMediaStreamSource(audioStream);

    analyser.fftSize = 512; // Increase for better frequency resolution
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    source.connect(analyser);

    const calculateLevels = (data) => {
      const subBassRange = [100, 200];
      const bassRange = [200, 250];
      const upperTrebleRange = [250, 300]
      const lowerMidrangeRange = [300, 350];
      const upperMidrangeRange = [350, 400];
      const trebleRange = [400, 500];

      const getBandLevel = (minFreq, maxFreq) => {
        const minIndex = Math.floor((minFreq / (audioCtx.sampleRate / 2)) * bufferLength);
        const maxIndex = Math.floor((maxFreq / (audioCtx.sampleRate / 2)) * bufferLength);
        let sum = 0;

        for (let i = minIndex; i < maxIndex && i < data.length; i++) {
          sum += data[i];
        }

        return maxIndex > minIndex ? sum / (maxIndex - minIndex) : 0;
      };

      return [
        getBandLevel(bassRange[0], bassRange[1]),
        getBandLevel(upperTrebleRange[0], upperTrebleRange[1]),
        getBandLevel(upperMidrangeRange[0], upperMidrangeRange[1]),
        getBandLevel(trebleRange[0], trebleRange[1]),
        getBandLevel(lowerMidrangeRange[0], lowerMidrangeRange[1]),
        getBandLevel(subBassRange[0], subBassRange[1]),
      ];
    };

    const getAudioLevels = () => {
      analyser.getByteFrequencyData(dataArray);
      const levels = calculateLevels(dataArray);
      setMultiplier(levels);
    };

    const intervalId = setInterval(getAudioLevels, 200); // Adjust the interval as needed

    return () => {
      clearInterval(intervalId);
      audioCtx.close();
    };
  }, [audioStream]);


  return (
    <div className='relative'>
      <img src={mic} className='' />
      <div className='rotate-180 flex absolute bottom-[48px] left-[16px] w-[2.4rem] h-[4.6rem] rounded-lg overflow-hidden' style={{ borderRadius: '1.75rem', flexDirection: 'row' }}>
        {
          // Map through the array to create columns
          multiplier?.map((circles, columnIndex) => (
            <div key={columnIndex} style={{
              flexDirection: 'column',
              alignItems: 'end',
              justifyContent: 'end',
            }}>
              {
                // Generate circles based on the value from the array
                Array.from({ length: Math.round(circles) > 150 ? (Math.round(circles) % 15 + 5) : 2 }).map((_, rowIndex) => (
                  <div key={rowIndex} style={{
                    width: '0.4rem',       // Circle width
                    height: '0.4rem',      // Circle height
                    backgroundColor: '#4AF867',
                    borderRadius: '2.75rem',   // Makes the div a circle          
                    boxSizing: 'border-box',
                  }}></div>
                ))

                // Array.from({ length: Math.round(circles) > 150 ? (Math.round(circles) % 15 + 5) : 2 }).map((_, rowIndex) => (
                //   <div key={rowIndex} style={{
                //     width: '0.4rem',       // Circle width
                //     height: '0.4rem',      // Circle height
                //     backgroundColor:  rowIndex >= Math.max(0, (Math.round(circles) % 15 + 5) - 5)
                //     ? (rowIndex % 2 === 0 ? '#072872' : '#fff')
                //     : (rowIndex % 2 === 0 ? '#17952C' : '#4AF867'),
                //     borderRadius: '2.75rem',   // Makes the div a circle          
                //     boxSizing: 'border-box',
                //   }}></div>
                // ))
              }
            </div>
          ))
        }
      </div>
    </div>
  );
};
