import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import {
  AudioVisualizer,
  MediaDevicesCapturePhoto,
} from '../features/mediaDevices';
import { useEffect, useState } from 'react';
import { type1 } from '../assets/imgs';
import { arc } from 'd3';
import { useNavigate } from 'react-router-dom';

export const ScoreCard = (props) => {
  // Convert the score to a number first
  const score = parseFloat(props.score);

  // Check if the conversion was successful
  const formattedScore = !isNaN(score) ? score.toFixed(2) : 'N/A';

  return (
    <div className="p-2 flex flex-col gap-[2rem] w-[23.5rem] items-end bg-fill-color-code1 border-[0.05rem] rounded-lg px-8 py-4">
      <p className="text-lg">Overall Score</p>
      <p className="text-5xl font-semibold flex items-end">
        {formattedScore} <span className="text-4xl">/100</span>
      </p>
    </div>
  );
};

export const DurationCard = (props) => {
  return (
    <div className="p-2 flex flex-col gap-[2rem] w-[23.5rem] items-end bg-fill-color-code1 border-[0.05rem] rounded-lg px-8 py-4">
      <p className="text-lg">Time Duration</p>
      <p className="text-5xl font-semibold flex items-end">
        {props.min} <p className="text-4xl mr-[0.5rem]">min</p>
        {props.sec} <p className="text-4xl">sec</p>
      </p>
    </div>
  );
};

export const PersonalCard = (props) => {
  //const percentage = props.per;
  const percentage = props.marks;
  const yellowArc = arc()
    .innerRadius(1)
    .outerRadius(0.9)
    .startAngle(-Math.PI / 1.4)
    .endAngle(-Math.PI / 2.5)
    .padAngle(0.05)
    .cornerRadius(2)();

  const orangeArc = arc()
    .innerRadius(1)
    .outerRadius(0.9)
    .startAngle(-Math.PI / 2.5)
    .endAngle(-Math.PI / 17)
    .padAngle(0)
    .cornerRadius(2)();

  const pinkArc = arc()
    .innerRadius(1)
    .outerRadius(0.9)
    .startAngle(-Math.PI / 22)
    .endAngle(Math.PI / 3.3)
    .padAngle(0)
    .cornerRadius(2)();

  const blueArc = arc()
    .innerRadius(1)
    .outerRadius(0.9)
    .startAngle(Math.PI / 3.2)
    .endAngle(Math.PI / 1.6)
    .padAngle(0)
    .cornerRadius(2)();

  return (
    <section className="h-[18rem] rounded-2xl overflow-hidden">
      <div className="relative flex flex-col gap-4 text-white bg-light-grey items-center py-4 w-[19rem] h-[22rem] p-4">
        <svg viewBox={[-1, -1, 2, 2].join(' ')} className="rotate-[7deg]">
          <path d={yellowArc} fill="#FFD200" />
          <path d={orangeArc} fill="#F68D2B" />
          <path d={pinkArc} fill="#F4A79D" />
          <path d={blueArc} fill="#344BFD" />
        </svg>
        <b className="absolute text-3xl top-[15%] left-[23%]  w-[10rem] h-[10rem] flex justify-center items-center rounded-full bg-[#1B3351]">
          {Number(percentage).toFixed(2)}
          {/* % */}
        </b>
        <b className="text-black text-lg mt-[-4rem]">
          <i>{props.name}</i>
        </b>
      </div>
    </section>
  );
};

export const PersonalCardStable = (props) => {
  return (
    <div className="flex flex-col gap-4 bg-white items-center py-4 ">
      <div className="h-[7.9rem] w-[7.9rem] bg-fill-color-code1 rounded-full relative">
        <span className="absolute top-[3rem] left-[3rem] text-xl">
          {props.per}
        </span>
      </div>
      <p>{props.name}</p>
    </div>
  );
};

export const PersonalCard1 = (props) => {
  // const percentage = props.per;

  // const percentage = 40;
  return (
    <div className="relative flex flex-col gap-4 text-white  items-center p-4 ">
      <div
        className="relative w-[13rem] h-[13rem] rounded-full"
        style={{
          backgroundImage:
            'conic-gradient(#F68D2B 0deg, #F68D2B 120deg, #344BFD 120deg, #344BFD 210deg, #FFD200 210deg )',
        }}
      >
        <div className="absolute w-[10rem] h-[10rem] top-[11%] left-[11%] bg-white rounded-full flex justify-center items-center">
          <b className=" text-black text-3xl">
            {((props.percentage / 5) * 100).toFixed(2)}%
          </b>
        </div>
      </div>

      <b className="text-black text-lg">
        <i>{props.name}</i>
      </b>
    </div>
  );
};

export const Card1 = ({}) => {
  // const percentage = props.per;
  const percentage = 40;
  const r = 80; // Radius of the circle
  const circumference = 2 * Math.PI * r;
  const dashArray = `${circumference / 30} ${circumference / 30}`; // This creates dashed lines
  const code = 0.5 * (100 / 80);
  // Calculate the total length of the dashed strokes based on the percentage
  const strokeOffset = ((100 - percentage) / 100) * 121;
  return (
    <div className="relative flex flex-col gap-4 text-white bg-white items-center ">
      <svg className="w-[25rem] h-[15rem] rotate-[-1deg]">
        <circle
          r="170"
          cy="240"
          cx="194"
          stroke="#3A5AFE"
          strokeWidth="53"
          strokeDasharray="2.5, 14.96"
          fill="transparent"
        />
      </svg>
      <svg className="w-[25rem] h-[15rem] absolute rotate-[-1.5deg] ">
        <circle
          r="120.4"
          cy="237"
          cx="194"
          stroke="#3A5AFE"
          strokeWidth="3"
          strokeLinecap="round"
          strokeDasharray="0.28, 13"
          fill="transparent"
        />
      </svg>
    </div>
  );
};

//  <div
//    className="w-[25rem] h-[25rem] rounded-full"
//    style={{
//      background: 'linear-gradient(#3A5AFE, #E9EDF0)',
//      WebkitMask:
//        'repeating-conic-gradient(#000 0deg 0deg, transparent 0.85deg 5deg)',
//      WebkitMaskSize: 'cover',
//    }}
//  ></div>;

export const Card3 = ({
  r = 90,
  cx = 178,
  cy = 154,
  strokeWidth = 20,
  total,
  prog = 40 + 10 + 20,
  prog2 = 10 + 20,
  prog3 = 20,
  color = '#D57D00',
  color2 = '#975CED',
  color3 = '#9C9898',
}) => {
  let progress;
  if (prog === 'NA') {
    progress = 0;
  } else {
    progress = parseInt(prog);
  }

  let progress2;
  if (prog2 === 'NA') {
    progress2 = 0;
  } else {
    progress2 = parseInt(prog2);
  }

  let progress3;
  if (prog3 === 'NA') {
    progress3 = 0;
  } else {
    progress3 = parseInt(prog3);
  }
  const circumference = 2 * 3.14 * r;
  const offset = circumference * ((100 - progress) / 100);
  const offset2 = circumference * ((100 - progress2) / 100);
  const offset3 = circumference * ((100 - progress3) / 100);

  return (
    <div className="w-[15rem] h-[15rem] relative flex flex-col gap-4 text-white  items-center py-4">
      <div className="flex flex-col absolute z-index text-center top-[45%] right-[44%]">
        <div className="text-gray-700 font-bold text-3xl">{total}</div>
      </div>
      <div className="rotate-[-90deg]">
        <svg className="w-[18rem] h-[18rem]">
          <circle
            r={r}
            cy={cy}
            cx={cx}
            strokeWidth={strokeWidth}
            style={{
              strokeLinecap: 'round',
              strokeDasharray: `${circumference * (progress / 100)} 30 ${
                circumference - 60 - circumference * (progress / 100)
              } 30`,
            }}
            className="fill-transparent stroke-gray-200"
          />
          <circle
            r={r}
            cy={cy}
            cx={cx}
            strokeWidth={strokeWidth + 4}
            stroke={color}
            color
            style={{ strokeLinecap: 'round' }}
            className={`fill-transparent`}
            stroke-dasharray={circumference + 'px'}
            stroke-dashoffset={offset + 'px'}
          />

          <circle
            r={r}
            cy={cy}
            cx={cx}
            strokeWidth={strokeWidth + 6}
            stroke={color2}
            color2
            style={{ strokeLinecap: 'round' }}
            className={`fill-transparent`}
            stroke-dasharray={circumference + 'px'}
            stroke-dashoffset={offset2 + 'px'}
          />
          <circle
            r={r}
            cy={cy}
            cx={cx}
            strokeWidth={strokeWidth + 8}
            stroke={color3}
            color2
            style={{ strokeLinecap: 'round' }}
            className={`fill-transparent`}
            stroke-dasharray={circumference + 'px'}
            stroke-dashoffset={offset3 + 'px'}
          />
        </svg>
      </div>
    </div>
  );
};

export const Card2 = ({
  r = 110,
  cx = 168,
  cy = 154,
  strokeWidth = 20,
  progres,
  color,
}) => {
  let progress;
  if (progres === 'NA') {
    progress = 0;
  } else {
    progress = parseInt(progres);
  }
  const circumference = 2 * 3.14 * r;
  const offset = circumference * ((100 - progress) / 100);
  console.log('pro', progress);
  const strokeColor = color
    ? `${color}33` // Assuming the color is in hex format (e.g., #ff0000), adding `33` sets 20% opacity.
    : 'rgba(128, 128, 128, 0.2)';

  return (
    <div className="w-[12rem] h-[12rem] relative flex flex-col gap-4 text-white bg-light-grey items-center py-4">
      <div className="flex flex-col absolute z-index text-center top-[60%] right-[25%]">
        <div className="text-gray-700  font-bold text-3xl">{progress}%</div>
      </div>
      <div className="rotate-[-90deg]">
        <svg className="w-[18rem] h-[18rem]">
          <circle
            r={r}
            cy={cy}
            cx={cx}
            strokeWidth={strokeWidth}
            color="#1AAEFE33"
            style={{
              strokeLinecap: 'round',
              strokeDasharray: `{${
                progress === 0
                  ? ''
                  : `${circumference * (progress / 100)} 20 ${
                      circumference - 40 - circumference * (progress / 100)
                    } 20}`
              }`,
              fill: 'transparent',
              stroke: strokeColor,
            }}
            // className="fill-transparent stroke-gray-200"
          />
          {progress > 0 && (
            <circle
              r={r}
              cy={cy}
              cx={cx}
              strokeWidth={strokeWidth}
              stroke={color}
              color
              style={{ strokeLinecap: 'round' }}
              className={`fill-transparent`}
              stroke-dasharray={circumference + 'px'}
              stroke-dashoffset={offset + 'px'}
            />
          )}
        </svg>
      </div>
    </div>
  );
};

export const Card4 = ({
  r = 110,
  cx = 168,
  cy = 154,
  strokeWidth = 20,
  progres,
  color,
}) => {
  let progress;
  if (progres === 'NA') {
    progress = 0;
  } else {
    progress = parseInt(progres);
  }
  const circumference = 2 * 3.14 * r;
  const offset = circumference * ((100 - progress) / 100);
  const strokeColor = color
    ? `${color}33` // Assuming the color is in hex format (e.g., #ff0000), adding `33` sets 20% opacity.
    : 'rgba(128, 128, 128, 0.2)';

  return (
    <div className="w-[12rem] h-[12rem] relative flex flex-col gap-4 text-white items-center py-4">
      <div className="flex flex-col absolute z-index text-center top-[60%] right-[25%]">
        <div className="text-gray-700 font-bold text-3xl">{progress}%</div>
      </div>
      <div className="rotate-[-90deg]">
        <svg className="w-[18rem] h-[18rem]">
          <circle
            r={r}
            cy={cy}
            cx={cx}
            strokeWidth={strokeWidth}
            style={{
              strokeLinecap: 'round',
              strokeDasharray: `${
                progres === 0
                  ? ''
                  : `${circumference * (progress / 100)} 20 ${
                      circumference - 40 - circumference * (progress / 100)
                    } 20`
              }`,
              fill: 'transparent',
              stroke: strokeColor,
            }}
            // className="fill-transparent stroke-gray-200"
          />
          {progres > 0 && (
            <circle
              r={r}
              cy={cy}
              cx={cx}
              strokeWidth={strokeWidth}
              stroke={color}
              color
              style={{ strokeLinecap: 'round' }}
              className={`fill-transparent`}
              stroke-dasharray={circumference + 'px'}
              stroke-dashoffset={offset + 'px'}
            />
          )}
        </svg>
      </div>
    </div>
  );
};

export const StartPageCard1 = (props) => {
  const [clicked, setClicked] = useState(0);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const navigate = useNavigate();
  const imageBlob = ({ imgSrc }) => {
    const base64Image = imgSrc?.split(',')[1];
    const mimeType = 'image/jpeg';
    return props.base64ToBlob(base64Image, mimeType);
  };

  return (
    <div className="p-2 w-[330px] shadow-2xl max-w-[437px] font-satoshi custom-sm:h-[27rem] custom-md:h-[92%]  justify-center items-center bg-white rounded-2xl p-4">
      <div className="flex flex-col p-4 h-full  items-center justify-between border-[1px] text-white border-dark-blue rounded-2xl  py-4">
        <span className="hidden">
          <MediaDevicesCapturePhoto
            cap={clicked}
            setImgSrc={props.setImage}
            setIsCameraOpen={setIsCameraOpen}
            propImg={props.img}
            setCandidateAudio={props.setCandidateAudio}
          />
        </span>
        <section className="flex justify-center overflow-hidden w-[100%] rounded-[10.7px]">
          <div className="">
            {isCameraOpen ? (
              <MediaDevicesCapturePhoto
                cap={clicked}
                setImgSrc={props.setImage}
                setIsCameraOpen={setIsCameraOpen}
                setCandidateAudio={props.setCandidateAudio}
              />
            ) : (
              <img className="w-[11rem]" src={props.img} alt="img" />
            )}
          </div>
        </section>

        <p className="font-satoshi font-medium text-[#00112D] text-[19.5px]">
          {props.text2}
        </p>

        <section className="flex flex-col justify-around fill-available gap-2">
          <button
            className="px-2 text-center py-2 mt-1 h-[52px] w-[264.4px] text-[19.5px] bg-[#00112D] shadow-xl rounded-2xl"
            onClick={() => {
              if (isCameraOpen) {
                setClicked(clicked + 1);
                props.setContent(1);
                props.handleButtonClick()
              }
            }}
          >
            Capture
          </button>
        </section>
        {/* <button
            className="px-2 py-2 text-[19.5px] my-1 text-black border-[1px] border-dark-blue fill-available rounded-2xl"
            onClick={() => {
              sessionStorage.setItem('fillForm', 2);
              navigate(-1);
            }}
          >
            Back
          </button> */}
      </div>
    </div>
  );
};

export const StartPageCard2 = (props) => {
  return (
    <div className="p-2 w-[23.5rem] font-satoshi custom-sm:h-[27rem] custom-lg:h-[30rem]  justify-center items-center bg-white rounded-[12.9px] p-4">
      <div className="flex flex-col  p-4 h-full  items-center justify-between border-[1px] text-white border-dark-blue rounded-[12.9px]  py-4">
        {props.imgSrc ? (
          <img
            className="w-[18rem] rounded-[12px]"
            src={props.imgSrc}
            alt="img"
          />
        ) : (
          <img className="w-[8rem] rounded-[12px]" src={props.img} alt="img" />
        )}

        <p className="font-satoshi font-medium text-[#00112D] text-[19.5px]"></p>

        <section className="flex flex-col justify-around fill-available gap-2">
          <button
            className="px-2 py-2 text-[19.5px]  bg-dark-blue fill-available rounded-[12.9px] "
            onClick={() => {
              props.setContent(0);
            }}
          >
            Retake
          </button>
          <button
            className="px-2 py-2 text-[19.5px] text-black border-[1px] border-dark-blue fill-available rounded-[12.9px]"
            onClick={() => props.setIsFinished(true)}
          >
            Next
          </button>
        </section>
      </div>
    </div>
  );
};

export const FinalPageCard1 = (props) => {
  return (
    <div className=" w-[20.7rem] h-[92%] p-4  shadow-2xl justify-center items-center bg-white rounded-2xl ">
      <div className="flex flex-col h-full justify-between items-center gap-1 border-[1px] text-white border-dark-blue rounded-2xl p-4">
        <div className="h-[276px] rounded-[10.7px] flex justify-center w-full overflow-hidden">
          <img
            className=" w-[150%] h-full max-w-[720px]"
            src={props.img}
            alt=""
          />
        </div>
        <p className="font-satoshi font-medium text-[#00112D] text-[19.5px]">
          Your Image as captured
        </p>
        {/* <p className="text-black font-[inter] text-[16px] opacity-[50%]">
          Live representation of your voice
        </p> */}
        <section className="flex flex-col justify-around fill-available gap-2">
          <button
            className="px-2 text-center py-2 mt-1 h-[52px] w-[264.4px] text-[19.5px] bg-[#00112D] shadow-xl rounded-2xl"
            onClick={() => {
              props.setContent(0);
              props.setImage(null);
            }}
          >
            Retake
          </button>
        </section>
      </div>
    </div>
  );
};

export const FinalPageCard2 = (props) => {
  return (
    <div className="p-2 w-[23.5rem] justify-center items-center bg-white rounded-2xl p-4">
      <div className="flex flex-col justify-center items-center gap-1 border-[1px]  text-white border-dark-blue rounded-2xl px-8 py-4">
        <div className="w-[20rem] h-[13rem] overflow-hidden border-[1px]  rounded-2xl border-[#000000]">
          <AudioVisualizer
            audioStream={props?.candidateAudio}
            setNoAuido={props?.setNoAuido}
          />
        </div>
        <p className="text-black mt-1  font-satoshi text-[22px]">
          Your audio as captured
        </p>
        {/* <p className="text-black font-[inter] text-[16px] opacity-[50%]">
          Live representation of your voice
        </p> */}
      </div>
    </div>
  );
};
