import {
  AIgif,
  AIImage,
  AIMockGIF,
  AIMockImg,
  inactive,
  send,
  ainonspeaking,
  aispeaking,
  openIcon,
  clevercruit,
  clevercruitlogo,
  alert,
  codeformer,
} from "../../assets/imgs";
import ChatBox from "../../components/chatBox";
import { MessageInput } from "../../components/textArea";
import WebCam from "../WebCam";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { NewCodeEditor } from "../codeEditor";
import { useLocation, useNavigate } from "react-router-dom";
import { getLocal } from "../../utils/localStorage";
import { handleApiResponse } from "../../API/services";
import { Loader } from "../../components/loader";
import { ModalPopup } from "../../components/AdminComponents/modal";
import NoFaceBackground from "./NoFaceBackground";

const SOCKET_BASE_URL = process.env.REACT_APP_SOCKET_BASE_URL;
// const SOCKET_BASE_URL = 'wss://k8back2.docooper.com';

export const Interview = () => {
  let location = useLocation();
  const [messages, setMessages] = useState([]);
  console.log(messages, "messages");
  const [isLogoVisible, setIsLogoVisible] = useState(false);
  const [timerRunning, setTimerRunning] = useState(
    sessionStorage.getItem("timerRunning") === "true"
  );
  const showTimer = messages.some((message, index) => {
    const isLastMessage = index === messages.length - 1;
    return (
      message.text !== "Do you want to proceed to the next question?" &&
      message.text !== "do you want to continue?" &&
      !message.isUser &&
      !message.codeEditor &&
      isLastMessage &&
      timerRunning
    );
  });

  const [isStarted, setStarted] = useState(
    sessionStorage.getItem("isStarted")
      ? sessionStorage.getItem("isStarted")
      : false
  );
  const { isCoding_quest_allow, additionalState } = location.state || {};
  const [interviewTime, setInterviewTime] = useState(
    sessionStorage.getItem("interviewTime")
      ? parseInt(sessionStorage.getItem("interviewTime"), 10)
      : 0
  );
  const [interviewTimeRun, setInterviewTimeRun] = useState(
    sessionStorage.getItem("interviewTimeRun") === "true"
  );
  const [timer, setTimer] = useState(
    sessionStorage.getItem("timer")
      ? parseInt(sessionStorage.getItem("timer"), 10)
      : 90
  );
  const [socket, setSocket] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const [speech, setSpeech] = useState("");

  const [message, setMessage] = useState("");
  const videoRef = useRef(null);
  const job_role =
    sessionStorage.getItem("jobRole") || sessionStorage.getItem("roleName");
  const company = sessionStorage.getItem("organization");
  // const codeWindow = {text: "Please solve this code using the editor.", isUser: false,}
  // const [messages, setMessages] = useState([]);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [first, setFirst] = useState(0);
  const [warningCount, setWarningCount] = useState(
    parseInt(sessionStorage.getItem("wc"), 10) || 0
  );
  const [hasFocus, setHasFocus] = useState(document.hasFocus());
  const [warningShown, setWarningShown] = useState(false);
  const first_name1 = sessionStorage.getItem("candFName");
  const last_name1 = sessionStorage.getItem("candLName");
  const first_name = sessionStorage.getItem("candFName");
  const last_name = sessionStorage.getItem("candLName");
  const lastName = useSelector((state) => state.fillForm?.lastName);
  const job = useSelector((state) => state?.candidateData?.user?.job_profile);
  const [fullScreenCounter, setFullScreenCounter] = useState(0);
  const [idleCounter, setIdleCounter] = useState(0);
  // const [questCounter, setQuestCounter] = useState(0);
  const [reloaded, setReloaded] = useState(false);
  const [inputHeight, setInputHeight] = useState("3.5rem");
  const [showLoader, setShowLoader] = useState(false);
  const [codingQuestions, setCodingQuestions] = useState([]);
  const [openCodeFirstTime, setOpenCodeFirstTime] = useState(true);
  const [showModal, setShowModal] = useState(true);
  const [audioSource, setAudioSource] = useState("");
  const [audio, setAudio] = useState();
  const [pauseAlerts, setPauseAlerts] = useState(false);
  const [questcounter, setQuestcounter] = useState(
    sessionStorage.getItem("questionCounter")
      ? parseInt(sessionStorage.getItem("questionCounter"), 10)
      : 1
  );
  const [loader, setLoader] = useState(
    sessionStorage.getItem("chatroomLoading")
      ? sessionStorage.getItem("chatroomLoading")
      : false
  );
  const [exitquestion, setExitQuestion] = useState(false);
  const [nextQuestion, setNextQuestion] = useState(false);
  const [next, setNext] = useState("");
  const [exit, setExit] = useState(
    sessionStorage.getItem("endInterview")
      ? sessionStorage.getItem("endInterview")
      : ""
  );
  const [face_detect, setFace_detect] = useState(
    sessionStorage.getItem("no_face_detect")
      ? sessionStorage.getItem("no_face_detect")
      : ""
  );
  const [cover, setCover] = useState(
    sessionStorage.getItem("setcoverModel")
      ? sessionStorage.getItem("setcoverModel")
      : false
  );
  // const [face_detect_counter, setFace_detect_counter] = useState(0);
  // sessionStorage.setItem("face_detect_counter", 0);

  const [endTimer, setEndTimer] = useState(
    sessionStorage.getItem("setEndTimer")
      ? parseInt(sessionStorage.getItem("setEndTimer"), 10)
      : 30
  );
  const [isEndTimer, setIsEndTimer] = useState(
    sessionStorage.getItem("setIsEndTimer")
      ? sessionStorage.getItem("setIsEndTimer")
      : false
  );

  const [no_Face_detected, setNo_Face_detected] = useState(
    sessionStorage.getItem("no_face_detect_count")
      ? parseInt(sessionStorage.getItem("no_face_detect_count"), 10)
      : 0
  );
  const warningLimit = 5;
  const [refreshCount, setRefreshCount] = useState(
    sessionStorage.getItem("firstLoadDone")
      ? parseInt(sessionStorage.getItem("firstLoadDone"), 10)
      : 0
  );
  const navigate = useNavigate();
  // let location = useLocation();
  const videoRefElement = useRef(null);
  const [recording, setRecording] = useState(false);
  let item = location?.state?.isCoding_quest_allow;
  let userImg = location?.state?.imgSrc;
  const [endInterviewCounter, setEndInterviewCounter] = useState(
    sessionStorage.getItem("setEndInterviewCounter") === "true"
  );
  const [faceSocket, setFaceSocket] = useState(null);
  const selectChatRef = useRef(null);
  // const locallyNext = localStorage.getItem('showNext');
  // const endTimer1 = sessionStorage.getItem("setIsEndTimer");
  const [handleCodeEditor, setHandleCodeEditor] = useState(false);
  const [count, setCount] = useState(3);

  useEffect(() => {
    let interval;
    if (handleCodeEditor) {
      interval = setInterval(() => {
        setCount((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            if (
              messages.every(
                (message) => message.text !== "Code has been submitted"
              )
            ) {
              sessionStorage.setItem("openEditor", true);
              setOpenEditor(true);
            }
            setTimerRunning(false);
            setIsListening(false);
            setTimer(0);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [handleCodeEditor]);

  useEffect(() => {
    const chatContainer = selectChatRef.current;
    const lastMessage = messages[messages.length - 1];
    if (chatContainer && lastMessage) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    const hasCodeEditorMessage = messages.some((message) => message.codeEditor);
    if (hasCodeEditorMessage) {
      setHandleCodeEditor(true);
    }
    const chatContainer = selectChatRef.current;
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }, [messages, timerRunning]);

  useEffect(() => {
    if (next === "NO") {
      setTimerRunning(true);
      setIsListening(true);
      setLoader(false);
    } else if (next === "YES") {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: "Do you want to proceed to the next question?",
          isUser: false,
        },
      ]);
    } else if (next === "Yes..") {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: "do you want to continue?",
          isUser: false,
        },
      ]);
    } else {
      setNext("");
    }
  }, [next]);
  // console.log('userImg', userImg)
  // const showTimer = messages.some((message, index) => {
  //   const isLastMessage = index === messages.length - 1;
  //   return (
  //     (message.text !== 'Do you want to proceed to the next question?' &&
  //       message.text !== 'do you want to continue?') &&
  //     !message.isUser &&
  //     !message.codeEditor &&
  //     isLastMessage &&
  //     timerRunning
  //   );
  // });
  // const [isStarted, setStarted] = useState(
  //   localStorage.getItem('isStarted')
  //     ? localStorage.getItem('isStarted')
  //     : false
  // );
  // const { isCoding_quest_allow, additionalState } = location.state || {};
  // const [interviewTime, setInterviewTime] = useState(
  //   localStorage.getItem('interviewTime')
  //     ? parseInt(localStorage.getItem('interviewTime'), 10)
  //     : 0
  // );
  // const [interviewTimeRun, setInterviewTimeRun] = useState(
  //   localStorage.getItem('interviewTimeRun') === 'true'
  // );
  // const [timer, setTimer] = useState(
  //   localStorage.getItem('timer')
  //     ? parseInt(localStorage.getItem('timer'), 10)
  //     : 90
  // );
  // const [timerRunning, setTimerRunning] = useState(
  //   localStorage.getItem('timerRunning') === 'true'
  // );
  // const [socket, setSocket] = useState(null);
  // const [isListening, setIsListening] = useState(false);
  // const [speech, setSpeech] = useState('');
  // const [message, setMessage] = useState('');
  // const videoRef = useRef(null);
  // const job_role =
  //   localStorage.getItem('jobRole') || localStorage.getItem('roleName');
  // const company = localStorage.getItem('organization');
  // // const codeWindow = {text: "Please solve this code using the editor.", isUser: false,}
  // // const [messages, setMessages] = useState([]);
  // const [isSpeaking, setIsSpeaking] = useState(false);
  // const [first, setFirst] = useState(0);
  // const [warningCount, setWarningCount] = useState(
  //   localStorage.getItem('wc') ? parseInt(localStorage.getItem('wc'), 10) : 0
  // );

  console.log(warningCount, "warningCount");

  // const [hasFocus, setHasFocus] = useState(document.hasFocus());
  // const [warningShown, setWarningShown] = useState(false);
  // const first_name1 = localStorage.getItem('candFName');
  // const last_name1 = localStorage.getItem('candLName');
  // const first_name = localStorage.getItem('candFName');
  // const last_name = localStorage.getItem('candLName');
  // const lastName = useSelector((state) => state.fillForm?.lastName);
  // const job = useSelector((state) => state?.candidateData?.user?.job_profile);
  // const [fullScreenCounter, setFullScreenCounter] = useState(0);
  // const [idleCounter, setIdleCounter] = useState(0);
  // // const [questCounter, setQuestCounter] = useState(0);
  // const [reloaded, setReloaded] = useState(false);
  // const [inputHeight, setInputHeight] = useState('3.5rem');
  // const [showLoader, setShowLoader] = useState(false);
  // const [codingQuestions, setCodingQuestions] = useState([]);
  // const [openCodeFirstTime, setOpenCodeFirstTime] = useState(true);
  // const [showModal, setShowModal] = useState(true);
  // const [audioSource, setAudioSource] = useState('');
  // const [questcounter, setQuestcounter] = useState(
  //   localStorage.getItem('questionCounter')
  //     ? parseInt(localStorage.getItem('questionCounter'), 10)
  //     : 1
  // );
  // const [loader, setLoader] = useState(
  //   localStorage.getItem('chatroomLoading')
  //     ? localStorage.getItem('chatroomLoading')
  //     : false
  // );
  // const [exitquestion, setExitQuestion] = useState(false);
  // const [nextQuestion, setNextQuestion] = useState(false);
  // const [next, setNext] = useState('');
  // const [exit, setExit] = useState(
  //   localStorage.getItem('endInterview')
  //     ? localStorage.getItem('endInterview')
  //     : ''
  // );
  // const [face_detect, setFace_detect] = useState(
  //   localStorage.getItem("no_face_detect")
  //     ? localStorage.getItem("no_face_detect")
  //     : ""
  // );
  // // const [face_detect_counter, setFace_detect_counter] = useState(0);
  // // localStorage.setItem("face_detect_counter", 0);

  // const [endTimer, setEndTimer] = useState(
  //   localStorage.getItem('setEndTimer')
  //     ? parseInt(localStorage.getItem('setEndTimer'), 10)
  //     : 45
  // );
  // const [isEndTimer, setIsEndTimer] = useState(
  //   localStorage.getItem('setIsEndTimer')
  //     ? localStorage.getItem('setIsEndTimer')
  //     : false
  // );

  // const [no_Face_detected, setNo_Face_detected] = useState(
  //   localStorage.getItem("no_face_detect_count")
  //     ? parseInt(localStorage.getItem("no_face_detect_count"), 10)
  //     : 0
  // );
  // const [audioDuration, setAudioDuration] = useState(null);
  const [coveringCTimer, setCoveringCTimer] = useState(
    sessionStorage.getItem("setcoveringCTimer")
      ? parseInt(sessionStorage.getItem("setcoveringCTimer"), 10)
      : 900
  );

  const [coveringCamera, setCoveringCamera] = useState(
    sessionStorage.getItem("setCoveringCamera")
      ? sessionStorage.getItem("setCoveringCamera")
      : false
  );

  const [avtorVoice, setAvtorVoice] = useState("");
  console.log(avtorVoice, "avtorVoice");

  useEffect(() => {
    let interval;
    if (isEndTimer) {
      interval = setInterval(() => {
        setEndTimer((prevTimer) => {
          const newTime = prevTimer - 1;
          sessionStorage.setItem("setEndTimer", newTime);
          if (newTime <= 0) {
            clearInterval(interval);
          }
          return newTime;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isEndTimer]);

  useEffect(() => {
    let interval;
    if (coveringCamera) {
      interval = setInterval(() => {
        setCoveringCTimer((prevTimer) => {
          const newTime = prevTimer - 1;
          sessionStorage.setItem("setcoveringCTimer", newTime);
          if (newTime <= 0) {
            clearInterval(interval);
          }
          return newTime;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [coveringCamera]);

  useEffect(() => {
    if (exit === "NO") {
      setTimerRunning(false);
      setIsListening(false);
      setIsSpeaking(false);
      setLoader(true);
    }
  }, [exit === "NO"]);

  useEffect(() => {
    sessionStorage.removeItem("newExp");
    sessionStorage.removeItem("newExpMonths");
    sessionStorage.removeItem("file");
  });

  const fname = getLocal("first_name");
  const lname = getLocal("last_name");
  const role = getLocal("job_role");

  const idleTimeAlert = 60;
  const idleTimeEnd = 300;

  const [isFullScreen, setIsFullScreen] = useState(
    window.screen.width === window.screen.availWidth
  );

  const [openEditor, setOpenEditor] = useState(false);

  const [webSocket, setWebSocket] = useState();

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes
      }:${seconds < 10 ? "0" + seconds : seconds}`;
  };

  // useEffect(() => {
  //   // const send = JSON.stringify({ suspicious_count: warningCount });

  //   // const send = JSON.stringify({
  //   //   suspicious_cause: face_detect
  //   //     ? face_detect
  //   //     : "Don't manipulate the window",
  //   // });

  //   // if (faceSocket) {
  //     if (sessionStorage.getItem('causeArray')) {
  //       sessionStorage.setItem(
  //         'causeArray',
  //         JSON.stringify({
  //           suspicious_cause: [
  //             ...JSON.parse(sessionStorage.getItem('causeArray'))
  //               ?.suspicious_cause,
  //             face_detect ? face_detect : "Don't manipulate the window",
  //           ],
  //         })
  //       );
  //     } else {

  //       sessionStorage.setItem(
  //         'causeArray',
  //         JSON.stringify({
  //           suspicious_cause: [
  //             face_detect ? face_detect : "Don't manipulate the window",
  //           ],
  //         })
  //       );
  //     }

  //     const send = sessionStorage.getItem('causeArray');

  //     try {
  //       faceSocket.send(send);
  //       console.log('sending suspicious', send);
  //     } catch (error) {
  //       console.error('Failed to send data via faceSocket', error);
  //     }
  //   // }
  // }, [warningCount]);

  const resetIdleCounter = () => {
    setIdleCounter(0);
  };

  useEffect(() => {
    let timeCounter;
    if (isStarted) {
      timeCounter = setInterval(() => {
        setIdleCounter((prevTimer) => prevTimer + 1);
      }, 1000);
    }

    return () => clearInterval(timeCounter);
  }, [idleCounter, isStarted]);

  // useEffect(() => {
  //   if (idleCounter > idleTimeEnd) {
  //     setTimerRunning(false);
  //     setSocket(null);
  //     console.log("connection closed");
  //     navigate("../certificate", {
  //       state: { timer: formatTime(timer), status: "Terminated" },
  //     });
  //   }
  // }, [idleCounter]);

  const handleConfirm = () => {
    setShowModal(false);
  };

  const openFullscreen = () => {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen().catch((err) => {
        console.error("Failed to enter fullscreen:", err);
      });
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };

  // useEffect(() => {
  //   if (endInterviewCounter) {
  //     endInterview();
  //   }
  // }, [endInterviewCounter]);

  // const endInterview = () => {
  //   setStarted(!isStarted);
  //   setTimerRunning(false);
  //   setIsListening(false);
  //   setInterviewTimeRun(false);
  //   if (socket) {
  //     socket.close();
  //     console.log("Socket connection closed");
  //   }
  //   setSocket(null);
  //   navigate("../certificate", { state: { timer: formatTime(timer) } });
  // };

  useEffect(() => {
    openFullscreen();
  }, []);

  const alertUser = (resposne) => {
    console.log("=========", pauseAlerts, refreshCount, (refreshCount % 4 === 0 && refreshCount > 0))
    if (!pauseAlerts || (refreshCount % 4 === 0 && refreshCount > 0)) {
      console.log("++++++++++++")
      let updatedCount;
      handleSocketCount(resposne);
      if(resposne !== "No face detected")
      setWarningCount((prevCount) => {
        updatedCount = prevCount + 1;
        sessionStorage.setItem("wc", updatedCount);
        //send to faceSocket
        return updatedCount;
      });
      console.log("updatedCount", updatedCount)
      setWarningShown(true);
      sessionStorage.setItem("no_face_detect", "");
      setTimeout(() => {
        setWarningShown(false);
        setFace_detect("");
      }, 3000);
    }
  };

  const alertFromServer = (response) => {
    console.log("alertFromServer")
    handleSocketCount(response);
    setWarningCount((prevCount) => {
      let updatedCount = prevCount + 1;
      sessionStorage.setItem("wc", updatedCount);
      return updatedCount;
    });

    setWarningShown(true);
    setTimeout(() => {
      setWarningShown(false);
      setFace_detect("");
    }, 3000);
  }

  const handleSocketCount = (currentFaceDetect) => {
    if (sessionStorage.getItem("causeArray")) {
      sessionStorage.setItem(
        "causeArray",
        JSON.stringify({
          suspicious_cause: [
            ...JSON.parse(sessionStorage.getItem("causeArray"))
              ?.suspicious_cause,
            currentFaceDetect
              ? currentFaceDetect
              : "Don't manipulate the window",
          ],
        })
      );
    } else {
      sessionStorage.setItem(
        "causeArray",
        JSON.stringify({
          suspicious_cause: [
            currentFaceDetect
              ? currentFaceDetect
              : "Don't manipulate the window",
          ],
        })
      );
    }

    const cause = sessionStorage.getItem("causeArray");

    try {
      console.log("sending suspicious", cause);
      faceSocket.send(cause);
      console.log("sent", cause);
    } catch (error) {
      console.error("Failed to send data via faceSocket", error);
    }
  };

  function windowSize() {
    let permit =
      window.screen.availWidth - window.innerWidth < 35 ? true : false;
    console.log(permit, window.innerWidth, window.screen.availWidth);
    setIsFullScreen(permit);
  }

  useEffect(() => {
    windowSize();
    window.addEventListener("resize", windowSize);
    return () => {
      window.removeEventListener("resize", windowSize);
    };
  }, []);

  useEffect(() => {
    if (!document.fullscreenElement) {
      openFullscreen();
    }
    if (isFullScreen && fullScreenCounter > 1) {
      console.log("this insided full", fullScreenCounter);
      if (socket && recording) {
        if (
          sessionStorage.getItem("isNext") ||
          sessionStorage.getItem("isEnd")
        ) {
          setTimerRunning(false);
        } else {
          setTimerRunning(true);
        }
        setInterviewTimeRun(true);
        alertUser();
      }
      console.log("due to window resize");
    }

    if (!isFullScreen) {
      setTimerRunning(false);
      setInterviewTimeRun(false);
      setIsListening(false);
      setIsSpeaking(false);
      if (audio) {
        audio.pause();
        setIsListening(false);
        console.log("stop audio");
      }
    }

    setFullScreenCounter((prev) => prev + 1);
  }, [isFullScreen]);

  useEffect(() => {
    // console.log(message);
    if (speech?.length > 0 && !openEditor) {
      if (speech.trim() !== "") {
        // if (nextQuestion || exitquestion) {
        //   setMessage(speech);
        // } else {
        const newMess = message + " " + speech;
        setMessage(newMess);
        // }
        setSpeech("");
        resetIdleCounter(0);
      }
    }
  }, [speech, setSpeech]);

  // useEffect(()=>{
  //   if(reloaded){
  //     endInterview();
  //   }
  // },[reloaded])

  useEffect(() => {
    if (sessionStorage.getItem("isStarted")) {
      setOpenEditor(Boolean(sessionStorage.getItem("openEditor")));
      const newRefreshCount = parseInt(refreshCount) + 1;
      sessionStorage.setItem("firstLoadDone", newRefreshCount);
      if (!sessionStorage.getItem("isNext")) {
        setTimerRunning(true);
      }
      handleSocket();
      handleFaceDetect();
      if (face_detect !== "No face detected") {
        setRefreshCount(newRefreshCount);
      }
    }
    return () => {
      sessionStorage.removeItem("firstLoadDone");
    };
  }, []);

  useEffect(() => {
    if (refreshCount % 4 === 0 && refreshCount > 0) {
      if (face_detect !== "No face detected") {
        alertUser();
      }
      setRefreshCount(1);
      sessionStorage.setItem("firstLoadDone", 1);
    }
  }, [refreshCount]);

  useEffect(() => {
    console.log("document hidden insider useffect", document.hidden)

    const handleVisibilityChange = () => {
      let startState = Boolean(sessionStorage.getItem("isStarted"))
      //if document.hidden is true means user switch or minimize current page
      console.log("document.......hidden insider", startState, document.hidden)

      if (startState && document.hidden == false) {
        console.log("document.hidden", document.hidden)
        alertFromServer()
      }

    };

    const handleBlur = () => {
      console.log("Window lost focus");
      if (audio) {
        audio.pause();
        console.log("stop audio");
      }
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
      setIsListening(false);
      setHasFocus(false);
    };

    // window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    console.log()

    return () => {
      // window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
      document.removeEventListener("visibilitychange", handleVisibilityChange)

    };
  }, [hasFocus, warningShown]);

  const decreaseCredit = async () => {
    const reqData = {
      candidate_uid: campType === "Regular" ? uid : uid1,
    };

  
    const response = await handleApiResponse(
      "/api/v1/candidate_credit_count/",
      reqData
    );
    
    if (response?.code === "token_not_valid") {
      navigate("/");
      sessionStorage.clear();
      return;
    }
    console.log(response);
  };

  const uid = getLocal("user");
  const campType = sessionStorage.getItem("campType");
  const [loader1, setLoader1] = useState(false);
  const uid1 = sessionStorage.getItem("CandidateUid");

  useEffect(() => {
    if (!audioSource) {
      console.error("No audio source provided.");
      return;
    }

    const newAudio = new Audio(audioSource);
    setAudio(newAudio);

    const handlePause = (event) => {
      setIsSpeaking(false);
      if (openEditor || nextQuestion) {
        setIsListening(false);
        setTimerRunning(false);
        setTimer(0);
      } else if (questcounter === 15) {
        setIsListening(false);
        setTimerRunning(false);
        setTimer(0);
        setLoader(true);
      } else {
        if (isFullScreen) {
          setIsListening(true);
          setTimerRunning(true);
          setTimer((prev) => {
            if (prev !== 180) {
              return 90;
            } else {
              return 180;
            }
          });
        }
        // console.log("timer after pause", timer);
      }
    };

    const handlePlay = (event) => {
      if (nextQuestion) {
        setIsSpeaking(false);
      } else {
        setIsSpeaking(true);
        setIsListening(false);
        setTimerRunning(false);
      }
    };

    newAudio.addEventListener("pause", handlePause);
    newAudio.addEventListener("play", handlePlay);

    newAudio.play().catch((error) => {
      console.error("Error playing audio:", error);
    });

    return () => {
      newAudio.removeEventListener("pause", handlePause);
      newAudio.removeEventListener("play", handlePlay);
    };
  }, [audioSource]);

  const endInterview = async () => {
    setLoader1(true);
    let networkError = sessionStorage.getItem("networkError");
    // await handleVideoSubmit();
    if (!loader1) {
      if (
        warningCount >= "5" ||
        no_Face_detected >= "3" ||
        exit === "NO" ||
        endInterviewCounter ||
        isEndTimer ||
        endTimer <= 1 ||
        coveringCTimer <= 1 ||
        networkError
      ) {
        if (socket) {
          socket.close();
          console.log("Socket connection closed");
        }
        if (faceSocket) {
          faceSocket.send(sessionStorage.getItem("causeArray"));
          console.log(" face Socket connection closed");
          faceSocket.close();
        }
        setAudioSource("");
        if (audio) {
          audio.pause();
        }
        setIsSpeaking(false);
        setTimerRunning(false);
        setInterviewTimeRun(false);
        setSocket(null);
        setFaceSocket(null);
        window.speechSynthesis.cancel();
        sessionStorage.removeItem("hasStartedRecording");
        const initialInterviewTime = 0;
        const initialTimer = 90;
        setInterviewTime(initialInterviewTime);
        setTimer(initialTimer);
        setTimerRunning(false);
        setInterviewTimeRun(false);
        sessionStorage.setItem("interviewTime", initialInterviewTime);
        sessionStorage.setItem("timer", initialTimer);
        sessionStorage.removeItem("showNext");
        sessionStorage.removeItem("endInterview");
        sessionStorage.removeItem("lastEndInterview");
        sessionStorage.removeItem("chatroomLoading");
        sessionStorage.removeItem("questionCounter");
        sessionStorage.removeItem("setIsEndTimer");
        sessionStorage.removeItem("setCoveringCamera");
        sessionStorage.removeItem("setcoveringCTimer");
        sessionStorage.removeItem("setcoverModel");
        const send = sessionStorage.getItem("causeArray");
        const data = {
          candidate_uid: campType === "Regular" ? uid : uid1,
          suspicious_cause: send,
        };
        const response = await handleApiResponse(
          "/api/v1/interview/start_analytics_generations/",
          data
        );
        console.log(response, "response data");
        setTimeout(() => {
          setLoader1(false);
          navigate("../certificate", { state: { timer: formatTime(timer) } });
        }, 2000);
      }
    }
  };

  const handleSocket = () => {

    let firstLoadDone=sessionStorage.getItem("firstLoadDone");

    console.log("is_startedis_started",firstLoadDone)

    setShowLoader(true);
    const ws = new WebSocket(
      `${SOCKET_BASE_URL}/ws/interview/${campType === "Regular" ? uid : uid1}/`
    );

    if (ws) {
      ws.onopen = () => {
        setSocket(ws);

        if(firstLoadDone==="1"){
          decreaseCredit();
        }
        
        setShowLoader(false);
        console.log("decreaseCredit websocket ")

      };

      ws.onmessage = (event) => {
        const res = JSON.parse(event.data);
        console.log(res, "response time");
        if (res?.avatar?.video_base64) {
          const base64Data =
            res.avatar.video_base64.split(",")[1] || res.avatar.video_base64;

          try {
            const byteCharacters = atob(base64Data);
            const byteArray = new Uint8Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
              byteArray[i] = byteCharacters.charCodeAt(i);
            }

            const videoBlob = new Blob([byteArray], { type: "video/mp4" });
            const videoUrl = URL.createObjectURL(videoBlob);
            setAvtorVoice(videoUrl);
          } catch (error) {
            console.error("Error decoding Base64 video data:", error);
          }
        }
        if (res?.speech) {
          setLoader(false);
          const binaryString = window.atob(res?.speech);
          const bytes = new Uint8Array(binaryString.length);
          for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }
          const blob = new Blob([bytes.buffer], { type: "audio/mpeg" });
          const audioUrl = URL.createObjectURL(blob);
          setAudioSource(audioUrl);
          setQuestcounter(res?.counter);
          if (res?.codeQuest) {
            setIsListening(false);
          }
        } else {
          if (res?.isEND) {
            setExitQuestion(res?.isEND);
            // setIsSpeaking(false);
            setIsListening(false);
            sessionStorage.setItem("isEnd", res?.isEND);
          } else if (res?.isNext) {
            setNextQuestion(res?.isNext);
            setIsSpeaking(false);
            setIsListening(false);
            sessionStorage.setItem("isNext", res?.isNext);
          }
        }

        if (res?.counter === 15) {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              text: res.text,
              isUser: false,
            },
          ]);
          sessionStorage.setItem("lastEndInterview", true);
          sessionStorage.setItem("chatroomLoading", true);
          sessionStorage.setItem("questionCounter", res?.counter);
          setIsEndTimer(true);
          sessionStorage.setItem("setIsEndTimer", true);
          sessionStorage.setItem("setEndInterviewCounter", true);
        } else {
          // if (res?.isEND || res?.isNext) {
          //   if (!sessionStorage.getItem("isNext")) {
          //     setMessages((prevMessages) => [
          //       ...prevMessages,
          //       {
          //         text: res?.isNext
          //           ? "Do you want to proceed to the next question?"
          //           : "Do you want to continue?",
          //         isUser: false,
          //       },
          //     ]);
          //   }
          // }
          //  else {
          if (typeof res.text === "string" || res?.codeQuest) {
            if (res.Is_essay) {
              console.log("setting 180 as the timer");
              setTimer(180);
            }
            setMessages((prevMessages) => [
              ...prevMessages,
              res?.codeQuest
                ? {
                  text: "Open the code editor to answer the coding questions",
                  isUser: false,
                  codeEditor: true,
                }
                : {
                  text: res.text,
                  isUser: false,
                },
            ]);
          } else {
            if (Array.isArray(res?.text)) {
              if (
                sessionStorage.getItem("isNext") ||
                sessionStorage.getItem("isEnd")
              ) {
                let data = res.text.slice(0, -1);
                data.sort((a, b) => a.id - b.id);
                data.forEach((ele, index) => {
                  if (index)
                    if (!ele.msg.includes("Coding_quest")) {
                      setOpenEditor(false);
                      console.log(ele.msg);
                      setMessages((prevMessages) => [
                        ...prevMessages,
                        {
                          text: ele.msg,
                          isUser: !ele.is_ai,
                        },
                      ]);
                    }
                });
              } else {
                const filteredText = res.text.sort((a, b) => a.id - b.id);
                filteredText.forEach((ele) => {
                  if (!ele.msg.includes("Coding_quest")) {
                    setMessages((prevMessages) => [
                      ...prevMessages,
                      {
                        text: ele.msg,
                        isUser: !ele.is_ai,
                      },
                    ]);
                  }
                });
              }
            }
          }
          // }
          if (res?.codeQuest) {
            setCodingQuestions(() => {
              return [...res.text];
            });
            setIsListening(false);
          } else {
            if (res.speech) {
              setIsSpeaking(true);
              setIsListening(false);
            } else {
              setIsSpeaking(false);
              setIsListening(false);
            }
          }
        }
      };
    }
  };

  const handleFaceDetect = (sendFrameCallback) => {
    const ws = new WebSocket(
      `${SOCKET_BASE_URL}/ws/face-detection/${campType === "Regular" ? uid : uid1
      }/`
    );
    ws.onopen = () => {
      setFaceSocket(ws);
      // let causeArray = JSON.parse(sessionStorage.getItem("causeArray"));
      // let send = causeArray?.suspicious_cause;
      // console.log("faceSocket", send);
      // if (send?.length > 0) {
      //   ws.send(sessionStorage.getItem("causeArray"));
      // }
      if (sendFrameCallback) {
        sendFrameCallback();
      }
    };

    ws.onmessage = (event) => {
      const res = JSON.parse(event.data);
      if (res.face_detect) {
        switch (res.face_detect[0]) {
          // case 1:
          //   setNo_Face_detected(1);
          //   console.log("got 1");More than one face detected!
          //   break;
          case "More than one face detected!":
            console.log("Multi face");
            setFace_detect("Multiple face detected");
            // alertUser("Multiple face detected");
            alertFromServer("Multiple face detected");

            break;
          case "No face detected!":
            // console.log("No face");
            sessionStorage.setItem("no_face_detect", "No face detected");
            sessionStorage.setItem(
              "no_face_detect_count",
              no_Face_detected + 1
            );
            setFace_detect("No face detected");
            setNo_Face_detected(no_Face_detected + 1);
            alertFromServer("No face detected");
            // alertUser();
            break;
          case "Candidate not looking forward!":
            console.log("Not looking forward");
            setFace_detect("Candidate not looking forward");
            // alertUser("Candidate not looking forward");
            alertFromServer("Candidate not looking forward");
            break;
          case "Looking away from the screen!":
            console.log("Looking away");
            setFace_detect("Candidate looking away");
            // alertUser("Candidate looking away");
            alertFromServer("Candidate looking away");
            break;
        }
      }
    };

    ws.onclose = () => {
      console.log("WebSocket2 connection closed.");
    };

    ws.onerror = (error) => {
      console.error("WebSocket2 error:", error);
    };

    return ws;
  };

  useEffect(() => {
    if (face_detect === "No face detected") {
      setTimerRunning(false);
      setInterviewTimeRun(false);
      setIsListening(false);
      setIsSpeaking(false);
      setCover(true);
      sessionStorage.setItem("setcoverModel", true);
      setAudioSource("");
      sessionStorage.setItem("setCoveringCamera", true);
      setCoveringCamera(true);
      if (audio) {
        audio.pause();
      }
      if (no_Face_detected == "3") {
        alertUser("No face detected");
      }
    }
  }, [face_detect]);

  const handleResumeInterview = () => {
    if (sessionStorage.getItem("isNext") || sessionStorage.getItem("isEnd")) {
      setTimerRunning(false);
    } else {
      setTimerRunning(true);
    }
    setInterviewTimeRun(true);
    handleFaceDetect();
    alertUser("No face detected");
    setCover(false);
    sessionStorage.removeItem("setcoverModel");
    sessionStorage.setItem("setCoveringCamera", false);
    setCoveringCamera(false);
    sessionStorage.setItem("setcoveringCTimer", 900);
  };

  return (
    <div
      id="componentToCapture"
      className="relative text-text-color-code1 flex fill-available gap-3 p-[2vh] h-[100vh]"
      onKeyDown={resetIdleCounter}
      onMouseDown={resetIdleCounter}
      onMouseMove={resetIdleCounter}
      onClick={openFullscreen}
    >
      {/* {isLogoVisible && (
        <div className="absolute z-[10000000] h-screen w-screen bg-black opacity-80 flex justify-center items-center backdrop-blur">
          <img
            src={clevercruitlogo}
            alt="Clevercruit Logo"
            className="w-[906px] h-[140px]"
          />
        </div>
      )} */}
      {!isFullScreen ? (
        <div className="z-10 bg-black absolute top-0 left-0 w-full h-full flex flex-col gap-2 justify-center items-center">
          <h1>Not Allowed. Go to Full Screen mode.</h1>
          <button onClick={openFullscreen}>Unlock</button>
          {isListening ? setIsListening(false) : ''}
          {timerRunning ? setTimerRunning(false) : ''}
        </div>
      ) : null}
      {/* {showModal && (
        <ModalPopup
          message="You need to share your entire screen for proceeding with the interview."
          onConfirm={handleConfirm}
        />
      )} */}
      {loader1 && <Loader />}
      {/* {showNoFaceDetectedPopUp (
        <div className="z-[12] bg-fill-color-code1 absolute top-0 left-0 w-full h-full flex flex-col gap-4 justify-center items-center">
          <article className="p-8 bg-fill-btn-color-code1 border-2 rounded-3xl w-[48rem] h-[32rem] flex justify-center items-center flex flex-col gap-4">
            <h1 className="text-[3rem] text-text-color-code1 font-semibold ">
              Where'd you go? We can't see you. To resume the interview uncover you camera or be in a well lit area.
            </h1>
            <p>
              It's so silent over there. Drag your mouse to activate the screen
            </p>
            <img
              src={inactive}
              alt="inActive"
              className="w-[32rem] h-[20rem]"
            />
          </article>
        </div>
      ) : null} */}
      {/* {idleCounter > idleTimeAlert ? (
        <div className="z-[12] bg-fill-color-code1 absolute top-0 left-0 w-full h-full flex flex-col gap-4 justify-center items-center">
          <article className="p-8 bg-fill-btn-color-code1 border-2 rounded-3xl w-[48rem] h-[32rem] flex justify-center items-center flex flex-col gap-4">
            <h1 className="text-[3rem] text-text-color-code1 font-semibold ">
              Where'd you go?
            </h1>
            <p>
              It's so silent over there. Drag your mouse to activate the screen
            </p>
            <img
              src={inactive}
              alt="inActive"
              className="w-[32rem] h-[20rem]"
            />
          </article>
        </div>
      ) : null} */}
      {openEditor && (
        <NewCodeEditor
          setOpenCodeFirstTime={setOpenCodeFirstTime}
          messages={codingQuestions}
          setOpenEditor={setOpenEditor}
          setMessages={setMessages}
          socket={socket}
          timer={timer}
          setLoader={setLoader}
          setIsSpeaking={setIsSpeaking}
        />
      )}
      {warningCount <= '5' && warningShown && !cover ? (
        <div className="z-[9999] bg-[#232638] bg-opacity-85 backdrop-blur-lg absolute top-0 left-0 w-full h-full flex flex-col gap-4 justify-center items-center">
          <article className="p-8 bg-[#00112d] border-[10px] border-[#22284e] rounded-3xl h-[534px] flex justify-center items-center flex-col gap-2">
            <img src={alert} alt="no img" className="w-[203px] h-[203px]" />
            <h5 className="italic text-[52px] text-white font-semibold ">
              {warningCount}/{warningLimit}
            </h5>
            <h5 className="italic text-[52px] text-[#0197FE] font-semibold ">
              Suspicious activity detected!
            </h5>
            <p className="text-[#EEEFF3] text-[17.8px]">
              Please follow all the guidelines accepted earlier.
            </p>
          </article>
        </div>
      ) : cover ? (
        <NoFaceBackground
          noFaceCount={no_Face_detected}
          resumeInterview={handleResumeInterview}
        />
      ) : null}

      <div className="flex flex-col gap-1 w-[94%] ">
        <WebCam
          setIsLogoVisible={setIsLogoVisible}
          isLogoVisible={isLogoVisible}
          setAudio={setAudio}
          nextQuestion={nextQuestion}
          isFullScreen={isFullScreen}
          showTimer={showTimer}
          setPauseAlerts={setPauseAlerts}
          no_Face_detected={no_Face_detected}
          loader1={loader1}
          setMessages={setMessages}
          setLoader1={setLoader1}
          isListening={isListening}
          warningLimit={warningLimit}
          warningCount={warningCount}
          setIsListening={setIsListening}
          setSpeech={setSpeech}
          timerRunning={timerRunning}
          setTimerRunning={setTimerRunning}
          setSocket={setSocket}
          timer={timer}
          setTimer={setTimer}
          isSpeaking={isSpeaking}
          setIsSpeaking={setIsSpeaking}
          socket={socket}
          endInterviewCounter={endInterviewCounter}
          setEndInterviewCounter={setEndInterviewCounter}
          resetIdleCounter={resetIdleCounter}
          setStarted={setStarted}
          isStarted={isStarted}
          setFirst={setFirst}
          handleSocket={handleSocket}
          interviewTime={interviewTime}
          setInterviewTime={setInterviewTime}
          interviewTimeRun={interviewTimeRun}
          setInterviewTimeRun={setInterviewTimeRun}
          openEditor={openEditor}
          videoRefElement={videoRefElement}
          setLoader={setLoader}
          loader={loader}
          audioSource={audioSource}
          exitquestion={exitquestion}
          questcounter={questcounter}
          exit={exit}
          setExit={setExit}
          first_name={first_name}
          first_name1={first_name1}
          last_name={last_name}
          last_name1={last_name1}
          recording={recording}
          setRecording={setRecording}
          face_detect={face_detect}
          handleFaceDetect={handleFaceDetect}
          setFaceSocket={setFaceSocket}
          faceSocket={faceSocket}
          setExitQuestion={setExitQuestion}
          setNext={setNext}
          endTimer={endTimer}
          isEndTimer={isEndTimer}
          setAudioSource={setAudioSource}
          audio={audio}
          coveringCTimer={coveringCTimer}
          setCover={setCover}
          openFullscreen={openFullscreen}
        />
      </div>
      <div className="flex flex-col gap-3 w-[40%]  relative">
        {isSpeaking ? (
          <img
            src={codeformer}
            alt="interviewer gif"
            className="w-[28.889vw] h-[47.266vh] rounded-[16px]"
            onLoad={resetIdleCounter}
            autoPlay
            loop
            muted // Optional: use if you want the video to be muted
            style={{ maxWidth: '200%' }}
          />
        ) : (
          // <div className=" bg-white w-full h-[38vh] rounded-[16px] overflow-hidden">
          //   <video
          //     loop
          //     muted
          //     autoPlay
          //     className="w-full h-full object-cover object-center"
          //   >
          //     <source src={avtorVoice} type="video/mp4" />
          //   </video>
          // </div>
          <img
            src={ainonspeaking}
            alt="interviewer photo"
            className=" w-[28.889vw] h-[47.266vh] rounded-[16px]"
            onLoad={resetIdleCounter}
            style={{ maxWidth: '200%' }}
          />
        )}
        <div className=" w-[28.889vw] border-[0.07rem] bg-fill-color-code1 flex flex-col relative rounded-[16px]">
          <div className="bg-white h-[46vh] rounded-[16px] w-[28.889vw]">
            <div className="relative w-full h-full">
              <div
                ref={selectChatRef}
                className="h-full py-2 overflow-y-auto select-none"
                style={{ borderRadius: '12px' }}
              >
                {messages?.map((message, index) => {
                  const isLastMessage = index === messages.length - 1;
                  const isSecondLastMessage = index === messages?.length - 2;
                  return (
                    <div
                      key={index}
                      className={`relative flex justify-${message.isUser
                        ? 'end bg-[#F1F1F180]  text-[#072872] pr-2'
                        : 'start mr-[3rem] ml-2'
                        } mb-2`}
                    >
                      {(message.text ===
                        'Do you want to proceed to the next question?' &&
                        isLastMessage) ||
                        (message.text === 'do you want to continue?' &&
                          isLastMessage &&
                          exit !== 'NO') ||
                        message?.text === '' ? (
                        ''
                      ) : (
                        <img
                          src={ainonspeaking}
                          alt="AI Image"
                          className={`w-8 h-8 mr-2 object-cover rounded-full ${message.isUser ? 'hidden' : 'block'
                            }`}
                        />
                      )}
                     {message?.text !== "" && (
  <span
    className={`flex flex-row-reverse text-sm gap-1 text-black ${message.isUser
      ? 'items-end text-[#072872]'
      : 'items-start'
      }`}
  >
    {message.isUser ? (
      <div className="flex">
        {/* <p className="mr-2">(You)</p> */}
        <img
          src={userImg}
          alt="user Image"
          className="w-[32px] h-[32px] object-cover rounded-full"
        />
      </div>
    ) : (
      <>
        {(message.text ===
          'Do you want to proceed to the next question?' &&
          isLastMessage) ||
          (message.text === 'do you want to continue?' &&
            isLastMessage &&
            exit !== 'NO') ||
          message?.text === '' ? (
          ''
        ) : (
          <p></p>
        )}
      </>
    )}
    <div
      className={`
        ${message.isUser &&
          !message.nextId &&
          message?.text !== ''
          ? 'overflow-auto'
          : message?.text !== '' && 'bg-[#DDECFB]'
        }
       p-2 text-left rounded-lg`}
    >
      <span
        className={`whitespace-pre-line ${message?.text === 'YES' ||
          message?.text === 'no_response'
          ? 'text-[#DD2023] font-poppins italic' // Change color to #DD2023 and apply Poppins font when candidate skips the question
          : message.text !== '' && message?.isUser
            ? 'text-[#072872] font-poppins italic' // Apply Poppins font and different color for user messages
            : 'text-[#072872] font-inter' // Default color for other messages
          }`}
      >
        {
          message?.text === 'YES' ||
            message?.text === 'no_response'
            ? 'Candidate skipped this question!' // Show this message if skipped
            : exit === 'NO' && isSecondLastMessage
              ? '... candidate’s response noted' // Timer message if next is NO and it's the second last message
              : (message.text ===
                'Do you want to proceed to the next question?' &&
                isLastMessage) ||
                (message.text ===
                  'do you want to continue?' &&
                  isLastMessage)
                ? '' // No message if these conditions are true
                : message.isUser
                  ? '... candidate’s response noted' // Response message for user
                  : message.text // Default message text
        }
      </span>

      <br />

      {message?.codeEditor && (
        <span
          onClick={() =>
            setOpenEditor(
              index !== messages.length - 1 ? false : true
            )
          }
          className="text-black"
        >
          Note: You will be given 30 min to answer the
          coding questions. <br />
        </span>
      )}
      {message?.codeEditor && (
        <span
          onClick={() =>
            setOpenEditor(
              index !== messages.length - 1 ? false : true
            )
          }
          className="bg-[#072872] text-white flex gap-2 italic cursor-pointer absolute bottom-[-2rem] p-2 rounded-b-2xl right-0 mb-2"
        >
          <img src={openIcon} alt="" />
          Open Editor
        </span>
      )}
    </div>
  </span>
)}

                    </div>
                  );
                })}
                {sessionStorage.getItem('isNext') && (
                  <div className=" p-2 rounded-lg fill-available flex flex-col justify-center items-center bg-[#F8F8F8]">
                    <p className="mb-2 w-[17vw] font-poppins text-[#072872] italic text-center">
                      Do you want to proceed to the next question ?
                    </p>
                    <div className="flex justify-center">
                      <button
                        onClick={() => {
                          setNext('NO');
                          setNextQuestion(false);
                          sessionStorage.removeItem('isNext');
                          sessionStorage.setItem('showNext', 'NO');
                          const newMess = {
                            text: 'Please reply to the above question',
                            isUser: false,
                          };
                          setMessages((prevMessages) => [
                            ...prevMessages,
                            newMess,
                          ]);
                        }}
                        className="border-[0.8px] rounded-[0.88px] border-[#072872] w-[7.569vw] font-satoshi font-[500] text-[12px] text-[#072872] p-2 ps-4 pe-4 rounded mr-2"
                      >
                        No
                      </button>
                      <button
                        onClick={() => {
                          setSpeech('YES');
                          setNext('YES');
                          setNextQuestion(false);
                          sessionStorage.removeItem('isNext');
                        }}
                        className="w-[7.569vw] font-satoshi rounded-[0.88px] font-[500] text-[12px] bg-[#072872] text-white p-2 ps-4 pe-4 rounded"
                        style={{
                          border: '0.33px solid',
                          borderImageSource:
                            'linear-gradient(178.6deg, #FFFFFF 13.55%, rgba(0, 0, 0, 0.15) 83.77%)',
                          borderImageSlice: 1,
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                )}
                {sessionStorage.getItem('isEnd') && (
                  <div className=" p-2 rounded-lg fill-available flex flex-col justify-center items-center bg-[#F8F8F8]">
                  <p className="mb-2 w-[17vw] font-poppins text-[#072872] italic text-center">
                  Do you want to continue ?
                  </p>
                  <div className="flex justify-center">
                    <button
                      onClick={() => {
                        setExit('NO');
                        setNext('NO');
                        setExitQuestion(false);
                        sessionStorage.removeItem('isEnd');
                        sessionStorage.setItem('endInterview', 'NO');
                      }}
                      className="border-[0.8px] rounded-[0.88px] border-[#072872] w-[7.569vw] font-satoshi font-[500] text-[12px] text-[#072872] p-2 ps-4 pe-4 rounded mr-2"
                    >
                      No
                    </button>
                    <button
                      onClick={() => {
                        setSpeech('Yes..');
                        setNext('Yes..');
                        // setExit("Yes..");
                        setExitQuestion(false);
                        sessionStorage.removeItem('isEnd');
                      }}
                      className="w-[7.569vw] font-satoshi rounded-[0.88px] font-[500] text-[12px] bg-[#072872] text-white p-2 ps-4 pe-4 rounded"
                      style={{
                        border: '0.33px solid',
                        borderImageSource:
                          'linear-gradient(178.6deg, #FFFFFF 13.55%, rgba(0, 0, 0, 0.15) 83.77%)',
                        borderImageSlice: 1,
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
                )}
              </div>
              {/* {endTimer1 !== 'true' &&
        locallyNext !== 'NO' &&
        showTimer &&
        formatTime(timer) && (
          <div className="absolute bottom-2 text-sm text-[#FF0000] bg-[#F1F1F1] border-2 p-2 px-4 rounded-2xl left-1/2 transform -translate-x-1/2">
            {formatTime(timer)}
          </div>
        )} */}
            </div>
          </div>

          <MessageInput
            faceSocket={faceSocket}
            sendImg={send}
            setMessages={setMessages}
            messages={messages}
            message={message}
            openEditor={openEditor}
            setMessage={setMessage}
            setIsListening={setIsListening}
            timerRunning={timerRunning}
            socket={socket}
            setSocket={setSocket}
            timer={timer}
            setTimer={setTimer}
            setTimerRunning={setTimerRunning}
            setIsSpeaking={setIsSpeaking}
            isSpeaking={isSpeaking}
            setEndInterviewCounter={setEndInterviewCounter}
            first={first}
            inputHeight={inputHeight}
            setInputHeight={setInputHeight}
            loader={loader}
            setLoader={setLoader}
            questcounter={questcounter}
            warningCount={warningCount}
            exit={exit}
            setNext={setNext}
            next={next}
          />
        </div>
      </div>
    </div>
  );
};
